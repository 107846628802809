import React, { Component } from 'react';
import Skeleton, { SkeletonTheme }  from 'react-loading-skeleton';
import { lightTheme } from './../theme';
import configData from "../../config.json";

class LoadingDotsComponent extends Component {
    state = {  }
    shouldComponentUpdate(nextProps, nextState) {    
      if(this.props.theme != nextProps.theme ||
        this.props.is_pornstar_or_channel != nextProps.is_pornstar_or_channel)
        return true
      else
        return false
    }
  
    render() {    
        return (
        <React.Fragment>
          {this.props.onlytext != 1 ? 
          [...Array(this.props.loop)].map((x, i) =>
            <div 
            className="videowrapper" key={i} 
            style={{marginBottom: this.props.is_pornstar_or_channel == 1 ? "21px" : "2px", 
              //margin: this.props.is_pornstar_or_channel == 1 ? "20px 0px 0px 10px" : "",
                marginTop: this.props.is_pornstar_or_channel == 1 ? "20px" : "18px"
              
              }}
            >
              {/* style={{marginLeft: "20px"}} */}
                <div
                    id={"video_preview_div"}        
                    style={{ display: "inline" }}
                    className="video_image"
                    
                    //onMouseEnter ={() => this.showVideo(id)}
                    //onMouseOut={() => this.hideVideo(id)}
                    // onMouseLeave={() => this.hideVideo(id)} #3C3C45 #EBEBEB
                  >
                     <SkeletonTheme 
                     color={this.props.theme == lightTheme ? 
                        configData.lightTheme.color : configData.darkTheme.color }
                     
                     highlightColor={this.props.theme == lightTheme ? 
                        configData.lightTheme.highlightColor : configData.darkTheme.highlightColor }>
                      <Skeleton          
                        className="img_class" ></Skeleton>
                        <br></br>
                       
              
                  <div 
                    className="videotitle" style={{marginTop: "11px"}}>
                      <Skeleton variant="text"  height={23}></Skeleton>
                      
                      {/* <b style={{display: this.props.is_pornstar_or_channel == 1 ? "block" : "block", }}> */}
                      <br></br>
                        {this.props.is_pornstar_or_channel != 1 ? 
                        
                        <Skeleton variant="text" height={17}></Skeleton>
                        : "" }
                        {/* </b> */}
                       {/* : "" }  */}
                  </div>
                  </SkeletonTheme>
                  <br></br>

                
        
                </div>
                
              </div>
              
              )
              : 
              [...Array(this.props.loop*6)].map((x, i) =>
              <div
                className="tagswrapper"
                  key={i}
                  style={{
                    // border: "1px solid green",
                    // width: "100%",
                    display: this.props.hide == 0 ? "block" : "block",
                    height: "35px",
                    position:"relative",
                    float:"left"
                  }}

                >

                  <div style={{width: '500px', height: '1px', backgroundColor: "pink"}}></div>
                  {this.props.onlytext != 1 ?
                  <Skeleton variant="text" animation="wave" width={"60%"}
                  
                  />
                  : 
                  <SkeletonTheme 
                     color={this.props.theme ? 
                        configData.lightTheme.color : configData.darkTheme.color }
                    //color= {this.props.theme  ? 'red' : 'green'} 
                     
                     highlightColor={this.props.theme ? 
                        configData.lightTheme.highlightColor : configData.darkTheme.highlightColor }>
                      <Skeleton variant="text" animation="wave" width={"240px"} height="21px"
                        //color={this.props.theme == lightTheme ? "gray" : "black" }                      
                        //highlightColor={this.props.theme == lightTheme ? "#EBEBEB" : "#3C3C45" }
                        
                      />
                  </SkeletonTheme>
                    }
                 </div>
              )
              }  
            {this.props.onlytext != 1 ? 
             Array.apply(0, Array(this.props.loop)).map(function (x, i) {
              
              
              
                <div 
                className="videowrapper" >
                <div
                    id={"video_preview_div"}        
                    style={{ display: "inline" }}
                    className="video_image"
                    
                    //onMouseEnter ={() => this.showVideo(id)}
                    //onMouseOut={() => this.hideVideo(id)}
                    // onMouseLeave={() => this.hideVideo(id)}
                  >
                    <Skeleton          
                      className="img_class" ></Skeleton>
                      <br></br>
                      <br></br>
                    <div 
                      className="videotitle">
                        <Skeleton variant="text" height={30}></Skeleton>
                        {/* <h4>{title}</h4> */}
                        <br></br>
                        <Skeleton variant="text" height={10}></Skeleton>
                  </div>
                  <br></br>
                  
                                
                      
                    </div>
                  </div>
            
            })
          : "" }
        </React.Fragment>
          );
    }
}
 
export default LoadingDotsComponent;