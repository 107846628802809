import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import React  from "react";

export const weights = ["4", "6", "8","10", "12", "14", "16"];

export const flags = [
    { country: "Afghanistan", url: "af"},
    { country: "Albania", url: "al"},
    { country: "Algeria", url: "dz"},
    { country: "American Samoa", url: "as"},
    { country: "Andorra", url: "ad"},
    { country: "Angola", url: "ao"},
    { country: "Anguilla", url: "ai"},
    { country: "Antarctica", url: "aq"},
    { country: "Antigua And Barbuda", url: "ag"},
    { country: "Argentina", url: "ar"},
    { country: "Armenia", url: "am"},
    { country: "Aruba", url: "aw"},
    { country: "Australia", url: "au"},
    { country: "Austria", url: "at"},
    { country: "Azerbaijan", url: "az"},
    { country: "Bahamas", url: "bs"},
    { country: "Bahrain", url: "bh"},
    { country: "Bangladesh", url: "bd"},
    { country: "Barbados", url: "bb"},
    { country: "Belarus", url: "by"},
    { country: "Belgium", url: "be"},
    { country: "Belize", url: "bz"},
    { country: "Benin", url: "bj"},
    { country: "Bermuda", url: "bm"},
    { country: "Bhutan", url: "bt"},
    { country: "Bolivia", url: "bo"},
    { country: "Bosnia And Herzegovina", url: "ba"},
    { country: "Botswana", url: "bw"},
    { country: "Bouvet Island", url: "bv"},
    { country: "Brazil", url: "br"},
    { country: "British Indian Ocean continent", url: "io"},
    { country: "Brunei Darussalam", url: "bn"},
    { country: "Bulgaria", url: "bg"},
    { country: "Burkina Faso", url: "bf"},
    { country: "Burundi", url: "bi"},
    { country: "Cambodia", url: "kh"},
    { country: "Cameroon", url: "cm"},
    { country: "Canada", url: "ca"},
    { country: "Cape Verde", url: "cv"},
    { country: "Cayman Islands", url: "ky"},
    { country: "Central African Republic", url: "cf"},
    { country: "Chad", url: "td"},
    { country: "Chile", url: "cl"},
    { country: "China", url: "cn"},
    { country: "Christmas Island", url: "cx"},
    { country: "Cocos (Keeling) Islands", url: "cc"},
    { country: "Colombia", url: "co"},
    { country: "Comoros", url: "km"},
    { country: "Congo", url: "cg"},
    { country: "Cook Islands", url: "ck"},
    { country: "Costa Rica", url: "cr"},
    { country: "Cote D'Ivoire", url: "ci"},
    { country: "Croatia", url: "hr"},
    { country: "Cuba", url: "cu"},
    { country: "Cyprus", url: "cy"},
    { country: "Czech Republic", url: "cz"},
    { country: "Denmark", url: "dk"},
    { country: "Djibouti", url: "dj"},
    { country: "Dominica", url: "dm"},
    { country: "Dominican Republic", url: "do"},
    { country: "Ecuador", url: "ec"},
    { country: "Egypt", url: "eg"},
    { country: "El Salvador", url: "sv"},
    { country: "Equatorial Guinea", url: "gq"},
    { country: "Eritrea", url: "er"},
    { country: "Estonia", url: "ee"},
    { country: "Ethiopia", url: "et"},
    { country: "Falkland Islands (Malvinas)", url: "fk"},
    { country: "Faroe Islands", url: "fo"},
    { country: "Fiji", url: "fj"},
    { country: "Finland", url: "fi"},
    { country: "France", url: "fr"},
    { country: "France, Metropolitan", url: "fx"},
    { country: "French Guiana", url: "gf"},
    { country: "French Polynesia", url: "pf"},
    { country: "French Southern Territories", url: "tf"},
    { country: "Gabon", url: "ga"},
    { country: "Gambia", url: "gm"},
    { country: "Georgia", url: "ge"},
    { country: "Germany", url: "de"},
    { country: "Ghana", url: "gh"},
    { country: "Gibraltar", url: "gi"},
    { country: "Greece", url: "gr"},
    { country: "Greenland", url: "gl"},
    { country: "Grenada", url: "gd"},
    { country: "Guadeloupe", url: "gp"},
    { country: "Guam", url: "gu"},
    { country: "Guatemala", url: "gt"},
    { country: "Guinea", url: "gn"},
    { country: "Guinea-Bissau", url: "gw"},
    { country: "Guyana", url: "gy"},
    { country: "Haiti", url: "ht"},
    { country: "Heard Island and Mcdonald Islands", url: "hm"},
    { country: "Honduras", url: "hn"},
    { country: "Hong Kong", url: "hk"},
    { country: "Hungary", url: "hu"},
    { country: "Iceland", url: "is"},
    { country: "India", url: "in"},
    { country: "Indonesia", url: "id"},
    { country: "Iran, Islamic Republic Of", url: "ir"},
    { country: "Iraq", url: "iq"},
    { country: "Ireland", url: "ie"},
    { country: "Israel", url: "il"},
    { country: "Italy", url: "it"},
    { country: "Jamaica", url: "jm"},
    { country: "Japan", url: "jp"},
    { country: "Jordan", url: "jo"},
    { country: "Kazakhstan", url: "kz"},
    { country: "Kenya", url: "ke"},
    { country: "Kiribati", url: "ki"},
    { country: "North Korea", url: "kp"},
    { country: "South Korea", url: "kr"},
    { country: "Kuwait", url: "kw"},
    { country: "Kyrgyzstan", url: "kg"},
    { country: "Laos", url: "la"},
    { country: "Latvia", url: "lv"},
    { country: "Lebanon", url: "lb"},
    { country: "Lesotho", url: "ls"},
    { country: "Liberia", url: "lr"},
    { country: "Libya", url: "ly"},
    { country: "Liechtenstein", url: "li"},
    { country: "Lithuania", url: "lt"},
    { country: "Luxembourg", url: "lu"},
    { country: "Macau", url: "mo"},
    { country: "North Macedonia", url: "mk"},
    { country: "Madagascar", url: "mg"},
    { country: "Malawi", url: "mw"},
    { country: "Malaysia", url: "my"},
    { country: "Maldives", url: "mv"},
    { country: "Mali", url: "ml"},
    { country: "Malta", url: "mt"},
    { country: "Marshall Islands", url: "mh"},
    { country: "Martinique", url: "mq"},
    { country: "Mauritania", url: "mr"},
    { country: "Mauritius", url: "mu"},
    { country: "Mayotte", url: "yt"},
    { country: "Mexico", url: "mx"},
    { country: "Micronesia", url: "fm"},
    { country: "Moldova", url: "md"},
    { country: "Monaco", url: "mc"},
    { country: "Mongolia", url: "mn"},
    { country: "Montserrat", url: "ms"},
    { country: "Morocco", url: "ma"},
    { country: "Mozambique", url: "mz"},
    { country: "Myanmar", url: "mm"},
    { country: "Namibia", url: "na"},
    { country: "Nauru", url: "nr"},
    { country: "Nepal", url: "np"},
    { country: "Netherlands", url: "nl"},
    { country: "Netherlands Antilles", url: "an"},
    { country: "New Caledonia", url: "nc"},
    { country: "New Zealand", url: "nz"},
    { country: "Nicaragua", url: "ni"},
    { country: "Niger", url: "ne"},
    { country: "Nigeria", url: "ng"},
    { country: "Niue", url: "nu"},
    { country: "Norfolk Island", url: "nf"},
    { country: "Northern Mariana Islands", url: "mp"},
    { country: "Norway", url: "no"},
    { country: "Oman", url: "om"},
    { country: "Pakistan", url: "pk"},
    { country: "Palau", url: "pw"},
    { country: "Panama", url: "pa"},
    { country: "Papua New Guinea", url: "pg"},
    { country: "Paraguay", url: "py"},
    { country: "Peru", url: "pe"},
    { country: "Philippines", url: "ph"},
    { country: "Pitcairn", url: "pn"},
    { country: "Poland", url: "pl"},
    { country: "Portugal", url: "pt"},
    { country: "Puerto Rico", url: "pr"},
    { country: "Qatar", url: "qa"},
    { country: "Reunion", url: "re"},
    { country: "Romania", url: "ro"},
    { country: "Russia", url: "ru"},
    { country: "Rwanda", url: "rw"},
    { country: "Saint Helena", url: "sh"},
    { country: "Saint Kitts And Nevis", url: "kn"},
    { country: "Saint Lucia", url: "lc"},
    { country: "Saint Pierre And Miquelon", url: "pm"},
    { country: "Saint Vincent And The Grenadines", url: "vc"},
    { country: "Samoa", url: "ws"},
    { country: "San Marino", url: "sm"},
    { country: "Sao Tome And Principe", url: "st"},
    { country: "Saudi Arabia", url: "sa"},
    { country: "Senegal", url: "sn"},
    { country: "Seychelles", url: "sc"},
    { country: "Sierra Leone", url: "sl"},
    { country: "Singapore", url: "sg"},
    { country: "Slovakia", url: "sk"},
    { country: "Slovenia", url: "si"},
    { country: "Solomon Islands", url: "sb"},
    { country: "Somalia", url: "so"},
    { country: "South Africa", url: "za"},
    { country: "Spain", url: "es"},
    { country: "Sri Lanka", url: "lk"},
    { country: "Sudan", url: "sd"},
    { country: "Suriname", url: "sr"},
    { country: "Svalbard And Jan Mayen Islands", url: "sj"},
    { country: "Swaziland", url: "sz"},
    { country: "Sweden", url: "se"},
    { country: "Switzerland", url: "ch"},
    { country: "Syrian Arab Republic", url: "sy"},
    { country: "Taiwan", url: "tw"},
    { country: "Tajikistan", url: "tj"},
    { country: "Tanzania", url: "tz"},
    { country: "Thailand", url: "th"},
    { country: "Togo", url: "tg"},
    { country: "Tokelau", url: "tk"},
    { country: "Tonga", url: "to"},
    { country: "Trinidad And Tobago", url: "tt"},
    { country: "Tunisia", url: "tn"},
    { country: "Turkey", url: "tr"},
    { country: "Turkmenistan", url: "tm"},
    { country: "Turks And Caicos Islands", url: "tc"},
    { country: "Tuvalu", url: "tv"},
    { country: "Uganda", url: "ug"},
    { country: "Ukraine", url: "ua"},
    { country: "United Arab Emirates", url: "ae"},
    { country: "United Kingdom", url: "gb"},
    { country: "United States", url: "us"},
    { country: "United States Minor Outlying Islands", url: "um"},
    { country: "Uruguay", url: "uy"},
    { country: "Uzbekistan", url: "uz"},
    { country: "Vanuatu", url: "vu"},
    { country: "Vatican City State (Holy See)", url: "va"},
    { country: "Venezuela", url: "ve"},
    { country: "Vietnam", url: "vn"},
    { country: "Virgin Islands (British)", url: "vg"},
    { country: "Virgin Islands (U.S.)", url: "vi"},
    { country: "Wallis And Futuna Islands", url: "wf"},
    { country: "Western Sahara", url: "eh"},
    { country: "Yemen", url: "ye"},
    { country: "Yugoslavia", url: "yu"},
    { country: "Zaire", url: "zr"},
    { country: "Zambia", url: "zm"},
    { country: "Zimbabwe", url: "zw"}
];
// export const flags = [
//   { country: "United Kingdom", url: "flags/gb.png" },
//   { country: "America", url: "flags/us" },
//   { country: "Germany", url: "flags/de" },
//   { country: "France", url: "flags/fr" },
//   { country: "Chech", url: "flags/cz" },
//   { country: "France", url: "flags/fr" },
//   { country: "Italy", url: "flags/it" },
//   { country: "Denmark", url: "flags/dk" },
//   { country: "Sweden", url: "flags/se.png" },
//   { country: "Japan", url: "flags/jp.png" },
// ];


export function title_to_url(title) {
  return title
    .replaceAll("-", "")
    .replaceAll(" ", "-")
    .replaceAll(",", "")
    .replaceAll(".", "")
    .toLowerCase();
}

export const quality_clicked = async(selection) => {

  if(selection == 1){
      await this.setState({quality_all: 1, quality_hd:0, quality_fourk:0, quality_vr:0, quality_non_hd:0})
  }
  if(selection == 2){
      if(this.state.quality_hd){
          await this.setState({quality_all: 0, quality_hd:0})
      }
      else{
          await this.setState({quality_all: 0, quality_hd:1})
      }
  }
  if(selection == 3){
      if(this.state.quality_fourk){
          await this.setState({quality_all: 0, quality_fourk:0})
      }
      else{
          await this.setState({quality_all: 0, quality_fourk:1})
      }
  }
  if(selection == 4){
      if(this.state.quality_vr){
          await this.setState({quality_all: 0, quality_vr:0})
      }
      else{
          await this.setState({quality_all: 0, quality_vr:1})
      }
  }
  if(selection == 5){
      if(this.state.quality_non_hd){
          await this.setState({quality_all: 0, quality_non_hd:0})
      }
      else{
          await this.setState({quality_all: 0, quality_non_hd:1})
      }
  }
  // if(this.state.quality_hd == 1 &&
  //     this.state.quality_fourk === 1 && 
  //     this.state.quality_vr === 1 && 
  //     this.state.quality_non_hd === 1
  //     )
  //     {
  //         this.setState({quality_all: 1, quality_hd: 0, quality_fourk: 0,
  //             quality_vr: 0,
  //             quality_non_hd: 0})

  //     }
  this.performQuery()

}

export function toast_success(message){
    //alert('cool')
    toast.success(<div>{message}</div>, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });    
}

export function toast_error(message){    
    //alert('error')
    toast.error(<div> {message}</div>, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        });
    }

export function toast_info(message){    
    //alert('info')
    toast.info(<div> {message}</div>, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        });
    }


export function scroll_top(top_position){
    //  window.scrollTo(0,0)
    //smoothScroll(0)
    
    
    
    

    //function detectBrowser() { 
         if(navigator.userAgent.indexOf("Firefox") != -1 ){
            //alert("firefox")
            window.scrollTo(0,top_position)
            return ;
         }
    //} 
    window.scroll({top: parseInt(top_position),  behavior: 'smooth'});

    if (!('scrollBehavior' in document.documentElement.style)) {
        //safari does not support smooth scroll
          (async () => {
            const {default: smoothScroll} = await import(
              /* webpackChunkName: 'polyfill-modern' */
              'smoothscroll-polyfill'
              )
            smoothScroll.polyfill()
          })()
        }
    //  window.scrollTo(0,0)

     //window.scrollTo({top: top_position,  behavior: 'smooth'});
}

export function return_country_from_country_code(country_code){
    var country_object=flags.filter(
        (objFromA) => objFromA.url == country_code
      )[0] || ""
    
    return country_object.country
}

export function nFormatter(num) {
    if (num >= 1000000000) {
       return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
    }
    if (num >= 1000000) {
       return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (num >= 1000) {
       return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    return num;
}

export const sortSuggestions = (a, b, inputValue) => {
    const aStartsWith = a.toLowerCase().startsWith(inputValue.toLowerCase());
    const bStartsWith = b.toLowerCase().startsWith(inputValue.toLowerCase());
    if (aStartsWith !== bStartsWith) {
      return aStartsWith ? -1 : 1;
    }
    const isANumber = /^\d/.test(a);
    const isBNumber = /^\d/.test(b);
    if (isANumber !== isBNumber) {
        return isANumber ? 1 : -1
    }
    return a.localeCompare(b);
  }
  
  export const filterOptions = (options, {inputValue}) => {
    const optionsStartingWith = options.filter(option => option.tag.toLowerCase().startsWith(inputValue.toLowerCase()));
    const optionsContaining = options.filter(option => option.tag.toLowerCase().includes(inputValue.toLowerCase()) && !optionsStartingWith.includes(option));
    return optionsStartingWith.concat(optionsContaining);
  }

export const change_sort = async(order) => {
  

  if(order == "newest"){
      await this.setState({ sort_new: 1, sort_trending: 0, sort_best: 0 })
      this.props.change_pretitle_str("Newest ")
  }
  if(order == "trending"){
      //alert("bike")
      await this.setState({ sort_new: 0, sort_trending: 1, sort_best: 0 })
      this.props.change_pretitle_str("Trending ")          
  }
  if(order == "best"){
      await this.setState({ sort_new: 0, sort_trending: 0, sort_best: 1 })
      this.props.change_pretitle_str("Best ")
  }
  this.performQuery()
}

export const change_best_parameter = async(parameter) => {      
  if(parameter == "Most viewed"){
      await this.setState({ best_parameter: "mostviewed" })
      this.props.change_pretitle_str("Most Viewed ")
  }
  if(parameter == "Most commented"){
      await this.setState({ best_parameter: "mostcommented" })            
      this.props.change_pretitle_str("Most Commented ")

  }
  if(parameter == "Our favorites"){
      await this.setState({ best_parameter: "ourfavorites" })
  }
  this.performQuery()
}

export const get_best_parameter_from_id = (id, sort_best) => {
   //console.log('bik')
  if(id == "mostviewed"){
       this.props.change_pretitle_str("Most Viewed ", sort_best)
       return "Most viewed"
  }
  if(id == "mostcommented"){
      this.props.change_pretitle_str("Most Commented ", sort_best)
      return "Most commented"
      
  }
  if(id == "ourfavorites"){
      this.props.change_pretitle_str("Our favorites ", sort_best)
      return "Our favorites"
  }

}

  
//}

export const  resetFiltersURL = (sourceURL) => {
    var url=new URLSearchParams(sourceURL)
    url.delete("is_vr")
    url.delete("is_hd")
    url.delete("is_4k")
    url.delete("min_mins")
    url.delete("max_mins")
    url.delete("order")
    url.delete("period")
    url.delete("parameter")
    //alert(url)
    return url
    // var rtn = sourceURL.split("?")[0],
    //         param,
    //         params_arr = [],
    //         queryString = (sourceURL.indexOf("?") != -1) ? sourceURL.split("?")[1] : "";

    // var keys = ["is_vr", "is_hd", "is_4k", "min_mins", "max_mins"]
    // for( var i = 0; i < keys.length; i++){
        
    //     if (queryString != "") {
    //         params_arr = queryString.split("&");
    //         for (var i = params_arr.length - 1; i >= 0; i -= 1) {
    //             param = params_arr[i].split("=")[0];
    //             if (param == keys[i]) {
    //                 params_arr.splice(i, 1);
    //             }
    //         }
    //         if (params_arr.length) rtn = rtn + "?" + params_arr.join("&");
    //     }
    // }
    // return rtn;
}

export const handleMinsChange = (event, newValue) => {
  console.log(newValue[0]+"-"+newValue[1])
  if(newValue[0] == newValue[1]){
    return
  }
  this.setState({mins: newValue})

  //this.performQuery()
}


export const performQuery = () => {
  // var params = [] 
  // params.push({
  //     key: "min_mins",
  //     value: "20"
  // })
  
  var params = {
  };
  if(this.state.sort_best == 1){
      params.order="best"
      params.parameter=this.state.best_parameter
  }
  if(this.state.sort_trending == 1){
      params.order="trending"
      params.period=this.state.sort_period
  }
  if(this.state.mins[0] > 0){
      params.min_mins=this.state.mins[0]
  }
  if(this.state.mins[1] < 40)
      params.max_mins=this.state.mins[1]
  if((this.state.quality_hd == 1 &&
      this.state.quality_fourk == 1 && 
      this.state.quality_vr == 1 && 
      this.state.quality_non_hd == 1) || this.state.quality_all == 1
      ){
          
      }  
      else{
          if(this.state.quality_hd == 1)
              params.is_hd=1
          if(this.state.quality_fourk == 1)
              params.is_4k=1
          if(this.state.quality_vr == 1)
              params.is_vr=1
          if(this.state.quality_non_hd == 1)
              params.is_not_hd=1
      }          
  var esc = encodeURIComponent;
  
  var query = Object.keys(params)
      .map(k => esc(k) + '=' + esc(params[k]))
      .join('&');   
  if(window.location.search.split('?')[1]==query){
      return
  }         
  window.history.pushState(
      "",
      "",
      "/"+document.location.href.split("/")[3]+"/1/?" +
      //document.location.href.split("/")[3]+"/1?"+
      query
  );
  this.props.parent_cmd(true)

}


export var height_to_remove_for_footer_desktop=115;
export var height_to_remove_for_footer_tablet=211; //was 210

export default {
  weights,
  flags,
  title_to_url,
  quality_clicked,
  change_sort,
  change_best_parameter,
  get_best_parameter_from_id,
  handleMinsChange,
  performQuery,
  nFormatter,
  scroll_top,
  return_country_from_country_code,
  toast_success,
  toast_error,
  toast_info,
  resetFiltersURL
};
