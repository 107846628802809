var purple="#5724E6"
var purple_hover="#7649F2" //#7649F2
var purple_pressed="#9670ff"
var light_purple="#e3d9ff"
var purple_d="#9F7DFF"
//var trans_button_hover="#F7F7F7"
var trans_button_hover="#f2f2f2"
//var cultured_d="#F7F7F7"
var cultured_d="#f2f2f2"
var hiketic="#02000D"
var trans_button_d_hover="#2A2A30"
var gray_for_horizontal_filters_white="#f1f1f1"
var gray_for_horizontal_filters_black="#d1d1d1"
var jet_ed="#3E3E47"
var purple_d_hover="#B9A1FF" //#B9A1FF
var purple_d_pressed="#d3c4ff"
var black_background="#1f1f24"
var white_background='#F8F8F8'
//var gray="#f7f7f7#3E3E47"
var gray='#f2f2f2'
var gray_d="#2a2a30"

var search_hover_dark = '#3E3E47'

var dark_liver="#4C4C58"
var metalic="#A8A8AE"
var purple_pressed="#9670FF"
var purple_d_pressed="#D3C4FF"


export const lightTheme = { 
    background: white_background,
    rightSideBackground: white_background,
    
    menu_footer_leftmenu_border_color: "#E0E0E0",
    purple: purple,
    purple_clicked: purple_pressed,
    gray_for_horizontal_filters: gray_for_horizontal_filters_white,
    search_background: white_background,
    search_background_hover: gray,
    pornstar_channel_description_background: white_background,
    tags_left_menu_href_color: dark_liver,
    trans_button_hover_background: trans_button_hover,
    trending_best_sort_color: purple,
    horizontal_filters_background: cultured_d,
    quality_box_border: "#E0E0E0",
    purple_hover: purple_hover,
    tags_bubble_border_color:  "#E0E0E0",//"#E0E0E0", 
    text_color: hiketic,
    textcolor_html_encoded: "%2302000D",
    tags_background_color: "#EDEDED",//cultured_d,
    gray: gray,
    menu_href: dark_liver,
    body: 'red',
    text: '#363537',
    toggleBorder: '#FFF',
    white_and_gray_d: '#FFF',
    width: '100%',
    gradient: 'linear-gradient(#39598A, #79D7ED)',
    scrollbar: "#C4C4C4",
    scrollbar_hover: "#C4C4C4"
  }
  
  export const darkTheme = {
    background: black_background,
    purple: purple_d,
    purple_clicked: purple_d_pressed,
    text_color: cultured_d,
    textcolor_html_encoded: "%23f2f2f2",
    menu_footer_leftmenu_border_color: "#2A2A30",
    rightSideBackground: black_background,
    pornstar_channel_description_background: "#2A2A30",
    gray_for_horizontal_filters: "#3E3E47", //gray_for_horizontal_filters_black,
    search_background: gray_d,
    
    search_background_hover: "#3E3E47",
    //#50505C
    tags_bubble_border_color: "#50505C", 
    trending_best_sort_color: cultured_d,
    menu_href: "#F7F7F7", //#2A2A30;
    horizontal_filters_background: "#2A2A30",
    tags_background_color: jet_ed,
    trans_button_hover_background: trans_button_d_hover,
    purple_hover: purple_d_hover,
    quality_box_border: "#50505C",
    tags_left_menu_href_color: metalic,
    gray: gray_d,
    body: '#363537',
    text: '#FAFAFA',
    width: '100%',
    white_and_gray_d: gray_d,
    toggleBorder: '#6B8096',
    gradient: 'linear-gradient(#091236, #1E215D)',
    scrollbar: jet_ed,
    scrollbar_hover: "red"
  } 