import React, { Component } from "react";
import axios from "axios";
import configData from "../../../config.json";
import Video from "../../video";
import Pagination from "../../pagination";
import { withRouter } from "react-router-dom";
import PrintTagsComponent from "./print_tags";
import { title_to_url, resetFiltersURL } from "../../global_arrays";
import GetTagAsyncComponent from "./../get_tags_async/get_tags_async";
import ErrorComponent from './../../error_component/error_component';
import LoadingDotsComponent from './../../loading_dots_component/loading_dots_component';
import { Helmet } from 'react-helmet'
import MenuWrapper from './../tags_left_menu/wrapper'
import NoVideosComponent from "../no_videos/no_videos";
import {  scroll_top, height_to_remove_for_footer_tablet, height_to_remove_for_footer_desktop } from "./../../global_arrays";
import { toast_info } from "./../../global_arrays";
import { tags } from "./../../global_tags"
import FooterComponent from './../footer/footer'
import {  toast_error } from "./../../global_arrays";

class UserTags extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.pathname != this.props.location.pathname ) {
      setTimeout(() => {
        this.componentDidMount();        
      }, 20);
      // navigated!
    }
  }
  setCurrentVideo = (id) => {
    this.setState({currentVideoShowing: id})
  }

  set_showingVideos = async(value) => {
    await this.setState({showingVideos: value})

  }

  
  go_to_link = (event, uri,set_showingVideos_state=-1, componentdidmount=-1) => {
    event.preventDefault();
    
    
    this.props.set_scrolly(0)
    if(set_showingVideos_state == 1){
      this.setState({showingVideos: 1})
    }
    this.props.history.push({
      pathname: uri,
      search: "",
    });
    if(componentdidmount == 1){
      this.componentDidMount();
    }
  };

  state = {
    ptags: [],
    selected_tags: [],
    videos: [],
    currentPage: -1,
    videosCount: -1,
    showSpinner: 0,
    showingVideos: 0,
    tag: [],
    //readyfornone is an extra flag. I don't want to show
    // no videos to show only when the api call has returned. this is what this flag
    //does
    readyfornone: 0,
    currentVideoShowing: -1,
    showDotsLoading: 0,
    showError: 0,
    pretitle_str: "",
    single_tag_id: -1,//is used when /tag, then being passed to get tags async
    filters_applied: 0,
    is_hd: 0,
    is_4k: 0,
    is_vr: 0,
    min_mins: 0,
    max_mins: 0,
    minorError: 0,
    minorErrorShown: 0,
    reload_usermenu: 0,
    tags_async_error_no_tag_selected: 0,
    comes_from_pagination_click: 0,
    showTagsAsyncLoadingWhenSearchingVideo: 0,
    calc_width: 0,
        
  };

  set_showTagsAsyncLoadingWhenSearchingVideo= async(value) => {
    this.setState({showTagsAsyncLoadingWhenSearchingVideo: value})
  }

  set_tags_async_error_no_tag_selected = async(value) => {
    await this.setState({tags_async_error_no_tag_selected: value})
  }

  change_pretitle_str = (value, sort_best) =>{
    if(value == this.state.pretitle_str ) return;
   
    this.setState({pretitle_str:value})
  }

  async_search_is_empty = async() => {
    await this.setState({ showingVideos: 0, videos: [], videosCount: -1, tag: [] });
    this.props.history.push("/tags");
    //this.componentDidMount();
    window.removeEventListener('resize', this.myCallback_tags)
    window.removeEventListener('resize', this.myCallback_videos)
    window.addEventListener('resize', this.myCallback_tags)
    this.myCallback_tags()
  };

  componentWillUnmount(){
    window.removeEventListener('resize', this.myCallback_tags)
    window.removeEventListener('resize', this.myCallback_videos)
    window.removeEventListener('scroll', this.props.detect_scroll_direction);

    this.setState({ptags: [], selected_tags: [], tag: [], video: []})
    this.props.inputChange([])

  }

  constructor(props){
    super(props);
    //function myCallback() { this.props.reportWindowSize(1,1) }
    this.myCallback_videos = this.myCallback_videos.bind(this)
    this.myCallback_tags = this.myCallback_tags.bind(this)
  }

  myCallback_videos() {
     this.setState({calc_width: this.props.scrollbarwidth})     
     this.props.reportWindowSize(0,2,1) 
     // 93 was 91, 93 is 2 pixels less WIP
    }

  myCallback_tags() { 
    this.setState({calc_width: this.props.scrollbarwidth})  
    this.props.reportWindowSize(0,1,-1,1)

  }


  async componentDidMount(return_to_first_page) {
    this.setState({showError: 0})
   
    
    
    if(this.state.comes_from_pagination_click == 1){
      scroll_top(0)
    }else{
      window.scrollTo(0, 0);
    }
    
    //the if below is needed because there is a bug. 
    //if the user selects a tag from the tagasync in the left, then
    //for some reason the filters are not closed and the scrollbar is hidden. this fixes it. 
    
    if(document.body.style.overflow == 'hidden'){
      document.body.style.overflow = 'visible';
      document.body.style.width = '';
    }
    

    var self = this;
    const start = Date.now();

    if(this.state.reload_usermenu == 0 ){
      this.setState({reload_usermenu: 1})
    }else{
      this.setState({reload_usermenu: 0})
    }
    window.addEventListener('scroll',(event) =>  this.props.detect_scroll_direction(event));
    //WIP giati spaei to detect_scroll_direction otan den deixnei video. pros to paron try-catch
    this.setState({comes_from_pagination_click: 1})


    await this.setState({videos: [], showError: 0})
    await this.setState({minorError: 0, minorErrorShown: 0})
    //starts
    const urlParams = new URLSearchParams(window.location.search);
    var myParam = urlParams.getAll("tag_id");
    if (myParam.length > 0) {
      this.setState({ showingVideos: 1 });
    }else{
      this.setState({ showingVideos: 0, ptags: [] });
    }
    if (window.location.pathname.split("/")[1] == "tag") {
      this.setState({ showingVideos: 1 });
      window.addEventListener('resize', this.myCallback_videos);
      myParam = [window.location.pathname.split("/")[2]];
    }else{
      window.addEventListener('resize', this.myCallback_tags);
    }
    

    this.setState({ tag: [], readyfornone: 0 });
    var local_page = 0;

    try {
      if (this.props.match.params.page) var page = this.props.match.params.page;
      else var page = 1;

      if (page > 0) local_page = page - 1;
      else local_page = page;
    } catch (e) {}
    if (return_to_first_page) {
      page = 1;
      local_page = 0;
    }
    this.setState({ currentPage: page }); //was page

    await this.setState({ showDotsLoading: 1});

    this.props.reportWindowSize(0,2,1)    


    if(this.state.ptags.length == 0){
        
        //  below is commented
        self.setState({ptags: tags})
          if(self.state.showingVideos == 0){

              self.setState({showDotsLoading: 0})
              self.props.reportWindowSize(0,1)              
          }


        // push values to autocomplete

        }
        var i = 0,
          j = 0;
          //  alert(myParam.length);
        var temp_selected_tags = [];
        for (i = 0; i < myParam.length; i++) {
          for (j = 0; j < this.state.ptags.length; j++) {
            if (this.state.ptags[j].id == myParam[i]) {
              temp_selected_tags.push({
                id: myParam[i],
                tag: this.state.ptags[j].tag,
              });
            }
          }
        }
        this.setState({ selected_tags: temp_selected_tags });
        this.props.inputChange(this.state.selected_tags)
        console.log(this.state.selected_tags)

    
    if (window.location.pathname.split("/")[1] == "tag") {
      this.setState({ showingVideos: 1 });
      //start

      const responsez = await axios({
        method: "get",
        url:
          configData.PRIVATE_API_ENDPOINT +
          "/get_tag?tag_id=" +
          window.location.pathname.split("/")[2],
          timeout: configData.TIMEOUT_LIMIT,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          self.setState({ tag: response.data });
          let title = title_to_url(response.data[0].tag);
          self.setState({ url_title: title });
          let uri =
            "/tag/" +
            response.data[0].id +
            "/" +
            title +
            "/" +
            self.state.currentPage +
            window.location.search;
          if (
            !window.location.pathname.split("/")[3] ||
             window.location.pathname.split("/")[3] != title
          ) {
            window.history.pushState("", "", uri);
          }
          })
        .catch(function (error) {
          //handle error
          self.setState({minorError: 1})
        });
      //end

      var tag_id = window.location.pathname.split("/")[2];
      const response_videos = await axios.get(
        configData.PRIVATE_API_ENDPOINT +
          "/get_videos_by_tag/" +
          local_page +
          "/" +
          configData.USER_VIDEOS_PER_PAGE +
          "?tag_id=" +
          tag_id +
          window.location.search.replace("?", "&"),
          {timeout: configData.TIMEOUT_LIMIT}
      ).then(function (response_videos) {
        setTimeout(() => {
          const videos = response_videos.data;
          self.setState({ videos, readyfornone: 1,showSpinner: 0,showDotsLoading: 0, showTagsAsyncLoadingWhenSearchingVideo: 0  })
          self.props.reportWindowSize(0,2,1)          


        }, configData.WAITING_MILIS - (Date.now() - start));


      })
      .catch(async function (error) {
        setTimeout(() => {
          self.setState({ showSpinner: 0,showError: 1, showDotsLoading: 0 , showTagsAsyncLoadingWhenSearchingVideo: 0});
          self.props.reportWindowSize(0,2,1)          
        }, configData.WAITING_MILIS - (Date.now() - start));
        //handle error
        if (error.response && error.response.status == 401) {
          alert("You are not authorized associate tags");
        } else  {
          await self.setState({showError: 1})
        }
      });

      const response_videos_count = await axios.get(
        configData.PRIVATE_API_ENDPOINT +
          "/get_videos_by_tag_count?tag_id=" +
          tag_id +
          window.location.search.replace("?", "&"),
          {timeout: configData.TIMEOUT_LIMIT}
      ).then(function (response_videos_count) {
        const videosCount = response_videos_count.data[0]["count(v.id)"];
        self.setState({ videosCount });
      })
      .catch(function (error) {
        self.setState({minorError: 1})

      });

    } else {
        if(window.location.search.length > 0){
          const response_videos = await axios.get(
            configData.PRIVATE_API_ENDPOINT +
              "/get_videos_by_tag/" +
              local_page +
              "/" +
              configData.USER_VIDEOS_PER_PAGE +
              window.location.search,
              {timeout: configData.TIMEOUT_LIMIT}
          ).then(function (response_videos) {
            setTimeout(() => {
              const videos = response_videos.data;
              self.setState({ videos, readyfornone: 1, showSpinner: 0, showDotsLoading: 0, showTagsAsyncLoadingWhenSearchingVideo: 0 })
              self.props.reportWindowSize(0,2,1)              



          }, configData.WAITING_MILIS - (Date.now() - start));

        })
        .catch(async function (error) {
          setTimeout(() => {
            self.setState({ showSpinner: 0,showError: 1, showDotsLoading: 0, showTagsAsyncLoadingWhenSearchingVideo: 0 });
            self.props.reportWindowSize(0,2,1)            
          }, configData.WAITING_MILIS - (Date.now() - start));
          //handle error
          if (error.response && error.response.status == 401) {
            alert("You are not authorized associate tags");
          } else {
            await self.setState({showError: 1})
          }
        });

        //WIP comment out dunno
        //   alert("pan")

        const response_videos_count = await axios.get(
          configData.PRIVATE_API_ENDPOINT +
            "/get_videos_by_tag_count" +
            window.location.search,
            {timeout: configData.TIMEOUT_LIMIT}
        ).then(function (response_videos_count) {
          const videosCount = response_videos_count.data[0]["count(v.id)"];
          self.setState({ videosCount });
        })
        .catch(function (error) {
          self.setState({minorError: 1})

        });

            }

    }
    if(this.state.showingVideos == 1){
      this.props.reportWindowSize(0,2,1)      

      window.removeEventListener('resize', this.myCallback_tags)
      window.removeEventListener('resize', this.myCallback_videos)
      window.addEventListener('resize', this.myCallback_videos);

      this.setState({filters_applied: 0, is_hd: 0, is_4k: 0, is_vr: 0, min_mins: 0, max_mins: 0})
      if(window.location.href.indexOf("is_hd=1") > -1){
        this.setState({filters_applied: 1, is_hd: 1})
      }
      if(window.location.href.indexOf("is_4k=1") > -1 ){
        this.setState({filters_applied: 1, is_4k: 1})
      }
      if(window.location.href.indexOf("is_vr=1") > -1 ){
        this.setState({filters_applied: 1, is_vr: 1})
      }
      var url_string = window.location.href
      var url = new URL(url_string);
      var min_mins = url.searchParams.get("min_mins");
      if(min_mins != null){
        this.setState({filters_applied: 1, min_mins: min_mins})
      }
      var max_mins = url.searchParams.get("max_mins");
      if(max_mins != null){
        this.setState({filters_applied: 1, max_mins: max_mins})
      }
    }else{
      this.props.reportWindowSize(0,1)      
      window.removeEventListener('resize', this.myCallback_tags)
      window.removeEventListener('resize', this.myCallback_videos)
      window.addEventListener('resize', this.myCallback_tags);
    }

    if(this.state.minorError == 1 && this.state.minorErrorShown == 0 && this.state.showError == 0){
      this.setState({minorErrorShown: 1 })
      toast_info("Some errors have occured, it is possible that you will experience some limited functionality")
    }


  }

  deleteInput = async(value) => {
    //alert(value)
        
    await this.props.deleteInput(value)
    if(this.props.selected_tags.length == 0){
      //alert("ante gamisou re poystara")
        try {
          this.async_search_is_empty();
        } catch (e) {}
    }
    // await this.setState(prevState => ({
    //   selected_tags: prevState.selected_tags.filter(tag => tag.tag !== value)
    // }));
    // if (this.state.selected_tags.length == 0) {
    //   try {
    //     this.async_search_is_empty();
    //   } catch (e) {}
    // }
  }

  inputChange = async(value) => {
    if(value.length > 5){
      toast_error("You can't select more than 5 tags")
      return
    }
    await this.setState({ selected_tags: value });    
        if (value.length == 0) {
      try {
        this.async_search_is_empty();
      } catch (e) {}
    }
  };

  go_to_letter_link(letter){
    var elem = document.getElementById("w"+letter)
    window.alert(elem.offsetHeight)
  }

  async shouldComponentUpdate(nextProps, nextState) {
    if(this.props.scrollbarwidth != nextProps.scrollbarwidth && nextProps.filters_showing == 0 && this.props.filters_showing == 1){     
      await this.setState({calc_width: 0})
   }
   if(this.props.number_of_filters != nextProps.number_of_filters){
 
   }
   if(this.props.selected_tags != nextProps.selected_tags){
    // alert(nextProps.selected_tags)
    if(nextProps.selected_tags.length == 0){
      //alert("0 tin panagia soy")
      this.props.wrapperclick()
      this.async_search_is_empty();
      
    }
   }
   if(this.props.overlay_full_except_menu_and_mobile_search_value != nextProps.overlay_full_except_menu_and_mobile_search_value){
    return true
   }

    if(
      this.props.mobile_or_tablet != nextProps.mobile_or_tablet ||
      this.state.showDotsLoading != nextState.showDotsLoading ||
      this.state.currentVideoShowing != nextState.currentVideoShowing  ||
      this.state.show_blur != nextState.show_blur ||
      this.state.scrolling_down != nextState.scrolling_down ||
      this.props.filters_showing != nextProps.filters_showing ||
      this.props.menu_showing != nextProps.menu_showing ||
      this.props.selected_tags != nextProps.selected_tags ||
      this.state.single_tag_id != nextState.single_tag_id ||
      this.state.ptags != nextState.ptags ||
      this.props.theme != nextProps.theme ||
      this.props.show_overlay  != nextProps.show_overlay ||
      this.state.filters_applied != nextState.filters_applied ||
      this.state.is_hd != nextState.is_hd ||
      this.state.is_4k != nextState.is_4k ||
      this.state.min_mins != nextState.min_mins ||
      this.state.max_mins != nextState.max_mins ||
      this.props.window_height != nextProps.window_height ||
      this.state.tags_async_error_no_tag_selected != nextState.tags_async_error_no_tag_selected ||
      this.state.videosCount != nextState.videosCount ||
      this.state.videos != nextState.videos ||      
      this.props.scrolling_down != nextProps.scrolling_down  ||
      this.state.showingVideos != nextState.showingVideos ||
      this.state.showTagsAsyncLoadingWhenSearchingVideo != nextState.showTagsAsyncLoadingWhenSearchingVideo ||
      this.props.pagination_show_less != nextProps.pagination_show_less ||
      this.props.scrollbarwidth != nextProps.scrollbarwidth ||
      this.state.calc_width != nextState.calc_width ||
      this.props.number_of_filters != nextProps.number_of_filters ||
      this.props.over_18_popup_showing != nextProps.over_18_popup_showing ||
      this.props.mobile_filter_showing != nextProps.mobile_filter_showing ||
      this.props.real_flag_for_mobile_search_input_popup_showing != nextProps.real_flag_for_mobile_search_input_popup_showing ||
      this.props.overlay_full_except_menu_and_mobile_search_value != nextProps.overlay_full_except_menu_and_mobile_search_value ||
      this.props.any_popup_showing_stop_video_hover != nextProps.any_popup_showing_stop_video_hover
      
      ) {
       //console.log("yes  - FUCKIN RENDER"+this.props.video.id)
       return true
  }
  //console.log("no - DO NOT RENDER"+this.props.video.id)
  return false
  }


  render() {

    var letters = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];
    // const App = (props) => {
    //   const history = useHistory();
    //   history.push("/path");
    // };
    //alert(this.state.selected_tags[0])
    //alert(this.props.match.params.id)
    return (

      <React.Fragment>
        {/* <html className="smooth_scroll">
          <div className="smooth_scroll_class"> */}
        {this.state.showingVideos == 1 ?
        
          <MenuWrapper
              Ptags={tags}
              
              go_to_link={this.props.go_to_link}
              mins={this.state.mins}
              is_main_page={0}

              show_filters={this.props.show_filters}
              //hide_filters={this.props.hide_filters}
              //performQuery={this.performQuery} -WIP it was uncommented no idea why
              quality_all={this.state.quality_all}
              quality_hd={this.state.quality_hd}
              quality_fourk={this.state.quality_fourk}
              quality_vr={this.state.quality_vr}
              quality_non_hd={this.state.quality_non_hd}
              handleMinsChange={this.handleMinsChange}
              quality_clicked={this.quality_clicked}
              filters_showing={this.props.filters_showing}
              parent_cmd={this.componentDidMount.bind(this)}
              showTags={1}

              scrolling_down={this.props.scrolling_down}
              mobile_filter_showing={this.props.mobile_filter_showing}              
              theme={this.props.theme}
              mobile_or_tablet={this.props.mobile_or_tablet}
              User={this.props.User}
              show_login_popup={this.props.show_login_popup}
              set_scrolly={this.props.set_scrolly}
              hide_filters={this.props.hide_filters}
              set_number_of_filters={this.props.set_number_of_filters}
              number_of_filters={this.props.number_of_filters}
              comes_from_tags={1}

              //this just triggers a re render in tags left menu get tags async. 
              //there is a bug and it doesn't pick if more than one selected_tag selected
           
              over_18_popup_showing={this.props.over_18_popup_showing}
              
              overlay_full_except_menu_and_mobile_search_value={this.props.overlay_full_except_menu_and_mobile_search_value}

              show_overlay={this.props.show_overlay}

              selected_tags={this.props.selected_tags}          
              inputChange={this.props.inputChange}
              deleteInput={this.deleteInput}

              
          ></MenuWrapper>
       
        : "" }
        <div>
          <div
              id="rightSideNoLeftMenu"
              className="rightSideNoLeftMenu"
              style={{

                minHeight: this.props.mobile_or_tablet == 0 ?
                  (this.props.window_height-height_to_remove_for_footer_desktop + 11 - this.props.real_flag_for_mobile_search_input_popup_showing *38)+"px":
                  (this.props.window_height-height_to_remove_for_footer_tablet+33 - this.props.real_flag_for_mobile_search_input_popup_showing *38)+"px",
                overflowY: "visible",
                
                // backgroundColor: this.props.filters_showing == 1 || this.props.show_overlay == 1 ? 
                //   this.props.theme == lightTheme ? 
                //   configData.OVERLAY_BACKGROUND_COLOR_WHITE_THEME : configData.OVERLAY_BACKGROUND_COLOR_DARK_THEME
                  
                //   : "",
              }}
              >
            
            {" "}

            <Helmet defer={false}>
              <title>{ this.state.showingVideos == 0 ?
              "Tags - Balaporn.com"+ "" :
              this.state.pretitle_str+"Tags: "+this.state.selected_tags.map((tag) =>( " "+tag.tag ))
              +(this.state.currentPage > 1 ? " #"+this.state.currentPage : "")+" - Balaporn.com"}</title>
            </Helmet>

          {this.state.ptags.length >= 0  ?
          <div style={{
            //marginLeft: this.props.mobile_or_tablet == 1 && this.props.mobile_filter_showing == 0 ? "-10px" : "-0px",
            marginLeft: this.props.mobile_or_tablet == 1  ?  
                // this.props.mobile_filter_showing == 1 ? this.state.ptags.length ? "-0px" : "-20px" : "-10px" : 
                this.props.mobile_filter_showing == 1 ? this.state.ptags.length > 0 ? "-6px" : "-0px" : "-11px" : 
              "-0px",
            width: this.props.mobile_filter_showing == 1 ? "calc( 100% - 3px )" : "",
          }}> 
              <GetTagAsyncComponent
                calc_width={this.state.calc_width}
                cmd_function={this.componentDidMount.bind(this)}
                scrollbarwidth={this.props.scrollbarwidth}
                async_search_is_empty={this.async_search_is_empty.bind(this)}
                comes_from_leftmenu_search={0}
                id={this.state.single_tag_id}
                Ptags={this.state.ptags}
                theme={this.props.theme}

                use_state_instead_of_props_for_selected={1}
              
                selected_tags={this.props.selected_tags}
                
                

                inputChange={this.props.inputChange}

                show_wide={1}
                tags_async_error_no_tag_selected={this.state.tags_async_error_no_tag_selected}
                set_tags_async_error_no_tag_selected={this.set_tags_async_error_no_tag_selected.bind(this)}
                go_to_link={this.go_to_link}     
                mobile_or_tablet={this.props.mobile_or_tablet}   
                mobile_filter_showing={this.props.mobile_filter_showing}      
                hide_filters={this.props.hide_filters}
                showTagsAsyncLoadingWhenSearchingVideo={this.state.showTagsAsyncLoadingWhenSearchingVideo}
                set_showTagsAsyncLoadingWhenSearchingVideo={this.set_showTagsAsyncLoadingWhenSearchingVideo}  
                showingVideos={this.state.showingVideos}
                
                deleteInput={this.deleteInput}
              ></GetTagAsyncComponent>
            </div>
              : ""
          }
            <div style={{width: "100%", 
                        //border: '1px solid red',
                        position:'relative',
                        paddingRight: this.props.mobile_filter_showing == 1 ? "10px" : "20px",                                                  
                        display: this.state.showingVideos == 0 ?  "inline-block" : "none"}}>
            {this.state.showingVideos == 0 && letters.map((letter) => (
              <div
                style={{
                  marginLeft: "20px",
                  //scrollBehavior: "smooth",
                  marginTop: this.state.tags_async_error_no_tag_selected == 1 ? "4px" : 
                    "4px",
                  display:
                    this.state.showingVideos == 0 && this.state.ptags.length > 0
                      && this.state.showDotsLoading == 0
                      ? "inline-block"
                      : "none",
                }}
              >
              

                <h4 style={{marginTop: "12px"}}>
                  <a
                     href={"tags#" + letter}
                     style={{textDecoration: 'none',}}
                    className="purple_href"

                    >{letter}
                  </a>&nbsp;
                </h4>
              </div>
            ))}
            </div>
            
            {this.state.showingVideos == 0 && letters.map((letter) => (
            <div>
             <a  
                key={letter}  name={letter} 
                style={{position:'absolute', marginTop: "-35px"}}>
              </a>

              <div
                key={letter}
                style={{
                  marginLeft: "20px",
                  width:"100%",
                  display:
                    this.state.showingVideos == 0 && this.state.ptags.length > 0
                      && this.state.showDotsLoading == 0
                      ? "inline-block"
                      : "none",
                }}
              >
                 
                
                <hr  id={"#"+letter} key={letter} style={{
                    width: this.props.mobile_or_tablet == 0 ? "calc( 100% - 6px )" : "calc( 100% - 40px )",
                    textAlign: "left",
                    marginLeft: "0",
                    marginTop:"6px"
                    }}
                    className="footer_style_border"
                    />
                    
                <h4 >
                  <div   className="channels_and_pornstars_name_href">
                    <div id={"wa"+letter}>
                      {/* <a  
                    //key={letter}  name={letter} 
                    //style={{top: "-20px"}}
                    > */}
                      {letter}
                    {/* </a> */}
                    </div>
                  </div>
                </h4>

                {this.state.showingVideos == 0 && this.state.ptags
                  .filter((tag) => String(tag.tag.toUpperCase()).startsWith(letter))
                  .map((ptag, index) => (
                    <PrintTagsComponent
                      PTag={ptag}
                      letter={+letter}
                      hide={this.state.showingVideos}
                      async_search_is_empty={this.async_search_is_empty.bind(this)}
                      go_to_link={this.go_to_link}
                      set_showingVideos={this.set_showingVideos}
                      show_blur={this.props.show_blur}
                    ></PrintTagsComponent>
                  ))}
              </div>
              </div>
            ))}

            <div
              style={{
                display: this.props.isSide ? "none" : "",
              }}
            >

              <div style={{height:"28px"}}>
              {this.state.showingVideos == 0 && this.state.tag.map((tag) => (
                <h4>
                  {tag.tag}
                  {tag.has_photo != null ? (
                    <img
                      src={configData.CDN_ENDPOINT + "flags/" + tag.has_photo.replace("flags/", "") + ".svg"}
                      width="18"
                    />
                  ) : (
                    ""
                  )}
                </h4>

              ))}
              </div>

              {this.state.showingVideos == 1  ?
                <div style={{height:  "40px" }}>
                </div>
                :""}

                {/* Line below is for the gray menu */}
            {this.state.showingVideos == 1 && this.props.mobile_or_tablet == 0   ?
                <MenuWrapper
                    show_horizontal={1}
                    reload_usermenu={this.state.reload_usermenu}
                    is_main_page={0}
                    performQuery={this.performQuery}
                    mins={this.state.mins}
                    quality_all={this.state.quality_all}
                    quality_hd={this.state.quality_hd}
                    quality_fourk={this.state.quality_fourk}
                    quality_vr={this.state.quality_vr}
                    quality_non_hd={this.state.quality_non_hd}
                    filters_showing={this.props.filters_showing}
                    handleMinsChange={this.handleMinsChange}
                    quality_clicked={this.quality_clicked}
                    show_order={2}
                    parent_cmd={this.componentDidMount.bind(this)}
                    change_pretitle_str={this.change_pretitle_str}
                    theme={this.props.theme}
                    show_overlay={this.props.show_overlay}
                    set_number_of_filters={this.props.set_number_of_filters}
                    over_18_popup_showing={this.props.over_18_popup_showing}
                    //number_of_filters={this.props.number_of_filters}
                    overlay_full_except_menu_and_mobile_search_value={this.props.overlay_full_except_menu_and_mobile_search_value}
                  
                    selected_tags={this.props.selected_tags}          
                    inputChange={this.props.inputChange}
                    deleteInput={this.deleteInput}
                    go_to_link={this.props.go_to_link}
                  
                  >

                </MenuWrapper>
              :""}
             


              {this.state.videos.length == 0 &&
              this.state.readyfornone == 1 &&
              this.state.showingVideos == 1 && this.state.currentPage == 1 && this.state.filters_applied == 0
                ? <NoVideosComponent
                    title="NO VIDEOS FOUND"
                    message="Search for other keywords or choose from a list of all tags"
                    button_message="SEE ALL TAGS"
                    button_link="/tags"
                    function_flag={1}
                    function_ref={this.componentDidMount.bind(this)}
                    show_higher={this.props.mobile_or_tablet == 0 ? 1 : 0}
                  />
                : ""}

              {this.state.videos.length == 0 &&
              this.state.readyfornone == 1 &&
              this.state.showingVideos == 1 && this.state.currentPage > 1 && this.state.filters_applied == 0
                ? <NoVideosComponent
                    title="NO VIDEOS FOUND"
                    message="This page doesn't have any videos"
                    button_message="GO TO FIRST PAGE"
                    button_link={
                      window.location.pathname.split("/")[1] == "tag"
                        ? "/tag/" +
                          window.location.pathname.split("/")[2] +
                          "/" +
                          window.location.pathname.split("/")[3] +
                          "/"
                        : "/tags/1"+window.location.search
                    }
                    function_flag={1}
                    function_ref={this.componentDidMount.bind(this)}
                    show_higher={this.props.mobile_or_tablet == 0 ? 1 : 0}
                  />
                : ""}
                {this.state.videos.length == 0 &&
              this.state.readyfornone == 1 &&
              this.state.showingVideos == 1 &&  this.state.filters_applied == 1
                ? <NoVideosComponent
                    title="NO VIDEOS FOUND"
                    message="This page doesn't have any videos"
                    button_message="RESET FILTERS"
                    button_link={
                      window.location.pathname.split("/")[1] == "tag"
                        ? "/tag/" +
                          window.location.pathname.split("/")[2] +
                          "/" +
                          window.location.pathname.split("/")[3] +
                          "/"
                        : "/tags/1?"+resetFiltersURL(window.location.search)
                    }
                    function_flag={1}
                    function_ref={this.componentDidMount.bind(this)}
                    show_higher={this.props.mobile_or_tablet == 0 ? 1 : 0}
                  />
                : ""}
                {this.state.showDotsLoading == 0 ?
                  <ErrorComponent showError={this.state.showError}></ErrorComponent>
                 : ""}
                <div style={{marginLeft:  this.props.mobile_filter_showing == 1 ? "10px" 
                : 
                this.props.mobile_or_tablet == 0 ? "10px" : ""}}>
                  {this.state.showDotsLoading == 1 ?
                    <div                  
                      style={{position:"relative" ,top: this.props.mobile_or_tablet != 0 ? "-18px" : ""}}
                      >
                      <LoadingDotsComponent
                        showDotsLoading={this.state.showDotsLoading}
                        loop={parseInt(configData.USER_VIDEOS_PER_PAGE)}
                        onlytext={!this.state.showingVideos}
                        theme={this.props.theme}
                        ></LoadingDotsComponent>
                    </div>
                : ""}
                </div>

                
                  {this.state.videos.map((video) => (
                    <div
                      onMouseLeave={() => this.setCurrentVideo(-1)}
                      style={{position:"relative" ,top: this.props.mobile_or_tablet != 0 ? "-18px" : "",
                      marginLeft:  this.props.mobile_filter_showing == 1 ? "10px" 
                        : 
                      this.props.mobile_or_tablet == 0 ? "10px" : ""}}
                      >
                      <Video
                          //comes_from_tags={1}
                          history={this.props.history}
                          key={video.id}
                          video={video}
                          currentVideo={this.state.currentVideoShowing}
                          title_marginleft_20px={this.props.mobile_filter_showing ? 1 : 0}
                          setcurrentvideo={this.setCurrentVideo}
                          show_blur={this.props.show_blur}
                          go_to_link={this.go_to_link}
                          show_login_popup={this.props.show_login_popup}
                          User={this.props.User}
                          any_popup_showing_stop_video_hover={this.props.any_popup_showing_stop_video_hover}
                      ></Video>
                    </div>
                    ))}
            
              <div className="Pagination" 
                  style={{
                    display: this.state.showingVideos != 1 ? "none" : "",
                    
                    }}>
                <Pagination
                  CurrentPage={this.state.currentPage}
                  VideosCount={this.state.videosCount}
                  VideosPerPage={configData.USER_VIDEOS_PER_PAGE}
                  go_to_link={this.go_to_link}
                  show_less_numbers={this.props.pagination_show_less}
                  margintop={this.props.mobile_or_tablet == 0 ? configData.paginationMarginTop.desktop 
                    : this.props.mobile_filter_showing == 1 ? configData.paginationMarginTop.mobile : configData.paginationMarginTop.tablet}
                  
                  marginbottom={this.props.mobile_or_tablet == 0 ? configData.paginationMarginBottom.desktop 
                  : this.props.mobile_filter_showing == 1 ? configData.paginationMarginBottom.mobile : configData.paginationMarginBottom.tablet}
                  VideoURI={
                    window.location.pathname.split("/")[1] == "tag"
                      ? "/tag/" +
                        window.location.pathname.split("/")[2] +
                        "/" +
                        window.location.pathname.split("/")[3] +
                        "/"
                      : "/tags/"
                  }
                  isVisible={
                    (this.state.showingVideos == 1 && this.state.videos.length > 0 )
                    // ||
                    // (this.state.showingVideos == 1 && this.state.showDotsLoading == 1 )

                    ? 1 : 0}
                ></Pagination>
              </div>
            </div>
          </div>
          <FooterComponent 
            mobile_filter_showing={this.props.mobile_filter_showing}
            mobile_or_tablet={this.props.mobile_or_tablet}
            show_overlay={this.props.show_overlay}  
            cookies_dialog_ok={this.props.cookies_dialog_ok}
            filters_showing={this.props.filters_showing}
            theme={this.props.theme}
          ></FooterComponent>
        </div>
        
     
        
      </React.Fragment>
    );
  }
}

export default withRouter(UserTags);