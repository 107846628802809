import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  body {
    background: ${({ theme }) => theme.background};
    //background-color: #00000050
    //background: red;
    // color: ${({ theme }) => theme.text};
   // opacity: 0.6;
    //background-color: red;

   
  }

  .intermediateContainer{
    background-color:  ${({ theme }) => theme.background};
  }

  // .App {
  //   background-color: ${({ theme }) => theme.background};
  // }

  .background {
    background: ${({ theme }) => theme.background};
  }

  .rightSideFullContainer {
    background-color: ${({ theme }) => theme.rightSideBackground};
  }

  .text_color{
    color: ${({ theme }) => theme.text_color};
  }

  .rightSideNoLeftMenu {
    background-color: ${({ theme }) => theme.rightSideBackground};
  }

  .rightSideNoLeftMenu.white {
    background-color: ${({ theme }) => theme.background};
  }

  

  .hamburger{
    background-color: ${({ theme }) => theme.purple};
  }

  #hamburger_wrapperz:hover .hamburger,
  #hamburger_wrapper:hover .hamburger  {
    background-color: ${({ theme }) => theme.purple_hover};
  }

  #hamburger_wrapperz:active .hamburger,
  #hamburger_wrapper:active .hamburger  {
    background-color: ${({ theme }) => theme.purple_clicked};
  }

  #cookies_dialog{
    background-color: ${({ theme }) => theme.background};
  }

  #menu {
   // border-bottom: 1px solid ${({ theme }) => theme.purple};
    //border-bottom: 1px solid ${({ theme }) => theme.menu_footer_leftmenu_border_color};        
    background-color: ${({ theme }) => theme.background};
  }
  #menu_inner {
    background-color: ${({ theme }) => theme.background};
    border-bottom: 1px solid ${({ theme }) => theme.menu_footer_leftmenu_border_color};    
  }

  #menu_container {
    //border-bottom: 1px solid ${({ theme }) => theme.menu_footer_leftmenu_border_color};
    //border-bottom: 1px solid ${({ theme }) => theme.menu_footer_leftmenu_border_color};
  }

  #menu_border_div {
    //border-bottom: 1px solid ${({ theme }) => theme.menu_footer_leftmenu_border_color};
   // border-bottom: 1px solid ${({ theme }) => theme.menu_footer_leftmenu_border_color};
  }
  

  #mobile_search_input_div{
    border-bottom: 1px solid ${({ theme }) => theme.menu_footer_leftmenu_border_color}; 
    background-color: ${({ theme }) => theme.background};
  
  }


  .tagsLeft{
    border-right: 1px solid ${({ theme }) => theme.menu_footer_leftmenu_border_color};
    background-color: ${({ theme }) => theme.background};
   // background-color: red; //${({ theme }) => theme.background};
    z-index: 99;
  }
  
  
  

  #tablet_filter_bar{
    border-right: 1px solid ${({ theme }) => theme.menu_footer_leftmenu_border_color};
  }



  .button_trans_background{
    color: ${({ theme }) => theme.purple};
  }

  
  @media only screen and (min-width: 1195px) {
    .button_trans_background:hover{
      background-color: ${({ theme }) => theme.trans_button_hover_background};
    }

  }

  @media only screen and (max-width: 1195px) {
    .button_trans_background:hover{
      background-color: ${({ theme }) => theme.background};
    }

  }
  

  .button_trans_background:active{
    color: ${({ theme }) => theme.purple_clicked};
  }

  .button_red_border{
    background-color: ${({ theme }) => theme.background};
    color: #E62466;
    border: 1px solid  #E62466;
  }
  

  .button_red_border:hover{
    background-color: ${({ theme }) => theme.background};
    color: #F24983;
    border: 1px solid #F24983;
  }

  .button_red_border:active{
    background-color: ${({ theme }) => theme.background};
    color: #FF70A1;
    border: 1px solid #FF70A1;
  }




  .button_red_background{
    background-color: #E62466;
    border: 1px solid  #E62466;
  }
  

  .button_red_background:hover{
    background-color: #F24983;
    border: 1px solid #F24983;
  }

  .button_red_background:active{
    background-color: #FF70A1;
    border: 1px solid #FF70A1;
  }



  .button_purple_background{
    background-color: ${({ theme }) => theme.purple};
    border: 1px solid ${({ theme }) => theme.purple};
  }


  .button_purple_background:hover{
    background-color: ${({ theme }) => theme.purple_hover};
    border: 1px solid ${({ theme }) => theme.purple_hover};
  }

  .button_purple_background:active{
    background-color: ${({ theme }) => theme.purple_clicked};
    border: 1px solid ${({ theme }) => theme.purple_clicked};
  }

  .button_purple_background_only_black {
    background-color: '#5724E6';
    border: 1px solid '#5724E6';
  }

  .button_purple_background_only_black:hover {
    background-color: "#7649F2";
    border: 1px solid #7649F2;
  }

  .button_purple_background_only_black:active {
    background-color: '#9670FF';
    border: 1px solid #9670FF;
  }

  .videotitle {
    color: ${({ theme }) => theme.text_color};
  }
  .comments_username {
    color: ${({ theme }) => theme.text_color};
  }

  .progress-bar {
    background-color: ${({ theme }) => theme.purple} !important;
  }

  .menuLeft {
    background-color: ${({ theme }) => theme.background} ;
    border-right: 1px solid ${({ theme }) => theme.gray} ;
  }

  #tags_left_menu_mobile_and_tablet {
    background-color: ${({ theme }) => theme.background} ;
  }

  a.menu_href_class:link {
    color: ${({ theme }) => theme.menu_href};
  }
  
  a.menu_href_class:visited {
    color: ${({ theme }) => theme.menu_href};
  }

  a.menu_href_class:hover {
    color: ${({ theme }) => theme.purple};
    
  }
  
  a.menu_href_class:active {
    color: ${({ theme }) => theme.purple_clicked};
  }
  

  .purple_rectangle_border {
    border-left: 14px solid ${({ theme }) => theme.purple};
  }

  
  .lightgray_lightblack_menu {
    background-color: ${({ theme }) => theme.gray};
  }
  .lightgray_lightblack_menu:hover {
    background-color: ${({ theme }) => theme.gray};
    }

  .lightgray_lightblack_menu:active {
    background-color: ${({ theme }) => theme.gray};
    }

  .lightgray_lightblack_menu:hover .comments_click_to_video{
    color: ${({ theme }) => theme.purple_hover};
   }
   
   .lightgray_lightblack_menu:active .comments_click_to_video{
    color: ${({ theme }) => theme.purple_clicked};
      }    
      
      

  .tag_wrapper{
    color: ${({ theme }) => theme.text_color};
    background-color: ${({ theme }) => theme.tags_background_color};
  }

  .tag_wrapper:hover {
    color: ${({ theme }) => theme.purple};
  }

  .tag_wrapper:active {
    color: ${({ theme }) => theme.purple_clicked};
  }

  .purple_href {
    color:  ${({ theme }) => theme.purple};
  }


  
  .icon_close_x_div_wrapper_only_purple {
    background-color: #5724E6;
   -webkit-mask: url(https://cdn2.balaporn.com/content/images/close_button_x.svg) no-repeat center;
    mask: url(https://cdn2.balaporn.com/content/images/close_button_x.svg) no-repeat center;
    -webkit-mask-size:contain;
    mask-size:contain;
    width: 15px;
    height: 15px;
  }

  .icon_close_x_div_wrapper_only_purple:hover {
    background-color: #7649F2;
   -webkit-mask: url(https://cdn2.balaporn.com/content/images/close_button_x.svg) no-repeat center;
    mask: url(https://cdn2.balaporn.com/content/images/close_button_x.svg) no-repeat center;
    -webkit-mask-size:contain;
    mask-size:contain;
    width: 15px;
    height: 15px;
  }

  .icon_close_x_div_wrapper_href_only_purple:active  .icon_close_x_div_wrapper_only_purple{
    background-color: #9670ff;
   -webkit-mask: url(https://cdn2.balaporn.com/content/images/close_button_x.svg) no-repeat center;
    mask: url(https://cdn2.balaporn.com/content/images/close_button_x.svg) no-repeat center;
    -webkit-mask-size:contain;
    mask-size:contain;
    width: 15px;
    height: 15px;
  }

  .icon_close_x_div_wrapper {
    background-color: ${({ theme }) => theme.purple};
   -webkit-mask: url(https://cdn2.balaporn.com/content/images/close_button_x.svg) no-repeat center;
    mask: url(https://cdn2.balaporn.com/content/images/close_button_x.svg) no-repeat center;
    -webkit-mask-size:contain;
    mask-size:contain;
    width: 15px;
    height: 15px;
  }

  .icon_close_x_div_wrapper:hover {
    background-color: ${({ theme }) => theme.purple_hover};
   -webkit-mask: url(https://cdn2.balaporn.com/content/images/close_button_x.svg) no-repeat center;
    mask: url(https://cdn2.balaporn.com/content/images/close_button_x.svg) no-repeat center;
    -webkit-mask-size:contain;
    mask-size:contain;
    width: 15px;
    height: 15px;
  }

  .icon_close_x_div_wrapper_href:active  .icon_close_x_div_wrapper{
    background-color: ${({ theme }) => theme.purple_clicked};
   -webkit-mask: url(https://cdn2.balaporn.com/content/images/close_button_x.svg) no-repeat center;
    mask: url(https://cdn2.balaporn.com/content/images/close_button_x.svg) no-repeat center;
    -webkit-mask-size:contain;
    mask-size:contain;
    width: 15px;
    height: 15px;
  }

  
  .report_div_wrapper {
    width: 15px;
  }

  .report_icon_div {
    background-color: ${({ theme }) => theme.purple_hover};
   -webkit-mask: url(https://cdn2.balaporn.com/content/images/heart_purple2.svg) no-repeat center;
    mask: url(https://cdn2.balaporn.com/content/images/heart_purple2.svg) no-repeat center;
    
    width: 20px;
    height: 25px;
 }



 #report_video_div_wrapper{
  background-color: ${({ theme }) => theme.purple} ;
 
}



.heart_div_wrapper:hover  #report_video_div_wrapper{
  background-color: ${({ theme }) => theme.purple_hover} ;
-webkit-mask: url(https://cdn2.balaporn.com/content/images/report.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/report.svg) no-repeat center;
  width: 20px;
  height: 25px;
}

.heart_div_wrapper:active  #report_video_div_wrapper{
background-color: ${({ theme }) => theme.purple_clicked};
-webkit-mask: url(https://cdn2.balaporn.com/content/images/report.svg) no-repeat center;
mask: url(https://cdn2.balaporn.com/content/images/report.svg) no-repeat center;
width: 20px;
height: 25px;
}



.report_video_div_wrapper:hover {
background-color: ${({ theme }) => theme.purple_hover};
-webkit-mask: url(https://cdn2.balaporn.com/content/images/report.svg) no-repeat center;
mask: url(https://cdn2.balaporn.com/content/images/report.svg) no-repeat center;
width: 20px;
height: 25px;
}

.report_video_div_wrapper:active {
background-color: ${({ theme }) => theme.purple_clicked};
-webkit-mask: url(https://cdn2.balaporn.com/content/images/report.svg) no-repeat center;
mask: url(https://cdn2.balaporn.com/content/images/report.svg) no-repeat center;
width: 20px;
height: 25px;
}

.report_video_div_wrapper.active {
  background-color: ${({ theme }) => theme.purple_hover};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/report.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/report.svg) no-repeat center;
  width: 20px;
  height: 25px;
  }

.report_video_div_wrapper {
  background-color: ${({ theme }) => theme.purple};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/report.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/report.svg) no-repeat center;
  width: 20px;
  height: 25px;
  }











  .heart_empty_show_video_div_wrapper {
    background-color: ${({ theme }) => theme.purple_hover};
   -webkit-mask: url(https://cdn2.balaporn.com/content/images/heart_purple2.svg) no-repeat center;
    mask: url(https://cdn2.balaporn.com/content/images/heart_purple2.svg) no-repeat center;
    
    width: 20px;
    height: 25px;
 }


 .heart_div_wrapper:hover #lalak {
    background-color: ${({ theme }) => theme.purple_hover};
  // -webkit-mask: url(https://cdn2.balaporn.com/content/images/heart_purple2.svg) no-repeat center;
  //   mask: url(https://cdn2.balaporn.com/content/images/heart_purple2.svg) no-repeat center;
    width: 20px;
    height: 25px;
}

.heart_div_wrapper:active #lalak {
  background-color: ${({ theme }) => theme.purple_clicked};
  // -webkit-mask: url(https://cdn2.balaporn.com/content/images/heart_purple2.svg) no-repeat center;
  // mask: url(https://cdn2.balaporn.com/content/images/heart_purple2.svg) no-repeat center;
  width: 20px;
  height: 25px;
}


 
 .heart_empty_show_video_div_wrapper:hover {
  background-color: ${({ theme }) => theme.purple_hover};
 -webkit-mask: url(https://cdn2.balaporn.com/content/images/heart_purple2.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/heart_purple2.svg) no-repeat center;
  width: 20px;
  height: 25px;
}

.heart_empty_show_video_div_wrapper:active {
  background-color: ${({ theme }) => theme.purple_clicked};
 -webkit-mask: url(https://cdn2.balaporn.com/content/images/heart_purple2.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/heart_purple2.svg) no-repeat center;
  width: 20px;
  height: 25px;
}


#lalak {
  background-color: ${({ theme }) => theme.purple};

 }


.heart_full_show_video_div_wrapper {
  background-color: ${({ theme }) => theme.purple};
 -webkit-mask: url(https://cdn2.balaporn.com/content/images/heart_full_purple.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/heart_full_purple.svg) no-repeat center;
  width: 20px;
  height: 25px;
}

.heart_full_show_video_div_wrapper:hover {
  background-color: ${({ theme }) => theme.purple_hover};
 -webkit-mask: url(https://cdn2.balaporn.com/content/images/heart_full_purple.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/heart_full_purple.svg) no-repeat center;
  width: 20px;
  height: 25px;
}

.heart_full_show_video_div_wrapper:active {
  background-color: ${({ theme }) => theme.purple_clicked};
 -webkit-mask: url(https://cdn2.balaporn.com/content/images/heart_full_purple.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/heart_full_purple.svg) no-repeat center;
  width: 20px;
  height: 25px;
}


.filters_icon_div{
  background-color: ${({ theme }) => theme.purple};
-webkit-mask: url(https://cdn2.balaporn.com/content/images/filters_mobile_purple_d.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/filters_mobile_purple_d.svg) no-repeat center;
  width: 20px;
  height: 20px;
}

.filters_icon_div:hover {
  background-color: ${({ theme }) => theme.purple_hover};
-webkit-mask: url(https://cdn2.balaporn.com/content/images/filters_mobile_purple_d.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/filters_mobile_purple_d.svg) no-repeat center;
  width: 20px;
  height: 20px;
}

.mobile_filter_bar:hover  .filters_icon_div{
  background-color: ${({ theme }) => theme.purple_hover};
-webkit-mask: url(https://cdn2.balaporn.com/content/images/filters_mobile_purple_d.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/filters_mobile_purple_d.svg) no-repeat center;
  width: 20px;
  height: 20px;
}

.mobile_filter_bar:active  .filters_icon_div{
  background-color: ${({ theme }) => theme.purple_clicked};
-webkit-mask: url(https://cdn2.balaporn.com/content/images/filters_mobile_purple_d.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/filters_mobile_purple_d.svg) no-repeat center;
  width: 20px;
  height: 20px;
}

.circle_number_of_selected_filters{
  background-color: ${({ theme }) => theme.purple};
}



.heart_div_wrapper:hover .heart_full_show_video_div_wrapper{
  background-color: ${({ theme }) => theme.purple_hover};
-webkit-mask: url(https://cdn2.balaporn.com/content/images/heart_full_purple.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/heart_full_purple.svg) no-repeat center;
  width: 20px;
  height: 25px;
}

.heart_div_wrapper:active .heart_full_show_video_div_wrapper{
background-color: ${({ theme }) => theme.purple_clicked};
-webkit-mask: url(https://cdn2.balaporn.com/content/images/heart_full_purple.svg) no-repeat center;
mask: url(https://cdn2.balaporn.com/content/images/heart_full_purple.svg) no-repeat center;
width: 20px;
height: 25px;
}

.theater_mode_icon_div_wrapper {
  background-color: ${({ theme }) => theme.purple};
 -webkit-mask: url(https://cdn2.balaporn.com/content/images/theater_purple.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/theater_purple.svg) no-repeat center;
  width: 20px;
  height: 25px;
}

.theater_wrapper:hover .theater_mode_icon_div_wrapper{
  background-color: ${({ theme }) => theme.purple_hover};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/theater_purple.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/theater_purple.svg) no-repeat center;
  width: 20px;
  height: 25px; 
}

.theater_wrapper:active .theater_mode_icon_div_wrapper{
  background-color: ${({ theme }) => theme.purple_clicked};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/theater_purple.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/theater_purple.svg) no-repeat center;
  width: 20px;
  height: 25px; 
}


.heart_left_menu {
  background-color: ${({ theme }) => theme.text_color};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/heart_purple2.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/heart_purple2.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
  width: 15px;
  height: 15px;  
}

.heart_left_menu.active {
  background-color: ${({ theme }) => theme.purple};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/heart_purple2.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/heart_purple2.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
  width: 15px;
  height: 15px;  
}

.watchlist_leftmenu:active .heart_left_menu {
  background-color: ${({ theme }) => theme.purple_clicked};
  -webkit-mask: url(  heart_purple2.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/heart_purple2.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
  width: 15px;
  height: 15px;
}



.settings_left_menu_icon {
  background-color: ${({ theme }) => theme.text_color};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/settings_dark.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/settings_dark.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
  width: 15px;
  height: 15px;
}

.settings_left_menu_icon.active {
  background-color: ${({ theme }) => theme.purple};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/settings_dark.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/settings_dark.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
  width: 15px;
  height: 15px;
}

.settings_left_menu_div:active .settings_left_menu_icon {
  background-color: ${({ theme }) => theme.purple_clicked};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/settings_dark.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/settings_dark.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
  width: 15px;
  height: 15px;
}

//

.comments_left_menu_icon {
  background-color: ${({ theme }) => theme.text_color};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/comment_dark.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/comment_dark.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
  width: 15px;
  height: 15px;
}

.comments_left_menu_icon.active {
  background-color: ${({ theme }) => theme.purple};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/comment_dark.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/comment_dark.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
  width: 15px;
  height: 15px;
}

.comments_left_menu_div:active .comments_left_menu_icon {
  background-color: ${({ theme }) => theme.purple_clicked};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/comment_dark.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/comment_dark.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
  width: 15px;
  height: 15px;
}

.edit_menu_icon {
  //background-color: ${({ theme }) => theme.text_color};
  background-color: #8B8B8F;
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/edit.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/edit.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
  width: 15px;
  height: 15px;
}

.edit_menu_icon:hover {
  background-color: ${({ theme }) => theme.purple};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/edit.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/edit.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
  width: 15px;
  height: 15px;
}

.edit_menu_icon.active {
  background-color: ${({ theme }) => theme.purple};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/edit.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/edit.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
  width: 15px;
  height: 15px;
  color:red;
}

.delete_menu_icon {
  //background-color: ${({ theme }) => theme.text_color};
 // background-color: #8B8B8F;
  background-color: #E62466;
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/delete.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/delete.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
  width: 15px;
  height: 15px;
}

.delete_menu_icon:hover {
  background-color: ${({ theme }) => theme.purple};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/delete.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/delete.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
  width: 15px;
  height: 15px;
}

.delete_menu_icon.active {
  background-color: ${({ theme }) => theme.purple};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/delete.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/delete.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
  width: 15px;
  height: 15px;
  color:red;
}

.comments_href:active .edit_menu_icon {
  background-color: ${({ theme }) => theme.purple_clicked};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/edit.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/edit.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
  color:red;
  width: 15px;
  height: 15px;
}

  

//

.user_button_menu_icon {
  background-color: ${({ theme }) => theme.purple};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/user_mobile_purple_d.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/user_mobile_purple_d.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
  margin-top:0px;
  width: 16px;
  height: 16px;
}

.user_button_menu_icon:hover {
  background-color: ${({ theme }) => theme.purple_hover};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/user_mobile_purple_d.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/user_mobile_purple_d.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;

}

.user_button_menu_icon.active {
  background-color: ${({ theme }) => theme.purple};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/user_mobile_purple_d.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/user_mobile_purple_d.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
  width: 15px;
  height: 15px;
}

.user_button_menu_div:active .user_button_menu_icon {
  background-color: ${({ theme }) => theme.purple_clicked};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/user_mobile_purple_d.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/user_mobile_purple_d.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
  width: 15px;
  height: 15px;
} 

.search_left_menu_icon {
  background-color: ${({ theme }) => theme.purple};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/search.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/search.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
  width: 24px;
  height: 23px;
}

.search_left_menu_icon:hover {
  background-color: ${({ theme }) => theme.purple_hover};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/search.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/search.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
  width: 24px;
  height: 23px;
}

.search_left_menu_icon:active {
  background-color: ${({ theme }) => theme.purple_clicked};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/search.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/search.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
  width: 24px;
  height: 23px;
}

.user_left_menu_icon {
  background-color: ${({ theme }) => theme.purple};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/user.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/user.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
  width: 24px;
  height: 24px;
}

.user_left_menu_icon:hover {
  background-color: ${({ theme }) => theme.purple_hover};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/user.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/user.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
  width: 24px;
  height: 24px;
}

.user_left_menu_icon:active {
  background-color: ${({ theme }) => theme.purple_clicked};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/user.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/user.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
  width: 24px;
  height: 24px;
}

.hamburger_left_menu_icon {
  background-color: ${({ theme }) => theme.purple};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/hamburger.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/hamburger.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
  width: 20px;
  height: 15px;
}

.hamburger_left_menu_icon:hover {
  background-color: ${({ theme }) => theme.purple_hover};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/hamburger.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/hamburger.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
  width: 20px;
  height: 15px;
}

.hamburger_left_menu_icon:active {
  background-color: ${({ theme }) => theme.purple_clicked};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/hamburger.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/hamburger.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
  width: 20px;
  height: 15px;
}

.hamburger_wrapper_href:active .hamburger_left_menu_icon{
  background-color: red;
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/hamburger.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/hamburger.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
  width: 20px;
  height: 15px;
}


.watched_left_menu_icon {
  background-color: ${({ theme }) => theme.text_color};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/watch_history_dark.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/watch_history_dark.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
  width: 15px;
  height: 15px;
}

.watched_left_menu_icon.active {
  background-color: ${({ theme }) => theme.purple};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/watch_history_dark.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/watch_history_dark.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
  width: 15px;
  height: 15px;
}

.watched_left_menu_div:active .watched_left_menu_icon {
  background-color: ${({ theme }) => theme.purple_clicked};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/watch_history_dark.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/watch_history_dark.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
  width: 15px;
  height: 15px;
} 


.comments_left_menu {
  background-color: ${({ theme }) => theme.text_color};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/comment_gray.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/comment_gray.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
  width: 15px;
  height: 15px;  
}

.comments_left_menu.active {
  background-color: ${({ theme }) => theme.purple};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/comment_gray.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/comment_gray.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
  width: 15px;
  height: 15px;  
}

.comments_left_menu_div:active .comments_left_menu {
  background-color: ${({ theme }) => theme.purple_clicked};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/comment_gray.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/comment_gray.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
  width: 15px;
  height: 15px;  
}


#refresh_trending_close_icon_div:hover #refresh_trending_close_icon{
  color: ${({ theme }) => theme.purple_hover};
}

#refresh_trending_close_icon_div:active #refresh_trending_close_icon{
  color: ${({ theme }) => theme.purple_clicked};
}


.channels_left_menu {
  background-color: ${({ theme }) => theme.text_color};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/channels_dark.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/channels_dark.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
  width: 15px;
  height: 15px;  
}
.channels_left_menu.active {
  background-color: ${({ theme }) => theme.purple};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/channels_dark.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/channels_dark.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
  width: 15px;
  height: 15px;  
}

.channels_menu_div:active .channels_left_menu{
  background-color: ${({ theme }) => theme.purple_clicked};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/channels_dark.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/channels_dark.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
 
}

.pornstars_left_menu {
  background-color: ${({ theme }) => theme.text_color};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/pornstars_dark.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/pornstars_dark.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
 
}

.pornstars_menu_div:active .pornstars_left_menu {
  background-color: ${({ theme }) => theme.purple_clicked};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/pornstars_dark.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/pornstars_dark.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
  
}

.pornstars_left_menu.active {
  background-color: ${({ theme }) => theme.purple};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/pornstars_dark.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/pornstars_dark.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
 
}

.tags_left_menu {
  background-color: ${({ theme }) => theme.text_color};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/tags_dark.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/tags_dark.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
 
}

.tags_arrow_for_expansion {
  background-color: ${({ theme }) => theme.purple};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/arrow-left.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/arrow-left.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
}

.tags_menu_div:active .tags_arrow_for_expansion {
  background-color: ${({ theme }) => theme.purple_clicked};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/arrow-left.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/arrow-left.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
}

#tags_menu_desktop_id:active .tags_arrow_for_expansion {
  background-color: ${({ theme }) => theme.purple_clicked};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/arrow-left.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/arrow-left.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
}

.tags_menu_div:active .tags_left_menu {
  background-color: ${({ theme }) => theme.purple_clicked};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/tags_dark.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/tags_dark.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
 
}

.tags_left_menu.active {
  background-color: ${({ theme }) => theme.purple};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/tags_dark.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/tags_dark.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
 
}

.videos_left_menu {
  background-color: ${({ theme }) => theme.text_color};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/videos_dark.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/videos_dark.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;  
 
}

.videos_left_menu.active {
  background-color: ${({ theme }) => theme.purple};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/videos_dark.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/videos_dark.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
 
}

.videos_menu_div:active .videos_left_menu {
  background-color: ${({ theme }) => theme.purple_clicked};
  -webkit-mask: url(https://cdn2.balaporn.com/content/images/videos_dark.svg) no-repeat center;
  mask: url(https://cdn2.balaporn.com/content/images/videos_dark.svg) no-repeat center;
  -webkit-mask-size:contain;
  mask-size:contain;
 
}

.videotitle:hover .purple_href {
  color: ${({ theme }) => theme.purple_hover} !important;
}

.videotitle:active .purple_href {
  color: ${({ theme }) => theme.purple_clicked} !important;
}

  .purple_href:hover {
    color:  ${({ theme }) => theme.purple_hover} !important;
  }

  .purple_href_hover {
    color:  ${({ theme }) => theme.purple_hover} !important;
  }

  .purple_href_hover:active {
    color:  ${({ theme }) => theme.purple_clicked} !important;
  }


  .purple_href:active {
    color:  ${({ theme }) => theme.purple_clicked} !important;
  }
  .purple_href_active {
    color:  ${({ theme }) => theme.purple_clicked} !important;
  }

  .purple_background {
    background-color: ${({ theme }) => theme.purple};
  }

  .menu_item_left.active {
    border-bottom: 2px solid ${({ theme }) => theme.purple};
    height: 57px;
  }
  

  .menu_item_left.active:active {
    
    border-bottom: 2px solid ${({ theme }) => theme.purple_clicked};
  }
  
  
  .menu_item_right.active {
    border-bottom: 2px solid ${({ theme }) => theme.purple};
  }

  .menu_item_right.active:active {
    border-bottom: 2px solid ${({ theme }) => theme.purple_clicked};
  }

  a.menu_href_class.active:link {
    color: ${({ theme }) => theme.purple};
  }
  a.menu_href_class:active {
    color: ${({ theme }) => theme.purple_clicked} !important;
  }
  
  a.menu_href_class.active:visited {
    color: ${({ theme }) => theme.purple};
  }

  .tab_bubble {
    color: ${({ theme }) => theme.menu_href}; 
    border: 1px solid ${({ theme }) => theme.tags_bubble_border_color};
    
    // margin-top: 6px;
  }
  
  .tab_bubble.active {    
    background-color: ${({ theme }) => theme.background};
    border: 1px solid ${({ theme }) => theme.purple};
    color: ${({ theme }) => theme.purple};
    // margin-top: 6px;
  }

  .tab_bubble:hover {
    color: ${({ theme }) => theme.purple};
  }


  .tab_bubble:active {
    color: ${({ theme }) => theme.purple_clicked} !important;
    border: 1px solid ${({ theme }) => theme.purple_clicked} !important;
  }  

  
  .tags_left_menu_href {
    color: ${({ theme }) => theme.tags_left_menu_href_color};
  }

  .tags_left_menu_href:active {
    color: ${({ theme }) => theme.purple_clicked};
    //font-weight: bold !important;
  }

  .pornstar_channel_in_view_video_href_class {
    color: ${({ theme }) => theme.text_color};
  }

   .settings_input_div{ 
    color: ${({ theme }) => theme.text_color} !important;
    background-color: ${({ theme }) => theme.background} !important;
    border: 1px solid  ${({ theme }) => theme.menu_footer_leftmenu_border_color} !important;
    padding-left: 29px !important;
    
}



  .pornstar_channel_input {
    color: ${({ theme }) => theme.text_color} !important;
    background-color: ${({ theme }) => theme.background} !important;
    border: 1px solid  ${({ theme }) => theme.menu_footer_leftmenu_border_color} !important;
  }

  .footer_style_border {
    border:  1px solid  ${({ theme }) => theme.menu_footer_leftmenu_border_color} !important;
  }


  // the code below changes the color of the close icon in autocomplete
  .MuiAutocomplete-endAdornment svg{
    fill: ${({ theme }) => theme.text_color} !important;
  }
  
  .rank_videos_number_channel_show_video_page {
    color: ${({ theme }) => theme.tags_left_menu_href_color};
  }

  .channels_and_pornstars_name_href {
    color: ${({ theme }) => theme.text_color};
  }

  select {
    background-image: url("data:image/svg+xml;utf8,<svg fill='${({ theme }) => theme.textcolor_html_encoded}' height='20' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M4.41075 6.91076C4.73618 6.58533 5.26382 6.58533 5.58926 6.91076L10 11.3215L14.4107 6.91076C14.7362 6.58533 15.2638 6.58533 15.5893 6.91076C15.9147 7.2362 15.9147 7.76384 15.5893 8.08928L10.5893 13.0893C10.2638 13.4147 9.73618 13.4147 9.41075 13.0893L4.41075 8.08928C4.08531 7.76384 4.08531 7.2362 4.41075 6.91076Z'/></svg>");

  }

  .comment_textarea{
    background-color:${({ theme }) => theme.gray} !important;
    color: ${({ theme }) => theme.text_color};
    outline: none;
    border-radius: 4px;
  }

  #footer_id{
    background-color:${({ theme }) => theme.background} !important;
  }
   

  //the below is for report in show videos, the email input in the form
  #email {
    color: ${({ theme }) => theme.text_color};
  }
  

  .comment_input{
    background-color:${({ theme }) => theme.gray} !important;
    color: ${({ theme }) => theme.text_color};
    border:1px solid ${({ theme }) => theme.menu_footer_leftmenu_border_color};
    border-radius: 4px;
  }

  .comment_input-focused, .comment_textarea-focused {
    background-color:${({ theme }) => theme.gray} !important;
    outline: none;
    border-width: 0px;  
    color: ${({ theme }) => theme.text_color};
    border:1px solid ${({ theme }) => theme.purple} ;
    border-radius: 4px;
    
  }

  .comment_input-focused::placeholder, .comment_input::placeholder, 
  .comment_textarea::placeholder, .comment_textarea-focused::placeholder {
    //color:  ${({ theme }) => theme.tags_left_menu_href_color}; 
    color: #A8A8AE; 
  }
  .channels_and_pornstars_name_href:hover {
    color: ${({ theme }) => theme.purple_hover};
  }

  .channels_and_pornstars_name_href:active {
    color: ${({ theme }) => theme.purple_clicked};
  }

  #popup{
    background: ${({ theme }) => theme.purple};
  }

  #internal_popup{
    background-color: ${({ theme }) => theme.background};
  }

  

  .comments_href{
    color:  ${({ theme }) => theme.text_color};
    //background-color: ${({ theme }) => theme.gray};
    
  }

  .comment_menu{
    background-color: ${({ theme }) => theme.gray};
  }
  .comments_href:hover{
    //color:  ${({ theme }) => theme.purple} !important;
    background-color: ${({ theme }) => theme.gray_for_horizontal_filters};
  }
  
  .comments_href:active{
    color:  ${({ theme }) => theme.purple_clicked} !important;
    background-color: ${({ theme }) => theme.gray_for_horizontal_filters};
    
  }

  .delete_comments_href{
    color:  ${({ theme }) => theme.text_color};
   // background-color: ${({ theme }) => theme.gray};
    
  }

  .delete_comments_href:hover{
    //color:  ${({ theme }) => theme.purple} !important;
    background-color: ${({ theme }) => theme.gray_for_horizontal_filters};
  }
  
  .delete_comments_href:active{
    //color:  ${({ theme }) => theme.purple_clicked} !important;
    color: #E62466;
    background-color: ${({ theme }) => theme.gray_for_horizontal_filters};
    
  }

  .circle:hover {
    background-color: ${({ theme }) => theme.purple};
    color: white;
  }


  .button_trans_background.white {
    color:  ${({ theme }) => theme.menu_href};
  }


  .img_class{
    background-color: ${({ theme }) => theme.gray};
  }

  .pagination-item {
    background-color: ${({ theme }) => theme.background+" !important"};
    border: ${({ theme }) => "1px solid "+theme.purple+" !important"};
    color: ${({ theme }) => theme.tags_left_menu_href_color+" !important"};
  }
  .pagination-item:hover {
    background-color: ${({ theme }) => theme.gray+" !important"};
  }

  .pagination-item.active {
    background-color: ${({ theme }) => theme.purple+" !important"};
    border: ${({ theme }) => "1px solid "+theme.purple+" !important"};
    color: white !important;
  }

  .pagination-item:active {
    border:  ${({ theme }) => "1px solid "+theme.purple_clicked+" !important"};
    color: ${({ theme }) => theme.purple_clicked+" !important"};
  }

  .pornstar_channel_specific_background_color {
    background-color: ${({ theme }) => theme.pornstar_channel_description_background};
  }

  .pornstar_channel_specific_background_color_tags_section{
    background-color: ${({ theme }) => theme.pornstar_channel_description_background};
  } 

  .pornstarPopup{
    background-color:  ${({ theme }) => theme.white_and_gray_d};
  }

  .scrollbar::-webkit-scrollbar {
    width: 8px; /* width of the scrollbar */
  }
  
  /* Track (background) */
  .scrollbar::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.background}; /* color of the track */
    border-radius: 4px; /* rounded edges */
  }
  
  /* Handle */
  .scrollbar::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.scrollbar}; /* color of the handle */
    border-radius: 4px; /* rounded edges */
  
  }
  
  

  .arrow-down{
    border-top: 20px solid ${({ theme }) => theme.pornstar_channel_description_background};
  }
  .arrow-up{
    border-bottom: 20px solid ${({ theme }) => theme.pornstar_channel_description_background};
  }

  .qualityBox.active {
    border: 1px solid ${({ theme }) => theme.purple};
    color: ${({ theme }) => theme.purple};
  }

  #tags_popup_desktop{
    background-color: ${({ theme }) => theme.background};
  }

  .qualityBox:active {
    border: 1px solid ${({ theme }) => theme.purple_clicked};
    color: ${({ theme }) => theme.purple_clicked};
  }

  .qualityBox:active:active {
    border: 1px solid ${({ theme }) => theme.purple_clicked};
    color: ${({ theme }) => theme.purple_clicked};
  }

  .leftMenuElement a {
    text-decoration: none;
    color: ${({ theme }) => theme.text_color}; 
  }
  
  @media only screen and (min-width: 1195px) {
  
  .leftMenuElement:hover {
    text-decoration: none;
    background-color: ${({ theme }) => theme.gray}; 
    height: 32px;
  } 
}

  .leftMenuElement_gray_background a {
    text-decoration: none;
    color: ${({ theme }) => theme.text_color}; 
  }
  
  .mobile_filter_bar{
    text-decoration: none;
    background-color: ${({ theme }) => theme.pornstar_channel_description_background};
    color: ${({ theme }) => theme.purple};
  }

  .mobile_filter_bar:hover {
    text-decoration: none;
    background-color: ${({ theme }) => theme.pornstar_channel_description_background};
    color: ${({ theme }) => theme.purple_hover};
  }

  .mobile_filter_bar:active {
    text-decoration: none;
    background-color: ${({ theme }) => theme.pornstar_channel_description_background};
    color: ${({ theme }) => theme.purple_clicked};
  }

  .leftMenuElement_gray_background:hover {
    text-decoration: none;
    background-color: ${({ theme }) => theme.search_background_hover}; 
    height: 32px;
  } 

  .menu_href_color {
    color: ${({ theme }) => theme.purple} !important;
  }

  .menu_href_color:active {
    color: ${({ theme }) => theme.purple_clicked} !important;
  }
  
  .qualityBox:hover {
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.purple};
    cursor: pointer;
  }
  

  .qualityBox {   
    border: 1px solid ${({ theme }) => theme.quality_box_border};
  }

  .qualityBox_horizontal:hover {
    
    background-color: ${({ theme }) => theme.gray};
    color: ${({ theme }) => theme.purple};
    
    cursor: pointer;
  }

  
.qualityBox_horizontal{
  
}
  .videotitle:hover {
    color: ${({ theme }) => theme.purple};
  }

  .videotitle:active {
    color: ${({ theme }) => theme.purple_clicked};
  }

  .videotitle.active {
    color: ${({ theme }) => theme.purple};
  }

  .videowrapper:active .videotitle{
    color: ${({ theme }) => theme.purple_clicked};
  }

  .horizontal_filters { 
      //background: ${({ theme }) => theme.horizontal_filters_background};
        background: ${({ theme }) => theme.background};

  }

  #refresh_trending_close_icon:active #refresh_trending_close_icon{
    color: red;
  }

  .hr_color {
    background-color: ${({ theme }) => theme.tags_background_color};
  }

  .mobile_user_menuz {
    background-color: ${({ theme }) => theme.search_background};
    
  }
  
  #tags_popup_desktop {
    background-color: ${({ theme }) => theme.search_background};
  }

  #desktop_pstar_channel_info_div
  {
    border-bottom: 1px solid ${({ theme }) => theme.tags_background_color} !important;
  }

 

  .theater_wrapper {
    border: 1.0px solid ${({ theme }) => theme.purple};
    color: ${({ theme }) => theme.purple};    
  }
  
  .theater_wrapper:hover {
    background-color:${({ theme }) => theme.gray};
 //   border: 1px solid ${({ theme }) => theme.purple_hover}  ;
    color: ${({ theme }) => theme.purple_hover} !important;
  }
  
  .theater_wrapper:active {
    background-color:${({ theme }) => theme.gray};
   // border: 1px solid ${({ theme }) => theme.purple_clicked};
    color: ${({ theme }) => theme.purple_clicked} !important;
  }


  @media only screen and (min-width: 1195px) {

    .heart_div_wrapper:hover {
      background-color: ${({ theme }) => theme.gray};
    }
  }
  @media only screen and (max-width: 1195px) {

    .heart_div_wrapper:hover {
      background-color: ${({ theme }) => theme.background};
    }
  }

  .report_div_wrapper:hover {
    background-color: ${({ theme }) => theme.gray};
  }
  
  #search_items {
    border: 1px solid ${({ theme }) => theme.purple};
    // border-top: 1px solid ${({ theme }) => theme.quality_box_border};
    z-index: 55;

    
  }

  

  .formcontrol2:not(active){
    color: ${({ theme }) => theme.text_color};
    background-color: ${({ theme }) => theme.pornstar_channel_description_background};
    border:1px solid ${({ theme }) => theme.menu_footer_leftmenu_border_color};
  }
  .form-group .formcontrol2:focus {
    color: ${({ theme }) => theme.text_color};
    background-color: ${({ theme }) => theme.pornstar_channel_description_background};
    border:1px solid ${({ theme }) => theme.menu_footer_leftmenu_border_color};
    outline: 0px solid ${({ theme }) => theme.pornstar_channel_description_background};
    box-shadow: none;
  }

  
  .inputElement:focus {
    border-color: #E0E0E0; /* Change this to your desired focus color */
    box-shadow: 0 0 2px rgba(33, 150, 243, 0.6);
    
  }



 
  .theater_wrapper a {
    color: ${({ theme }) => theme.purple};
  }

  .search_individual_item_wrapper{
    background-color: ${({ theme }) => theme.search_background}
  }

  .search_individual_item_wrapper.active{
    background-color: ${({ theme }) => theme.search_background_hover}
  }

  .search_link_href_item_classname {
    text-decoration: none;
  
    color: ${({ theme }) => theme.tags_left_menu_href_color};
  
  }
  
  .search_link_href_item_classname:hover{ 
    text-decoration: none;  
    color: ${({ theme }) => theme.tags_left_menu_href_color} 
  }

  .search_link_href_item_classname:active {
    text-decoration: none;
    color: ${({ theme }) => theme.purple};  
  }

 .search_items_title {
  background-color: ${({ theme }) => theme.pornstar_channel_description_background};
  color: ${({ theme }) => theme.text_color};
  border-top: 1px solid ${({ theme }) => theme.quality_box_border};
 }

 .dummy_search_top_border{
  border-top: 1px solid ${({ theme }) => theme.quality_box_border};
 }
  

 .custom-highlight {
  background-color: transparent;
  color: ${({ theme }) => theme.purple};
}


`;