import React, { Component } from 'react';
import { Form } from 'react-bootstrap'
class OrderFilter extends Component {
    state = {  }
    componentDidMount(){
       if( window.location.href.indexOf("order=trending") > 0){
           //alert('bike')
            this.props.change_pretitle_str("Trending ", this.props.sort_best)
       }
       else if( window.location.href.indexOf("order=newest")){
        this.props.change_pretitle_str("Newest ", this.props.sort_best)
           // alert("newest")
        }
        
    }

    shouldComponentUpdate(nextProps, nextState) {        
        if(
            
            this.props.sort_best != nextProps.sort_best ||
            this.props.sort_trending != nextProps.sort_trending || 
            this.props.sort_new != nextProps.sort_new ||
            this.props.sort_period != nextProps.sort_period ||
            this.props.best_parameter != nextProps.best_parameter ||
            this.props.show_overlay != nextProps.show_overlay ||
            this.props.filters_showing != nextProps.filters_showing

            
          ) {
             //console.log("yes  - FUCKIN RENDER")
             return true
        }
       //console.log("no - DO NOT RENDER")
        return false
      }
    render() { 
        return (
            <div 
            id="horizontal_order"
            style={{position:"relative", float:"right",
                 height: this.props.sort_trending == 1 || this.props.sort_best == 1 ? "31px" : "19px",
                 zIndex: "3",                
                minWidth:"319px",
                
                display: "inline-block"  ,
                width:"100%", 
                left:"243px", marginTop: "24px",marginLeft: "240px"}}
            className={this.props.filters_showing == 1  || this.props.show_overlay == 1 ? "" : ""}>
                    <a href="javascript:void(0);"
                        onClick={() => this.props.change_sort("newest")}
                        >
                        <div style={{position:"relative", float:"left",fontSize:'16px'}}
                            className={this.props.sort_new == 1 ? "tab_bubble active " : "tab_bubble "}>
                            Newest
                        </div>
                    </a>
                    <a href="javascript:void(0);"
                        onClick={() => this.props.change_sort("trending")}
                        >
                        <div style={{position:"relative", float:"left",marginLeft: "8px"}}
                            className={this.props.sort_trending == 1 ? "tab_bubble active " : "tab_bubble "}>                    
                            Trending
                        </div>
                    </a>
                    <a href="javascript:void(0);"
                        onClick={() => this.props.change_sort("best")}>
                        <div style={{position:"relative", float:"left",marginLeft: "8px",
                        //  fontSize: "16px", lineHeight:"20px"
                        }}
                            className={this.props.sort_best == 1 ? "tab_bubble active " : "tab_bubble "}>
                            Best videos
                        </div>
                    </a>
                        {/* <div 
                       className={"tab_bubble_no_hover"}
                        style={{
                            position:"relative",
                           
                            float:"left", 
                            // marginTop: "8px",
                            // marginLeft: "2px", 
                            border: "0px solid black",

                            border: '1px solid purple',
                           // width: "100%",
                          //  height: "20px",
                            
                            display: this.props.sort_best == 1 ? "inline": "none"
                            }}
                        > */}

                        <div style={{
                            marginTop:"25px",position:"relative",
                            display: this.props.sort_best == 1 ? "" : "none"
                        }}>
                            <div
                                style={{position:"absolute",float:"left",top: "33px", left: "0px",
                                border: '0px solid green',  }}
                                >
                                <h2                     
                                    className="pornstar_channel_in_view_video_href_class custom-h2" 
                                    style={{marginLeft: "0px",position:"relative",float:"left"}}> 
                                    BEST
                                </h2>
                                <div
                                style={{position:"relative",float:"left",marginLeft: "15px"}}
                        >

                            <Form.Group controlId="exampleForm.ControlSelect1"
                                    onChange={(val) => this.props.change_best_parameter(val.target.value)}
                                        style={{ display: [7,15,30].length > 1 ? "inline": "none",}}
                                    >
                                        <Form.Control as="select"
                                          className="formcontrol2"
                                        value={this.props.sort_best == 1 ?  this.props.get_best_parameter_from_id(this.props.best_parameter,this.props.sort_best): ()=>{}}
                                        //style={{height:"30px",fontSize:"14px",marginLeft: "3px",marginTop:"-4px",paddingTop:"5px",borderRadius: '4px'}}
                                        //style={{height:"30px",fontSize:"14px",marginLeft: "3px",marginTop:"-4px",lineHeight: "30px",borderRadius: '4px'}}
                                        style={{
                                            height: "24px", lineHeight:'22.5px', 
                                            width: "auto", paddingTop: "0px",
                                            paddingRight:"0px", 
                                                        paddingBottom: 0,  
                                                        //marginLeft: '-8px',
                                                        marginTop:"-1px",
                                                        textAlign:"left",
                                                        paddingLeft:"8px",
                                                        paddingRight: "0px",
                                                        width: this.props.best_parameter == 'mostcommented' 
                                                        ? '168px' : "auto",
                                                        //132px
                                                        right:"0px",
                                    }}
                                        
                                        >
                                            {["Most viewed","Most commented","Our favorites"].map((pornstar,index) => (
                                            <option value={pornstar}>{pornstar}</option>
                                            ))}
                                    </Form.Control>
                                </Form.Group>
                            </div>
                        </div>  
                       
                    {/* </div> */}
                    </div>

                    {/* trending */}
                    {/* <div 
                         
                        // className={this.props.sort_trending == 1 ? "tab_bubble active" : "tab_bubble"}
                        className={"tab_bubble_no_hover"}
                        style={{
                            position:"relative", 
                            float:"left", 
                            marginTop: "18px",
                            marginLeft: "2px", 
                            border: "0px solid black",
                            display: this.props.sort_trending == 1 ? "inline-block": "none"
                            }}
                        > */}
                         <div style={{
                            marginTop:"25px",position:"relative",
                            display: this.props.sort_trending == 1 ? "" : "none"
                        }}>
                        
                            <div
                                 style={{position:"absolute",float:"left",top: "33px", left: "0px"  }}
                                //style={{position:"relative",float:"left",width: "100%",display: 'inline-block'}}
                                >
                                <h2                     
                                    className="pornstar_channel_in_view_video_href_class custom-h2" 
                                    style={{marginLeft: "0px",position:"relative",float:"left"}}> 
                                    TRENDING
                                </h2>
                                <div
                                style={{position:"relative",float:"left",marginLeft: "15px"}}
                        >

                    <Form.Group controlId="exampleForm.ControlSelect1"
                                onChange={(val) => this.props.change_sort_period(val.target.value)}
                                    style={{  display: [7,15,30].length > 1 ? "inline": "none"
                                            
                                }}
                                >
                                    <Form.Control as="select" 
                                    className="formcontrol2"
                                    value={this.props.sort_period}
                                  
                                style={{ height: "24px", lineHeight:'23.5px', 
                                            paddingTop: "0px",
                                            paddingRight:"0px", 
                                            paddingBottom: 0,  
                                            //marginLeft: '-8px',
                                            marginTop:"-1px",
                                            textAlign:"left",
                                            paddingLeft:"8px",
                                            paddingRight: "8px",
                                            width: this.props.sort_period == 7 ? "auto" : "95px", //91 is the original
                                            right:"0px", }}

                                  
                                  >
                                        {[7,15,30].map((pornstar,index) => (
                                        <option value={pornstar}>{pornstar} days&nbsp;&nbsp;&nbsp;&nbsp;</option>
                                        ))}
                                </Form.Control>
                            </Form.Group>
                                        
                            </div>
                        </div>  
                       
                    </div>
                
                
            </div>
            
          );
    }
}
 
export default OrderFilter;