import React, { Component } from "react";
import "./../App.css";
import configData from "../config.json";
import axios from "axios";
import { title_to_url } from "./global_arrays";
import {  toast_error, toast_info } from "./global_arrays";

import { ProgressBar } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";

import {  
  faCircleNotch,
} from "@fortawesome/free-solid-svg-icons";
import 'react-toastify/dist/ReactToastify.css';

library.add(faCircleNotch);

class Video extends Component {

  make_progress_bar_invisible_helper = (id) => {
    try{
    document.getElementById("progressbar"+id).style.visibility = "hidden"
  }catch(e){

  }
  }

  shouldComponentUpdate(nextProps, nextState) {
    
    if(this.props.currentVideo == nextProps.video.id || 
        this.state.hide_add_to_fav != nextState.hide_add_to_fav ||
        this.props.show_blur != nextProps.show_blur ||
        this.props.title_marginleft_20px != nextProps.title_marginleft_20px ||
        this.props.any_popup_showing_stop_video_hover != nextProps.any_popup_showing_stop_video_hover
      ) {
         //console.log("yes  - FUCKIN RENDER"+this.props.video.id)
         return true
    }
    //console.log("no - DO NOT RENDER"+this.props.video.id)
    return true
  }

  showVideo =  (id,counter) => {
    //console.log("show video")
    if(id != undefined){  
      
      this.props.setcurrentvideo(id)
      var self=this;

      try{
        var data_src = document.getElementById("video_preview_element"+id).getAttribute('data-src')  
        document.getElementById("video_image"+id).style.borderRadius='4px'    
        //document.getElementById("video_preview_element"+id).style.borderRadius='0px'
        //alert(this.preview_url)
        //document.getElementById("temporary_video_placeholder").setAttribute('src', data_src)
        document.getElementById("progressbar"+id).style.visibility = "visible"
      }catch(e){
        console.log("Exception - calling showVideo again"+counter)
        setTimeout(function() { 
          if(counter < 6)
             self.showVideo(id,counter+1) 
         },1500)
        }
      if(navigator.userAgent.indexOf("OPR") > 0 || navigator.userAgent.indexOf("Opera") > 0){
        data_src = data_src.replace(".mp4",".webm ")
      }     
    //console.log(data_src)
    var xhrReq = new XMLHttpRequest();
    xhrReq.open('GET', data_src, true);
    xhrReq.responseType = 'blob';
    //var self=this;

    xhrReq.onload = function() {
      if (this.status == 200) {
        try{
          
          document.getElementById("video_preview_element"+id).setAttribute('src', data_src)          
          self.setState({showVideo: 1})
          setTimeout(function() { self.make_progress_bar_invisible_helper(id) },500)
        }catch(e){
          if(counter < 6 ){
              console.log("again calling showvideo due to exception")
             setTimeout(function() { self.showVideo(id) },500)
          }else{  
            console.log("more than 6, exiting showvideo")
          }
        }
      }
  }

  
    xhrReq.onprogress = function(e){
      if(e.lengthComputable) {
          var percentComplete = ((e.loaded/e.total)*100|0);
          //console.log('progress: ', percentComplete);
          self.setState({percentageLoaded: percentComplete})
      }
  }
  xhrReq.send();

    }
    //this.setState({ showVideo: 1 });
  };

  hideVideo = (id) => {
    //console.log("hide");
    this.setState({ showVideo: 0 });
    //document.getElementById("video_preview_div"+id).style.visibility="hidden"
    document.getElementById("video_image"+id).style.borderRadius='4px'    
    try{
     document.getElementById("progressbar"+id).style.visibility="hidden"
  }catch(e){}
  };
  
  componentDidMount() {
    //add_to_watchlist
    // if(this.props.watchlist == 1)
    //   alert(this.props.watchlist)
    // if (this.props.watchlist) {
    //   alert(this.props.watchlist+':'+this.props.video.id+'0-+'+"delete_from_watchlist" + this.props.video.id)
    //   document.getElementById(
    //     "delete_from_watchlist" + this.props.video.id
    //   ).style.display = "block";
    //   document.getElementById(
    //     "add_to_watchlist" + this.props.video.id
    //   ).style.display = "none";
    // } 
  }

  add_to_watchlist = () => {
    var bodyFormData = new FormData();
    
    //alert("add");
    const start = Date.now();
    bodyFormData.append("token", localStorage.getItem("token"));
    bodyFormData.append("video_id", this.props.video.id);
    this.setState({addToWatclistSpinner: 1})
    //alert(toast_success("[anagia"))
    
    //toast_success("Panagitsa")
    
    //this.toast_success("Successfully added to watchlist")
    var self = this;
    axios({
      method: "post",
      url: configData.PRIVATE_API_ENDPOINT + "/add_to_watchlist",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
      timeout: configData.TIMEOUT_LIMIT
    })
      .then(function (response) {
        //handle success
        //alert("added");
        setTimeout(async() => {
          await self.setState({addToWatclistSpinner: 0})
          
          
          document.getElementById("heart"+self.props.video.id).src=configData.CDN_ENDPOINT+"images"+"/heart-active.svg"
          ///document.getElementById("add_to_favorites"+self.props.video.id).style.display="none"
          self.setState({hide_add_to_fav: 1})
          //toast_success("Successfully added to watchlist")
          
            //alert("test")
        }, configData.WAITING_MILIS - (Date.now() - start));
      })
      .catch(function (error) {
        //handle error
        setTimeout(async() => {
          
          if (error.response.status == 401) {
            setTimeout(async() => {
              //document.getElementById("heart"+self.props.video.id).src="/heart.svg"
              self.setState({addToWatclistSpinner: 0})
              toast_error("You are not logged in")
            }, configData.WAITING_MILIS - (Date.now() - start));
          } else if (error.response.status == 595) {
            setTimeout(async() => {
              //alert("This video is already in your watchlist");
              self.setState({addToWatclistSpinner: 0})
              toast_info("The video is already in your watchlist")
              document.getElementById("heart"+self.props.video.id).src=configData.CDN_ENDPOINT+"images"+"/heart-active.svg"
              ///document.getElementById("add_to_favorites"+self.props.video.id).style.display="none"
              self.setState({hide_add_to_fav: 1})
            }, configData.WAITING_MILIS - (Date.now() - start));
          } else {
            toast_error("An unknown error occured. Please try again later")
          }
        });
    }, configData.WAITING_MILIS - (Date.now() - start));
  };

  secondsToMinutes(totalSeconds) {
    let hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    let minutes = Math.floor(totalSeconds / 60);
    if (minutes < 10) minutes = "0" + minutes;
    let seconds = totalSeconds % 60;
    if (seconds < 10) seconds = "0" + seconds;
    if (hours >= 1) {
      return "0" + hours + ":" + minutes + ":" + seconds;
    } else {
      return minutes + ":" + seconds;
    }

  }

  state = {
    showVideo: 0,
    addToWatclistSpinner: 0,
    percentageLoaded: 0,
    watchlistHover : 0,
    hide_add_to_fav: 0,
  };

  render() {
    // const App = (props) => {
    //   const history = useHistory();
    //   history.push("/path");
    // };
   
   
    // try{
    //   alert('panagia=1')
    //   var x=this.props.video.title[2] }
    //     catch(e){
    //       alert('panagia  ')
    //       return({})}
   
   
  // try{
      const {
        id,
        title,
        hash,
        preview_filename,
        thumb_filename,
        duration,
        provider_name,
        has_to_be_viewed_externally, 
        is_hd,
      } = this.props.video;
      const { showVideo } = this.state;
      let thumb_url = configData.CDN_ENDPOINT +configData.env+ "/video/" + hash + "/" + thumb_filename;
      let preview_url =
        configData.CDN_ENDPOINT +configData.env+"/video/" + hash + "/" + preview_filename;
    // }catch(e){alert('wnasou');return({})}
    
    return (  
      <React.Fragment>
       
        <div 
        onMouseLeave={() =>  this.hideVideo(id,0)}
        className="videowrapper" key={id}>
          <div style={{position:"relative",zIndex:"9999",top:"2px", borderRadius:"4px",}}>
            
          <ProgressBar id={"progressbar"+id} 
          style={{ height: "2px", 
              visibility: "hidden", 
              marginLeft: "0.5px",
              borderRadius:"4px",
              marginRight: "0.5px"}} 
              now={this.state.percentageLoaded} />
          </div>
          <div 
            onMouseEnter={() =>  this.showVideo(id,0)}
            onTouchStart={() =>  this.showVideo(id,0)}
            onMouseLeave={() =>  this.hideVideo(id,0)}
            //onTouchCancel={() =>  this.hideVideo(id,0)}
            //onTouchCancelCapture={() =>  this.hideVideo(id,0)}

            //onTouchEnd={() =>  this.hideVideo(id,0)}
            //onTouchEndCapture={() =>  this.hideVideo(id,0)}

            >
            
          
            <div
              style={{ display: showVideo == 1 && this.props.currentVideo == id  && this.props.any_popup_showing_stop_video_hover == 0 ? "none" : "inline" }}
              className="video_image"
              
            >
              <a href={"/watch/"+id+"/"+title_to_url(title)}
                onClick={(event) => this.props.go_to_link(event, "/watch/"+id+"/"+title_to_url(title))}
              >
                <img          
                className={this.props.show_blur == 1 ? "img_class blur" : "img_class" } src={thumb_url}
                id={"video_image"+id} 
                  style={{borderRadius: "4px", }}></img>
                {/* <Blur className="img_class" style={{height:"200px",width: "100%"}} blurRadius={55} img={thumb_url} /> */}
              </a>

              <div 
                className="quality_on_video "
                style={{display: has_to_be_viewed_externally == 1 ? "" : "none", top: '8px', left: '8px', width: 'auto'}}
              ><div style={{top: "50%",
                // transform: "translateY(-50%)",position:'relative',height:"20px",
                // paddingTop: navigator.userAgent.indexOf("Firefox") != -1 ? "10px" : "10.5px", 
                 paddingLeft: "4px",paddingRight:"4px"
                }}>
                EXTERNAL
                </div>
                </div>


              <div 
                className="quality_on_video "
                style={{display: is_hd < 1 ? "none" : ""}}
              ><div style={{

                // position:'relative',
                // top: "50%", transform: "translateY(-50%)",      
                // height: "14px", //lineHeight:"14px"
                }}>
                {is_hd == 1 ? "HD" : "4K"}
                </div>
                </div>
                
                <div 
                  className="duration_on_video"
                  style={{ left: is_hd < 1 ? "8px " : "44px", width: duration > 3600 ? "68px " : "48px"}}
                  >
                    <div style={{
                //       top: "50%",
                // transform: "translateY(-50%)",position:'relative',
                // height:"20px",
                // paddingTop: navigator.userAgent.indexOf("Firefox") != -1 ? "10px" : "10.5px", 
                
                }}>
                  {this.secondsToMinutes(duration)}
                </div>
              </div>
                {/* <div id={"add_to_watchlist" + this.props.video.id}>
                  <a href="javascript:void(0)" onClick={this.add_to_watchlist}>
                    Add to watchlist-z
                  </a>
                </div> */}
            </div>
          
          
          <div
            id={"video_preview_div"+id}        
            style={{ display: showVideo == 1 && this.props.currentVideo == id && this.props.any_popup_showing_stop_video_hover == 0 ? "inline" : "none" }}
            className="video_image"
            
          
          >
            {/* {this.props.watchlist == 1 ?  */}
              <div id={"delete_from_watchlist"+id} 
                className="watchlist_on_video"
                style={{width: "184px",height:"24px",display: this.props.watchlist ? "block" : "none", }}
                >
                  <a
                      href="javascript:void(0);"
                      onClick={() => this.props.DeleteFunction(this.props.video.id)}
                      style={{textDecoration: "none"}}
                    >
                      <div 
                      style={{
                        
                        display: this.state.watchlistHover == 1 || this.props.deleteFromWatclistSpinner == 1 ? "inline-block" : "none",
                        backgroundColor: "rgba(2, 0, 13, 0.6)",
                        float: "left",
                        color: "white",
                        height: "25px",
                        borderRadius: "4px",
                        marginLeft:"-3px",
                        paddingTop:"1px",
                        paddingRight:"1px"
                       
                        
                        }}
                
                  >&nbsp;Delete from favorites&nbsp;</div>
                  <div style={{
                   // background: "rgba(2, 0, 13, 0.4)",
                    
                    }}>
                      <div
                      style={{
                        display: this.props.deleteFromWatclistSpinner == 0 ? "none" : "inline-block",
                        color: "white",
                        
                        
                          
                      }}
                      className="watchlist_on_video_heart_image_wrapper"
                      onMouseEnter = { () => this.setState({watchlistHover : 1 })}
                      onTouchStart={() =>  this.showVideo(id,0)}
                      onMouseLeave = { () => this.setState({watchlistHover : 0 })}
                      > <div style={{
                        paddingBottom: "35px", 
                        fontSize: "19px",
                        textAlign: "center",
                        verticalAlign: "middle",}}>
                        <FontAwesomeIcon
                        style={{
                          display: this.props.deleteFromWatclistSpinner == 0 ? "none" : "inline-block",
                          //textAlign: "right",
                          //position:"absolute",
                          // marginRight:"1px",
                           marginBottom: "1px", 
                          width: "20px",
                          
                          textAlign: "center",
                          color: "white"
                            }}
                            icon="circle-notch"
                          spin
                          />
                           </div>
                        </div>
                      
                    {/* <FontAwesomeIcon
                        style={{
                          display: this.props.deleteFromWatclistSpinner == 0 ? "none" : "inline-block",
                          //textAlign: "right",
                          right:"0px", 
                          width: "12px",
                          textAlign: "center",
                          color: "white"
                            }}
                            icon="circle-notch"
                          spin
                          /> */}
                  </div>
                  <div
                      style={{
                        display: this.props.deleteFromWatclistSpinner == 1 ? "none" : "inline-block",
                        color: "white",
                        
                      
                          
                      }}
                      className="watchlist_on_video_heart_image_wrapper"
                      onMouseEnter = { () => this.setState({watchlistHover : 1 })}
                      onMouseLeave = { () => this.setState({watchlistHover : 0 })}
                      > <div
                      style={{
                        backgroundColor: "white",
                        WebkitMask: "url(https://cdn2.balaporn.com/content/images/close_button_x.svg) no-repeat center",
                          mask: "url(https://cdn2.balaporn.com/content/images/close_button_x.svg) no-repeat center",
                          //Webkitmas:contain;
                        //  MaskSiz:contain;
                          width: "20px",
                          height: "20px",
                          marginTop:"2.5px",
                          marginLeft: "2.2px"

                      }}
                    ></div>
                      
                      
                      </div>
                    
                    </a>       
                </div>     
            {/* : "" }        */}
              <div 
              id={"add_to_watchlist"+id} 
              className="watchlist_on_video" 
              style={{display: this.props.watchlist ? "none" : "block"}}
              > 
                        
                  <a href="javascript:void(0)" //onClick={this.add_to_watchlist}
                    onClick={() => this.props.User != null && this.props.User != "Unauthorized" 
                    ? this.add_to_watchlist() : this.props.show_login_popup(window.location.pathname)}
                  >
                  <div id={"add_to_favorites"+id}
                  style={{
                        
                        display: (this.state.watchlistHover == 1 || 
                        this.state.addToWatclistSpinner == 1) && this.state.hide_add_to_fav == 0 ? "inline-block" : "none",
                        background:  "rgba(2, 0, 13, 0.6)",
                        float: "left",
                        height: this.props.comes_from_tags == 1 ? "23px" : "25px", 
                        borderRadius: '4px',
                        color: "white",
                        paddingTop: this.props.comes_from_tags == 1 ? "2px" : "1px",
                        marginLeft:"-3px",
                        paddingTop:"1px",
                        fontSize:"16px"
                        }}
                
                  >&nbsp;Add to favorites&nbsp;</div>  
                  <div style={{
                    background: this.state.addToWatclistSpinner == 1 ? "rgba(2, 0, 13, 0.6)" : "rgba(2, 0, 13, 0.4)",
                    width: "24px",
                    display: this.state.addToWatclistSpinner == 0 ? "none" : "inline-block",
                    height: this.props.comes_from_tags == 1 ? "23px" : "25px", }}>
                    <FontAwesomeIcon
                        style={{
                          display: this.state.addToWatclistSpinner == 0 ? "none" : "inline-block",
                          //textAlign: "right",
                          
                          marginRight:"3.0px",
                          marginTop: "3.2px", 
                          width: "18px",
                          height: "18px",
                          textAlign: "center",
                          color: "white"                      
                        }}
                        icon="circle-notch"
                      spin
                      />
                    </div>
                    <div
                      style={{
                        display: this.state.addToWatclistSpinner == 1 ? "none" : "inline-block",
                        background: this.state.watchlistHover == 1 ? "rgba(2, 0, 13, 0.6)" : "rgba(2, 0, 13, 0.4)",                      
                        width: "25px",
                        borderRadius: "4px",
                        height: this.props.comes_from_tags == 1 ? "23px" : "25px", 
                        paddingTop: this.props.comes_from_tags == 1 ? "1px" : "0px", 
                        //for some reason this div is taller in tags page
                        
                      }}
                      className="watchlist_on_video_heart_image_wrapper"
                      onMouseEnter = { () => this.setState({watchlistHover : 1 })}
                      onMouseLeave = { () => this.setState({watchlistHover : 0 })}
                      >
                        <img 
                            id={"heart"+id} 
                            src={configData.CDN_ENDPOINT+"images"+"/heart-not-active.svg" }
                            width="20"
                            style={{
                              marginLeft: "-1px",
                              marginTop: "-0.5px"
                            }}
                            ></img>  
                        </div>
                  </a>
              </div>
              <a href={"/watch/"+id+"/"+title_to_url(title)}
                onClick={(event) => this.props.go_to_link(event, "/watch/"+id+"/"+title_to_url(title))}
                className="purple_href"
              >
                <video
                  id={"video_preview_element"+id} 
                  loop
                  // style={{ display: showVideo == 1 ? "inline" : "none" }}
                  preload={'auto'}
                  autoPlay
                  muted
                  // className="video_image"
                  className={this.props.show_blur == 1 ? "video_image blur" : "video_image" } 
                  data-src={preview_url}
                  style={{
                    borderRadius:"4px"
                  
                  }}
                ></video>
              </a>
            </div>
          </div>
          <a href={"/watch/"+id+"/"+title_to_url(title)}
            onClick={(event) => this.props.go_to_link(event, "/watch/"+id+"/"+title_to_url(title))}
            style={{textDecoration: "none"}}
            title={title}
            className="purple_href"
            >
            <div 
                onMouseLeave={() =>  this.hideVideo(id,0)}
                className={showVideo == 1 && this.props.currentVideo == id && this.props.any_popup_showing_stop_video_hover == 0 ? 
                this.props.show_blur == 1 ? "videotitle active blur" : "videotitle active"
                : 
                this.props.show_blur == 1 ? "videotitle blur" : "videotitle"}
                
                >
                  <h3 className="custom-h3" 
                      style={{
                        marginLeft: this.props.title_marginleft_20px ? "0px" : "", 
                        marginTop: "8px", lineHeight:"21px",fontWeight:"600"}}>{title}</h3>
            </div>
          </a>
            <div 
              onMouseLeave={() =>  this.hideVideo(id,0)}
              className={this.props.show_blur == 1 ? "providertitle blur" : "providertitle"}
              style={{height: "2px", marginLeft: this.props.title_marginleft_20px ? "0px" : "", }}>
                <div className="caps12">{provider_name+".com"}</div>
            </div>
          
        </div>
      </React.Fragment>   
    );
  }
}

export default Video;
