import React from 'react';

import { withRouter } from 'react-router-dom';
import { lightTheme } from './../../theme';
class FooterComponent extends React.Component {

    shouldComponentUpdate(nextProps, nextState) {
    
        if(
            
            this.props.mobile_or_tablet != nextProps.mobile_or_tablet ||
            this.props.cookies_dialog_ok != nextProps.cookies_dialog_ok || 
            this.props.mobile_filter_showing != nextProps.mobile_filter_showing ||
            this.props.theme != nextProps.theme ||
            this.props.show_overlay != nextProps.show_overlay ||
            this.props.filters_showing != nextProps.filters_showing
           
          ) {
             //console.log("yes  - FUCKIN RENDER")
             return true
        }
       //console.log("no - DO NOT RENDER")
        return false
      }
      
    render() { 
        
        return (
           
        this.props.mobile_or_tablet == 0 ? 
            <React.Fragment>
              <div style={{position:"relative", float: "left", width: "100%", 
                            marginTop: this.props.margintop > 0 ? "50px" : "",
                            height: "46px", zIndex: "70",
                           
                            }}>

                                
            <div 
                id="footer_id"
                style={{
                        position: "absolute",
                        bottom: "0px",
                        float: "center",
                        margin: "auto",
                        textAlign: "center",
                        maxWidth: "2400px",    
                        // backgroundColor: this.props.filters_showing == 1 || this.props.show_overlay == 1 ? 
                        //     this.props.theme == lightTheme ? 
                        //     configData.OVERLAY_BACKGROUND_COLOR_WHITE_THEME : configData.OVERLAY_BACKGROUND_COLOR_DARK_THEME
                            
                        //     : "",                    
                        height: "46px",
                        borderTop: this.props.theme == lightTheme ? "1px solid #E0E0E0" : "1px solid #2A2A30",
                        lineHeight: "46px",
                        
                        
                        zIndex: "70",
                        width: "100%",
                        boxShadow: "0px -4px 14px 0px rgba(2, 0, 13, 0.14)",
                        }}
                        
                        >
                              <center>
                        <h4 className="body16"
                            style={{
                                lineHeight: "46px",
                                position: "relative",
                                float: "left",                                
                                color: "#8B8B8F", 
                                marginLeft: "32px"}}>Copyright: © {new Date().getFullYear()}, balaporn.com</h4>

                            
                            <h4
                                style={{
                                    position: "relative",
                                    left: "0px",
                                    right: "0px",                                    
                                    display: "inline",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    lineHeight: "46px",
                                    fontWeight: "bold",
                                }}
                                className="pornstar_channel_in_view_video_href_class body16">
                                BalaPorn
                            </h4>
                            <a href="/contact-us" 
                            className={document.location.href.split("/")[3].includes("contact-us")  ? "menu_href_color body16"
                            : "purple_href body16"}
                                onClick={(event) => { event.preventDefault(); this.props.history.push("/contact-us");}}
                                style={{
                                    lineHeight: "46px",
                                    position: "relative",
                                    float: "right",
                                    textDecoration: "none",

                                    color: "#8B8B8F", 
                                    marginRight: "20px"}}>Contact us</a>
                            <a href="/cookie-policy" 
                            className={document.location.href.split("/")[3].includes("cookie-policy")  ? "menu_href_color body16"
                            : "purple_href body16"}
                                onClick={(event) => { event.preventDefault();  this.props.history.push("/cookie-policy");}}
                                style={{
                                    lineHeight: "46px",
                                    position: "relative",
                                    float: "right",
                                    textDecoration: "none",

                                    color: "#8B8B8F", 
                                    marginRight: "32px"}}>Coodkie policy</a>

                            <a href="/terms-of-use" 
                            className={document.location.href.split("/")[3].includes("terms-of-use")  ? "menu_href_color body16"
                            : "purple_href body16"}                                 

                                onClick={(event) => { event.preventDefault();  this.props.history.push("/terms-of-use");}}
                                style={{
                                    lineHeight: "46px",
                                    position: "relative",
                                    float: "right",
                                    textDecoration: "none",

                                    color: "#8B8B8F", 
                                    marginRight: "20px"}}>Terms of use</a>
                            
                            <a href="/privacy-policy" 
                            className={document.location.href.split("/")[3].includes("privacy-policy")  ? "menu_href_color body16"
                            : "purple_href body16"}
                                onClick={(event) => { event.preventDefault(); this.props.history.push("/privacy-policy");}}
                                style={{
                                    lineHeight: "46px",
                                    position: "relative",
                                    float: "right",
                                    textDecoration: "none",

                                    color: "#8B8B8F", 
                                    marginRight: "20px"}}>Privacy policy</a>
                            
                            </center>
                        </div>
                        </div>
                        
                        {/* periergo. den douleuei to parapanw padding an den iparxei kai ayto to tab edw */}
                        <div style={{height:"0px",display:this.props.cookies_dialog_ok  == 0 ?"inline-block" : "none"}}> </div>
                        </React.Fragment>
                    : 
                    //mobile
                    <React.Fragment>
                        <div 
                          id="footer_id"
                        style={{
                                position:  "relative", 
                                textAlign: "center",
                                left: "0px",
                                display: "inline-block", 
                                backgroundColor: '#f8f8f8',
                                marginTop: this.props.margintop > 0 ? "50px" : "",  
                                // backgroundColor: this.props.filters_showing == 1 || this.props.show_overlay == 1 ? 
                                //     this.props.theme == lightTheme ? 
                                //     configData.OVERLAY_BACKGROUND_COLOR_WHITE_THEME : configData.OVERLAY_BACKGROUND_COLOR_DARK_THEME
                                    
                                //     : "",                             
                                height: "120px",
                                zIndex: "68", //WIP here make it 72 and it will go above
                                width: "100%",
                                borderTop: this.props.theme == lightTheme ? "1px solid #E0E0E0" : "1px solid #2A2A30",
                                boxShadow: "0px -4px 14px 0px rgba(2, 0, 13, 0.14)",
                                
                                }}
                                > 
                                <h4 className="body16"
                                    style={{
                                        position: "relative",
                                        float: "center",
                                        paddingTop: "20px",
                                        color: "#8B8B8F", 
                                        marginLeft: "0px"}}>Copyright: © 2022, balaporn.com</h4>                                
                                    <h4
                                        style={{
                                            position: "relative",
                                            left: "0px",
                                            right: "0px",
                                            float: "center",
                                            fontWeight: "bold",
                                        }}
                                        className="pornstar_channel_in_view_video_href_class body16">
                                        BalaPorn
                                    </h4>
                                    <a href="/cookie-policy" 
                                   className={document.location.href.split("/")[3].includes("cookie-policy")  ? "menu_href_color body16"
                                   : "purple_href body16"}
                                        onClick={(event) => { event.preventDefault();  this.props.history.push("/cookie-policy");}}
                                        style={{
                                            position: "relative",
                                            float: "center",
                                            textDecoration: "none",
        
                                            color: "#8B8B8F", 
                                            marginRight: "20px"
                                        }}>Cookie policy</a>
        
                                    <a href="/terms-of-use" 
                                    className={document.location.href.split("/")[3].includes("terms-of-use")  ? "menu_href_color body16"
                                    : "purple_href body16"}                                  
                                        onClick={(event) => { event.preventDefault();  this.props.history.push("/terms-of-use");}}
                                        style={{
                                            position: "relative",
                                            float: "center",
                                            textDecoration: "none",
        
                                            color: "#8B8B8F", 
                                            marginRight: "20px"
                                        }}>Terms of use</a>
                                    
                                    <a href="/privacy-policy" 
                                    className={document.location.href.split("/")[3].includes("privacy-policy")  ? "menu_href_color body16"
                                    : "purple_href body16"}
                                        onClick={(event) => { event.preventDefault(); this.props.history.push("/privacy-policy");}}
                                        style={{
                                            position: "relative",
                                            float: "center",
                                        textDecoration: "none",
        
                                            color: "#8B8B8F", 
                                            marginRight: "20px"
                                        }}>Privacy policy</a>
                                    <a href="/contact-us" 
                                    className={document.location.href.split("/")[3].includes("contact-us")  ? "menu_href_color body16"
                                    : "purple_href body16"}
                                        onClick={(event) => { event.preventDefault(); this.props.history.push("/contact-us");}}
                                        style={{
                                            position: "relative",
                                            float: "center",
                                        textDecoration: "none",
        
                                            color: "#8B8B8F", 
                                            marginRight: "20px"
                                        }}>Contact us</a>
                                </div>
                                <div style={{
                                    height: this.props.mobile_filter_showing == 1 ? "35px" : "20px",
                                    display:this.props.cookies_dialog_ok  == 0 ?"inline-block" : "none"}}> </div>
                            
                            </React.Fragment>
                    
            );
    }
}
 
export default withRouter(FooterComponent);