import React, { Component } from "react";
import _ from "lodash";
import { ThemeConsumer } from "styled-components";

class Pagination extends Component {
  state = {};
  // render () {
    a
  // }
  
  shouldComponentUpdate(nextProps, nextState) {
    //alert("panagia")
    
    if(this.props.VideosCount != nextProps.VideosCount || 
      this.props.CurrentPage != nextProps.CurrentPage ||
      this.props.isVisible != nextProps.isVisible ||
      this.props.VideosPerPage != nextProps.VideosPerPage || 
      this.props.show_less_numbers != nextProps.show_less_numbers ||
      this.props.margintop != nextProps.margintop ||
      this.props.marginbottom != nextProps.marginbottom
      ) {
         return true
    }
    return false
  }

render() {
    const pageCount = Math.ceil(
      this.props.VideosCount / this.props.VideosPerPage
    );
   
    //alert(this.props.VideosPerPage +"<="+ this.props.VideosCount)
    //  alert(this.props.CurrentPage)
    //alert(pageCount)
    let pleft = 0
    let pright = 0
    if(this.props.show_less_numbers && this.props.show_less_numbers == 1){
      pleft = this.props.CurrentPage - 1;
      pright = this.props.CurrentPage - -2;
    }
    else{
      pleft = this.props.CurrentPage - 3;
      pright = this.props.CurrentPage - -4;  
    }
    // let pleft = this.props.CurrentPage - 3;
    // let pright = this.props.CurrentPage - -4;


    //for mobilep
    //let pleft = this.props.CurrentPage - 1;
    //let pright = this.props.CurrentPage - -2;

    if (pright > pageCount + 1) pright = pageCount + 1;
    //alert(pleft);
    if (pleft <= 0) pleft = 1;
    const pages = _.range(pleft, pright);
  

    return (
      <center style={{ 
                      //display: this.props.isVisible == 0 ? "none" : "block", 
                      visibility: this.props.isVisible == 0 ? "hidden " : "visible",
                      position: "relative", 
                      height: "60px",
                      minHeight: this.props.height == "80px" ? "80px" : "60px",
                      height: 'auto', // THIS IS A NEW LINE FOR MOBILE FIX, PROBABLY HAS TO BE REMOVED
                      marginTop: this.props.margintop+"px",
                      marginBottom: this.props.marginbottom+"px",
                      //display: pageCount == 1 ? "none" : "inline-block" 
                    }}
                      >
        {/* Select page {this.props.CurrentPage}/{pageCount} */}
        {/* <NavLink className="nav-link" to="/videos">
          Videos
        </NavLink> */}
        <nav aria-label="Page navigation example">
          <ul className="pagination flex-wrap" >
            <li              
              style={{display: this.props.CurrentPage > 1 ? "inline-block": "none",
              
            }}
            >
              <a
                className="page-link pagination-item "
                href={
                  this.props.VideoURI +
                  (this.props.CurrentPage - 1) +
                  "/" +
                  window.location.search
                }
                style={{paddingTop: "9px", height: "38px",fontSize: "16px",marginBottom:"9px"}}
                onClick={(event) =>
                  this.props.go_to_link(
                    event,
                    this.props.VideoURI +
                      (this.props.CurrentPage - 1) +
                      "/" +
                      window.location.search
                  )
                }
                aria-label="Previous"
              >
                <span aria-hidden="true" className="purple_href">PREVIOUS</span>
              </a>
            </li>

            {pages.map((page) => (
              <li
                //key={1}
                key={page}
                
                className={
                  this.props.CurrentPage == page
                    ? "page-item active"
                    : "page-item"
                }
                style={{}}
              >
                <a
                  className={
                    this.props.CurrentPage == page
                      ? "page-link pagination-item active"
                      : "page-link pagination-item"
                  }
                  style={{
                    cursor: this.props.CurrentPage == page? "not-allowed" : "",
                    paddingTop: "9px", height: "38px",fontSize: "16px"

                  }}
                  href={
                    this.props.VideoURI + page + "/" + window.location.search
                  }
                  
                  onClick={(event) =>{ 
                    if(this.props.CurrentPage == page ){
                      event.preventDefault()
                    }else{ this.props.go_to_link(
                      event,
                      this.props.VideoURI + page + "/" + window.location.search
                    )
                  }
                  }}
                >
                  {page}
                </a>
              </li>
            ))}
            <li
              style={{display: this.props.CurrentPage < pageCount ? "inline-block" : "none",  }}
            >
              
              <a
                className="page-link pagination-item"
                
                href={
                  this.props.VideoURI +
                  (this.props.CurrentPage - -1) +
                  "/" +
                  window.location.search
                }
                style={{paddingTop: "9px", height: "38px",fontSize: "16px", marginBottom:"9px"}}
                onClick={(event) =>
                  this.props.go_to_link(
                    event,
                    this.props.VideoURI +
                      (this.props.CurrentPage - -1) +
                      "/" +
                      window.location.search
                  )
                }
                aria-label="Next"
              >
                <span aria-hidden="true" className="purple_href" >NEXT </span>
              </a>
            </li>                       
            
          </ul>
          
        </nav>
      </center>
    );
  }
}

export default Pagination;
