import React, { Component } from 'react';
import TagsLeftMenu from './tags_left_menu'
import OrderFilter from './order_filter'
import FiltersHorizontal from './filters_horizontal'
import { withRouter } from 'react-router-dom';
import "./../../../App.css";
import { lightTheme } from './../../theme';
import configData from "../../../config.json";


class MenuWrapper extends Component {


  
      quality_clicked = async(selection) => {

        if(selection == 1){
            await this.setState({quality_all: 1, quality_hd:0, quality_fourk:0, quality_vr:0, quality_non_hd:0})
        }
        if(selection == 2){
            if(this.state.quality_hd){
                await this.setState({quality_all: 0, quality_hd:0})
            }
            else{
                await this.setState({quality_all: 0, quality_hd:1})
            }
        }
        if(selection == 3){
            if(this.state.quality_fourk){
                await this.setState({quality_all: 0, quality_fourk:0})
            }
            else{
                await this.setState({quality_all: 0, quality_fourk:1})
            }
        }
        if(selection == 4){
            if(this.state.quality_vr){
                await this.setState({quality_all: 0, quality_vr:0})
            }
            else{
                await this.setState({quality_all: 0, quality_vr:1})
            }
        }
        if(selection == 5){
            
            if(this.state.quality_non_hd){
                await this.setState({quality_all: 0, quality_non_hd:0})
                //alert("nonhd")
            }
            else{
                await this.setState({quality_all: 0, quality_non_hd:1})
            }
        }
        // if(this.state.quality_hd == 1 &&
        //     this.state.quality_fourk == 1 && 
        //     this.state.quality_vr == 1 && 
        //     this.state.quality_non_hd == 1
        //     )
        //     {
        //         this.setState({quality_all: 1, quality_hd: 0, quality_fourk: 0,
        //             quality_vr: 0,
        //             quality_non_hd: 0})

        //     }
        this.performQuery()



     }

     change_sort = async(order) => {
        //alert('bike')
        if(order == "newest"){
            await this.setState({ sort_new: 1, sort_trending: 0, sort_best: 0, quality_all: 1 })
            this.props.change_pretitle_str("Newest ")
        }
        if(order == "trending"){
            //alert('bike')
            await this.setState({ sort_new: 0, sort_trending: 1, sort_best: 0, quality_all: 1 })
            this.props.change_pretitle_str("Trending ")          
        }
        if(order == "best"){
            await this.setState({ sort_new: 0, sort_trending: 0, sort_best: 1, quality_all: 1 })
            this.props.change_pretitle_str("Best ")
            //this.props.history.push("/videos?order=best&parameter=mostviewed");
        }
        this.performQuery()
     }

     change_best_parameter = async(parameter) => {   
            
        if(parameter == "Most viewed"){
            await this.setState({ best_parameter: "mostviewed" })
            this.props.change_pretitle_str("Most Viewed ")
        }
        if(parameter == "Most commented"){
            await this.setState({ best_parameter: "mostcommented" })            
            this.props.change_pretitle_str("Most Commented ")

        }
        if(parameter == "Our favorites"){
            await this.setState({ best_parameter: "ourfavorites" })
        }
        this.performQuery()
     }

     get_best_parameter_from_id = (id, sort_best) => {
         //console.log('bik')
        if(id == "mostviewed"){
             this.props.change_pretitle_str("Most Viewed ", sort_best)
             return "Most viewed"
        }
        if(id == "mostcommented"){
            this.props.change_pretitle_str("Most Commented ", sort_best)
            return "Most commented"
            
        }
        if(id == "ourfavorites"){
            this.props.change_pretitle_str("Our favorites ", sort_best)
            return "Our favorites"
        }

      }

        
     //}

     handleMinsChange = (event, newValue) => {
        if(newValue[0] == newValue[1]){
          return
        }
        this.setState({mins: newValue})

       // this.performQuery()
      }


      performQuery = async() => {
        // var params = [] 
        // params.push({
        //     key: "min_mins",
        //     value: "20"
        // })
        
        var params = {
        };
        if(this.state.show_all == 0){
            if(this.state.sort_best == 1){
                params.order="best"
                params.parameter=this.state.best_parameter
            }
            if(this.state.sort_trending == 1){
                params.order="trending"
                params.period=this.state.sort_period
            }
            if(this.state.mins[0] > 0){
                params.min_mins=this.state.mins[0]
            }
            if(this.state.mins[1] < 40)
                params.max_mins=this.state.mins[1]
            if((this.state.quality_hd == 1 &&
                this.state.quality_fourk == 1 && 
                this.state.quality_vr == 1 && 
                this.state.quality_non_hd == 1) || this.state.quality_all == 1
                ){
                   this.setState({quality_hd: 0,quality_fourk: 0, quality_vr: 0, quality_non_hd: 0, quality_all: 1}) 
                }  
                else{
                    if(this.state.quality_hd == 1)
                        params.is_hd=1
                    if(this.state.quality_fourk == 1)
                        params.is_4k=1
                    if(this.state.quality_vr == 1)
                        params.is_vr=1
                    if(this.state.quality_non_hd == 1)
                        params.is_not_hd=1
                }
        } else{
            await this.setState({show_all: 1})
            params.show_all=1   
            //  alert('p[anagia')
        }         
        var esc = encodeURIComponent;
        
        var query = Object.keys(params)
            .map(k => esc(k) + '=' + esc(params[k]))
            .join('&');   
        if(window.location.search.split('?')[1]==query){
            return
        }    
        var uri = window.location.pathname
        if(uri[uri.length-1] == '/'){
            uri = uri.slice(0,uri.length-1)
        }
        var mySubString = uri.substring(
            uri.lastIndexOf("/") + 1, 
            uri.length
        );
        
        //const params = new URLSearchParams(window.location.search)
        for (const param of new URLSearchParams(window.location.search)) {
            if(param[0] == "tag_id"){
                query=query+"&tag_id="+param[1]
            }
        }
        //alert(uri)
        if(mySubString%1 == 0 ){
            //alert("has page")
            uri = uri.substring(0, 
                uri.lastIndexOf("/")+ 1)           
        }
        
        window.history.pushState(
            "",
            "",
            //"?"+query
            //"/"
            uri+"?" +
            //document.location.href.split("/")[3]+"/1?"+
            query
        );
        try{
            this.props.hide_filters(1,1);
        }catch(e){}
        if(this.props.mobile_or_tablet == 1)
            this.props.set_scrolly(0)
        this.props.parent_cmd(true)

        var num_of_filters = 0
        if(this.state.quality_hd == 1){
            num_of_filters = num_of_filters+1
        }
        if(this.state.quality_fourk == 1){
            num_of_filters = num_of_filters+1
        }
        if(this.state.quality_vr == 1){
            num_of_filters = num_of_filters+1
        }
        if(this.state.mins[0] > 0 || this.state.mins[1] < 40){
            num_of_filters = num_of_filters+1
        }
        if(this.state.quality_non_hd == 1){
            num_of_filters = num_of_filters+1
        }        
        this.props.set_number_of_filters(num_of_filters)

      }



    state = {
        mins: [0,40],
        show_all: 0,
        quality_all: 1,
        quality_hd: 0,
        quality_fourk: 0,
        quality_vr: 0,
        quality_non_hd: 0,
        sort_new: 1,
        sort_best: 0,
        sort_trending: 0,
        sort_period: 7,
        best_parameter: "mostviewed",
        url_search_params: "",
        
    }

    change_sort_period = async(sort_period) =>{
        await this.setState({sort_period: sort_period})
        //alert('bike')
        this.performQuery()
        
    }

    
    componentDidUpdate () {
        if (this.state.url_search_params != window.location.search) {
            this.setState({url_search_params: window.location.search})  
            var self=this;          

            setTimeout(function() { 
                self.componentDidMount() 
            
            },35)
        }
    }

    componentDidMount = async() => { // was componentWillMount (?)
        this.setState({            
            quality_all: 1,
            quality_hd: 0,
            quality_fourk: 0,
            quality_vr: 0,
            quality_non_hd: 0,
            sort_new: 1,
            sort_best: 0,
            sort_trending: 0,
            sort_period: 7,
            best_parameter: "mostviewed",
            mins: [0,40],
            })
        
        
        const queryString = window.location.search;
        this.setState({url_search_params: queryString})
        const urlParams = new URLSearchParams(queryString);
        //urlParams.get('min_mins')
        //if( urlParams.get('show_all')  != 1){
            //alert('panagia')
        if( urlParams.get('parameter') && urlParams.get('parameter').length > 0 ){        
            await this.setState({best_parameter: urlParams.get('parameter')})
        }
        
        if( urlParams.get('period') > 0 ){        
            await this.setState({sort_period: urlParams.get('period')})
        }
        if( urlParams.get('order')  == "trending"){
            await this.setState({sort_trending: 1})
            await this.setState({sort_new: 0})
        }
        if( urlParams.get('order')  == "best"){
            await this.setState({sort_best: 1})
            await this.setState({sort_new: 0})
        }
        
        if( urlParams.get('show_all')  == 1){
            await this.setState({show_all: 1})   
            //alert('panmagia')         
        }

        if( urlParams.get('is_4k')  == 1){
            await this.setState({quality_fourk: 1})
            await this.setState({quality_all: 0})
        }
        if( urlParams.get('is_hd')  == 1){
            await this.setState({quality_hd: 1})
            await this.setState({quality_all: 0})
        }
        if( urlParams.get('is_vr')  == 1){
            await this.setState({quality_vr: 1})
            await this.setState({quality_all: 0})
        }
        if( urlParams.get('is_not_hd')  == 1){
            await this.setState({quality_non_hd: 1})
            await this.setState({quality_all: 0})
        }
        if( urlParams.get('min_mins')>0){
            await this.setState({mins: [urlParams.get('min_mins'),this.state.mins[1]]})
        }
        if( urlParams.get('max_mins')>0){
            await this.setState({mins: [this.state.mins[0],urlParams.get('max_mins')]})
            }
        // }else{
        //     await this.setState({show_all: 1})
        //     //alert("panagia2")
        //     //this.performQuery()
        // }
        var num_of_filters = 0
        if(this.state.quality_hd == 1){
            num_of_filters = num_of_filters+1
        }
        if(this.state.quality_fourk == 1){
            num_of_filters = num_of_filters+1
        }
        if(this.state.quality_vr == 1){
            num_of_filters = num_of_filters+1
        }
        if(this.state.mins[0] > 0 || this.state.mins[1] < 40){
            num_of_filters = num_of_filters+1
        }
        if(this.state.quality_non_hd == 1){
            num_of_filters = num_of_filters+1
        }
        this.props.set_number_of_filters(num_of_filters)
        
    }

    
    shouldComponentUpdate(nextProps, nextState) {
    
        if(
            this.state.mins != nextState.mins ||
            this.state.quality_all != nextState.quality_all ||
            this.state.quality_hd != nextState.quality_hd ||
            this.state.quality_fourk != nextState.quality_fourk ||
            this.state.quality_vr != nextState.quality_vr ||
            this.state.quality_non_hd != nextState.quality_non_hd ||
            this.state.sort_best != nextState.sort_best ||
            this.state.sort_trending != nextState.sort_trending || 
            this.state.sort_new != nextState.sort_new ||
            this.state.sort_period != nextState.sort_period ||
            this.state.best_parameter != nextState.best_parameter ||
            this.props.scrolling_down != nextProps.scrolling_down ||
            this.props.mobile_filter_showing != nextProps.mobile_filter_showing ||
            this.props.Ptags != nextProps.Ptags ||
            this.props.theme != nextProps.theme  || 
            this.props.mobile_or_tablet != nextProps.mobile_or_tablet ||
            //reload_usermenu={this.props.reload_usermenu} ||
            this.props.reload_usermenu != nextProps.reload_usermenu ||
            this.props.show_overlay != nextProps.show_overlay ||
            this.props.filters_showing != nextProps.filters_showing ||
            this.props.number_of_filters != nextState.number_of_filters ||
            this.props.selected_tags != nextProps.selected_tags ||
            this.props.over_18_popup_showing != nextProps.over_18_popup_showing ||
            this.props.overlay_full_except_menu_and_mobile_search_value != nextProps.overlay_full_except_menu_and_mobile_search_value
            //||
            //this.props.Ptag != nextProps.Ptags
            
          ) {
             //console.log("yes  - FUCKIN RENDER")
             return true
        }
       //console.log("no - DO NOT RENDER")
        return false
      }

    render() { 
       // console.log("panagitsowrpper")
        return ( 
            <React.Fragment>

            {this.props.show_horizontal != 'undefined' &&  this.props.show_horizontal != 1 ? 
                // WIP doesn't work for pornstar and channel
                 <div className={this.props.filters_showing == 1  || this.props.show_overlay == 1 ? "" : ""}
                        
                    style={{zIndex: '999'}}
                 > 

                    <a href="javascript:void(0);"
                        onClick={ () => this.props.filters_showing == 0 ? this.props.show_filters() : this.props.hide_filters()}>
                    <div id="tablet_filter_bar" className="tablet_filter_bar pornstar_channel_in_view_video_href_class"
                            style={{
                                backgroundColor: this.props.theme == lightTheme ? "#F8F8F8" : "#1F1F24"
                                // backgroundColor: 
                                // this.props.filters_showing == 1 || this.props.show_overlay == 1 ? 
                                //     this.props.theme == lightTheme ? 
                                //     configData.OVERLAY_BACKGROUND_COLOR_WHITE_THEME : configData.OVERLAY_BACKGROUND_COLOR_DARK_THEME
                                    
                                //     : 
                                //     this.props.theme == lightTheme ? "#FFF" : "#1F1F24",
                            
                            
                            }}
                            onMouseOver={() => document.getElementById("tablet_filter_bar").style.backgroundColor = 
                            this.props.theme == lightTheme ? "#F6F6F6" //cultured_d
                            : "#2a2a30"// gray_d
                        }
                            onMouseOut={() => document.getElementById("tablet_filter_bar").style.backgroundColor =
                            this.props.theme == lightTheme ? "#F8F8F8" : "#1F1F24"
                                         }
                            >
                            <div id="tablet_filter_bar_inner" style={{position: 'relative', top: "",fontSize: "12px"}}>
                                <center>
                                <img src={this.props.theme == lightTheme ? 
                                    configData.CDN_ENDPOINT+"images"+"/filters_mobile_purple.svg"
                                    :
                                    configData.CDN_ENDPOINT+"images"+"/filters_mobile_purple_d.svg"
                                    }></img><br></br>
                                Filters
                                
                                {this.props.number_of_filters > 0 ? 
                                <div style={{width: "100%",textAlign:"center"}}>
                                
                                    <div className="circle_number_of_selected_filters" 
                                        style={{
                                            position:"relative",
                                           float:"center",right:'21px',marginTop: "4px"
                                            
                                        }}>{this.props.number_of_filters}
                                    </div>
                               
                                    </div>
                                : ""}
                                
                                </center>
                            </div>
                        </div>
                    </a>
                </div>
                : "" }
                
                <div id="mobile_filter_bar" className=" " 
                style={{zIndex: "9999",
                        
            
                    }}
                
                >
               

                        <a href="javascript:void(0);"
                        className="button14 mobile_filter_bar"
                        id='mobile_filters_href_div'
                        style={
                            
                            {
                            display: this.props.scrolling_down == 1 && 
                            this.props.mobile_filter_showing == 1 && this.props.over_18_popup_showing == 0 
                            && this.props.show_overlay == 0 && this.props.overlay_full_except_menu_and_mobile_search_value == 0
                            ? "inline-block": "none",
                         
                            //width: "146px",
                            width:  this.props.number_of_filters > 0 ? "152px" : "118px",
                            zIndex:  "9999",
                            borderRadius: "8px",
                            height:"56px",
                            padding: "18px 16px",
                            boxShadow: "0px 4px 8px rgba(2, 0, 13, 0.14)",
                           
                            // backgroundColor: this.props.theme == lightTheme ? "white" : "black",
                            // color: this.props.theme == lightTheme ? "#5724E6" : "#9F7DFF",
                            
                        }}
                        onClick={ () => this.props.filters_showing == 0 ? this.props.show_filters() : this.props.hide_filters()}>
                             {/* <img src={this.props.theme == lightTheme ? 
                                configData.CDN_ENDPOINT+"images"+"/filters_mobile_purple.svg"
                                :
                                configData.CDN_ENDPOINT+"images"+"/filters_mobile_purple_d.svg"
                                }                                                        
                                style={{display:"inline-block",float:"center",marginRight: "8px"}}
                                width='15px'
                                ></img> */}

                                <div className="filters_icon_div" style={{border:"0px solid green", marginLeft:"0px"}}>
                                    
                                    
                                    
                                    
                                    </div>

                                    <div id="filters_icon_text_div" style={{marginTop:"-20px",
                                                marginRight:"-8px", 
                                                width: this.props.number_of_filters > 0 ? "100px" : "66px",
                                                textAlign:'left', border: '0px solid red', 
                                                float:"right"}}>
                                        FILTERS{this.props.number_of_filters > 0 ? 
                                        // ("("+this.props.number_of_filters+")") 
                                        <div className="circle_number_of_selected_filters" style={{
                                             marginRight: "11px"
                                            //marginRight: "-5px"
                                        
                                        }}>{this.props.number_of_filters}</div>
                                        
                                        
                                        : ""}
                                    </div>
                                
                                </a>
                    </div>

                    
            {/* {this.props.showTags == 1 ? */}
  
            <div style={{zIndex: '999'}}>
                <TagsLeftMenu
                    Ptags={this.props.Ptags}
                    go_to_link={this.props.go_to_link}
                    mins={this.state.mins}
                    showTags={this.props.showTags}
                    is_main_page={this.props.is_main_page}
                    performQuery={this.performQuery}
                    quality_all={this.state.quality_all}
                    //User={this.props.User}
                    quality_hd={this.state.quality_hd}
                    quality_fourk={this.state.quality_fourk}
                    quality_vr={this.state.quality_vr}
                    quality_non_hd={this.state.quality_non_hd}
                    handleMinsChange={this.handleMinsChange}
                    quality_clicked={this.quality_clicked}
                    filters_showing={this.props.filters_showing}
                    hide_filters={this.props.hide_filters}
                    theme={this.props.theme}
                    mobile_or_tablet={this.props.mobile_or_tablet}
                    show_overlay={this.props.show_overlay}
                    User={this.props.User}
                    show_login_popup={this.props.show_login_popup}
                    rerenter_menuwrapper={this.props.rerenter_menuwrapper}   
                    reload_usermenu={this.props.reload_usermenu}   
                    show_blur={this.props.show_blur}   
                    
                    comes_from_tags={this.props.comes_from_tags}
//                    selected_tags={this.props.selected_tags}

                    mobile_filter_showing={this.props.mobile_filter_showing}

                    selected_tags={this.props.selected_tags}          
                    inputChange={this.props.inputChange}
                    deleteInput={this.props.deleteInput}
                ></TagsLeftMenu>
                </div>
                {/* : ""} */}
                {this.props.show_order == 1 ?
                <OrderFilter
                    sort_new = {this.state.sort_new}
                    sort_best = {this.state.sort_best}
                    sort_trending = {this.state.sort_trending}
                    sort_period = {this.state.sort_period}
                    change_sort = {this.change_sort} 
                    change_sort_period = {this.change_sort_period}
                    change_best_parameter = {this.change_best_parameter}          
                    best_parameter = {this.state.best_parameter}
                    get_best_parameter_from_id = {this.get_best_parameter_from_id}
                    change_pretitle_str={this.props.change_pretitle_str}
                    show_overlay={this.props.show_overlay}
                    filters_showing={this.props.filters_showing}
                    ></OrderFilter>
                : ""}
           
                {this.props.show_horizontal == 1 ?
                <FiltersHorizontal
                    go_to_link={this.props.go_to_link}
                    mins={this.state.mins}
                    is_main_page={this.props.is_main_page}
                    performQuery={this.performQuery}
                    quality_all={this.state.quality_all}
                    quality_hd={this.state.quality_hd}
                    sort_new = {this.state.sort_new}
                    sort_best = {this.state.sort_best}
                    sort_trending = {this.state.sort_trending}
                    sort_period = {this.state.sort_period}
                    quality_fourk={this.state.quality_fourk}
                    quality_vr={this.state.quality_vr}
                    quality_non_hd={this.state.quality_non_hd}
                    handleMinsChange={this.handleMinsChange}
                    quality_clicked={this.quality_clicked}

                    change_sort = {this.change_sort} 
                    change_sort_period = {this.change_sort_period}
                    change_best_parameter = {this.change_best_parameter}          
                    best_parameter = {this.state.best_parameter}
                    get_best_parameter_from_id = {this.get_best_parameter_from_id}

                    change_pretitle_str={this.change_pretitle_str}

                    

                    theme={this.props.theme}
                    reload_usermenu={this.props.reload_usermenu}
                    show_overlay={this.props.show_overlay}
                    ></FiltersHorizontal>
                
                : "" }


            </React.Fragment>
        );
    }
}
 
export default withRouter(MenuWrapper); 