import React from 'react';
import { withRouter } from 'react-router-dom';
class NoVideosComponent extends React.Component {
    render() {         
        //return
        return (
            <div
                style={{
                    position: "relative", 
                    // left: "0",
                    // right: "0",
                    // top: "0",
                    // bottom: "0",
                    //border: '1px solid pink',
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingTop:  "100px"
                }}
            >
                <div
                    style={{
                        minHeight: "280px",
                        width: "calc(100% - 20px)",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        textAlign: "center",
                        
                        //paddingTop:  "calc(50% - 3px)"
                    }}
                >
                    <h1 style={{ fontSize: "20px", lineHeight: "24px" }} className="purple_href">
                        {this.props.title}
                    </h1>
                    <h3 style={{ fontSize: "16px", lineHeight: "20px", marginTop: "12px" }} className="purple_href">
                        {this.props.message}
                    </h3>
                    <button
                        onClick={async () => { 
                            await this.props.history.push(this.props.button_link); 
                            if (this.props.function_flag === 1) { 
                                this.props.function_ref(); 
                            } 
                        }}
                        className={"button_purple_background"}
                        style={{ marginTop: "20px" }}                                
                    >
                        {this.props.button_message}
                    </button>
                </div>
            </div> 
        );
    }
}
 
export default withRouter(NoVideosComponent);