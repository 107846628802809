import React from 'react';
import { lightTheme } from './../../theme';

class CookiesDialog extends React.Component {
    state = {
        show_div: 1
    }

    shouldComponentUpdate(nextProps, nextState) {
    
        if(
            
            this.props.mobile_filter_showing != nextProps.mobile_filter_showing ||
            this.state.show_div != nextState.show_div ||
            this.props.show_overlay != nextProps.show_overlay ||
            this.props.theme != nextProps.theme ||
            this.props.padding_px != nextProps.padding_px ||
            this.props.filters_showing != nextProps.filters_showing 
            
            //this.props.Ptag != nextProps.Ptags
            
          ) {
             //console.log("yes  - FUCKIN RENDER")
             return true
        }
       //console.log("no - DO NOT RENDER")
        return false
      }

    componentDidMount(){
        if(localStorage.getItem("cookies_dialog_ok") == 1){
            this.setState({show_div: 0})
        }
    }

    dialog_ok = () => {
        this.setState({show_div: 0})
        localStorage.setItem("cookies_dialog_ok", "1")
        this.props.set_cookies_dialog(1)
    }

    render() { 
        return (
        this.props.mobile_filter_showing != -1 ?
            this.props.mobile_filter_showing == 1 ?  
            <div 
            id="cookies_dialog" 
            style={{
                position:  "fixed", //"fixed", 
                bottom: this.state.show_div == 0 ? "-500px" : "0px",
                left: this.props.padding_px+"px",
                height: this.state.show_div == 0 ?  "0px" : "64px",
                borderTop: this.props.theme == lightTheme ? "0px solid #E0E0E0" : "1px solid #2A2A30",
                lineHeight: "20px",
                zIndex: "99",
                width: "100%",
                boxShadow: "0px 4px 14px rgba(2, 0, 13, 0.14)",
                boxShadow: "0px -4px 14px 0px rgba(2, 0, 13, 0.14)",
                textAlign: "center",
                webkitTransition: "all 0.2s ease-in",
                mozTransition: "all 0.2s ease-in",
                oTransition: "all 0.2s ease-in",
                transition: "all 0.2s ease-in",
                display: this.props.filters_showing == 1  || this.props.show_overlay == 1 ? "none" : ""            

                }}
                className={this.props.filters_showing == 1  || this.props.show_overlay == 1 ? "overlay  body" : "body"}>
                     <div style={{display: "inline-block",width: "200px",paddingTop: "12px"}}
                     className="pornstar_channel_in_view_video_href_class body16"
                     
                     >
                         By using this site you agree with our use of
                    <a href="/cookie-policy"
                    className="purple_href" onClick={(event) => this.props.go_to_link(event, "/cookie-policy")}
                    
                    > cookies</a> </div>
                            <a href="javascript:void(0)" onClick={this.dialog_ok}
                                style={{marginLeft:"16px",lineHeight: "64px", position:"absolute", marginTop:"0px", textDecoration:"none",fontSize:"14px", fontWeight:"700"}}
                                className="purple_href"
                            >
                                OK</a >
                    
                
                </div> : 
                  //desktop/tablet view
                <div 
                   id="cookies_dialog" 
                   style={{
                           position:  "fixed", 
                           bottom: this.state.show_div == 0 ? "-500px" : "0px",
                           left: this.props.padding_px+"px",
                           right: this.props.padding_px+"px",
                           height: this.state.show_div == 0 ?  "0px" : "46px",
                           lineHeight: "46px",
                           borderTop: this.props.theme == lightTheme ? "0px solid #E0E0E0" : "1px solid #2A2A30",
                           boxShadow: "0px -4px 14px 0px rgba(2, 0, 13, 0.14)",
                           zIndex: "9999",
                           textAlign: "center",
                           webkitTransition: "all 0.2s ease-in",
                           mozTransition: "all 0.2s ease-in",
                           oTransition: "all 0.2s ease-in",
                           transition: "all 0.2s ease-in",
                           display: this.props.filters_showing == 1  || this.props.show_overlay == 1 ? "none" : ""
 
   
                           }}
                           className={this.props.filters_showing == 1  || this.props.show_overlay == 1 ? "overlay body" : "body"}>
                               
                               <h4 className="pornstar_channel_in_view_video_href_class body16"
                               style={{lineHeight: "46px"}}>
                                 By using this site you agree withd our use of <a href="/cookie-policy"
                           className="purple_href" onClick={(event) => this.props.go_to_link(event, "/cookie-policy")}>cookies</a>  
                           
                           <a href="javascript:void(0)" onClick={this.dialog_ok}
                            style={{marginLeft:"16px",textDecoration:"none",fontSize:"14px", fontWeight:"700"}}
                            className="purple_href"
                            >
                                OK</a >
                            </h4>
                       
                       </div>
               : ""
             //mobile

       
        );
    }
}
 
export default CookiesDialog;