import React, { Component } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { lightTheme } from './../theme';
//import { tags } from "./../global_tags"  
import axios from "axios";
//import configData from "../../config.json";
//import { light } from '@material-ui/core/styles/createPalette';

library.add(faSearch);

class SearchUIComponent extends React.Component {
    
    state = {
        tags: [],
        show_more_tags: 0,
        cancelToken: axios.CancelToken.source(),
        previousRequests: [],
        pornstars_like: [],
        channels_like: [],
        is_focused: 0,
        temp_focus_lost: 0,
    }    
      
      filterOptions = (options, inputValue) => {
        const optionsStartingWith = options.filter(option => {
          return option.tag.toLowerCase().startsWith(inputValue.toLowerCase());
        });
        const optionsContaining = options.filter(option => {
          return option.tag.toLowerCase().includes(inputValue.toLowerCase()) && !optionsStartingWith.includes(option);
        });
        return optionsStartingWith.concat(optionsContaining);
      };


      
    shouldComponentUpdate(nextProps, nextState) {
      if(         
        this.props.search_expanded != nextProps.search_expanded ||
        this.state.tags != nextState.tags ||
        this.state.pornstars_like != nextState.pornstars_like ||
        this.state.channels_like != nextState.channels_like ||
        this.state.show_more_tags != nextState.show_more_tags ||
        this.props.theme != nextProps.theme ||
        this.state.is_focused != nextState.is_focused ||
        this.props.search_popup_showing  != nextProps.search_popup_showing ||
        this.state.temp_focus_lost != nextState.temp_focus_lost ||
        this.props.mobile_filter_showing != nextProps.mobile_filter_showing ||
        this.props.mobile_or_tablet != nextProps.mobile_or_tablet

      ) {
         return true
    }
    return false
    }


    render() { 
        return (
        <React.Fragment>               
           { this.props.search_expanded == 1 ? 
            <div 
                id="search_input_wrapper_desktop"
                style={{
                    width: this.state.is_focused == 1 || this.props.search_popup_showing == 1 ? "306px" : "256px",
                    //width: this.state.is_focused == 0 ? "296px" : "326px",
                    height: "40px",
                    position:"relative",
                    float: "right",
                    marginRight: "20px",
                    
                    padding: "10px, 0px, 10px, 16px",
                    borderRadius: "4px",
                    top: '11px',                    
                    transition: "all 0.3s ease",
                    zIndex: 9999,
                    //border: this.state.is_focused == 1 ? 
                    // this.props.theme == lightTheme ? "1px solid #5724E6"  : "1px solid #9F7DFF" 
                    //     : "1px solid transparent" ,
                    //left: "20%",

                }}
                onClick={(event) => event.stopPropagation()}
                onMouseOver={() => {
                  document.getElementById("search_input").style.border = 
                    this.props.theme == lightTheme ?  "1px solid #5724E6" : "1px solid #9F7DFF"
                  if(this.props.search_popup_showing == 1){
                    document.getElementById("search_input").style.borderBottom = "1px solid transparent"
                    }
                  }}
                onMouseOut={() => {
                  document.getElementById("search_input").style.border = 
                    this.state.is_focused == 1 ? 
                      this.props.theme == lightTheme ? "1px solid #5724E6"  : "1px solid #9F7DFF"                
                      :  
                    
                      "1px solid transparent"
                  //this.props.search_popup_showing
                  if(this.props.search_popup_showing == 1){
                    document.getElementById("search_input").style.borderBottom = "1px solid transparent"
                  }
                  // document.getElementById("search_input_wrapper_desktop").style.borderBottom = 
                  //   this.props.search_popup_showing == 1 ? 
                  //   "1px solid transparent" : 
                  //     this.props.theme == lightTheme ? "0px solid #5724E6"  : "0px solid #9F7DFF" 
                       
                      
                  
              }}
            >
                <form onSubmit={this.props.search}>
                    <input id='search_input' 
                        autocomplete="off"
                        type="search"
                        dark={this.props.theme == lightTheme ? "false" : "true"} 
                        style={{
                        width:this.state.is_focused == 1 || this.props.search_popup_showing == 1
                          ? "266px" : "216px",// ? "256px" : "286px" ,
                        height: "38px",
                        transition: "all 0.3s ease",
                        border: this.state.is_focused == 1 || this.props.search_popup_showing == 1 ? 
                          this.props.theme == lightTheme ? "1px solid #5724E6"  : "1px solid #9F7DFF" 
                          : "1px solid transparent" , 
                        paddingLeft: "16px",
                        paddingTop: "3px",
                        backgroundColor: this.props.theme == lightTheme ?  "#F2F2F2" : "#3E3E47",
                        borderRadius: "4px 0 0 4px",
                        outline: "none",
                        //#8B8B8F
                        color: this.props.theme == lightTheme ? "black" : "white",
                        
                        
                    }}
                    placeholder="Search videos"
                    onChange={(event) => this.props.handleSearch(event)} 
                    //onClick={(event) => this.props.set_search_item_selected_active_element(-1)}
                    
                    onKeyDown={(event) => {
                        if (event.key === "ArrowDown" || event.key === "ArrowUp") {
                            this.props.search_arrow_navigator(event)

                          }
                        if (event.key === "Enter" ) {
                            //this.props.search_arrow_navigator(event)
                            event.preventDefault();
                            this.props.search(event,1)

                          }                             
                        if (event.key === "Escape") {
                          // Run your JavaScript code here
                          //alert('Escape key was pressed!');
                          document.getElementById("search_input").value = ""
                          //document.getElementById("search_items").style.display = "none";
                          document.getElementById("search_input").style.borderRadius = "4px";
                          document.getElementById("search_input").style.borderRadius = "4px 0 0 4px";
                          
                          document.getElementById("search_input").style.borderBottom = 
                              this.props.theme == lightTheme ?  "1px solid #5724E6" : "1px solid #9F7DFF"


                          document.getElementById("search_input_search_button").style.borderRadius = "0 4px 4px 0";

                          this.props.set_search_popup_showing(0)
                        }
                      }}
                      onFocus={async (event) => {
                          var value = document.getElementById("search_input").value
                          await this.setState({is_focused: 1})
                          var self=this;
                          
                          // if(value.length > 0 && this.props.search_popup_showing == 0){
                          //   //document.getElementById("search_input").value = value
                          //   setTimeout(async function (event)    {
                          //     await self.props.handleSearch(event)
                          //     //console.log('Hello, world!');
                          //   }, 240);
                          //   //this.props.handleSearch(event)
                          // } 
                          if(this.props.search_popup_showing == 1 ){
                            
                            //alert()
                            setTimeout(async function (event)    {
                              document.getElementById("search_input").style.borderBottom = "1px solid transparent";
                              document.getElementById("search_items").style.borderTop = "0px solid red";
                            }, 40);
                            
                          }
                          //document.getElementById("search_input_wrapper_desktop").style.width="328px"
                          //document.getElementById("search_input").style.width="284px"
                      }}
                      onBlur={() => {
                        var self=this
                        self.setState({temp_focus_lost: 0})
                        setTimeout(async function (event)    {
                             self.setState({is_focused: 0})                          
                        }, 40);
                      //this.props.handleSearch(event)
                       }
                    }
                    ></input>
                    <button 
                        onClick={(event) => this.props.search(event)} 
                        type='submit'
                        style={{
                            //marginTop: this.props.show_wide == 1 ? "12px": "8px ",
                            width: "40px",                        
                            //height: this.props.show_wide == 1 ? "45.5px" : "",
                            height: "38px",
                            cursor: "pointer",
                            float: "right",
                            display: "block",
                            alignItems: "center",
                            marginRight: "0px",
                            borderRadius: "0 4px 4px 0"
                        }}
                        id="search_input_search_button"
                        className="button_purple_background semi_rounded_border"
                        //disabled={this.state.selected_tags.length > 0 ? false : true}
                    >
                    
                    <FontAwesomeIcon
                        className="fa-sync"
                        icon="search"
                        style={{
                         position:"relative",                      
                         right: "4px",
                         top: "0px",
                        lineHeight: "29px",
                        }}                            
                    />
                    </button>
                </form>
                {/* <div style={{
                    width: "416px", 
                    position: "absolute", 
                    height: '155px',
                    zIndex: 9999,
                    backgroundColor:"red"}}>dd</div> */}

            </div>
        : 
        
        
         <div style={{
             position:"relative",
             float:"right",
             //marginRight: this.props.mobile_filter_showing == 1 ?  "-5px" : "30px",
             marginTop: "18px",width: "18px", marginRight:"16px",
          
         }}>
        <a 
            id="search_button_href"

            href="javascript:void(0);" 
            onClick={async() => {await this.props.set_mobile_search_input_popup_showing();document.getElementById("mobile_search_input").focus()}}>
            {/* <FontAwesomeIcon
                className="fa-sync fa-1x purple_href"
                icon="search"
                style={{
                    position:"relative",    
                                      
                   
                    
                }}                            
                /> */}
                <div className={"search_left_menu_icon"} 
                                    style={{
                                      //  marginTop: "22px",
                                        marginRight: this.props.mobile_filter_showing == 1 ? "-3px" : 
                                        this.props.mobile_or_tablet == 1 ? '0px' :  '-12px', 
                                        marginTop: this.props.mobile_filter_showing == 1 ? "-1px" : "1",
                                        position:"relative",float:"right", 
                                        
                                    
                                    }}
                                    ></div>
                {/* <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.16675 3.33333C5.94509 3.33333 3.33341 5.94501 3.33341 9.16667C3.33341 12.3883 5.94509 15 9.16675 15C12.3884 15 15.0001 12.3883 15.0001 9.16667C15.0001 5.94501 12.3884 3.33333 9.16675 3.33333ZM1.66675 9.16667C1.66675 5.02453 5.02461 1.66667 9.16675 1.66667C13.3089 1.66667 16.6667 5.02453 16.6667 9.16667C16.6667 13.3088 13.3089 16.6667 9.16675 16.6667C5.02461 16.6667 1.66675 13.3088 1.66675 9.16667Z" 
                fill="red"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2858 13.2857C13.6113 12.9603 14.1389 12.9603 14.4643 13.2857L18.0893 16.9107C18.4148 17.2362 18.4148 17.7638 18.0893 18.0893C17.7639 18.4147 17.2363 18.4147 16.9108 18.0893L13.2858 14.4643C12.9604 14.1388 12.9604 13.6112 13.2858 13.2857Z" 
                fill="red"/>
                </svg> */}

            </a>
        </div>}
        

        </React.Fragment>
        );
    }
}
 
export default SearchUIComponent;