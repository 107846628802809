import React, { Component } from 'react';
import Slider from '@material-ui/core/Slider';
import QualityComponent from './quality'
import OrderHorizontal from './order_horizontal'
import { lightTheme } from './../../theme';
function valuetext(value) {

    return value < 40 ? value : value+"+";
  }
  
class FiltersHorizontal extends Component {
    state = {  }
    
    shouldComponentUpdate(nextProps, nextState) {

      if(
          this.props.quality_all != nextProps.quality_all ||
          this.props.quality_hd != nextProps.quality_hd ||
          this.props.quality_fourk != nextProps.quality_fourk ||
          this.props.quality_vr != nextProps.quality_vr ||
          this.props.quality_non_hd != nextProps.quality_non_hd ||
          this.props.mins != nextProps.mins ||
          this.props.change_sort != nextProps.change_sort ||
          this.props.change_sort_period != nextProps.change_sort_period ||
          this.props.change_best_parameter != nextProps.change_best_parameter ||
          this.props.best_parameter != nextProps.best_parameter ||
          this.props.get_best_parameter_from_id != nextProps.get_best_parameter_from_id || 
          this.props.theme != nextProps.theme ||
          this.props.sort_best != nextProps.sort_best ||
          this.props.sort_trending != nextProps.sort_trending || 
          this.props.sort_new != nextProps.sort_new ||
          this.props.sort_period != nextProps.sort_period ||
          this.props.best_parameter != nextProps.best_parameter ||
          this.props.show_overlay != nextProps.show_overlay
          ) {
              //console.log("yes  - FUCKIN RENDER"+this.props.videos)
              return true
      }
      // console.log("no - DO NOT RENDER")
      return false
   } 

    render() { 
     
     
        return ( 
         
            <div
            id="full_screen_filters_left"
            style={{ display: "inline-block", width: "",marginTop: '2px'}}
            className={ "horizontal_filters"}
           
            >               

            <div style={{position:"relative",float:"left"}}>
            <OrderHorizontal
                change_sort = {this.props.change_sort} 
                change_sort_period = {this.props.change_sort_period}
                change_best_parameter = {this.props.change_best_parameter}          
                best_parameter = {this.props.best_parameter}
                get_best_parameter_from_id = {this.props.get_best_parameter_from_id}
                sort_new = {this.props.sort_new}
                sort_best = {this.props.sort_best}
                sort_trending = {this.props.sort_trending}
                sort_period = {this.props.sort_period}
                
                ></OrderHorizontal>
                 
            </div>
            <div style={{position:"relative",float:"right"}}>
            <div 
                     //className="sub12"
                     style={{position:"relative",float:"left",
                     //marginLeft: "315px" ,
                     //marginRight: "12px",
                     marginTop: "6.5px",
                     fontSize:"14px"
                    }}
                     className="pornstar_channel_in_view_video_href_class">
                        DURATION:&nbsp;&nbsp;&nbsp;</div> 
            <Slider
                      value={this.props.mins}
                      max={40}
                      step={5}
                      style={{width:"200px",marginTop: "3px", color:  this.props.theme == lightTheme ?  "#5724E6" : "#9F7DFF"}}
                      className="purple_href"
                      // onMouseUp={() => this.props.performQuery()}
                      // onChange={this.props.handleMinsChange}
                      onChange={this.props.handleMinsChange}
                      onChangeCommitted={() => this.props.performQuery()}
                      valueLabelDisplay="auto"
                      valueLabelFormat={valuetext}
                      aria-labelledby="range-slider"
                      getAriaValueText={valuetext}
                  />
                  <div style={{display:"inline-block",
                   position:"relative", marginLeft: "24px",marginTop: "-3.5px"}}>
                    <QualityComponent
                        quality_clicked={this.props.quality_clicked}
                        quality_all={this.props.quality_all}
                        quality_hd={this.props.quality_hd}
                        quality_fourk={this.props.quality_fourk}
                        quality_vr={this.props.quality_vr}
                        quality_non_hd={this.props.quality_non_hd}
                        is_horizontal={1}

                        
                      
                        ></QualityComponent>
                        </div>
            </div>
                   
                     
        </div>
         );
    }
}
 
export default FiltersHorizontal;