import React, { Component,  Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import axios from "axios";
import configData from "./../../config.json";
import MenuComponent from './menu'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {  title_to_url, toast_error } from "./../global_arrays";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { config, library } from "@fortawesome/fontawesome-svg-core";
import { flags } from "./../global_arrays.jsx";
import Skeleton, { SkeletonTheme }  from 'react-loading-skeleton';

import "./../../App.css";
import LoadingDotsComponent from './../loading_dots_component/loading_dots_component';
import CookiesDialog from "./cookies_dialog/cookies_dialog";

import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme } from './../theme';
import { GlobalStyles } from './../global';
import jwtDecode from "jwt-decode";
import { withRouter } from 'react-router-dom';
import { GoogleLogin } from 'react-google-login';
import ErrorComponent from './../error_component/error_component'
import { tags } from "./../global_tags"  
import DOMPurify from 'dompurify'

import {  
  faUser,
  faLock,
  faEnvelope,
  faEye, 
  faEyeSlash,
  faCircleNotch,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import zIndex from "@material-ui/core/styles/zIndex.js";
import { transform } from "lodash";


library.add(faCircleNotch, faUser, faLock, faEnvelope, faEye, faEyeSlash, faArrowRight);
const CLIENT_ID = '257815729355-3j0adbgoh3fomdqrjp96j8nsnfjae30k.apps.googleusercontent.com';

const LoginComponent = React.lazy(() => import('./login/login'));
const ShowVideo = React.lazy(() => import('./show_video/show_video'));
const LogoutComponent = React.lazy(() => import('./logout/logout'));
const ResetPasswordComponent = React.lazy(() => import('./reset_password/reset_password'));
const RegisterComponent = React.lazy(() => import('./register/register'));

const WatchedProfileComponent = React.lazy(() => import('./profile/watched'));
const FavoritesProfileComponent = React.lazy(() => import('./profile/favorites'));
const UserSettingsComponent = React.lazy(() => import('./profile/settings'));

const ShowVideos = React.lazy(() => import('./show_videos/show_videos'));
const ChannelsComponent = React.lazy(() => import('./channels/channels'));
const ChannelComponent = React.lazy(() => import('./channel/channel'));
const PornstarComponent = React.lazy(() => import('./pornstar/pornstar'));
const PornstarsComponent = React.lazy(() => import('./pornstars/pornstars'));
const WatchlistComponent = React.lazy(() => import('./watchlist/watchlist'));
const WatchedComponent = React.lazy(() => import('./watched/watched'));
const MyCommentsComponent = React.lazy(() => import('./profile/mycomments'));
const UserTags = React.lazy(() => import('./tags/tags'));
const SetBlurOnComponent = React.lazy(() => import('./set_blur/set_blur_on'));
const SetBlurOffComponent = React.lazy(() => import('./set_blur/set_blur_off'));
const TermsOfUseComponent = React.lazy(() => import('./terms_of_use/terms_of_use'));
const PrivacyPolicyComponent = React.lazy(() => import('./privacy_policy/privacy_policy'));
const CookiePolicyComponent = React.lazy(() => import('./cookie_policy/cookie_policy'));
const ContactUsComponent = React.lazy(() => import('./contact_us/contact_us'));
const Component404 = React.lazy(() => import('./404/404'));

const SearchPageComponent = React.lazy(() => import('./search_page/search_page'));


//const CookiesDialog = React.lazy(() => import('./cookies_dialog/cookies_dialog'));

class UserNavBar extends Component {
  constructor(props) {
    super(props);
  }

  
  state = {
    ptags: [],
    ptags_initial: [],
    theme: lightTheme,
    filters_showing: 0,
    tags_desktop_popup_showing: 0,
    tags_desktop_popup_hovering_now: 0,
    pornstar_input_focused: 0,
    mobile_filter_showing: -1,
    tags_filter_value: "",
    tablet_filter_showing: 0,
    previous_scroll_y: 0,
    scrolly: 0,
    login_scroll_y: 0,
    scrolling_down: 1, //by mistake 0 is 1 and 1 is 0 ;-/
    menu_showing: 0,
    columns: 0,
    disable_scroll: 0,  
    show_blur: 0,
    tablet_filter_bar_absolute: -1,
    mobile_or_tablet: -1,
    show_overlay: 0,
    menu_selected: 0,
    mobile_user_menu_showing: 0,    
    comment_got_deleted: 0, // this will change when a comment is deleted and it will trigger componentdidmount in comment component
    login_showSpinner: 0,
    login_rememberme: 1,
    login_showPassword1clearText: 0,
    login_showError: 1,
    login_error_message: "",
    login_popup_showing: 0,
    login_return_location: "",
    cookies_dialog_ok: 0,
    window_height: 0,
    window_innerheight_refreshed: 0,
    delete_comment_popup_showing: 0,
    pornstar_countries_popup_showing: 0,
    pornstars_by_country_popup_scroll_y: 0,
    pornstar_countries: [],
    pornstar_countries_initial: [],
    pornstar_countries_showDotsLoading: 1,
    pornstar_countries_showError: 0,
    pagination_show_less: 0,
    search_popup_showing: 0,

    tags_filter_desktop_focused: 0, 
    //search status elements
    tags: [],
    show_more_tags: 0,
    cancelToken: axios.CancelToken.source(),
    previousRequests: [],
    pornstars_like: [],
    channels_like: [],
    search_pornstars_loadingdots: 0,
    channel_pornstars_loadingdots: 0,

    mobile_search_input_popup_showing: 0,

    search_expanded: 0,
    search_item_selected_active_element: 2,
    search_string: "",
    tag_popup_stay_active: 0,


    
    // user: [],

    padding_px_larger_screens: -1,
    padding_percentage_larger_screens: 0.035,

    comment_menu_showing: 0,
    current_comment_menu_id_showing: 0,
    scrollbarwidth: 0,

    scrolly_after_menu_open: 0,


    email_focus: 0,
    password_focus: 0,
    number_of_filters: 0,

    tags_left_menu_width_desktop: 245,
    tags_left_menu_width_mobile_and_tablet: 265,
    is_main_page: 0,

    login_popup_redirect: undefined,

    overlay_full_except_menu_and_mobile_search_value: 0,

    overlay_full_except_menu: 0,

    over_18_popup_showing: 0,

    real_flag_for_mobile_search_input_popup_showing: 0,

    tags_expanded_div_became_fixed: 0,

    selected_tags: [],

    any_popup_showing_stop_video_hover: 0,

    // top_over_18_popup_px: window.innerWidth > 511 ? (window.innerHeight/2) - 121 : 
    //                       window.innerWidth > 379 ? (window.innerHeight/2) - 131 :
    //                       window.innerWidth > 312 ? (window.innerHeight/2) - 141 :  (window.innerHeight/2) - 151,
    
    //this.state.mobile_filter_showing && this.state.mobile_filter_showing == 1 ? (window.innerHeight/2) - (document.getElementById('popup').offsetHeight/2) : 0
    
    //422*352/window.innerWidth,
    
  };

  change_login_rememberme = () => {
  
    if(this.state.login_rememberme == 0){
      this.setState({login_rememberme: 1})
    }else{
      this.setState({login_rememberme: 0})
    }
    

  }

  async_search_is_empty = async() => {
    // await this.setState({ showingVideos: 0, videos: [], videosCount: -1, tag: [] });
    // this.props.history.push("/tags");
    // this.componentDidMount();
    // window.removeEventListener('resize', this.myCallback_tags)
    // window.removeEventListener('resize', this.myCallback_videos)
    // window.addEventListener('resize', this.myCallback_tags)
    // this.myCallback_tags()
  };

  inputChange = async(value) => {     //get tags async    

    
    if(value.length > 5){ 
      toast_error("You can't select more than 5 tags")
      return
    }
    await this.setState({ selected_tags: value });    
    if (value.length == 0) {
      try {
        //this.async_search_is_empty();
      } catch (e) {}
    }
  };

  deleteInput = async(value) => {    //get tags async 
    await this.setState(prevState => ({
      selected_tags: prevState.selected_tags.filter(tag => tag.tag !== value)
    }));
    if (this.state.selected_tags.length == 0) {
      try {
        //this.async_search_is_empty();
      } catch (e) {}
    }
  }

  set_number_of_filters = async(filters_number) => {
    this.setState({number_of_filters: filters_number})
  }

  set_current_comment_menu_id_showing = async(comment_id) => {
    await this.setState({current_comment_menu_id_showing: comment_id, comment_menu_showing: 1})
  }


  filterOptionsPornstarsPerCountry = (options, inputValue) => {    
    const optionsStartingWith = options.filter(option => {
      return this.return_country_from_country_code(option.country).toLowerCase().startsWith(inputValue.toLowerCase());
    });    
    const optionsContaining = options.filter(option => {
      return this.return_country_from_country_code(option.country).toLowerCase().includes(inputValue.toLowerCase()) && !optionsStartingWith.includes(option);
    });
    return optionsStartingWith.concat(optionsContaining);
  };


  filterOptions = (options, inputValue) => {
    const optionsStartingWith = options.filter(option => {
      return option.tag.toLowerCase().startsWith(inputValue.toLowerCase());
    });
    const optionsContaining = options.filter(option => {
      return option.tag.toLowerCase().includes(inputValue.toLowerCase()) && !optionsStartingWith.includes(option);
    });
    return optionsStartingWith.concat(optionsContaining);
  };

  make_search_inactive = () => {
    if(this.state.search_expanded == 1){
      document.getElementById("search_input").value = ""; 
      document.getElementById("search_input").style.borderRadius = "4px";
      document.getElementById("search_input").style.borderRadius = "4px 0 0 4px";
      
      document.getElementById("search_input").style.border = "1px solid transparent"


      document.getElementById("search_input_search_button").style.borderRadius = "0 4px 4px 0";  
      document.getElementById("search_input").blur()
    }
    else{
      document.getElementById("mobile_search_input").value = "";
      document.getElementById("mobile_search_input_div").style.borderRadius = "4px";
      document.getElementById("mobile_search_input").style.borderRadius = "4px 0 0 4px";
     // document.getElementById("mobile_search_input_search_button").style.borderRadius = "0 4px 4px 0";

      // document.getElementById("mobile_search_input").style.borderBottom = 
      //     this.props.theme == lightTheme ?  "1px solid #5724E6" : "1px solid #9F7DFF"    
    }
  }

  search = (event, comesfromenter=-1) => {
    event.preventDefault()
   // alert('df')
    // closes the search popup if open
    if(comesfromenter == 1 && this.state.search_item_selected_active_element != -1){
      //alert("enter")
      document.getElementById("search_item_href_el"+this.state.search_item_selected_active_element).click()
      if(this.state.search_expanded == 1){
        var input_value = document.getElementById("search_input").value = "";
        this.setState({search_string: ""})    
      }
      else{
        var input_value = document.getElementById("mobile_search_input").value = ""; 
        this.set_mobile_search_input_popup_showing(0)
        
      }
      this.make_search_inactive();
      return
    }
    if(this.state.mobile_search_input_popup_showing == 1){
      //this.setState({mobile_search_input_popup_showing: 0,real_flag_for_mobile_search_input_popup_showing: 0, overlay_full_except_menu_and_mobile_search_value: 0})
      this.setState({overlay_full_except_menu_and_mobile_search_value: 0})

      document.getElementById("menu_inner").style.borderBottom= ""
      try{
        document.getElementsByClassName("tablet_filter_bar")[0].style.top = '58px'
      }catch(e){}
      
      //this.set_search_popup_showing(0)
      
    }


    if(this.state.search_expanded == 1){
      var input_value = document.getElementById("search_input").value;    
    }
    else{
      var input_value = document.getElementById("mobile_search_input").value;    
    }
    if(input_value.length == 0){
        return
    }

    if(this.state.mobile_or_tablet == 0){
      //this.set_search_popup_showing(0)
      //this.make_search_inactive();
    }
    
    document.getElementById("search_items").style.display = "none"

   
    
    this.setState({show_overlay: 0})
    
    document.body.style.overflow = 'visible'; 

    
    document.getElementById("menu").style.marginLeft=""
    this.go_to_link(event,"/search/"+ DOMPurify.sanitize(input_value).replace("/",""))  


}


  handleSearch = async (event) => {
    const start = Date.now();
    //alert('y')
    this.setState({search_pornstars_loadingdots: 1,search_item_selected_active_element: -1 })
    if(this.state.search_expanded == 1){
      var value = document.getElementById("search_input").value;    
    }
    else{
      var value = document.getElementById("mobile_search_input").value;    
    }
    this.setState({search_string: value})
    if (value.length == 0) {
      document.getElementById("search_items").style.display = "none";
      this.setState({ show_more_tags: 0 });
      this.set_search_popup_showing(0);
      if(this.state.search_expanded == 1){
  //      document.getElementById("search_input").style.borderRadius = "4px";
    //    document.getElementById("search_input").style.borderRadius = "4px 0 0 4px";
    document.getElementById("search_input").style.borderRadius = "4px 0 0 4px";
        
        document.getElementById("search_input").style.borderBottom = 
            this.props.theme == lightTheme ?  "1px solid #5724E6" : "1px solid #9F7DFF"


  //      document.getElementById("search_input_search_button").style.borderRadius = "0 4px 4px 0";
      
      }else{
    //    document.getElementById("mobile_search_input_div").style.borderRadius = "4px";
      //  document.getElementById("mobile_search_input").style.borderRadius = "4px 0 0 4px";
        //document.getElementById("mobile_search_input_search_button").style.borderRadius = "0 4px 4px 0";
        if(this.state.mobile_or_tablet == 1 ){
          //this.setState({show_overlay: 0})
          

          this.setState({overlay_full_except_menu_and_mobile_search_value: 0})
          document.body.style.overflow = 'visible';
          document.getElementById("menu").style.marginLeft=""
        }
        document.getElementById("mobile_search_input").style.borderRadius = "4px";

        // document.getElementById("mobile_search_input").style.borderBottom = 
        //   this.props.theme == lightTheme ?  "1px solid #5724E6" : "1px solid #9F7DFF"


      }
    return
    } else {
        var myElement;
      if(this.state.search_expanded == 1){
        myElement = document.getElementById("search_input_wrapper_desktop");

        
     //   document.getElementById("search_input_wrapper_desktop").style.borderRadius = "4px 4px 0 0";
        //document.getElementById("search_input_wrapper_desktop").style.borderBottom = "1px solid red";
        
         document.getElementById("search_input").style.borderRadius = "4px 0 0 0"// "4px 0 0 4px";
        
        
        
        // document.getElementById("search_input_search_button").style.borderRadius = "0 4px 0px 0";

        document.getElementById("search_input").style.borderBottom = "1px solid transparent";
        
        document.getElementById("search_items").style.borderTop = "0px solid red";
        
      }else{
        myElement = document.getElementById("mobile_search_input");
        if(this.state.mobile_or_tablet == 1 ){
          //this.setState({show_overlay: 1})
          var scrollbarwidth=this.getScrollBarWidth()
         // alert(scrollbarwidth)

          //WIP 
          document.getElementById("menu").style.marginLeft=-(scrollbarwidth/2)+"px"
          
          
          this.setState({overlay_full_except_menu_and_mobile_search_value: 1})
          document.body.style.overflow = 'hidden';
        }
        


        // document.getElementById("mobile_search_input_div").style.borderRadius = "4px 4px 0 0";
        // document.getElementById("mobile_search_input").style.borderRadius = "4px 0 0 0";
        // document.getElementById("mobile_search_input_search_button").style.borderRadius = "0px";

        document.getElementById("mobile_search_input").style.borderRadius = "4px 4px 0 0"

        document.getElementById("mobile_search_input").style.borderBottom = "1px solid transparent";
        document.getElementById("search_items").style.borderTop = "0px solid transparent";

        
      }
      const rect = myElement.getBoundingClientRect();
      const rect2 = myElement.getBoundingClientRect();
      const leftPos = rect.left;
      const rightPos = rect.right;
      //const topPos = rect.top;      
     // alert(this.state.search_expanded)
      if(this.state.search_expanded == 1 ){
        //document.getElementById("search_items").style.right = (Math.round(leftPos)-1)+"px"
        document.getElementById("search_items").style.left = (rightPos  -306)+"px"
        document.getElementById("search_items").style.width = document.getElementById("search_input").offsetWidth+"px"
        
        //document.getElementById("search_input_wrapper_desktop").width = (rightPos - leftPos)+"px"
        //alert('gitsa')
      }else{
        document.getElementById("search_items").style.left = (leftPos)+"px"
        document.getElementById("search_items").style.width = document.getElementById("mobile_search_input").offsetWidth+"px"
      } 
      //document.getElementById("search_items").style.top = Math.round(topPos+45)+"px"
      document.getElementById("search_items").style.top = this.state.search_expanded == 1 ? "49px" : "96px"
      document.getElementById("search_items").style.display = "block";

      this.set_search_popup_showing(1);
    }
    if(this.state.search_expanded == 1){
      this.setState({
        tags: this.filterOptions(tags, document.getElementById("search_input").value)
      });
    }else{
      this.setState({
        tags: this.filterOptions(tags, document.getElementById("mobile_search_input").value)
      });
    }
  
    this.state.previousRequests.forEach(cancelToken => cancelToken.cancel());
    this.setState({
      cancelToken: axios.CancelToken.source(),
      previousRequests: [],
    });
  
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      const request1 = axios.get(
        configData.PRIVATE_API_ENDPOINT + "/get_pornstars_like/0/6?pornstar=" + value + "&is_enabled=1&limit_without_offset=6",
        {
          cancelToken: this.state.cancelToken.token,
          timeout: configData.TIMEOUT_LIMIT,
        }
      );
  
      const request2 = axios.get(
        configData.PRIVATE_API_ENDPOINT + "/get_channels_like/0/6?channel=" + value + "&is_enabled=1&limit_without_offset=6",
        {
          cancelToken: this.state.cancelToken.token,
          timeout: configData.TIMEOUT_LIMIT,
        }
      );
  
      this.setState(prevState => ({
        previousRequests: [...prevState.previousRequests, this.state.cancelToken]
      }));
  
      Promise.all([request1])
        .then(responses => {
          this.setState({
            pornstars_like: responses[0].data,
            //channels_like: responses[1].data,
            //showSpinner: 0,
            search_pornstars_loadingdots: 0,
          });setTimeout(() => {
            this.setState({ showDotsLoading: 0, showError: 1 });
          }, configData.WAITING_MILIS - (Date.now() - start));
        })
        .catch(thrown => {
          if (axios.isCancel(thrown)) {
            console.log("Request canceled", thrown.message);
          } else {
            setTimeout(() => {
              this.setState({ showDotsLoading: 0, showError: 1 });
            }, configData.WAITING_MILIS - (Date.now() - start));
          }
        });

        Promise.all([request2])
        .then(responses => {
          this.setState({
            //pornstars_like: responses[0].data,
            channels_like: responses[0].data,
            channel_pornstars_loadingdots: 0,
          });
        })
        .catch(thrown => {
          if (axios.isCancel(thrown)) {
            console.log("Request canceled", thrown.message);
          } else {
            setTimeout(() => {
              this.setState({ showDotsLoading: 0, showError: 1 });
            }, configData.WAITING_MILIS - (Date.now() - start));
          }
        });


    }, 1000);
  };

  set_mobile_search_input_popup_showing = (force_mobile=0) => {
  
    // const myElement = document.getElementById("search_button_href");
    // const rect = myElement.getBoundingClientRect();
    // var leftPos = rect.left;
    // if(this.state.mobile_filter_showing == 1){
    //   if(leftPos - 300  < 10){
    //     leftPos = 310
    //   }
    //   document.getElementById("mobile_search_input_div").style.left = Math.round(leftPos-300)+"px"
    // }
    // else{
    //   document.getElementById("mobile_search_input_div").style.left = Math.round(leftPos-300)+"px"
    // }

    if(this.state.mobile_search_input_popup_showing == 0){
      if(this.state.mobile_or_tablet == 1){
        if(this.state.mobile_filter_showing == 1){
          this.setState({mobile_search_input_popup_showing: 1, real_flag_for_mobile_search_input_popup_showing:1 })
          document.getElementById("mobile_search_input_div").style.left='0px'
          document.getElementById("mobile_search_input_div").style.width="calc( 100% - 0px )"
          document.getElementById("mobile_search_input").style.width="calc( 100% - 10px )"
          document.getElementById("mobile_search_input").style.borderRadius = "4px";
          document.getElementById("menu_inner").style.borderBottom= "1px solid transparent";
          
          document.getElementById("mobile_search_input_div").style.paddingLeft="10px"
        }else{
          this.setState({mobile_search_input_popup_showing: 1, real_flag_for_mobile_search_input_popup_showing:1 })
          document.getElementById("mobile_search_input_div").style.left='0px'
          document.getElementById("mobile_search_input_div").style.width="calc( 100% - 0px )"
          document.getElementById("mobile_search_input").style.width="calc( 100% - 20px )"
          document.getElementById("mobile_search_input").style.borderRadius = "4px";
          document.getElementById("menu_inner").style.borderBottom= "1px solid transparent";
          
          document.getElementById("mobile_search_input_div").style.paddingLeft="20px"

          try{
            document.getElementsByClassName("tablet_filter_bar")[0].style.top = '101px'
          }catch(e){}
        }
      }else{
      this.setState({mobile_search_input_popup_showing: 1, show_overlay: 1})
      
      //document.body.style.overflow = 'hidden';
      var scrollbarwidth=this.getScrollBarWidth()

      document.getElementById("menu").style.marginRight=(scrollbarwidth/2)+'px'
      document.body.style.overflow = 'hidden'; 
      document.getElementById("mobile_search_input_div").style.width="306px"
      document.getElementById("mobile_search_input").style.width="266px"
      document.getElementById("mobile_search_input_div").style.paddingLeft="0px"
      document.getElementById("mobile_search_input_div").style.paddingBottom="0px"

      const myElement_init = document.getElementById("search_button_href");
      const rect_init = myElement_init.getBoundingClientRect();
      var leftPos_init = rect_init.left;
      
      document.getElementById("mobile_search_input_div").style.left = Math.round(leftPos_init-300)+"px"
      document.getElementById("search_items").style.left = Math.round(leftPos_init-300)+"px"
      

    }
     // document.body.style.overflow = 'hidden';
      
    }else{
      //alert('panagia')
      this.setState({mobile_search_input_popup_showing: 0, show_overlay: 0, real_flag_for_mobile_search_input_popup_showing: 0, overlay_full_except_menu_and_mobile_search_value: 0})
      //document.body.style.overflow = 'visible';
      document.getElementById("menu").style.marginRight=''
      document.getElementById("menu").style.marginLeft=''
      document.getElementById("menu_inner").style.borderBottom= "";
      document.body.style.overflow = 'visible'; 
      try{
        document.getElementsByClassName("tablet_filter_bar")[0].style.top = '58px'
      }catch(e){}
      if(force_mobile == 1){
        document.getElementById("mobile_search_input").style.borderRadius = "4px 0 0 4px";
        
        // document.getElementById("mobile_search_input").style.borderBottom = 
        //     this.props.theme == lightTheme ?  "1px solid #5724E6" : "1px solid #9F7DFF"
      }else{
        if(this.state.search_expanded == 1){
        document.getElementById("search_input").style.borderRadius = "4px 0 0 4px";
          
          document.getElementById("search_input").style.borderBottom = 
              this.props.theme == lightTheme ?  "1px solid #5724E6" : "1px solid #9F7DFF"
        }else{
          document.getElementById("mobile_search_input").style.borderRadius = "4px";
          
          // document.getElementById("mobile_search_input").style.borderBottom = 
          //     this.props.theme == lightTheme ?  "1px solid #5724E6" : "1px solid #9F7DFF"
        }
      }
    }

  }

  set_search_popup_showing = (f_value) => {
    this.setState({search_popup_showing: f_value})
    //alert('vv')
    if(f_value == 0){
      document.getElementById("search_items").style.display = "none";
      this.setState({search_string: ""})
    //  ,show_overlay: 0})
      //document.body.style.overflow = 'visible';
      
    }

    
  }


  set_cookies_dialog = (f_value) => {
    this.setState({cookies_dialog_ok: f_value})
  }

  set_mobile_filter_showing=(f_value) => {
    this.setState({mobile_filter_showing: f_value})
  }

  set_overlay=(overlay_value) => {
    this.setState({show_overlay: overlay_value})
  }

  // show_filters = () => {    
  //   document.getElementById('tagsLeft').style.left = "all 0.2s ease-in";
  //   document.getElementById('tagsLeft').style.left = "all 0.2s ease-in";
  //   alert('show')
  //   //document.getElementById('tagsLeft').style.display = "block";
  //   document.getElementById('rightSideFullContainer').style.color = "red";
  //   this.setState({ filters_showing:1 })
    

  // }
  // hide_filters = () => {
  //   document.getElementById('tagsLeft').style.display = "none";
  //   this.setState({ filters_showing: 0 })
  // }
  // wrapperclick = () => {
//   if(this.state.filters_showing == 1){
  //     this.hide_filters();
  //   }
  // }

  helper_desktop_view_close_mobile_search = async() => {
    if(this.state.mobile_search_input_popup_showing == 1){
      await this.set_mobile_search_input_popup_showing(0)
      this.setState({mobile_search_input_popup_showing: 0,show_overlay: 0, real_flag_for_mobile_search_input_popup_showing: 0})
      //document.body.style.overflow = 'visible';
      this.set_search_popup_showing(0)
      
    }
    this.setState({search_expanded: 1})
    if(this.state.search_popup_showing == 1){
      if(this.state.mobile_search_input_popup_showing == 1){
        this.setState({mobile_search_input_popup_showing: 0})
        this.set_search_popup_showing(0)
        
      }
      var myElement = document.getElementById("search_input");
      
      
      const rect = myElement.getBoundingClientRect();
      const leftPos = rect.left;
      const topPos = rect.top;      
      
      document.getElementById("search_items").style.left = Math.round(leftPos)+"px"
  
      document.getElementById("search_items").style.top = Math.round(topPos+38)+"px"
  
      
    }
  }
 
  helper_mobile_or_tablet_view_close_desktop_search = async() => {
    this.setState({search_expanded: 0})
    if(this.state.search_popup_showing == 1 && this.state.mobile_search_input_popup_showing == 0){
      //this.setState({mobile_search_input_popup_showing: 0})
      this.set_search_popup_showing(0)
    }

    //reposition search popup and search 
    if(this.state.mobile_search_input_popup_showing == 1){
      const myElement_init = document.getElementById("search_button_href");
      const rect_init = myElement_init.getBoundingClientRect();
      var leftPos_init = rect_init.left;
      const topPos_init = rect_init.top;

      //document.getElementById("mobile_search_input_div").style.left = Math.round(leftPos_init-150)+"px"
      if(this.state.mobile_filter_showing == 1){
        document.getElementById("mobile_search_input_div").style.left='0px'
          document.getElementById("mobile_search_input_div").style.width="calc( 100% - 0px )"
          document.getElementById("mobile_search_input").style.width="calc( 100% - 10px )"
         // document.getElementById("mobile_search_input").style.borderRadius = "4px";
          document.getElementById("menu_inner").style.borderBottom= "1px solid transparent";
          
          document.getElementById("mobile_search_input_div").style.paddingLeft="10px"
       
       document.getElementById("search_items").style.width = document.getElementById("mobile_search_input").offsetWidth+"px" 
       document.getElementById("search_items").style.left='10px'
      }
      else{
        this.wrapperclick()
        // document.getElementById("mobile_search_input_div").style.left='0px'
        //   document.getElementById("mobile_search_input_div").style.width="calc( 100% - 0px )"
        //   document.getElementById("mobile_search_input").style.width="calc( 100% - 20px )"
        // //  document.getElementById("mobile_search_input").style.borderRadius = "4px";
        //   document.getElementById("menu_inner").style.borderBottom= "1px solid transparent";
          
        //   document.getElementById("mobile_search_input_div").style.paddingLeft="20px"


        // document.getElementById("search_items").style.width = document.getElementById("mobile_search_input").offsetWidth+"px" 
        // document.getElementById("search_items").style.left='20px'
      }
  

      // JUST REMOVED WIP NO IDEA
      // if(this.state.search_popup_showing == 1){
      //   const myElement = document.getElementById("mobile_search_input");
      //   const rect = myElement.getBoundingClientRect();
      //   const leftPos = rect.left;
      //   const topPos = rect.top;      
        
      //   document.getElementById("search_items").style.left = Math.round(leftPos)+"px"
      //   document.getElementById("search_items").style.top = Math.round(topPos+38)+"px"
      // }


    }
  }


  reportWindowSize = async(is_pornstar,is_related=-1) => {
    var columns=2    
    var window_width = window.innerWidth;    
    this.setState({is_main_page: 1})
    // if(window_width > 1595){
    //   await this.setState({padding_px_larger_screens: window_width*this.state.padding_percentage_larger_screens})      
    // }else{
    //   await this.setState({padding_px_larger_screens: 0})
    // }

    if(window_width > 1595){
      await this.setState({padding_percentage_larger_screens: 0.075})
    }else if(window_width > 1195){
      await this.setState({padding_percentage_larger_screens: 0.025})
    }else{
      await this.setState({padding_percentage_larger_screens: 0.0})
    }

    if(window_width > 1195){
      await this.setState({padding_px_larger_screens: window_width*this.state.padding_percentage_larger_screens}) 
      var intermediateContainer_window_width = document.getElementById("intermediateContainer").offsetWidth -(2*this.state.padding_px_larger_screens) + 22;
      
    //  document.getElementById("menu").style.width = "100%"//document.getElementById("menu_container").offsetWidth+'px'
      
      //document.getElementById("menu").style.width = (document.getElementById("menu_container").offsetWidth-4)+'px' 
      document.getElementById("menu").style.width = "100%" //(document.getElementById("menu_container").offsetWidth+1)+'px'
      
      document.getElementById("menu_inner").style.width = (document.getElementById("menu_container").offsetWidth-6)+'px' 
      
      document.getElementById("menu_inner").style.paddingLeft = "0px"
      document.getElementById("menu_inner").style.paddingRight = "0px"
      //  document.getElementById("menu").style.left = "0px"
      
      //  var padding =  (window.innerWidth - (document.getElementById("menu_container").offsetWidth))/2 -4
      
      //  document.getElementById("menu").style.paddingLeft = (padding - 2)+"px"
      //  document.getElementById("menu").style.paddingRight = (padding)+"px"
       //document.getElementById("menu").style.height = "58px"

     //  document.getElementById("menu_border_div").style.width =(document.getElementById("intermediateContainer").offsetWidth -(2*this.state.padding_px_larger_screens) + 4)+'px'
     //571

    }else if(window_width > 571){
      await this.setState({padding_px_larger_screens: window_width*this.state.padding_percentage_larger_screens})      
      var intermediateContainer_window_width = document.getElementById("intermediateContainer").offsetWidth -(2*this.state.padding_px_larger_screens) ; //WIP was 40
      
      //document.getElementById("menu").style.width = "100%"
      
      document.getElementById("menu").style.width = "100%" //(document.getElementById("menu_container").offsetWidth+1)+'px' 
      document.getElementById("menu").style.left = "0px"
      
      // var padding =  (window.innerWidth - (document.getElementById("menu_container").offsetWidth))/2
      
      // document.getElementById("menu").style.paddingLeft = padding+"px"
      // document.getElementById("menu").style.paddingRight = (padding+16)+"px"
      document.getElementById("menu_inner").style.width = (document.getElementById("menu_container").offsetWidth)+'px' 
        
      // var padding =  (window.innerWidth - (document.getElementById("menu_container").offsetWidth))/2
    
      document.getElementById("menu_inner").style.paddingLeft = "10px"
      document.getElementById("menu_inner").style.paddingRight = "21px"
    }else{
      await this.setState({padding_px_larger_screens: window_width*this.state.padding_percentage_larger_screens})      
      var intermediateContainer_window_width = document.getElementById("intermediateContainer").offsetWidth -(2*this.state.padding_px_larger_screens) ; //WIP was 40
      
      //document.getElementById("menu").style.width = "100%"
      
      document.getElementById("menu").style.width = "100%" //(document.getElementById("menu_container").offsetWidth+1)+'px' 
      document.getElementById("menu").style.left = "0px"
      document.getElementById("menu_inner").style.width = (document.getElementById("menu_container").offsetWidth)+'px' 
        
        //var padding =  (window.innerWidth - (document.getElementById("menu_container").offsetWidth))/2
      
        document.getElementById("menu_inner").style.paddingLeft = "10px"
        document.getElementById("menu_inner").style.paddingRight = "0px"
        
      
      // var padding =  (window.innerWidth - (document.getElementById("menu_container").offsetWidth))/2
      
      // document.getElementById("menu").style.paddingLeft = (padding+10)+"px"
      // document.getElementById("menu").style.paddingRight = (padding)+"px"

    }
    intermediateContainer_window_width=intermediateContainer_window_width-this.state.scrollbarwidth;
   // alert(window_width)
    this.setState({window_innerheight_refreshed: window.innerHeight})
    //for the rightSideNoLeftMenu
    //this.setState({mobile_or_tablet: -1})

      this.setState({window_height: window.innerHeight})
      //alert(this.state.window_height)
      if(window_width <= 571){
        document.getElementsByClassName("tagsLeft")[0].style.width = this.state.tags_left_menu_width_mobile_and_tablet+"px"
        document.getElementById("rightSideFullContainer").style.marginLeft="10px"
        document.getElementById("rightSideFullContainer").style.marginRight="10px"
        document.getElementById('hamburger_wrapper').style.display = "block";
        document.getElementById('menu_desktop').style.display = "none";
        await this.setState({search_expanded: 0,pagination_show_less: 1,mobile_filter_showing: 1, tablet_filter_showing: 0, mobile_or_tablet: 1, pagination_show_less: 1})
        
        if(this.state.filters_showing == 0){
          this.setState({scrolling_down:1})
        }
        this.setState({columns: 2}) // is for related columns in video view page
        
        if(window_width > 400){
          columns = 2 
          var width= ((intermediateContainer_window_width-0) -(columns+1)*10)/columns + 2.5
        }else{
          columns = 1
          var width= ((intermediateContainer_window_width-0) -(columns+1)*10)/columns - 1
        }
        document.getElementById("rightSideFullContainer").style.marginLeft="5.5px"
        document.getElementById("rightSideFullContainer").style.width=(intermediateContainer_window_width)+"px"
        try{
          document.getElementById("horizontal_order").style.left="10px"
        }catch(e){}
        try{

          
            // document.getElementById("tagsLeft").style.height=(window.innerHeight - 58)+"px"
            // document.getElementById("tagsLeft").style.position="fixed"
            // document.getElementById("tagsLeft").style.zIndex="1001"
            // document.getElementById("tagsLeft").style.top="58px"
            // document.getElementById("tagsLeft").style.marginLeft="0px"
            // document.getElementById("tagsLeft").style.paddingLeft="15px"        
          
          // if(this.state.filters_showing == 1 ){  
          //   var scrolled = window.pageYOffset || document.documentElement.scrollTop;
          //   if(scrolled <= 58){
          //     var dist = 58-scrolled
          //     document.getElementById('tagsLeft').style.top = dist + 'px';
          //     document.getElementById('tagsLeft').style.height = "calc(100% - " + (58 - scrolled) + "px)";

          //   }else{
          //     document.getElementById('tagsLeft').style.top = "0px";
          //     document.getElementById('tagsLeft').style.height = "100%"; 
          //   }
          //   document.getElementById("tagsLeft").style.position="fixed"
          //   }

              document.getElementById('tagsLeft').style.top =  '58px';
              document.getElementById('tagsLeft').style.height = "calc(100% - " + 58 + "px)";
              document.getElementById("tagsLeft").style.position="fixed"
        }
        catch(e){}
        //close desktop view popup if open
        this.helper_mobile_or_tablet_view_close_desktop_search()



      }
      else if(window_width > 571 && window_width <= 1195){
        document.getElementsByClassName("tagsLeft")[0].style.width = this.state.tags_left_menu_width_mobile_and_tablet+'px'

        document.getElementById("rightSideFullContainer").style.marginLeft="0px"
        document.getElementById("rightSideFullContainer").style.marginRight="0px"
        if(this.state.mobile_user_menu_showing == 1){
          this.show_hide_user_menu_mobile()
        }
        if(window_width <= 735){
            columns = 2
            this.setState({search_expanded: 0,columns: 3,pagination_show_less: 1}) // is for related columns in video view page
            
            this.helper_mobile_or_tablet_view_close_desktop_search()            
          }
          else if(window_width <= 990){
            columns = 3
            this.setState({search_expanded: 1,columns: 6, pagination_show_less: 0}) // is for related columns in video view page
                 
            this.helper_desktop_view_close_mobile_search()
          }else{
            columns = 4
            this.setState({search_expanded: 1,columns: 6, pagination_show_less: 0}) // is for related columns in video view page
            
            this.helper_desktop_view_close_mobile_search()
          }

          document.getElementById('hamburger_wrapper').style.display = "block";
          //width_to_remove = 81 //equals width of left bar plus the width of right offset ( 50 + 20 desired free on the right)
          document.getElementById('menu_desktop').style.display = "none";

          //detect_scroll_direction will run, so it gets the left bar properly set if not on top of page
          var should_run_detect_scroll_direction = 0
          if(this.state.tablet_filter_showing == 0){
            should_run_detect_scroll_direction = 1
          }
          await this.setState({mobile_filter_showing: 0, tablet_filter_showing: 1, mobile_or_tablet: 1})
          if(should_run_detect_scroll_direction == 1){
            
            this.detect_scroll_direction()
          }
          document.getElementById("rightSideFullContainer").style.marginLeft="28px"
          document.getElementById("rightSideFullContainer").style.width=(intermediateContainer_window_width-64)+"px"
          
          //width= ((intermediateContainer_window_width+ 40) -(columns+1)*10)/columns 
          try{
            document.getElementById("horizontal_order").style.left="83px"
          }catch(e){}
          try{
            if(this.state.filters_showing == 1 ){  
              document.getElementById('tagsLeft').style.top =  '58px';
              document.getElementById('tagsLeft').style.height = "calc(100% - " + 58 + "px)";
              document.getElementById("tagsLeft").style.position="fixed"
            }
          }
          catch(e){}
          //close desktop view popup if open
          //this.helper_mobile_or_tablet_view_close_desktop_search()
          //width=220

          //tablet WIP 
          width= ((intermediateContainer_window_width- 84 ) -(columns+1)*10)/columns
          
      }
      else{
        document.getElementsByClassName("tagsLeft")[0].style.width = this.state.tags_left_menu_width_desktop+"px"
        document.getElementById("rightSideFullContainer").style.marginLeft="0px"

        try{
          document.getElementById("tagsLeft").style.paddingLeft="5px"
        }catch(e){}
        await this.setState({scrolly: 0, mobile_filter_showing: 0, tablet_filter_showing: 0, mobile_or_tablet: 0, pagination_show_less: 0})
        if(this.state.menu_showing == 1){
          
          this.hide_menu();
          this.setState({menu_showing: 0})
        }
        if(this.state.filters_showing == 1){          
          this.hide_filters();
          
        }
        if(this.state.mobile_user_menu_showing == 1){
          this.show_hide_user_menu_mobile()
        }

        // if(this.state.mobile_search_input_popup_showing == 1){
        //   this.setState({mobile_search_input_popup_showing: 0})
        //   this.set_search_popup_showing(0)
        // }
        // if(this.state.search_popup_showing == 1){
        //   var myElement = document.getElementById("search_input");
        //   const rect = myElement.getBoundingClientRect();
        //   const leftPos = rect.left;
        //   const topPos = rect.top;      
          
        //   document.getElementById("search_items").style.left = Math.round(leftPos)+"px"
        //   document.getElementById("search_items").style.top = Math.round(topPos+45)+"px"
        // }



        if(window_width < 1350){
          columns = 4
         // alert(columns)
         
         this.helper_mobile_or_tablet_view_close_desktop_search()
         //this.helper_desktop_view_close_mobile_search()
        }
        else if(window_width < 1400){
          
          this.helper_mobile_or_tablet_view_close_desktop_search()
          //this.helper_desktop_view_close_mobile_search()
          columns = 4
          
        }
        else if(window_width < 1550){
          columns = 4
          //this.helper_mobile_or_tablet_view_close_desktop_search()
          this.helper_desktop_view_close_mobile_search()
        }
        else if(window_width < 1700){

          this.helper_desktop_view_close_mobile_search()
          
          columns = 5
          
        }
        else{
          columns = 6
          
          this.helper_desktop_view_close_mobile_search()

          this.setState({columns: 6}) // is for related columns in video view page
          
        }
        //alert(columns)
        //columns = 4
        // document.getElementById("rightSideFullContainer").style.marginLeft="-10px"
        // document.getElementById("rightSideFullContainer").style.marginRight="-10px"
        //document.getElementById("rightSideFullContainer").style.width="calc(100% - 255px)"
        //window_width


        /// important line - removed 20 from 224 - it was 246 - current 8th march
        document.getElementById("rightSideFullContainer").style.width=(intermediateContainer_window_width-246)+"px"
        
        //document.getElementById("rightSideFullContainer").style.width="calc(100% - 255px)"
        document.getElementById('hamburger_wrapper').style.display = "none";
        document.getElementById('menu_desktop').style.display = "block";
        document.getElementById('menu_desktop').style.marginLeft = "245px";
        

        //document.getElementById('tagsLeft').style.left = (document.getElementById("rightSideFullContainer").offsetLeft-247)+"px";
        
        document.getElementById('tagsLeft').style.left =""
        document.getElementById('tagsLeft').style.top = "58px";

        //document.getElementById("tagsLeft").style.height=(window.innerHeight - 504)+"px" //58px+46 ( menu + footer )
        document.getElementById("tagsLeft").style.height="100px"
        document.getElementById("tagsLeft").style.position="fixed"
        

        //read comments below
        document.getElementById("tagsLeft").style.marginLeft="-10px"
        document.getElementById("tagsLeft").style.paddingLeft="15px"
        //these two lines fix a bug in the slider. The popup is being cut from the div on the left, this is why
        //this is needed



       // document.getElementById("tagsLeft").style.zIndex="999"


        width= ((intermediateContainer_window_width-260 ) -(columns+1)*10)/columns

        try{
          document.getElementById("horizontal_order").style.left="255px"
        }catch(e){}
        
      
      }
    
      
    //   width_to_remove = 0;
    // if(parseInt(is_pornstar) == 1){

    //   var height = 360*width/400 -3;
      
    // }
    // else if(parseInt(is_pornstar) == 2){ // is tag
    //   var height = 5;
    // }
    // else{
    //   var height = 135*width/240 -3;
    // }

    var height = 135*width/240;


    //var height = 203*width/383 ; // WIP FIX here for jumping related pagination
    //var elements = ""
     var elements = document.getElementsByClassName('videowrapper');
     for (var ia = 0; ia < elements.length; ia++) {
         elements[ia].style.width = width + 'px';
         elements[ia].style.height = (height+62)  + 'px'
     }

    elements = document.getElementsByClassName('tagswrapper');
    for (var ib = 0; ib < elements.length; ib++) {
        elements[ib].style.width = width + 'px';
    }
  
    elements = document.getElementsByClassName('img_class');
    for (var ic = 0; ic < elements.length; ic++) {
        elements[ic].style.height = height + 'px';
    }
  
    elements = document.getElementsByClassName('video_image');
    for (var id = 0; id < elements.length; id++) {
        elements[id].style.height = (height) + 'px';
        //elements[id].style.height = (width) + 'px';
        //elements[id].style.width = (width+) + 'px';
    }
  
    // elements = document.getElementsByClassName('videowrapper');
    // for (var ie = 0; ie < elements.length; ie++) {
    //   elements[ie].style.height = (height+62)  + 'px'
    
    
    //   //   if(parseInt(is_pornstar) == 1){
    // //      elements[ie].style.height = (height+65)  + 'px';
    // //   }
    // //   else if(parseInt(is_pornstar) == 3){ // is channel
    // //     elements[ie].style.height = (height+68)  + 'px';
    // //  }
    // //   else{ // 
    // //     elements[ie].style.height = (height+65)  + 'px'
    // //   }
    // }


    // var horizontal_order=0
    // try{
    //   horizontal_order=document.getElementById("horizontal_order").offsetHeight +10
    // }catch(e){
    //   horizontal_order=0
    // }


    if(this.state.mobile_or_tablet == 0){
      try{        
        // document.getElementById("tagsLeft").style.height=
        // (document.getElementById("rightSideFullContainer").offsetHeight+horizontal_order-5  )
        // +"px"

       var distance_from_bottom = document.documentElement.scrollHeight - window.scrollY - window.innerHeight;
  
          if(distance_from_bottom < 46){
            document.getElementById("tagsLeft").style.height=(window.innerHeight - 58 + distance_from_bottom - 46 -  document.getElementById("cookies_dialog").style.height.replace("px", ""))+"px"
          }else{
             document.getElementById("tagsLeft").style.height=(window.innerHeight - 58 -  document.getElementById("cookies_dialog").style.height.replace("px", ""))+"px"
          }
        //alert("panagia"+document.innerHeight)
        //document.getElementById("rightSideFullContainer").offsetHeight+
      }
      catch(e){}
    }
  
  }

  


  reportWindowSize_no_left_menu = async(is_pornstar,is_related=-1,has_horizontal_menu=-1, is_tag_line=-1,bugfix_remove_40px=-1) => {
    //alert('panagia')
    //alert('bike')
    this.setState({is_main_page: 0})
    var columns=2
    var columns_related=4
    var width_to_remove = 7
    //alert(is_pornstar+"-"+is_related)
    this.setState({window_height: window.innerHeight})
    //alert(is_related)
    var window_width = window.innerWidth;
    if(window_width > 1595){
      await this.setState({padding_percentage_larger_screens: 0.075})
      
       
       
    }else if(window_width > 1195){
      await this.setState({padding_percentage_larger_screens: 0.025})
      
    }else{
      await this.setState({padding_percentage_larger_screens: 0.0})
      
    }

    if(window_width > 1195){
      await this.setState({padding_px_larger_screens: window_width*this.state.padding_percentage_larger_screens})      
      if(is_related == 1 && is_pornstar == 0){
        var intermediateContainer_window_width = document.getElementById("intermediateContainer").offsetWidth -(2*this.state.padding_px_larger_screens) + 37;
      }else{
      var intermediateContainer_window_width = document.getElementById("intermediateContainer").offsetWidth -(2*this.state.padding_px_larger_screens) + 72;
      }
      
      
      document.getElementById("menu").style.width = "100%" //(document.getElementById("menu_container").offsetWidth+1)+'px'
      
      document.getElementById("menu_inner").style.width = (document.getElementById("menu_container").offsetWidth-6)+'px' 
      //document.getElementById("menu").style.width =  (document.getElementById("menu_container").offsetWidth+1)+'px' 
      // document.getElementById("menu").style.left = "0px"
      
      // var padding =  (window.innerWidth - (document.getElementById("menu_container").offsetWidth))/2 -4
      
      // document.getElementById("menu").style.paddingLeft = (padding -2)+"px"
      // document.getElementById("menu").style.paddingRight = (padding )+"px"
      document.getElementById("menu_inner").style.paddingLeft = "0px"
        document.getElementById("menu_inner").style.paddingRight = "0px"
      document.getElementById("rightSideNoLeftMenu").style.width=(intermediateContainer_window_width-38)+"px"
      //"50px"
    }else if(window_width > 571){
        await this.setState({padding_px_larger_screens: 0})      
        var intermediateContainer_window_width = document.getElementById("intermediateContainer").offsetWidth -(2*this.state.padding_px_larger_screens) ;      
        
        document.getElementById("menu").style.width = "100%" //document.getElementById("menu_container").offsetWidth+'px' 
        
        
        document.getElementById("menu").style.left = "0px"

        document.getElementById("menu_inner").style.width = (document.getElementById("menu_container").offsetWidth)+'px' 
        
       // var padding =  (window.innerWidth - (document.getElementById("menu_container").offsetWidth))/2
      
        document.getElementById("menu_inner").style.paddingLeft = "10px"
        document.getElementById("menu_inner").style.paddingRight = "21px"
        
        
        if(has_horizontal_menu == -1){
          document.getElementById("rightSideNoLeftMenu").style.width=(intermediateContainer_window_width-0)+"px"
        }else{
          document.getElementById("rightSideNoLeftMenu").style.width=(intermediateContainer_window_width-60)+"px"
        }
      }else{
        await this.setState({padding_px_larger_screens: 0})      
        var intermediateContainer_window_width = document.getElementById("intermediateContainer").offsetWidth -(2*this.state.padding_px_larger_screens) ;      
        
        document.getElementById("menu").style.width = "100%" //document.getElementById("menu_container").offsetWidth+'px' 
        document.getElementById("menu").style.left = "0px"

        document.getElementById("menu_inner").style.width = (document.getElementById("menu_container").offsetWidth)+'px' 
        
        //var padding =  (window.innerWidth - (document.getElementById("menu_container").offsetWidth))/2
      
        document.getElementById("menu_inner").style.paddingLeft = "10px"
        document.getElementById("menu_inner").style.paddingRight = "0px"
        
        
        
          document.getElementById("rightSideNoLeftMenu").style.width=(intermediateContainer_window_width-0)+"px"
        

      }
    

//    document.getElementById("rightSideNoLeftMenu").style.width =  (intermediateContainer_window_width - this.state.scrollbarwidth)+"px"
//    var intermediateContainer_window_width = document.getElementById("intermediateContainer").offsetWidth;
    
    // if(bugfix_remove_40px == 1){
    //  // alert('panagia')
    //   //intermediateContainer_window_width = intermediateContainer_window_width + 40;//for some strange reason
      
    //     document.getElementById("rightSideNoLeftMenu").style.right = '-20px'
    //     document.getElementById("rightSideNoLeftMenu").style.left = '+20px'
    //     document.getElementById("rightSideNoLeftMenu").style.width = 'calc(100% + 0px)'
      
    // }
      if(window_width <= 571){
        
        document.getElementById('hamburger_wrapper').style.display = "block";
        document.getElementById('menu_desktop').style.display = "none";
        width_to_remove = 0;
        
        if(window_width >= 400 || is_tag_line == 1){
          columns = 2;
        }else{
          if(is_pornstar == 1 && is_related == 1)
            columns = 2
          else
            columns = 1;
        }
        await this.setState({search_expanded: 0,mobile_filter_showing: 1, tablet_filter_showing: 0, mobile_or_tablet: 1, pagination_show_less: 1})
        if(this.state.filters_showing == 0){
          this.setState({scrolling_down:1})
        }
        //if((is_related == 1 && (is_pornstar == 1 || is_pornstar == 3))|| is_pornstar == 2){ //is tag
        
        if(is_related == 1 && is_pornstar >= 0){
        //if(is_pornstar == 0){
        //if(parseInt(is_related == 2)){
          //columns = 2
          intermediateContainer_window_width = intermediateContainer_window_width + 40;
          
          
          //var width= ((window_width) -(columns+1)*20)/columns -10  
        }
        
        else{
          //alert('ll')
          if(is_pornstar == 0 && is_related == 1){
            
            intermediateContainer_window_width= intermediateContainer_window_width - 0

          }
          
         // if(is_pornstar == -1)
          //  intermediateContainer_window_width = intermediateContainer_window_width + 21 //only for related
          
        }
        //columns = 2
        
        // if(columns > 1)
        //   var width= ((intermediateContainer_window_width - 40) -(columns+1)*10)/columns 
        // else
        //   var width= ((intermediateContainer_window_width ) -(columns+1)*10)/columns  + 12
        


        this.setState({columns: 2}) // is for related columns in video view page
        
        //columns_related=2;
        if(is_pornstar == -1 && is_related == 1){
          document.getElementById("rightSideNoLeftMenu").style.marginLeft="20px"
          document.getElementById("rightSideNoLeftMenu").style.marginRight="0px" 
          if(columns > 1)
            var width= ((intermediateContainer_window_width - 40) -(columns+1)*10)/columns + 22
          else
            var width= ((intermediateContainer_window_width ) -(columns+1)*10)/columns  - 2
            
          
          
          
        }else{
          document.getElementById("rightSideNoLeftMenu").style.marginLeft="-3px"
          document.getElementById("rightSideNoLeftMenu").style.marginRight="0px" 
          if(columns > 1 || is_related == 1 ){
            //alert(is_related)
            if(is_related == 1){ // show pornstars and channels
              columns = 2 ;
              var width= ((intermediateContainer_window_width - 40) -(columns+1)*10)/columns  +2
              document.getElementById("rightSideNoLeftMenu").style.marginLeft="-5px"
            }else{
              var width= ((intermediateContainer_window_width - 40) -(columns+1)*10)/columns + 22
            }
          }
          else
            var width= ((intermediateContainer_window_width ) -(columns+1)*10)/columns  - 2
        }
        //document.getElementById("rightSideNoLeftMenu").style.width=(intermediateContainer_window_width-20)+"px"
        if(is_pornstar == -1 && is_related == 1){
          document.getElementById("rightSideNoLeftMenu").style.width=(intermediateContainer_window_width-60)+"px"
        }else if(is_pornstar == 0 && is_related == 2){ //tag showing videos
          document.getElementById("rightSideNoLeftMenu").style.width=(intermediateContainer_window_width+20)+"px"

        }else{
          document.getElementById("rightSideNoLeftMenu").style.width=(intermediateContainer_window_width-20)+"px"
        }
        try{
          if(this.state.filters_showing == 1){
            // var scrolled = window.pageYOffset || document.documentElement.scrollTop;          
            // if(scrolled <= 58){
            //   var dist = 58-scrolled
            //   document.getElementById('filtersLeft').style.top = dist + 'px';
            //   document.getElementById('filtersLeft').style.height = "calc(100% - " + (58 - scrolled) + "px)";

            // }else{
            //   document.getElementById('filtersLeft').style.top = "0px";
            //   document.getElementById('filtersLeft').style.height = "100%"; 
            // }
          
  
  
            // document.getElementById("filtersLeft").style.position="fixed"
            
            document.getElementById('filtersLeft').style.top =  '58px';
            document.getElementById('filtersLeft').style.height = "calc(100% - " + 58 + "px)";
            document.getElementById("filtersLeft").style.position="fixed"

            
        }
        document.getElementsByClassName("tagsLeft")[0].style.width = this.state.tags_left_menu_width_mobile_and_tablet+"px"
         }catch(e){}

         //close desktop view popup if open
         this.helper_mobile_or_tablet_view_close_desktop_search()
         width = width  - 0

      }

      
      else if(window_width > 571 && window_width <= 1195){
        
        // if(this.state.menu_showing == 1){
        //   this.hide_menu();
        //   this.setState({menu_showing: 0})
        // }
        
        // if(this.state.filters_showing == 1){
        //   //alert(this.state.filters_showing)
        //   this.hide_filters(1);
        //   this.setState({filters_showing: 0})
        // }
        
        // if(this.state.mobile_user_menu_showing == 1){
        //   //alert('gia')
        //   this.show_hide_user_menu_mobile() 670
        // }
        
        if(window_width <= 670){
          this.setState({ pagination_show_less: 1, search_expanded: 0})
          
          if(is_related == 1 || is_tag_line == 1)
            columns = 2
          else{
            if(has_horizontal_menu == -1)
              columns = 3
            else
              columns =2
          }
            //this.setState({columns: 3}) // is for related columns in video view page
            //columns_related=3;
            this.helper_mobile_or_tablet_view_close_desktop_search()
          }

      else if(window_width <= 735){
        this.setState({ pagination_show_less: 1, search_expanded: 0})
        
        if(is_related == 1 || is_tag_line == 1)
          columns = 3
        else
          columns = 2
          //this.setState({columns: 3}) // is for related columns in video view page
          //columns_related=3;
          this.helper_mobile_or_tablet_view_close_desktop_search()
        }
        
        
        else if(window_width <= 990){
          this.setState({ pagination_show_less: 0, search_expanded: 1})  
          if(is_related == 1 || is_tag_line == 1)
            columns = 3        
          else{
            if(has_horizontal_menu == -1){
              columns = 4
            }else{
              columns = 3
          }
        }
         
          this.helper_desktop_view_close_mobile_search()
        }
        else if(window_width <= 1100){
          this.setState({ pagination_show_less: 0, search_expanded: 1})          
          if(has_horizontal_menu == -1){
            columns = 4
          }else{
            columns = 3
        }
         
          this.helper_desktop_view_close_mobile_search()
        }
        else{
          this.setState({ pagination_show_less: 0, search_expanded: 1})  
          if(is_related == 1 || is_tag_line == 1)
            columns = 4
          else{
            if(has_horizontal_menu == -1){        
              columns = 5
            }else{
              columns = 4
            }
          }
          //this.setState({columns: 6}) // is for related columns in video view page
          //columns_related=4;
          this.helper_desktop_view_close_mobile_search()
        }
        
        document.getElementById('hamburger_wrapper').style.display = "block";
       // width_to_remove = 104 //equals width of left bar plus the width of right offset ( 50 + 20 desired free on the right)
        document.getElementById('menu_desktop').style.display = "none";
        await this.setState({mobile_filter_showing: 0, tablet_filter_showing: 1, mobile_or_tablet: 1,})
        if(has_horizontal_menu == -1 ){
          //below is margin for show_video
          document.getElementById("rightSideNoLeftMenu").style.marginLeft=is_related == 1 && is_pornstar == -1 ? "20px" : "0px"
          document.getElementById("rightSideNoLeftMenu").style.marginRight=is_related == 1 && is_pornstar == -1 ? "20px" : "0px"
          if(is_pornstar == 0 && is_related == 1){
            intermediateContainer_window_width= intermediateContainer_window_width + 40
          }else{
            intermediateContainer_window_width= intermediateContainer_window_width + 40
          }
          var width= ((intermediateContainer_window_width  - 60) -(columns+1)*10)/columns 


          


        }else{
          //alert("horizontal menu tabletzz")
          document.getElementById("rightSideNoLeftMenu").style.marginLeft="73px"
          document.getElementById("rightSideNoLeftMenu").style.marginRight="0px"
          //  document.getElementById("purple_header").style.left="0px"
          if(is_pornstar == 0 && is_related == 1){
            intermediateContainer_window_width= intermediateContainer_window_width + 40
          }
          this.setState({scrolling_down: 0})
          this.detect_scroll_direction()
          var width= ((intermediateContainer_window_width - 84 ) -(columns+1)*10)/columns 
          
          
           if(is_pornstar == 0 && is_related == 2){ //tag showing videos bug needed for some reason
            document.getElementById("rightSideNoLeftMenu").style.width=(intermediateContainer_window_width-64)+"px"
  
           }


          //document.getElementById("tagsLeft").style.height=(window.innerHeight - 58 )+"px"
        }
        try{
          // document.getElementById("filtersLeft").style.height=(window.innerHeight - 56)+"px"
          // document.getElementById("filtersLeft").style.position="fixed"
          // document.getElementById("filtersLeft").style.zIndex="1001"
          // document.getElementById("filtersLeft").style.top="59px"
          if(this.state.filters_showing == 1){
            // var scrolled = window.pageYOffset || document.documentElement.scrollTop;

            // if(scrolled <= 58){
            //   var dist = 58-scrolled
            //   document.getElementById('filtersLeft').style.top = dist + 'px';
            //   document.getElementById('filtersLeft').style.height = "calc(100% - " + (58 - scrolled) + "px)";

            // }else{
            //   document.getElementById('filtersLeft').style.top = "0px";
            //   document.getElementById('filtersLeft').style.height = "100%"; 
            // }
          
  
  
            // document.getElementById("filtersLeft").style.position="fixed"
            document.getElementById('filtersLeft').style.top =  '58px';
            document.getElementById('filtersLeft').style.height = "calc(100% - " + 58 + "px)";
            document.getElementById("filtersLeft").style.position="fixed"
        }
        document.getElementsByClassName("tagsLeft")[0].style.width = this.state.tags_left_menu_width_mobile_and_tablet+"px"
         }catch(e){}

         //close desktop view popup if open
         //this.helper_mobile_or_tablet_view_close_desktop_search()
          

      }
      else{
        //document.getElementsByClassName("tagsLeft")[0].style.width = this.state.tags_left_menu_width_desktop+"px"
        // if(has_horizontal_menu == 1){
        //   document.getElementById("rightSideNoLeftMenu").style.marginLeft="0px"
        //   document.getElementById("rightSideNoLeftMenu").style.marginRight="0px"
        // }
        //this.setState({mobile_filter_showing: 1,scrolling_down:1, tablet_filter_showing: 0, mobile_or_tablet: 1})
        await this.setState({mobile_filter_showing: 0, tablet_filter_showing: 0, mobile_or_tablet: 0, pagination_show_less: 0})
        if(this.state.menu_showing == 1){
          this.hide_menu();
          this.setState({menu_showing: 0})
          this.setState({scrolly: 0})
        }
        // if(this.state.mobile_search_input_popup_showing == 1){
        //   this.setState({mobile_search_input_popup_showing: 0})
        //   this.set_search_popup_showing(0)
        // }
        
        if(this.state.filters_showing == 1){
          //alert(this.state.filters_showing)
          this.hide_filters(1);
          this.setState({filters_showing: 0})
          this.setState({scrolly: 0})
        }

        if(this.state.mobile_user_menu_showing == 1){
          this.show_hide_user_menu_mobile()
        }

        // if(this.state.mobile_user_menu_showing == 1){
        //   this.show_hide_user_menu_mobile()
        // }

        // if(this.state.mobile_search_input_popup_showing == 1){
        //   this.setState({mobile_search_input_popup_showing: 0})
        //   this.set_search_popup_showing(0)
        // }
        // if(this.state.search_popup_showing == 1){
        //   var myElement = document.getElementById("search_input");
        //   const rect = myElement.getBoundingClientRect();
        //   const leftPos = rect.left;
        //   const topPos = rect.top;      
          
        //   document.getElementById("search_items").style.left = Math.round(leftPos)+"px"
        //   document.getElementById("search_items").style.top = Math.round(topPos+45)+"px"
        // }     

        if(window_width < 1350){
          if(is_related == 1 || is_tag_line == 1)
            columns = 6;
          else
            columns = 5
                    
            this.helper_mobile_or_tablet_view_close_desktop_search()
          //this.setState({search_expanded: 1})
          // if(this.state.search_popup_showing == 1 && this.state.mobile_search_input_popup_showing == 0){
          //   //this.setState({mobile_search_input_popup_showing: 0})
          //   this.set_search_popup_showing(0)
          // }
          //this.helper_desktop_view_close_mobile_search()
          
        }
        else if(window_width < 1400){
          if(is_related == 1 || is_tag_line == 1)
            columns = 6
          else
            columns = 5
          
         // this.setState({search_expanded: 1})
         // this.helper_desktop_view_close_mobile_search()
         this.helper_mobile_or_tablet_view_close_desktop_search()
        }
        else if(window_width < 1550){
          if(is_related == 1 || is_tag_line == 1)
            columns = 6
          else
            columns = 5
          this.setState({search_expanded: 1})
          this.helper_desktop_view_close_mobile_search()
          
        }
        else if(window_width < 1700){
          if(is_related == 1 || is_tag_line == 1)
            columns = 6
          else
            columns = 5          
          
          this.setState({search_expanded: 1})
          this.helper_desktop_view_close_mobile_search()
        }
        else{
          columns = 6      
          
          if(this.state.mobile_search_input_popup_showing == 1){
            this.setState({mobile_search_input_popup_showing: 0})
            this.set_search_popup_showing(0)
          }          

          this.setState({search_expanded: 1})
          this.helper_desktop_view_close_mobile_search()
        }
        if(has_horizontal_menu == -1){
          
          document.getElementById("rightSideNoLeftMenu").style.marginLeft="-17px"
          document.getElementById("rightSideNoLeftMenu").style.marginRight="-20px"
      }else{
        document.getElementById("rightSideNoLeftMenu").style.marginLeft="-17px"
        document.getElementById("rightSideNoLeftMenu").style.marginRight="-20px"
       
      }
        document.getElementById('hamburger_wrapper').style.display = "none";
        document.getElementById('menu_desktop').style.display = "block";
    

        if(parseInt(is_pornstar) == 1){

          columns = columns + 0
          
        }
        //pornstar-search-input


        //var width= ((document.getElementById("pornstar-search-input").offsetWidth - 40) -(columns)*20)/columns 
        if(is_pornstar == 0 && is_related == 1){
          intermediateContainer_window_width= intermediateContainer_window_width + 37
        }else{
          intermediateContainer_window_width= intermediateContainer_window_width + 2
        }
        var width= ((intermediateContainer_window_width - 60) -(columns+1)*10)/columns 

      
      }
    
      width_to_remove = 0;
    if(parseInt(is_pornstar) == 1 || parseInt(is_pornstar) == 3){

      //var height = 360*width/400 -3;
      var height=width      
      
      //var height = 135*width/240 -3;
      
    }
    else if(parseInt(is_pornstar) == 2){ // is tag
      var height = 5;
    }
    else{
      var height = 135*width/240 ;
      //var height = 203*width/383 ;
      //height=500
    }
  
    var elements = document.getElementsByClassName('videowrapper');
    for (var i = 0; i < elements.length; i++) {
        elements[i].style.width = width + 'px';
        if(parseInt(is_pornstar) == 1){
          elements[i].style.height = (height+28)  + 'px';
        }
        else if(parseInt(is_pornstar) == 3){ // is channel
          elements[i].style.height = (height+28)  + 'px';
        }
        else{ // 
          elements[i].style.height = (height+62)  + 'px'
        }
      }

    elements = document.getElementsByClassName('tagswrapper');
    for (var i = 0; i < elements.length; i++) {
        elements[i].style.width = width + 'px';
    }
  
    elements = document.getElementsByClassName('img_class');
    for (var i = 0; i < elements.length; i++) {
        elements[i].style.height = (height ) + 'px';       
    }
  
    elements = document.getElementsByClassName('video_image');
    for (var i = 0; i < elements.length; i++) {
        elements[i].style.height = height  + 'px';
    }
  
    // elements = document.getElementsByClassName('videowrapper');
    // for (var i = 0; i < elements.length; i++) {
    //   if(parseInt(is_pornstar) == 1){
    //      elements[i].style.height = (height+28)  + 'px';
    //   }
    //   else if(parseInt(is_pornstar) == 3){ // is channel
    //     elements[i].style.height = (height+28)  + 'px';
    //  }
    //   else{ // 
    //     elements[i].style.height = (height+62)  + 'px'
    //   }
    // }
  
    this.props.enable_footer()

    //if(parseInt(localStorage.getItem("is_over_18")) != 1){         
      //this.popup_resize()      
      //indow.dispatchEvent(new Event('resize'));
    //}

  }





  set_theme_light = () => {
    this.setState({theme: lightTheme})
    var bodyFormData = new FormData();
    bodyFormData.append("token", localStorage.getItem("token"));
    bodyFormData.append("theme", 1);

    localStorage.setItem("theme", "lightTheme");
    if(this.props.User && this.props.User != "Unauthorized"){
      axios({
        method: "post",
        data: bodyFormData,
        url: configData.PRIVATE_API_ENDPOINT + "/change_theme",
        headers: { "Content-Type": "multipart/form-data" },
        timeout: configData.TIMEOUT_LIMIT
      })
        .then(function (response) {
        })
        .catch(function (error) {
          console.log("error saving user theme as prefs ")
        });
    }
  }
  
  set_theme_dark = () => {
    this.setState({theme: darkTheme})
    var bodyFormData = new FormData();
    bodyFormData.append("token", localStorage.getItem("token"));
    bodyFormData.append("theme", 2);
    //alert('pan agia')
    localStorage.setItem("theme", "darkTheme");
    if(this.props.User && this.props.User != "Unauthorized"){
      axios({
        method: "post",
        data: bodyFormData,
        url: configData.PRIVATE_API_ENDPOINT + "/change_theme",
        headers: { "Content-Type": "multipart/form-data" },
        timeout: configData.TIMEOUT_LIMIT
      })
        .then(function (response) {
        })
        .catch(function (error) {
          console.log("error saving user theme as prefs ")
        });
    }
  }

  go_to_link = (event, uri, hide_menu=-1) => {
    //scroll_top(0)
    //indow.scrollTo(0,0)
    //alert("PANAGIA")
    //this.hide_menu()
    if(hide_menu == 1){
      this.hide_menu()
    }
    try{
      event.preventDefault();
    }catch(e){}
    this.props.history.push(uri);
  };

  detect_scroll_direction = async () =>{
    
    var self=this;
    try{

      
      if(this.state.mobile_or_tablet == 0 && this.state.is_main_page == 1 ){
        
        
        
        try{        
          // document.getElementById("tagsLeft").style.height=
          // (document.getElementById("rightSideFullContainer").offsetHeight+horizontal_order-5  )
          // +"px"

          var distance_from_bottom = document.documentElement.scrollHeight - window.scrollY - window.innerHeight;
  
          if(distance_from_bottom < 46){
            document.getElementById("tagsLeft").style.height=(window.innerHeight - 58 + distance_from_bottom - 46 - document.getElementById("cookies_dialog").style.height.replace("px", "") )+"px"
          }else{
             document.getElementById("tagsLeft").style.height=(window.innerHeight - 58 - document.getElementById("cookies_dialog").style.height.replace("px", ""))+"px"
          }
        }
        catch(e){console.log("detect_scroll_direction exception")}

      }

      if(this.state.mobile_filter_showing == 1 ){
        if((await this.state.previous_scroll_y) < window.scrollY){
          this.setState({scrolling_down: 0}) //by mistake 0 is 1 and 1 is 0 ;-/
          setTimeout(function(){ self.setState({previous_scroll_y: window.scrollY}) }, 50);
        }
        else{
          this.setState({scrolling_down: 1}) //by mistake 0 is 1 and 1 is 0 ;-/
          setTimeout(function(){ self.setState({previous_scroll_y: window.scrollY}) }, 50);
        }
      }
      else{
        return
      }
      }catch(e){} // WIP  sxesi me to scroll toy tags. kanei sub otan mpainei kai den exei videos
                //


     
  }

  show_over_18_menu(){
    alert('are you over 18')
  }

  mobile_menu_scroll = async () => {
  
    var tags_distance_from_top=document.getElementById('videos_left_menu_mobile_and_tablet').getBoundingClientRect().top;    
    if(tags_distance_from_top <= -48){ //45 kind of smooth
      document.getElementById('tags_left_menu_mobile_and_tablet').style.position='fixed'
      
      document.getElementById('tags_left_menu_mobile_and_tablet').style.top='-20px'    
      this.setState({tags_expanded_div_became_fixed: 1})  
    }else{
      document.getElementById('tags_left_menu_mobile_and_tablet').style.position=''
      document.getElementById('tags_left_menu_mobile_and_tablet').style.top='0px'
     // document.getElementById('tags_left_menu_mobile_and_tablet').style.display='inline'    
  //    document.getElementById('tags_left_menu_mobile_and_tablet').style.marginTop='2px'
      this.setState({tags_expanded_div_became_fixed: 0})
    }
    
    

  }
  show_menu = async() => {

    document.getElementById("menuLeft").addEventListener('scroll', this.mobile_menu_scroll);
    this.setState({any_popup_showing_stop_video_hover: 1})
    console.log('')
    
    if(this.state.filters_showing == 1 && this.state.menu_showing == 0){    
      this.hide_filters(1);
      
    }else{
      await this.setState({scrolly: 0,scrolly_after_menu_open: 0})
    }
    if(this.state.scrolly > 0){
      this.setState({scrolly_after_menu_open: this.state.scrolly})
    }
    this.setState({scrolling_down: 0, scrolly: 0})
    var self=this;
    setTimeout(function() { 
    try{      
      

      var scrollbarwidth=this.getScrollBarWidth()

      document.getElementById("menu").style.marginRight=(scrollbarwidth/2)+'px'
      
      document.body.style.overflow = 'hidden';

    //   try{
    //     document.getElementById("rightSideNoLeftMenu").style.paddingRight=scrollbarwidth+'px'
    //  }catch(e){
    //  }




    //   try{
    //     document.getElementById("rightSideFullContainer").style.paddingRight=scrollbarwidth+'px'
    //  }catch(e){
    //    document.getElementById("rightSideNoLeftMenu").style.paddingRight=scrollbarwidth+'px'
    //  }
     
      

    //WIP maybe wrong 


      // if(this.state.mobile_or_tablet == 1){
      //     document.getElementById("menu").style.paddingRight=scrollbarwidth+'px'
      // }else{
      //   document.getElementById("menu").style.marginRight=scrollbarwidth+'px'
      // }
      
      // this.setState({scrollbarwidth: scrollbarwidth})


      document.getElementById('menuLeft').style.left = "-250px";
      document.getElementById('menuLeft').style.display = "block";
      document.body.style.overflow = 'hidden';
      //document.getElementById('menuLeft').style.left = "-250px";
      setTimeout(function() { 
        document.getElementById('menuLeft').style.left = "0px";
        //self.setState({scrolling_down: 0})
        },5)
    }catch(e){console.log("")}
    self.setState({ filters_showing:1,menu_showing:1 })
    //window.dispatchEvent(new Event('resize'));
  }.bind(this),10)

  if(this.state.mobile_user_menu_showing == 1){
    this.show_hide_user_menu_mobile();
  }

  setTimeout(function() {     
    self.setState({scrolling_down: 0})
    },355)

   // document.getElementById("menu").style.borderBottom="1px solid #2A2A30"
    
  }

  set_over18_value = (value) => {
    //window.removeEventListener('resize', this.popup_resize)
    if (value == 1){

      
      this.setState({show_blur: 0,  show_overlay: 0, over_18_popup_showing: 0})
      document.getElementById('popup').style.display = "none";
      localStorage.setItem("is_over_18", 1)
      document.body.style.overflow = 'visible';
      //el.dispatchEvent(new CustomEvent('scroll'))
      //document.getElementById('body').dispatchEvent(new Event('scroll'));
      if ( (window.innerWidth - document.documentElement.clientWidth > 0 )        )
        window.dispatchEvent(new Event('resize'));
    }
    else{
      
      window.location.href="https://google.com"
    }

    //alert(value)
  }

  delete_popup_resize = () => {
    //console.log('delete popup resize')
    var width_to_remove=document.getElementById('popup_comment_delete_dialog').offsetWidth/2

    var height_to_remove=document.getElementById('popup_comment_delete_dialog').offsetHeight/2 + 0

   document.getElementById('popup_comment_delete_dialog').style.left = 'calc(50% - ' + (width_to_remove) + 'px)';
 
   // document.getElementById('popup_comment_delete_dialog').style.left='6px'
    document.getElementById('popup_comment_delete_dialog').style.top = 'calc(50% - ' + (height_to_remove)  + 'px)';
  }


  show_over_18_popup_f = async() => {

    //window.addEventListener('resize', this.popup_resize)

    await this.setState({show_blur: 1, show_overlay: 1,over_18_popup_showing:1})
    //the over_18_popup_showing has to be linked to the tags wrapper via props. 
    //it then sets the display to hidden

    this.setState({scrolling_down: 0})
   // document.body.style.overflow = 'hidden';
    var scrollbarwidth=this.getScrollBarWidth()
    
    document.getElementById("menu").style.marginRight=(scrollbarwidth/2)+'px'
    document.body.style.overflow = 'hidden'; 
    //this.setState({filters_showing: 1})
    //alert('d')
    //document.getElementById('popup').style.visibility = "hidden";
    //document.getElementById('popup').style.visibility = "";
    document.getElementById('popup').style.display = "inline-block";
    
    
    var width_to_remove=document.getElementById('popup').offsetWidth/2

    var height_to_remove=document.getElementById('popup').offsetHeight/2 + 5

    var popup_height = document.getElementById('popup').offsetHeight;
    var window_height = window.innerHeight;
    var ptop_for_popup = (window_height/2) - (popup_height)/2
    //console.log("pheight: "+ptop_for_popup)
    //document.getElementById("popup").style.top=ptop_for_popup+"px"
    // if(this.state.mobile_filter_showing  != -1){
    //   this.setState({top_over_18_popup_px: ptop_for_popup})
    // }
    //this.setState({top_over_18_popup_px: 100})

   //document.getElementById('popup').style.left = 'calc(50% - ' + (width_to_remove) + 'px)';
 
   // document.getElementById('popup').style.left='6px'
    //document.getElementById('popup').style.top = 'calc(50% - ' + (height_to_remove)  + 'px)';

    //alert(this.state.filters_showing)
    
  }

  hide_menu = (hide_action) => {
    //this.enableScrolling();
    this.setState({scrollbarwidth:0,any_popup_showing_stop_video_hover: 0}); 
    document.getElementById("menuLeft").removeEventListener('scroll', this.mobile_menu_scroll);
    //try{
      //this.setState({ menu_showing: 0,filters_showing:0 })

      this.setState({ menu_showing: 0,filters_showing:0 })
      
     // if(hide_action == 1){
       try{
          document.getElementById('menuLeft').style.left = "-250px";
        }
        catch(e){}
       //  }
       //document.body.style.width = '';
      
      
      document.getElementById("menu").style.marginRight='0px'
       document.body.style.overflow = 'visible';
       window.dispatchEvent(new Event('resize'));
        // settimeout works with the animation. Without timeout, it goes to none without animation
        setTimeout(function() { 
          //without try, it breaks when menuleftmenu is not there / e.g. pornstars page
          try{
          
           //document.getElementById('menuLeft').style.display = "none"; //WIP - recently deleted
        }catch(e){}
          },0)
        
        // this.setState({ filters_showing: 0 })
        if(this.state.scrolly_after_menu_open){
          //alert("has to scroll down")
          setTimeout(function() { 
            window.scrollTo(0,self.state.scrolly_after_menu_open)
            //self.setState({scrolly_after_menu_open: 0,})
            },50)               
        }
        setTimeout(function() { 
          //window.scrollTo(0,self.state.scrolly_after_menu_open)
          self.setState({scrolling_down: 1,})
          },150) 
         var self=this;
        // if(this.state.scrolly > 0){
        // setTimeout(function() { 
        //     window.scrollTo(0,self.state.scrolly)
            
        //     },25)
        // }
     // catch(e){}
   
  }

  getScrollBarWidth () {

    var body = document.body,
    html = document.documentElement;

    var height = Math.max( body.scrollHeight, body.offsetHeight, 
                       html.clientHeight, html.scrollHeight, html.offsetHeight );


    if(height == window.innerHeight){
      return 0 // if the height of the window is same as height of document, there is no scrollbar. minheight
    }

    var inner = document.createElement('p');
    inner.style.width = "100%";
    inner.style.height = "200px";
  
    var outer = document.createElement('div');
    outer.style.position = "absolute";
    outer.style.top = "0px";
    outer.style.left = "0px";
    outer.style.visibility = "hidden";
    outer.style.width = "200px";
    outer.style.height = "150px";
    outer.style.overflow = "hidden";
    outer.appendChild (inner);
  
    document.body.appendChild (outer);
    var w1 = inner.offsetWidth;
    outer.style.overflow = 'scroll';
    var w2 = inner.offsetWidth;
    if (w1 == w2) w2 = outer.clientWidth;
  
    document.body.removeChild (outer);
    
    return (w1 - w2);
  };

  show_filters = async() => {
    //var scrollbarwidth=this.getScrollBarWidth()
    //document.body.style.width = 'calc(100vw - '+scrollbarwidth+'px)';
    document.getElementsByClassName("tagsLeft")[0].style.overflowY = "hidden"
    await this.setState({ overlay_full_except_menu:1, filters_showing:1,scrolly: window.scrollY,scrollbarwidth: scrollbarwidth, any_popup_showing_stop_video_hover: 1})
   // document.body.style.overflow = 'hidden';
    try{
      document.getElementById("filtersLeft").style.zIndex = "990"
    }catch(e){
      document.getElementById("tagsLeft").style.zIndex = "990"
    }
      // document.getElementById("cookies_dialog").style.zIndex = "990"
    
    // try{
    //    document.getElementById("rightSideFullContainer").style.paddingRight=scrollbarwidth+'px'
    // }catch(e){
    //   document.getElementById("rightSideNoLeftMenu").style.paddingRight=scrollbarwidth+'px'
    // }

    
    //WIP just removed maybe wrong
    
   // document.getElementById("menu").style.paddingRight=(scrollbarwidth/2)+'px'


        if(scrollbarwidth > 0){
          window.dispatchEvent(new Event('resize')); // filthy bug, it moves if scrollbar
          //and then resize is breaking if I apply margin
        }
     // document.getElementById("menu").style.marginRight=(scrollbarwidth/2)+'px'
      
      
      var scrollbarwidth=this.getScrollBarWidth()
    
      document.body.style.overflow = 'hidden'; 

      document.getElementById("menu").style.marginRight=(scrollbarwidth/2)+'px'


    //document.body.style.paddingRight = scrollbarwidth+'px';


    
    
    
    //window.scrollTo(0, 0);
    
    
    
    //this.disableScrolling();
    // document.getElementById('tagsLeft').style.left = "all 0.2s ease-in";
    // document.getElementById('tagsLeft').style.left = "all 0.2s ease-in";
    var self=this;
    try{
      document.getElementById('tagsLeft').style.left = "-250px";
      document.getElementById('tagsLeft').style.display = "block";
      //document.getElementById('tagsLeft').style.width = "265px";
      //document.getElementById("tagsLeft").style.height=(window.innerHeight - 56)+"px"
      //document.getElementsByClassName("tagsLeft")[0].style.overflowY = "auto"
      //document.getElementById('tagsLeft').style.left = "-250px";
      setTimeout(function() { 
        document.getElementById('tagsLeft').style.left = "0px";
       
        //var scrolled = window.pageYOffset || document.documentElement.scrollTop;

        // if(scrolled <= 58){
        //   var dist = 58-scrolled
        //   document.getElementById('tagsLeft').style.top = dist + 'px';
        //   document.getElementById('tagsLeft').style.height = "calc(100% - " + (58 - scrolled) + "px)";

        // }else{
        //   document.getElementById('tagsLeft').style.top = "0px";
        //   document.getElementById('tagsLeft').style.height = "100%"; 
        // }
        document.getElementById('tagsLeft').style.top =  '58px';
        document.getElementById('tagsLeft').style.height = "calc(100% - " + 58 + "px)";
        document.getElementById("tagsLeft").style.position="fixed"
        self.setState({scrolling_down: 0})
        setTimeout(function() { 
            document.getElementsByClassName("tagsLeft")[0].style.overflowY = "auto"
        },250)
        },0)
    }catch(e){
      document.getElementById('filtersLeft').style.left = "-250px";
      //document.getElementById('filtersLeft').style.width = "265px";
      document.getElementById('filtersLeft').style.display = "block";
      //document.getElementById("filtersLeft").style.overflowY = "auto"
      //document.getElementById('filtersLeft').style.left = "-250px";
      setTimeout(function() { 
        document.getElementById('filtersLeft').style.left = "0px";

        // var scrolled = window.pageYOffset || document.documentElement.scrollTop;

        // if(scrolled <= 58){
        //   var dist = 58-scrolled
        //   document.getElementById('filtersLeft').style.top = dist + 'px';
        //   document.getElementById('filtersLeft').style.height = "calc(100% - " + (58 - scrolled) + "px)";

        // }else{
        //   document.getElementById('filtersLeft').style.top = "0px";
        //   document.getElementById('filtersLeft').style.height = "100%"; 
        // }
        // document.getElementById("filtersLeft").style.position="fixed"
        document.getElementById('filtersLeft').style.top =  '58px';
        document.getElementById('filtersLeft').style.height = "calc(100% - " + 58 + "px)";
        document.getElementById("filtersLeft").style.position="fixed"
        
        self.setState({scrolling_down: 0})
        setTimeout(function() { 
          document.getElementsByClassName("tagsLeft")[0].style.overflowY = "auto"
        },250)
        },0)
    
    }
    if(this.state.mobile_user_menu_showing == 1){
      this.show_hide_user_menu_mobile();
    }
    //this.reportWindowSize()    
    //window.dispatchEvent(new Event('resize'));
  }

  hide_filters = (hide_action, scroll_to_y) => {
    //this.enableScrolling();
    //alert('d')
      //document.body.style.width = '';
      document.body.style.overflow = 'visible';
      this.setState({ any_popup_showing_stop_video_hover: 0 })
      //document.getElementById("cookies_dialog").style.zIndex = "9999"
      
      try{
        document.getElementById("filtersLeft").style.zIndex = "9"
      }catch(e){
        document.getElementById("tagsLeft").style.zIndex = "9"
      }
     
     document.getElementById("menu").style.marginRight='0px'
    
    // document.getElementById("filtersLeft").style.zIndex = "9"
      try{
       
         // document.getElementsByClassName("tagsLeft")[0].style.overflowY = "hidden"
        
       if(hide_action == 1){
            document.getElementById('tagsLeft').style.left = "-250px";
          }
          
          // settimeout works with the animation. Without timeout, it goes to none without animation
          setTimeout(function() { 
            //without try, it breaks when tagsleftmenu is not there / e.g. pornstars page
            try{
              document.getElementById('tagsLeft').style.display = "none";
              //document.getElementById('tagsLeft').style.width = "245px";
            }catch(e){}
              },200)
                        

            this.setState({ overlay_full_except_menu : 0, filters_showing: 0, scrollbarwidth: 0})
            var self=this;
           
            if(scroll_to_y == 1){
              setTimeout(function() {
                  if(self.state.scrolly > 0 ) 
                    window.scrollTo(0,self.state.scrolly)
                    //self.setState({scrolly: 0, scrolling_down: 1})
                  },25)
              
              }
          }
        catch(e){
          //document.getElementById("filtersLeft").style.overflowY = "hidden"
          try{
            if(hide_action == 1){
              document.getElementById('filtersLeft').style.left = "-250px";
            }
          }catch(e){}  
          // settimeout works with the animation. Without timeout, it goes to none without animation
          setTimeout(function() { 
            //without try, it breaks when filtersLeftmenu is not there / e.g. pornstars page
            try{
              document.getElementById('filtersLeft').style.display = "none";
              //document.getElementById('filtersLeft').style.width = "245px";
            }catch(e){}
              },200)
          
            this.setState({ filters_showing: 0, scrollbarwidth: 0 })
            var self=this;
            if(scroll_to_y == 1){
              setTimeout(function() {
                  if(self.state.scrolly > 0 ) 
                    window.scrollTo(0,self.state.scrolly)
                    //self.setState({scrolly: 0, scrolling_down: 1})
                  },25) 
              }
        }

        //this.reportWindowSize()
        window.dispatchEvent(new Event('resize'));
        setTimeout(function() { 
          //without try, it breaks when tagsleftmenu is not there / e.g. pornstars page
          self.setState({scrolly: 0, scrolling_down: 1})
            },300)

  }


  wrapperclick = (event) => {
    if(this.state.comment_menu_showing == 1){
      try{
        document.getElementById("comment_menu"+this.state.current_comment_menu_id_showing).style.display = "none"
      }catch(e){}
      this.setState({comment_menu_showing: 0,current_comment_menu_id_showing: 0,})
    }

    if(this.state.mobile_search_input_popup_showing == 1){
     // this.setState({mobile_search_input_popup_showing: 0,show_overlay: 0})
      //document.body.style.overflow = 'visible';
      //alert('lls')
      this.set_mobile_search_input_popup_showing()
    }
    if(this.state.search_popup_showing == 1){
      document.getElementById("search_items").style.display = "none"
      this.setState({search_popup_showing: 0})
    //  alert('kk')
      //if(this.state.search_item_selected_active_element == -1){
      if(this.state.search_expanded == 1){
        document.getElementById("search_input").value = this.state.search_string;  

        document.getElementById("search_input_wrapper_desktop").style.borderRadius = "4px";
        document.getElementById("search_input").style.borderRadius = "4px 0 0 4px";
        document.getElementById("search_input_search_button").style.borderRadius = "0 4px 4px 0";      
      }
      else{
        document.getElementById("mobile_search_input").value = this.state.search_string;
        //alert('c')
      }
      this.setState({search_string: ""})
      document.getElementById("menu").style.marginRight='0px'
      //document.getElementById("menu").style.paddingRight='0px'


      //}
    }
    if(this.state.filters_showing == 1 && this.state.menu_showing == 0 && this.state.mobile_user_menu_showing == 0){
      this.hide_filters(1,1);//hide_action
      //alert("hide filters")
      //this.hide_menu();
    }
    if(this.state.menu_showing == 1 && this.state.filters_showing == 1){
      this.hide_menu();//hide_action
    }
    if(this.state.mobile_user_menu_showing == 1){
      this.show_hide_user_menu_mobile()    
    }
    if(this.state.login_popup_showing == 1){
      this.hide_login_popup()
    }
    if(this.state.delete_comment_popup_showing == 1){
      this.setState({delete_comment_popup_showing: 0, show_overlay: 0});
      document.body.style.overflow = 'visible';
      document.getElementById("menu").style.marginRight="";
      window.dispatchEvent(new Event('resize'));
    }

    if(this.state.pornstar_countries_popup_showing == 1){
      //this.setState({pornstar_countries_popup_showing: 0, show_overlay: 0})
      this.hide_pornstar_countries()
    }

  }

  

  UNSAFE_componentWillReceiveProps = (nextProps) =>{
    this.setState({filters_showing: 0,
      //mobile_filter_showing: -1,
      previous_scroll_y: 0,
      scrolling_down: 1})
    if(localStorage.getItem("was_light_theme") == 1 &&
    !document.location.href.split("/")[3].includes("register") &&
    !document.location.href.split("/")[3].includes("login") &&
    !document.location.href.split("/")[3].includes("reset_password") ){
      localStorage.removeItem("was_light_theme")
      this.set_theme_light()
    }
    if(document.location.href.split("/")[3].includes("video")){
      this.setState({menu_selected:1})
    }
    else if(document.location.href.split("/")[3].includes("tag")){
      this.setState({menu_selected:2})
    }
    else if(document.location.href.split("/")[3].includes("pornstar")){
      this.setState({menu_selected:3})
    }
    else if(document.location.href.split("/")[3].includes("channel")){
      this.setState({menu_selected:4})
    }
    else if(document.location.href.split("/")[3].includes("login")){
      this.setState({menu_selected:5})
    }
    else if(document.location.href.split("/")[3].includes("register")){
      this.setState({menu_selected:6})
    }
    else{
      this.setState({menu_selected: 7})
    }
    //alert("change uri from navbar alert");
    //this.props.UpdateUser();
    //this.props.UpdateUser();
    //console.log(nextProps);
    // if (nextProps.location.pathname != this.props.match.location.pathname) {
    //   // navigated!
    //   alert("new");
    // }
  }

  delete_comment_show_dialog = async(comment_id) => {

    await this.setState({comment_id_to_delete: comment_id})
    await this.setState({show_overlay: 1, delete_comment_popup_showing: 1})
    
    //document.body.style.overflow = 'hidden';
    var scrollbarwidth=this.getScrollBarWidth()

    document.getElementById("menu").style.marginRight=(scrollbarwidth/2)+'px'
    document.body.style.overflow = 'hidden';     

    this.delete_popup_resize()

    window.addEventListener('resize', this.delete_popup_resize)
    
    //document.getElementById("popup_comment_delete_dialog").style.display = "block"    
  }

  delete_comment = (comment_id) => {
    var bodyFormData = new FormData();
    bodyFormData.append("token", localStorage.getItem("token"));
    bodyFormData.append("comment_id", comment_id);
    var self = this;
    axios({
      method: "post",
      url: configData.PRIVATE_API_ENDPOINT + "/delete_comment",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
      timeout: configData.TIMEOUT_LIMIT
    })
      .then(async function (response) {
        //handle success
        self.setState({filters_showing: 0})
        if(self.state.comment_got_deleted == 0)
          await self.setState({comment_got_deleted: 1})
        else
          await self.setState({comment_got_deleted: 0})
        
       // alert(self.state.comment_got_deleted)
        
      })
      .catch(function (error) {
        //handle error
        self.setState({filters_showing: 0})
        if (error.response && error.response.status == 401) {
          toast_error("You have been logged out. Please try to log in again")
          } else {
          toast_error("An unknown error occured. Please try again later")
        }
      });
  };

  escFunction = (event) =>{
    if (event.key === "Escape") {
      this.wrapperclick()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    
    // if (prevState.mobile_filter_showing != this.state.mobile_filter_showing) {
    //   // Execute your code here after state variable initialization
    //   //console.log("mobile_filter")
    //   if(parseInt(localStorage.getItem("is_over_18")) != 1){
    //      //this.show_over_18_popup_f()
    //      this.popup_resize()      
    //    }
    // }
    //console.log('update')
  }

  async componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    this.setState({ptags: tags.filter((ptag) => ptag.is_main_tag == 1)})
    this.setState({ptags_initial: tags})

    //var has_blur = localStorage.getItem("blur")
    if(parseInt(localStorage.getItem("blur")) == 1){
      this.setState({show_blur: 1})
    }
    if(parseInt(localStorage.getItem("is_over_18")) != 1){
      this.show_over_18_popup_f()      
    }
    if(localStorage.getItem("cookies_dialog_ok") == 1){
      this.setState({cookies_dialog_ok: 1})
  }
    this.setState({previous_scroll_y: 0})    
   if(localStorage.getItem("theme") == null){
      try{
        var user = jwtDecode(localStorage.getItem("token"))
        theme = user.theme
        if(theme == 1){
          this.setState({theme: lightTheme})
          return
        }
        if(theme == 2){
          this.setState({theme: darkTheme})
          return
        }
      }catch(e){}
    }




    


    try{
        var theme = localStorage.getItem("theme")
        if(localStorage.getItem("theme") == null){
          if ( window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)') 
          &&  window.matchMedia('(prefers-color-scheme: dark)').matches ) {
            this.setState({theme: darkTheme})
          }else{
             this.setState({theme: lightTheme})
          }
        }
        else if(theme == 'darkTheme'){
          this.setState({theme: darkTheme})
        }else{
          this.setState({theme: lightTheme})
        }
      }catch(e){

      }
  }
  

  show_hide_user_menu_mobile = () => {
    if(this.state.mobile_user_menu_showing == 0){
      this.setState({any_popup_showing_stop_video_hover: 1})
      //this.set_overlay(1)
      
      var scrollbarwidth=this.getScrollBarWidth()
      
      //document.body.style.width = 'calc(100vw - '+(scrollbarwidth+9)+'px)';
      //document.getElementById("menu_item_right").style.marginRight = "55px" 
      
      
      //document.getElementById("menu_item_right").style.marginRight=-scrollbarwidth+'px'
      
      document.getElementById("menu").style.marginRight=(scrollbarwidth/2)+'px'
      document.body.style.overflow = 'hidden'; 
      if(this.state.filters_showing == 1){
        this.hide_filters(1,1);//hide_action
        // this.set_overlay(1)
        
       
        //this.hide_menu();
      }
      if(this.state.menu_showing == 1 && this.state.filters_showing == 1){
        this.hide_menu();//hide_action
      }
      

      document.getElementById("mobile_user_menu").style.display="block"


      this.setState({mobile_user_menu_showing: 1, filters_showing: 1, scrolling_down: 0, scrollbarwidth:scrollbarwidth})
      
      
      
      
    }
    else{
      this.setState({any_popup_showing_stop_video_hover: 0})
      document.getElementById("menu_item_right").style.marginRight='0px'
      this.setState({mobile_user_menu_showing: 0, filters_showing: 0, scrolling_down: 1, scrollbarwidth:0})
      document.body.style.width = '';
      document.getElementById("circle").style.marginRight = "25px" 
      document.body.style.overflow = 'visible';
      document.getElementById("menu").style.marginRight='0px'
      document.getElementById("mobile_user_menu").style.display="none"
    //  this.set_overlay(0)
    }
  }
   hide_pornstar_countries = async () => {
    document.body.style.width = '';
    document.body.style.overflow = 'visible';
    
    document.getElementById("pornstars_per_country_wrapper").style.display='none'
    
    //document.getElementById("rightSideNoLeftMenu").style.paddingRight='0px'

    //document.body.style.paddingRight = "0px"; 

  //   try{
  //     document.getElementById("rightSideFullContainer").style.paddingRight='0px'
  //  }catch(e){
     document.getElementById("rightSideNoLeftMenu").style.paddingRight='0px'
   //}
   
   document.getElementById("menu").style.marginRight='0px'
   document.getElementById("menu").style.paddingRight='0px'
    
    
    await this.setState({pornstar_countries_popup_showing: 0, scrollbarwidth: 0})
    this.set_overlay(0)
    window.removeEventListener('resize', this.reportWindowSize_pornstars_popup);    
    //window.scrollTo(0, this.state.pornstars_by_country_popup_scroll_y);
    //this.setState({pornstars_by_country_popup_scroll_y: 0})
    window.dispatchEvent(new Event('resize'));
   }  
    

   show_pornstar_countries = async () => {
    var self=this
    const start = Date.now();
    var scrollbarwidth=this.getScrollBarWidth()

    document.getElementById("rightSideNoLeftMenu").style.paddingRight=scrollbarwidth+'px'

    //var scrollbarwidth=this.getScrollBarWidth()

  

    if(this.state.filters_showing == 1 && this.state.menu_showing == 1){    
      await this.hide_menu()
    }
    document.getElementById("pornstar-countries-search-input").value = ""
    
    document.getElementById("pornstars_per_country_wrapper").style.display='inline-block'
    document.getElementById("pornstars_per_country_wrapper").style.top=(
      ((window.innerHeight-document.getElementById("pornstars_per_country_wrapper").offsetHeight)/2) - 7 )+"px"
   

    //document.getElementById("menu").style.marginRight=(scrollbarwidth/2)+'px'
    
    await this.setState({pornstar_countries_popup_showing: 1, pornstar_countries_showDotsLoading: 1, pornstar_countries_showError: 0, scrollbarwidth: scrollbarwidth})    
    window.addEventListener('resize', this.reportWindowSize_pornstars_popup);
    this.reportWindowSize_pornstars_popup()
    
    //window.scrollTo(0, 0);
    
    //if(this.state.pornstar_countries && this.state.pornstar_countries.length == 0){
    if( this.state.pornstar_countries_initial.length == 0){
      const response = axios.get(
        configData.PUBLIC_API_ENDPOINT +
          "/get_active_countries_for_pornstars",
        {timeout: configData.TIMEOUT_LIMIT}
      ).then(function (response) {
        
        setTimeout(async() => { 

          const pornstar_countries = response.data;
          await self.setState({ pornstar_countries });
          self.setState({pornstar_countries_initial: response.data})
          await self.setState({pornstar_countries_showDotsLoading: 0})
          //self.reportWindowSize(1,1)
          //alert(self.state.pornstar_countries.length)
          // if(self.state.videos.length != configData.USER_VIDEOS_PER_PAGE){

          //     alert(self.state.videos.length+"-"+configData.USER_VIDEOS_PER_PAGE)
          // }
          //alert(self.state.videos.length+"-"+configData.USER_VIDEOS_PER_PAGE)
          //window.scrollTo(0, 0);
  //        await self.props.reportWindowSize();

          //self.setState({rerenter_menuwrapper: 1})

        }, configData.WAITING_MILIS - (Date.now() - start));
      
      })
      .catch(async function (ex) {
        
        
        setTimeout(async() => {
          await self.setState({pornstar_countries_showDotsLoading: 0,pornstar_countries_showError: 1})
          await self.props.reportWindowSize();
      }, configData.WAITING_MILIS - (Date.now() - start));
    });
  }else{
    this.setState({pornstar_countries_showDotsLoading: 0, pornstar_countries: this.state.pornstar_countries_initial})
    document.getElementById("pornstar_countries_div").scrollTop = 0;
  }

    this.set_overlay(1)
    document.body.style.overflow = 'hidden'; 

    window.dispatchEvent(new Event('resize'));

    
  }

  myCallback_login_popup_resize = () => {
    this.reportWindowSize_login(1)
  } 

  show_login_popup = async(return_to_location) =>{
    //this.hide_filters()
    //return
    

    if(this.state.filters_showing == 1 && this.state.menu_showing == 0){
      await this.hide_filters(1,1);//hide_action
      //alert("hide filters")
      //this.hide_menu();
    }
    if(this.state.menu_showing == 1 && this.state.filters_showing == 1){
      await this.hide_menu();//hide_action
    }

    this.setState({login_popup_showing: 1,show_overlay: 1,scrolling_down: 0})
    this.setState({login_popup_redirect: return_to_location})
    //this.hide_menu()
    //document.getElementById("login_button").style.marginTop = "50px";
    document.getElementById("login_error_message").style.display = "none";
    document.getElementById("inputEmail").style.borderColor = ""
    document.getElementById("inputPassword").style.borderColor = ""
    document.getElementById("inputEmail").value = ""
    document.getElementById("inputPassword").value = ""
    document.getElementById("login_wrapper").style.display="inline-block"
    //document.getElementById("login_wrapper").style.height="573px"
    
    if(!document.location.href.match("login")){
       if(this.state.mobile_filter_showing == 1){
        document.getElementById("login_wrapper").style.top=(window.scrollY+
          ((window.innerHeight-document.getElementById("login_wrapper").offsetHeight)/2))+"px"
       }
      else{
        document.getElementById("login_wrapper").style.top=(window.scrollY+
          ((window.innerHeight-document.getElementById("login_wrapper").offsetHeight)/2) -0 )+"px"
      }
    }

       var scrollbarwidth=this.getScrollBarWidth()
    //this.set_overlay(1)
    document.body.style.overflow = 'hidden'; 

    document.getElementById("menu").style.marginRight=(scrollbarwidth/2)+'px'

    // if(this.state.mobile_or_tablet == 1){
    //   document.getElementById("menu").style.paddingRight=(scrollbarwidth/2)+'px'
    // }else{
    //   document.getElementById("menu").style.marginRight=(scrollbarwidth/2)+'px'
    // }

    


    
     this.reportWindowSize_login(1)
     //window.addEventListener('resize', this.reportWindowSize_login)
     window.addEventListener('resize', this.myCallback_login_popup_resize)  
    // window.scrollY+
     //((window.innerHeight-document.getElementById("login_wrapper").offsetHeight)/2) -0 window.addEventListener('resize', this.reportWindowSize_login);
   // 
    //this.setState({ scrolling_down: 0})
    var self=this;
    setTimeout(function() { 
      //document.getElementById('tagsLeft').style.left = "0px";
      self.setState({scrolling_down: 0})
      
      },255)

      //alert('pan')
    
  }

  set_scrolly = (value) => {
    //alert(value)
    this.setState({scrolly: value})
  }

  hide_login_popup = () => {
    document.body.style.overflow = 'visible';
    this.setState({login_popup_showing: 0})
    document.getElementById("login_wrapper").style.display="none"
    this.set_overlay(0)
    window.removeEventListener('resize', this.myCallback_login_popup_resize);
    this.setState({login_return_location: ""})


    document.getElementById("menu").style.marginRight='0px'
    
    

    //window.scrollTo(0, this.state.login_scroll_y);
    //this.setState({login_scroll_y: 0})
    var self=this;
    setTimeout(function() { 
      //document.getElementById('tagsLeft').style.left = "0px";
      self.setState({scrolling_down: 1})
      
      },255)
      window.dispatchEvent(new Event('resize'));
  }

  login_change_showPassword1clearText = (value) => {
    //alert("panagitsa"+value)
    this.setState({login_showPassword1clearText: value})
  }

  login_change_rememberme = (value) => {    
    this.setState({login_rememberme: value})
  }

  reportWindowSize_pornstars_popup = () => {
    var window_width = window.innerWidth;
    var elements = null
    
    if( window_width <= 571){       
        document.getElementById("pornstars_per_country_wrapper").style.width="calc(100% - 20px)"
        //document.getElementById("login_wrapper").style.margin="0 auto"
        // elements = document.getElementsByClassName('pornstars_per_country_wrapper');
        //   for (var i = 0; i < elements.length; i++) {
        //       elements[i].style.width = "calc(100% - 80px)"
        //       elements[i].style.margin = "0 auto"
        //   }
          
       
    
    }
    else{
          document.getElementById("pornstars_per_country_wrapper").style.width=""
          // elements = document.getElementsByClassName('pornstars_per_country_wrapper');
          // for (var i = 0; i < elements.length; i++) {
          //     elements[i].style.width = "560px"
          //     elements[i].style.margin = "0 auto"
          // }
        
        this.set_mobile_filter_showing(0)

    }    
    document.getElementById("pornstars_per_country_wrapper").style.top=(
      ((window.innerHeight-document.getElementById("pornstars_per_country_wrapper").offsetHeight)/2) - 7 )+"px" 
  }

  reportWindowSize_login = (is_popup=0,is_reset_password=0) => {
    var window_width = window.innerWidth;
    var elements = null

    elements = document.getElementsByClassName('login_wrapper');
          for (var i = 0; i < elements.length; i++) {              
              elements[i].style.paddingLeft = "48px"
              elements[i].style.paddingRight = "48px"
            }


   if(is_popup==1){
      document.getElementById("login_wrapper").style.top=(window.scrollY+
      ((window.innerHeight-document.getElementById("login_wrapper").offsetHeight)/2))+"px"
      
   }else{
    document.getElementById("login_wrapper").style.top=(document.getElementById("rightSideNoLeftMenud").offsetHeight-document.getElementById("login_wrapper").offsetHeight)/2+"px"
    try{
      document.getElementById("reset_wrapper").style.top=(document.getElementById("rightSideNoLeftMenud").offsetHeight-document.getElementById("reset_wrapper").offsetHeight)/2+"px"
    }catch(e){}
    //  '0px' height: "580px"
   }   
   
    
    if( window_width <= 571){       
        document.getElementById("login_wrapper").style.width='900px !important'//"calc(100% - 10px)"
        document.getElementById("login_wrapper").style.margin="0 auto"
        //document.getElementById("login_wrapper").style.marginLeft="24px"
        //alert('popu')
        if(is_popup == 0){
        var x = 0//0document.getElementById("rightSideNoLeftMenud").offsetWidth
      }
        var y = 0//document.getElementsByClassName("login_wrapper")[0].offsetWidth
        // if(y == 0){
        //   var y = document.getElementsByClassName("login_wrapper")[1].offsetWidth
        // }

        try{
          document.getElementsByClassName("login_wrapper")[0].style.left = (x-y)/2+"px"
          document.getElementsByClassName("login_wrapper")[1].style.left = (x-y)/2+"px"
        }catch(e){}
        
        // try{
        //   document.getElementsByClassName("login_wrapper")[1].style.marginLeft = "24px"
        //   document.getElementsByClassName("login_wrapper")[1].style.marginLeft = "24px"
        // }catch(e){alert('exce')}
    
        elements = document.getElementsByClassName('login_wrapper');
          for (var i = 0; i < elements.length; i++) {
              elements[i].style.width = "calc(100% - 20px)"
              elements[i].style.margin = "0 auto"
              elements[i].style.paddingLeft = "24px"
              elements[i].style.paddingRight = "24px"
            }
        
          
       
      //document.getElementById("login_wrapper").style.top="65px"
      // this.set_mobile_filter_showing(1)
      // this.setState({mobile_or_tablet: 1})
      // //alert('bole' + window_width)
      // document.getElementById('hamburger_wrapper').style.display = "block";
      // document.getElementById('menu_desktop').style.display = "none";
      //alert("panagia")
    }
    else{
      
  ///      document.getElementById("login_wrapper").style.width="560px"


  //hot fixing a bug, login_wrapper gets below the page and this is a hotfix
 if(document.getElementById("login_wrapper").getBoundingClientRect().top + window.pageYOffset + 
    document.getElementById("login_wrapper").offsetHeight 
  > document.getElementById("footer_id").getBoundingClientRect().bottom + window.pageYOffset){
    
    this.hide_login_popup()
    this.setState({show_overlay: 1})
  

   setTimeout(() => {
    this.show_login_popup()
  }, 1);
    return 
  }
        if(is_reset_password == 0){
          if(window_width > 1195)
            document.getElementById("login_wrapper").style.width="560px"
          else
            document.getElementById("login_wrapper").style.width="480px"          
          //document.getElementsByClassName("login_wrapper")[0].style.width="560px"
          document.getElementById("login_wrapper").style.margin="0 auto"
        }else{
          if(window_width > 1195)
            document.getElementById("reset_wrapper").style.width="560px"
          else
            document.getElementById("reset_wrapper").style.width="480px"          
          //document.getElementsByClassName("reset_wrapper")[0].style.width="560px"
          document.getElementById("reset_wrapper").style.margin="0 auto"
        }
          if(is_popup == 0){
            // var x = document.getElementById("rightSideNoLeftMenud").offsetWidth
            // var y = document.getElementsByClassName("login_wrapper")[0].offsetWidth
            // if(y == 0){
            //   var y = document.getElementsByClassName("login_wrapper")[1].offsetWidth
            // }
            

            try{
              // document.getElementsByClassName("login_wrapper")[0].style.left = (x-y)/2+"px"
              // document.getElementsByClassName("login_wrapper")[1].style.left = (x-y)/2+"px"
              document.getElementsByClassName("login_wrapper")[0].style.left = "0px"
              document.getElementsByClassName("login_wrapper")[1].style.left = "0px"
            }catch(e){}
        }
        
          // elements = document.getElementsByClassName('login_wrapper');
          // for (var i = 0; i < elements.length; i++) {
          //     elements[i].style.width = "560px"
          //     elements[i].style.margin = "0 auto"
          //  }
        
     
     }
    

    
  }
  google_login = (response,return_to_location) => {
    if(response.accessToken){
      var self=this;
      this.setState({ login_showSpinner: 0, login_showError: 0 });
      //this.props.login_change_showSpinner(0)
      axios({
        method: "get",
        url: configData.PRIVATE_API_ENDPOINT + "/google_user_login?token="+response.accessToken,
        headers: { "Content-Type": "multipart/form-data" },
        timeout: configData.TIMEOUT_LIMIT
      })
        .then(function (response) {
          //self.setState({ channels_like: response.data });
          localStorage.removeItem("theme")
          localStorage.setItem("token", response.data.access_token);

          try{
            if(self.state.login_return_location.length > 0){
              var return_to=self.state.login_return_location
            }else{
              var return_to = window.location.search.split("return_to=")[1];
            }
          }catch(e){
              var return_to = window.location.search.split("return_to=")[1];
          }

          if (return_to == "/login" || return_to == '/register' || return_to == '/reset_password'){
              return_to = "/"
          }

          // // old 
  
          // var return_to = window.location.search.split("return_to=")[1];
          // if (return_to == "/login" || return_to == '/register'){
          //     return_to = "/"
          // }
          // // old end
  
          self.setState({ login_showSpinner: 0 });
  
          if (return_to != undefined) {
            window.location = return_to;
          } else window.location = "/";
        })
        .catch(function (error) {
          self.setState({ showSpinner: 0 });
          if (error.response && error.response.status == 401) {            
            document.getElementById("login_error_message").style.display = "block";
          }
          //handle error 
          else self.setState({showError: 1})
        });
    }
    
  }
  return_country_from_country_code(country_code){
    var country_object= ""
    try{
      country_object = flags.filter(
        (objFromA) => objFromA.url == country_code
      )[0]
      return country_object.country
        //alert(country_object)
    }catch(e){return ""}
      
  } 

  set_search_item_selected_active_element = async(f_value) => {
    await this.setState({search_item_selected_active_element: f_value})
    if(this.state.search_item_selected_active_element == -1){
      if(this.state.search_expanded == 1){
        document.getElementById("search_input").value = this.state.search_string;
      }
      else{
        document.getElementById("mobile_search_input").value = this.state.search_string;
      }
    }
    
  }

  search_arrow_navigator = async(event) =>{
    if (event.key === "ArrowDown" ) {
      //alert('down')
      //this.setState({search_item_selected_active_element: this.state.search_item_selected_active_element + 1})
      var value = this.state.search_item_selected_active_element + 1
      if(this.state.show_more_tags == 0 && value == 5 && this.state.tags.length > 5){
        await this.setState({search_item_selected_active_element: this.state.tags.length })
      }
      else if(value >= this.state.tags.length + 
        (this.state.pornstars_like.length > 5 ? 5 : this.state.pornstars_like.length) + 
        (this.state.channels_like.length > 5 ? 5 : this.state.channels_like.length)){
        //await this.setState({search_item_selected_active_element: 0 })
        await this.setState({search_item_selected_active_element: -1 })
      }else{
        await this.setState({search_item_selected_active_element: value })
      }  
      
      
    }
    if(event.key === "ArrowUp"){      
      var value = this.state.search_item_selected_active_element - 1
      
      if(this.state.show_more_tags == 0 && value == this.state.tags.length -1 && this.state.tags.length > 5){
        await this.setState({search_item_selected_active_element: 4 })
        //alert('panagia')
      }
      else if(value < -1){        
        //await this.setState({search_item_selected_active_element: this.state.tags.length + this.state.pornstars_like.length + this.state.channels_like.length -1 })
        await this.setState({search_item_selected_active_element: 
          this.state.tags.length + 
          (this.state.pornstars_like.length > 5 ? 5 : this.state.pornstars_like.length) + 
          (this.state.channels_like.length > 5 ? 5 : this.state.channels_like.length) -1 })
      }else{
        await this.setState({search_item_selected_active_element: value })
      }
    }
    if(this.state.search_expanded == 1 && this.state.search_item_selected_active_element >= 0){
      document.getElementById("search_input").value = document.getElementById("search_item"+this.state.search_item_selected_active_element).innerText.replace(/\d+\s+(video|videos).*$/i, "");      
    }
    else if(this.state.search_expanded == 0 && this.state.search_item_selected_active_element >= 0){
      document.getElementById("mobile_search_input").value = document.getElementById("search_item"+this.state.search_item_selected_active_element).innerText.replace(/\d+\s+(video|videos).*$/i, "");
    }
    if(this.state.search_item_selected_active_element == -1){
      if(this.state.search_expanded == 1){
        document.getElementById("search_input").value = this.state.search_string;
      }
      else{
        document.getElementById("mobile_search_input").value = this.state.search_string;
      }
    }

  }

  login = async(event,return_to_location) => {   

    event.preventDefault();
    //alert(return_to_location)
    const start = Date.now();
    //const query = new URLSearchParams(this.props.location.search);
    await this.setState({ login_showSpinner: 1 ,showError: 0});
    //return
    //alert("panagia")
    //this.props.login_change_showSpinner(1)
    var bodyFormData = new FormData();
    var email = document.getElementById("inputEmail").value;
    var pass = document.getElementById("inputPassword").value;
   // document.getElementById("login_button").style.marginTop = "50.5px";
    document.getElementById("login_error_message").style.display = "none";
    document.getElementById("inputEmail").style.borderColor = ""
    document.getElementById("inputPassword").style.borderColor = ""
   // document.getElementById("login_wrapper").style.height = '580px'
    //var password = document.getElementById("passwordDiv").value;
    //alert(username + " " + password);
    bodyFormData.append("username", email);
    bodyFormData.append("password", pass);
    
    bodyFormData.append("rememberme", this.state.login_rememberme == 1 ? 1 : 0);
    var self = this;
    axios({
      method: "post",
      data: bodyFormData,
      url: configData.PRIVATE_API_ENDPOINT + "/token",
      headers: { "Content-Type": "multipart/form-data" },
      timeout: configData.TIMEOUT_LIMIT
    })
      .then(function (response) {
        //self.setState({ channels_like: response.data });
        setTimeout(async() => {
          //alert(response.data.access_token);
          localStorage.removeItem("theme")
          localStorage.setItem("token", response.data.access_token);

          
          try{
            if(self.state.login_return_location.length > 0){
              var return_to=self.state.login_return_location
            }else{
              var return_to = window.location.search.split("return_to=")[1];
            }
          }catch(e){
              var return_to = window.location.search.split("return_to=")[1];
          }

          if (return_to == "/login" || return_to == '/register' || return_to == '/reset_password'){
              return_to = "/videos"
          }

          self.setState({ login_showSpinner: 0 });
          //this.props.login_change_showSpinner(1)
          if (return_to != undefined) {
            window.location = return_to;
          } else window.location = "/videos";
        }, configData.WAITING_MILIS - (Date.now() - start));
      })
      .catch(function (error) {
        setTimeout(async() => {
          //
          //document.getElementById("login_wrapper").style.height = '600px'
          if (error.response && error.response.status == 401) {
            document.getElementById("login_error_message").style.display = "block";
           // document.getElementById("login_button").style.marginTop = "-2px";
            document.getElementById("inputEmail").style.borderColor = "red"
            document.getElementById("inputPassword").style.borderColor = "red"
            self.setState({login_error_message: "Invalid username or password"})
            //document.getElementById("login_wrapper").style.height = '617px'
            // document.getElementById("rememberme_checkbox").style.marginTop = "-58px";
            
          }
          //handle error 
          else{
            document.getElementById("login_error_message").style.display = "block";
           // document.getElementById("login_button").style.marginTop = "-2px";
            self.setState({login_error_message: "Unknown error. Please try again later"})
            //document.getElementById("login_wrapper").style.height = '609px'
            }
            self.setState({ login_showSpinner: 0 });
        }, configData.WAITING_MILIS - (Date.now() - start));
      });

  };

  handleTagsFilterInput = async ( event) => {

    var ptags_temp = this.state.ptags_initial

    this.setState({tags_filter_value: event.target.value})

    var optionsStartingWith ;
    var optionsContaining;
    if(event.target.value.length == 0){
      optionsStartingWith = ptags_temp.filter((ptag) => ptag.is_main_tag == 1).filter(ptag => {
        return ptag.tag.toLowerCase().startsWith(event.target.value);
      });    
      optionsContaining = ptags_temp.filter((ptag) => ptag.is_main_tag == 1).filter(ptag => {
        return ptag.tag.toLowerCase().includes(event.target.value.toLowerCase()) && !optionsStartingWith.includes(ptag);
      });
    
    var new_ptag = optionsStartingWith.concat(optionsContaining);
    
    await this.setState({ptags: new_ptag})
  }else{
    optionsStartingWith = ptags_temp.filter(ptag => {
      return ptag.tag.toLowerCase().startsWith(event.target.value);
    });    
    optionsContaining = ptags_temp.filter(ptag => {
      return ptag.tag.toLowerCase().includes(event.target.value.toLowerCase()) && !optionsStartingWith.includes(ptag);
    });
  
  var new_ptag = optionsStartingWith.concat(optionsContaining);
  
  await this.setState({ptags: new_ptag})
  }
    

  }

  handlePornstarPerCountrySearch = async(event, test) => {
      await this.setState({pornstar_countries: []})
      var vvalue = document.getElementById("pornstar-countries-search-input").value
      var x = this.filterOptionsPornstarsPerCountry(this.state.pornstar_countries_initial, vvalue)      
      this.setState({pornstar_countries: x})
      console.log(vvalue)
  }

   getElementDistanceFromWindowRight(element) {
    var rect = document.getElementById(element).getBoundingClientRect();
    var distanceFromLeft = rect.left;

    var windowWidth = window.innerWidth;
    var distanceFromRight = windowWidth - distanceFromLeft - rect.width;//document.getElementById(element).offsetWidth;
    return distanceFromRight -20;
}



 set_tag_popup_stay_active = (value) => {
  this.setState({tag_popup_stay_active: value})
 }
  
  show_desktop_tags_popup = () =>{
   // alert('d')
   this.setState({tags_desktop_popup_showing: 1})
   var menu = document.getElementById('menu_inner').getBoundingClientRect();
   
   document.getElementById('tags_popup_desktop').style.left = menu.left+"px";
  console.log(this.getElementDistanceFromWindowRight("menu_inner")+"px")
   document.getElementById('tags_popup_desktop').style.right = this.getElementDistanceFromWindowRight("menu_inner")+"px"; //should be the same left and right
  
   
  
   // document.getElementById('tags_popup_desktop').style.right = '0px';
   let elements = document.getElementsByClassName('tags_desktop_popup_tag');

   var tag_width = (document.getElementById("menu_inner").offsetWidth-40)/4
    // Iterate over each element and set its width
    for (let i = 0; i < elements.length; i++) {
        elements[i].style.width = tag_width+'px'; // Change '200px' to whatever width you want
    }

  }



  hide_desktop_tags_popup = (skip_timeout=0) => {
    if(skip_timeout == 1){
      //alert('1')
      this.setState({ tags_desktop_popup_showing: 0, tag_popup_stay_active: 0 })
    }else{
      setTimeout(() => {
        if(this.state.tags_desktop_popup_hovering_now == 0 && this.state.tag_popup_stay_active == 0){
          this.setState({ tags_desktop_popup_showing: 0,tag_popup_stay_active: 0 }
            
            )
        }
        ;
      }, 100); // 1000 milliseconds = 1 second
  }
  }
 
    shouldComponentUpdate(nextProps, nextState) {
      

      if(
          this.state.theme != nextState.theme || 
          this.state.tags_desktop_popup_showing != nextState.tags_desktop_popup_showing ||
          this.props.User != nextProps.User || 
          this.state.show_menu_value != nextState.show_menu_value || 
          this.state.show_filters_value != nextState.show_filters_value || 
          this.state.menu_selected != nextState.menu_selected ||
          this.props.show_footer != nextProps.show_footer ||
          this.state.mobile_filter_showing != nextState.mobile_filter_showing ||
          this.state.show_overlay != nextState.show_overlay || 
          this.state.filters_showing != nextState.filters_showing ||          
          this.state.scrolling_down != nextState.scrolling_down ||
          this.state.mobile_or_tablet != nextState.mobile_or_tablet || 
          this.state.login_rememberme != nextState.login_rememberme ||
          this.state.cookies_dialog_ok != nextState.cookies_dialog_ok ||
          this.state.login_showSpinner != nextState.login_showSpinner ||
          this.state.login_showPassword1clearText != nextState.login_showPassword1clearText ||
          this.state.comment_got_deleted != nextState.comment_got_deleted  ||
          this.state.window_height != nextState.window_height ||
          this.state.delete_comment_popup_showing != nextState.delete_comment_popup_showing ||
          this.state.pornstar_countries_popup_showing != nextState.pornstar_countries_popup_showing ||
          this.state.pornstar_countries != nextState.pornstar_countries ||
          this.state.pornstar_countries_showDotsLoading != nextState.pornstar_countries_showDotsLoading ||
          this.state.pagination_show_less != nextState.pagination_show_less ||
          this.state.tags != nextState.tags ||
          this.state.pornstars_like != nextState.pornstars_like ||
          this.state.channels_like != nextState.channels_like ||
          this.state.show_more_tags != nextState.show_more_tags ||
          this.state.mobile_search_input_popup_showing != nextState.mobile_search_input_popup_showing ||
          this.state.search_popup_showing != nextState.search_popup_showing ||
          this.state.search_expanded != nextState.search_expanded ||
          this.state.search_item_selected_active_element != nextState.search_item_selected_active_element ||
          this.state.pornstar_input_focused != nextState.pornstar_input_focused ||
          this.state.padding_px_larger_screens != nextState.padding_px_larger_screens ||
          this.state.comment_menu_showing != nextState.comment_menu_showing ||
          this.state.current_comment_menu_id_showing != nextState.current_comment_menu_id_showing ||
          this.state.scrollbarwidth != nextState.scrollbarwidth ||
          this.state.email_focus != nextState.email_focus ||
          this.state.password_focus != nextState.password_focus || 
          this.state.number_of_filters != nextState.number_of_filters ||
          this.state.over_18_popup_showing != nextState.over_18_popup_showing  ||
          this.state.ptags != nextState.ptags ||
          this.state.real_flag_for_mobile_search_input_popup_showing != nextState.real_flag_for_mobile_search_input_popup_showing ||
          this.state.tags_expanded_div_became_fixed != nextState.tags_expanded_div_became_fixed ||
          this.state.tags_filter_desktop_focused != nextState.tags_filter_desktop_focused ||
          this.state.overlay_full_except_menu_and_mobile_search_value != nextState.overlay_full_except_menu_and_mobile_search_value ||
          this.state.selected_tags != nextState.selected_tags ||
          this.state.any_popup_showing_stop_video_hover != nextState.any_popup_showing_stop_video_hover ||
          this.state.top_over_18_popup_px != nextState.top_over_18_popup_px
          
          
         
        ) {
           //console.log("yes  - FUCKIN RENDER")
           return true
      }
     //console.log("no - DO NOT RENDER")
      return false
    }

  render() {
    return (
      <React.Fragment>       
      <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover={false}
          />


<div id="tags_popup_desktop"
  className="scrollbar"
      onMouseOver={() => this.setState({tags_desktop_popup_hovering_now: 1, tags_desktop_popup_showing:1})}
       onMouseOut={() => this.setState({tags_desktop_popup_hovering_now: 0,tags_desktop_popup_showing:0})//this.hide_desktop_tags_popup()// this.setState({tags_desktop_popup_hovering_now: 0,tags_desktop_popup_showing:0})
     }
        style={{
          position:'fixed',
          top:'58px',
          left:"40px",
          right:"40px", 
          height: "500px",
          bottom:"200px",
          zIndex:'9999',
          display: this.state.tags_desktop_popup_showing == 0 ? "none" : "",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
         // backgroundColor:"yellow",
          
          height: "500px",overflowY: 'auto',
          alignContent:"center"
          
        }}
       >

              <div style={{position:"relative",width: "100%",border: '0px solid red',alignItems:"center"}}

                  // onMouseOver={() => this.setState({ tags_desktop_popup_showing: 1,tags_desktop_popup_hovering_now: 1 })}
                  // //   onMouseLeave={() => this.setState({ tags_desktop_popup_hovering_now: 0, tags_desktop_popup_showing: 0 })}

                  //   //  onMouseOut={(event) => {event.stopPropagation();this.hide_desktop_tags_popup()}}
                    // onMouseOut={(event) => {
                    //   event.stopPropagation();
                    //   this.setState({tags_desktop_popup_hovering_now: 0,tags_desktop_popup_showing:0 });
                    //   this.hide_desktop_tags_popup()}
                    //   }


              >
                    <FontAwesomeIcon icon="search" size="1x" 
                                    style={{
                                      position:"absolute",  
                                      top: "11.5px",
                                      left: "29px",
                                    
                                      // right: "0px",
                                      zIndex: "9999",
                                     // marginTop: "10.5px",
                                      color: this.state.tags_filter_desktop_focused == 0 && navigator.userAgent.indexOf("Firefox") == -1 ? "8B8B8F" : 
                                          this.state.theme == lightTheme ? "#5724E6" : "#9F7DFF"

                                    }}
                                  />
                                <input
                                    className="form-control text_color"
                                    type="search"
                                    dark={this.state.theme == lightTheme ? "false" : "true"}
                                    value={this.state.tags_filter_value}
                                    style={{
                                      marginLeft:"-1px",
                                      position:"relative",
                                      borderRadius: "4px",
                                     // display: this.state.tags_expanded == 1 ? "" : "none",
                                      // width: this.props.pornstar_countries_popup_showing == 1 ? 
                                      // document.getElementById('rightSideNoLeftMenu').offsetWidth+"px" 
                                      // : "calc(100% - 91px)", 
                                      //width: 'calc(document.getElementById("rightSideNoLeftMenu").offsetWidth-91+"px")',
                                      position:"relative",
                                      borderRadius: "4px",
                                      marginBottom: "10px",
                                      paddingLeft: "32px",
                                      marginLeft: "20px",
                                      //color: "white",
                                      backgroundColor: this.state.theme == lightTheme ?  "#F2F2F2" : "#3E3E47",
                                      color: this.state.theme == lightTheme ?  "#02000D" : "#f2f2f2",
                                      border: '1px solid transparent',
                                      // border: this.state.tags_filter_desktop_focused == 1 ? 
                                      //   this.props.theme == lightTheme ? "1px solid #5724E6"  : "1px solid #9F7DFF" 
                                      //   : "1px solid transparent" , 
                                      //width: '200px',
                                      width: 'calc( 100% - 40px )',
                                      marginTop: "24px",
                                      boxShadow: "none",lineHeight: '42px',
                                      paddingTop: "9px"
                                      
                                      }}
                                    onClick={(event) => {event.stopPropagation()
                                    //  ;event.preventDefault();
                                     }}
                                    onFocus={(event) => {event.stopPropagation();event.preventDefault(); this.setState({tags_filter_desktop_focused: 1})}}
                                    onBlur={(event) => { event.stopPropagation();event.preventDefault();this.setState({tags_filter_desktop_focused: 0}) } }
                                    placeholder="FILTER TAGS"
                                    id="" //this.handleInput(value)
                                    onChange={async(event) => {
                                        //console.log(event.target.value)
                                        event.stopPropagation();event.preventDefault();
                                      //  await this.setState({tags_filter_value: event.target.value})
                                        await this.handleTagsFilterInput(event);
                                        this.show_desktop_tags_popup()
                                    }} 
                                  ></input>
                                 {""}
                          </div>  


          <div style={{width: "100%",display:"inline-block",textAlign:"center", border: '0px solid red',
            height: this.state.ptags.length > 0 ? "calc(100% - 79px)" :  "calc(100% - 74px)"//74
            }}>
          {1 == 1 ? this.state.ptags.map(               
                  (ptag,index) => (

                    <div style={{position:"relative",textAlign:"left",
                    marginLeft: index == 0 || index % 4 == 0? "21px" : "", border:'0px solid pink',float:'left',width: "500px",}}
                      className="leftMenuElement_no_background_hover tags_desktop_popup_tag "
                        onMouseOver={() => this.setState({ tags_desktop_popup_showing: 1,tags_desktop_popup_hovering_now: 1 })}
                      // onMouseOut={(event) => {
                      //   event.stopPropagation();
                      //   this.setState({tags_desktop_popup_hovering_now: 0 });
                      //   this.hide_desktop_tags_popup()}
                      // }
                        
                      >
                         <a
                        href={"/tag/" + ptag.id + "/" + title_to_url(ptag.tag)}
                        style={{textDecoration: "none"}}
                        onClick={(event) =>{
                          this.setState({tags_desktop_popup_hovering_now:0,tags_desktop_popup_showing:0})
                          this.go_to_link(
                            event,
                            "/tag/" + ptag.id + "/" + title_to_url(ptag.tag)
                          );
                          
                        }
                        }
                        className={this.state.show_blur == 1 ? "tags_left_menu_href purple_href blur" : "tags_left_menu_href purple_href"}
                      >
                        {
                        //ptag.tag
                        }
                      {ptag.tag.toLowerCase().includes(this.state.tags_filter_value.toLowerCase()) ? (
                          <div 
                            style={{position:"relative",float:'left',display:'inline-block'}}
                            dangerouslySetInnerHTML={{
                            __html: (() => {
                              const re = new RegExp(this.state.tags_filter_value, 'gi');
                              const highlighted = ptag.tag.replace(re, `<mark class="custom-highlight">$&</mark>`);
                              return highlighted;
                            })()
                          }} />
                        ) : (
                          <div>{ptag.tag}</div>
                        )} 
                        
                        {ptag.has_photo != null ? (
                              <img 
                                style={{
                                  marginLeft: "8px",
                                  bottom: "-5px",
                                  position:"relative",
                                  float:'left'
                                }}
                                src={configData.CDN_ENDPOINT + "flags/" + ptag.has_photo.replace("flags/", "") +".svg"}
                                width="20"
                                className="flag_wrapper_shadow_class"
                                
                              />
                            ) : (
                              ""
                            )}
                        </a>
                      </div>
                    
                      
                  )
                ) : ""}

                {this.state.ptags.length == 0 ? 
                    <div
                    className="custom-h3 text_color">
                        No tags found
                    </div> : ""  
                    }
                <div style={{position:"relative",border:'0px solid pink',float:'left',width: "500px",}}
                        className="tags_desktop_popup_tag"
                        onMouseOver={() => this.setState({ tags_desktop_popup_showing: 1,tags_desktop_popup_hovering_now: 1 })}
                        //   onMouseLeave={() => this.setState({ tags_desktop_popup_hovering_now: 0, tags_desktop_popup_showing: 0 })}
    
                          //  onMouseOut={(event) => {event.stopPropagation();this.hide_desktop_tags_popup()}}
                          // onMouseOut={(event) => {
                          //   event.stopPropagation();
                          //   this.setState({tags_desktop_popup_hovering_now: 0 });
                          //   this.hide_desktop_tags_popup()}
                          // }
                        >
                         
                         <div
                            className="leftMenuElement purple_href"
                            // onMouseOver={() => this.setState({ tags_desktop_popup_showing: 1,tags_desktop_popup_hovering_now: 1 })}
                            // //   onMouseLeave={() => this.setState({ tags_desktop_popup_hovering_now: 0, tags_desktop_popup_showing: 0 })}
        
                            //   //  onMouseOut={(event) => {event.stopPropagation();this.hide_desktop_tags_popup()}}
                            //   onMouseOut={(event) => {
                            //     event.stopPropagation();
                            //     this.setState({tags_desktop_popup_hovering_now: 0 });
                            //     this.hide_desktop_tags_popup()}
                            //   }
                                
                            style={{ 
                              display: this.state.ptags.length == 0 ? "none" : "" ,textAlign:"left", paddingLeft: "0px", lineHeight: navigator.userAgent.indexOf("Firefox") != -1 ? "16.5px":"17px"}}
                            >
                            <a href={"/tags/"}
                               className="purple_href"
                               
                               style={{textDecoration:"none",
                               marginLeft: this.state.ptags.length == 0 || this.state.ptags.length % 4 == 0? "21px" : "",
                               color: this.state.theme == lightTheme ? "#5724E6" : '#9F7DFF',
                              
                              }}
                               onMouseOver={() => this.setState({ tags_desktop_popup_showing: 1,tags_desktop_popup_hovering_now: 1 })}

                                onClick={(event) => {
                                  event.preventDefault();
                                  this.setState({tags_desktop_popup_hovering_now:0,tags_desktop_popup_showing:0})
                                  
                                  //this.make_search_inactive();
                                  this.go_to_link(event,"/tags/")}}
                                >Show all tags&nbsp;<FontAwesomeIcon  icon={"arrow-right"} 
                                size="1x" /></a>
                            
                            </div>
                        </div>
                </div>
                


        </div>

            


                <div id="mobile_user_menu"
                     className="mobile_user_menuz"
                        style={{
                                width: "122px",
                                height: "auto",
                                textAlign:"left",
                                position:"fixed",
                                float:"right",
                                borderRadius: "4px",
                                marginTop: "58px",
                                right: "20px",
                                boxShadow: "0px 4px 14px rgba(2, 0, 13, 0.14)",
                                display: "none",
                                zIndex: "955",
                                color: "black"
                              
                        }}>
                        <div className="leftMenuElement_gray_background"
                          style={{paddingLeft: "5px", 
                                  width: "122px",
                                  borderRadius: "0px !important",
                                  marginLeft: "0px",
                                  marginTop: "0px",


                                }}>              
                             <a 
                           href={"/profile/settings"}
                          
                           onClick={(event) => {this.show_hide_user_menu_mobile() ; this.go_to_link(event, "/profile/settings")}}
                          style={{textDecoration: "none"}}
                          className={document.location.href.split("/")[4] != undefined && document.location.href.split("/")[4].includes("settings")   ? "menu_href_color settings_left_menu_div " : "tags_left_menu_href settings_left_menu_div"}
                        >
                      
                            <h4 className="body14">
                            <div className={document.location.href.split("/")[4] != undefined && document.location.href.split("/")[4].includes("settings") ? "settings_left_menu_icon active" : "settings_left_menu_icon"} 
                                            style={{
                                              position:"relative",
                                              float:'left',
                                              marginTop: "1px",
                                              marginRight: "8px", 
                                              // width: "15px",
                                              // height: '15px',
                                            }}
                                          ></div>Settings                  
                            </h4>
                      
                      </a>
                            
                        </div>
                        {/* <hr></hr> */}
                        
                        <div className="leftMenuElement_gray_background"
                          style={{paddingLeft: "5px",
                                  width: "122px",
                                  borderRadius: "0px !important",
                                  marginLeft: "0px",
                                  marginTop: "0px",        
                          
                          }}>
                
                        <a 
                           href={"/profile/watched"}
                          
                           onClick={(event) => {this.show_hide_user_menu_mobile() ; this.go_to_link(event, "/profile/watched")}}
                          style={{textDecoration: "none"}}
                          className={document.location.href.split("/")[4] != undefined && document.location.href.split("/")[4].includes("watched")   ? "menu_href_color watched_left_menu_div " : "tags_left_menu_href watched_left_menu_div"}
                        >
                      
                            <h4 className="body14">
                            <div className={document.location.href.split("/")[4] != undefined && document.location.href.split("/")[4].includes("watched") ? "watched_left_menu_icon active" : "watched_left_menu_icon"} 
                                            style={{
                                              position:"relative",
                                              float:'left',
                                              marginTop: "0px",
                                              marginRight: "8px", 
                                              // width: "15px",
                                              // height: '15px',
                                            }}
                                          ></div>Watched                  
                            </h4>
                      
                      </a>
                      </div> 
                      <div className="leftMenuElement_gray_background"
                          style={{paddingLeft: "5px",
                                  width: "122px",
                                  borderRadius: "0px !important",
                                  marginLeft: "0px",
                                  marginTop: "0px",
                          
                          }}>
                
                        <a
                              href={"/profile/favorites"}
                              onClick={(event) => {this.show_hide_user_menu_mobile() ; this.go_to_link(event, "/profile/favorites")}}
                              style={{textDecoration: "none"}}
                              className={document.location.href.split("/")[4] != undefined && document.location.href.split("/")[4].includes("favorites")  ? "menu_href_color watchlist_leftmenu" : "tags_left_menu_href watchlist_leftmenu"}
                            >
                    
                        
                        <h4 className="body14 ">
                        <div className={document.location.href.split("/")[4] != undefined && document.location.href.split("/")[4].includes("favorites")  ? "heart_left_menu active" : "heart_left_menu"}
                                        style={{
                                          position:"relative",
                                          float:'left',
                                          marginTop: "1px",
                                          marginRight: "8px", 
                                          // width: "15px",
                                          // height: '15px',
                                        }}></div>                    
                            Favorites
                            
                          </h4>
                          </a>
                      </div>
                      <div className="leftMenuElement_gray_background"
                          style={{paddingLeft: "5px",
                                  width: "122px",
                                  borderRadius: "0px !important",
                                  marginLeft: "0px",
                                  marginTop: "0px",
                          
                          }}>
                            <a
                              href={"/profile/mycomments"}
                              onClick={(event) => {this.show_hide_user_menu_mobile() ; this.go_to_link(event, "/profile/mycomments")}}
                              style={{textDecoration: "none"}}
                              className={document.location.href.split("/")[4] != undefined && document.location.href.split("/")[4].includes("mycomments")   ? "menu_href_color comments_left_menu_div " : "tags_left_menu_href comments_left_menu_div"} >
                          
                              
                              <h4 className="body14 ">

                              <div className={document.location.href.split("/")[4] != undefined && document.location.href.split("/")[4].includes("mycomments") ? "comments_left_menu_icon active" : "comments_left_menu_icon"} 
                                              style={{
                                                position:"relative",
                                                float:'left',
                                                marginTop: "2px",
                                                marginRight: "8px", 
                                                // width: "15px",
                                                // height: '15px',
                                              }}></div>                    
                                  Comments
                                  
                                </h4>
                                </a>
                          {/* <a href="/profile/mycomments/"
                            className="tags_left_menu_href"
                            onClick={(event) => {this.show_hide_user_menu_mobile() ; this.go_to_link(event, "/profile/mycomments/")}}
                            className={document.location.href.split("/")[4] != undefined && document.location.href.split("/")[4].includes("mycomments")  ? "menu_href_color" : "tags_left_menu_href"}>
                              <h4 className="body14">
                                <img 
                                  src={this.state.theme == lightTheme ?
                                    (document.location.href.split("/")[4] != undefined && document.location.href.split("/")[4].includes("mycomments") == true ? 
                                    configData.CDN_ENDPOINT+"images"+"/comment_purple.svg" :
                                    configData.CDN_ENDPOINT+"images"+"/comment_dark.svg") : 
                                    
                                    (document.location.href.split("/")[4] != undefined && document.location.href.split("/")[4].includes("mycomments") == true ? 
                                    configData.CDN_ENDPOINT+"images"+"/comment_purple_d.svg" :
                                    configData.CDN_ENDPOINT+"images"+"/comment_gray.svg")
                                    }
                                  style={{marginRight: "8px",}}
                                  width="15px"
                                  ></img>Comments
                              </h4>
                            </a> */}
                          
                      </div>

                      <div className="leftMenuElement_gray_background"
                          style={{paddingLeft: "5px",
                                  width: "122px",
                                  borderRadius: "0px !important",
                                  marginLeft: "0px",
                                  marginTop: "0px",}}>
                
                          <a
                          // href={"/logout?return_to=/videos" } 
                            //className="tags_left_menu_href"
                            href={"/logout?return_to=/videos" } 
                            onClick={(event) => 
                              {this.show_hide_user_menu_mobile() ;this.go_to_link(event,"/logout?return_to=/videos") }}
                            className={"tags_left_menu_href"}>
                              <h4 className="body14">
                                <img 
                                  src={configData.CDN_ENDPOINT+"images"+"/logout.svg"}
                                  style={{marginRight: "8px",}}
                                  width="15px"
                                  ></img>Logout
                              </h4>
                            </a>
                          
                      </div>
                      
                      {/* <div>
                        <a href={"/logout?return_to=" + window.location.pathname} 
                          onClick={(event) => 
                            {this.show_hide_user_menu_mobile() ;this.go_to_link(event,"/logout?return_to=" + window.location.pathname) }}
                          style={{color: "#02000D",textDecoration: "none"}}
                          >
                        <div className={document.location.href.split("/")[4] && document.location.href.split("/")[4] && document.location.href.split("/")[4].includes("settings")  ?
                             "leftMenuElement menu_href_color" 
                            : "leftMenuElement pornstar_channel_in_view_video_href_class"}>
                        <img src="/logout.svg"
                          style={{marginLeft: "2px", marginTop: "-2px", marginRight: "8px"}}
                          width="16px"></img>Logout</div> </a>
                      </div> */}
                      </div> 
                      
                      
                      
                    
                      {!window.location.href.match("login") ? 
                          <React.Fragment>
                            <div id="login_wrapper" 
                              style={{
                                paddingTop: this.state.mobile_or_tablet == 1 ? "24px"  : "40px",
                                position: "absolute",
                                display: "none",
                                zIndex: "9999",
                                left: "0", 
                                right: "0", 
                                marginLeft: "auto", 
                                marginRight: "auto", 
                                height: "auto" 
                                
                              }}

                              className="login_wrapper">                              
                             
                             
                              <div 
                              style={{ display: "inline",right: "38px", top: '23px',zIndex:'9',
                                  position:"absolute", height:"0px" }}
                              >
                                
                                <a href="javascript:void(0)" onClick={() => this.hide_login_popup()}
                                    id="login_close_button_div"
                                    style={{textDecoration: "none", display: "inline-block",
                                   // marginRight:"-0px",
                                    position:"absolute", float:"right"
                                    }}
                                    className="icon_close_x_div_wrapper_href_only_purple"
                                    
                                    title="Close"
                                  >
                                    
                                      <div
                                        className="icon_close_x_div_wrapper_only_purple"
                                        style={{ }}>             
                                        </div>
                                      </a>
                                    
                                </div>



                                {/* <h4 style={{
                                  position:"relative",
                                  float:"center",
                                  textAlign:"center",
                                   width: "100%",fontSize:"30px",marginTop: "0px"}}>BalaPorn</h4>
                                       */}
                                      <h4 style={{
                                  position:"relative",
                                  float:"center",
                                  textAlign:"center",zIndex:'1',
                                   width: "100%",fontSize:"30px",}}>Login</h4>
                                      
                                <h4 
                                  className="body16"
                                  style={{color: "#4C4C58", position:"relative", float:"left",textAlign:"center", width: "100%", marginLeft: "-0px"}}
                                  
                                  >Not a member? Click here to 
                                  <a className="always_purple_href"
                                    href={this.state.login_popup_redirect == undefined ? "/register" : "/register?return_to="+this.state.login_popup_redirect }
                                    id="signup_href"
                                    //onClick={(event) => {event.preventDefault(); this.props.go_to_link(event, "/register")}} 
                                    onClick={(event) => {event.preventDefault();   this.hide_login_popup() ;  
                                      this.state.login_popup_redirect == undefined ? this.props.history.push("/register") : this.props.history.push("/register?return_to="+this.state.login_popup_redirect)}} 
                                    style={{textDecoration: "none",  color: "#5724E6" }} 
                                    onMouseOver={(event) => document.getElementById("signup_href").style.color="#7649F2"}
                                    onMouseOut={(event) => document.getElementById("signup_href").style.color="#5724E6"}
                                    >
                                    &nbsp;SIGN UP</a></h4>
                

                                    <div style={{width: "100%", top:"-10px", position:"relative",display:"inline-block", border: '0px solid green'}}></div>

                                    <GoogleLogin
                                        clientId={ CLIENT_ID }
                                        buttonText='Login'
                                        onSuccess={ this.google_login }
                                        onFailure={ this.handleLoginFailure }
                                        cookiePolicy={ 'single_host_origin' }
                                        responseType='access_token'
                                        render={renderProps => (
                                          <button
                                          onClick={renderProps.onClick}
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center", // Center content horizontally
                                            width: "calc(100%)",
                                            backgroundColor: "white",
                                            height: "40px",
                                            border: "1px solid #5724E6",
                                            marginTop: '-6px',
                                            borderRadius: "4px",
                                            color: "#5724E6",
                                            padding: "0 12px", // Add some padding to separate the image and text
                                          }}
                                          className="button14"
                                        >
                                          <img
                                            src={configData.CDN_ENDPOINT + "images" + "/google.svg"}
                                            style={{ marginRight: "8px", marginTop: "0px" }}
                                          ></img>
                                          <h4 className="button14" style={{  marginTop: "9px" }}>LOGIN WITH GOOGLE</h4>
                                        </button>
                                        )}
                                      
                                      />
                                  
                            <form className="form-signin" onSubmit={(event) => this.login(event,this.state.login_popup_redirect)}>   
                              <div 
                                style={{"height":"28px"}}></div>
                                      
                                      <div
                                style={{
                                  position:"relative",
                                  float:"left",
                                  width: "calc(50% - 24px)",
                                  borderTop: "1px solid #E0E0E0",
                                  height: "0px",
                                  marginTop: "12px",
                                  marginRight: "17px"
                                }}>

                                </div>
                                or
                                <div
                                style={{
                                  position:"relative",
                                  float:"right",
                                  width: "calc(50% - 24px)",
                                  borderTop: "1px solid #E0E0E0",
                                  height: "0px",
                                  marginTop: "12px",
                                  marginLeft: "12px"
                                }}></div>
                               <div 
                                  style={{height:"12px"}}></div>

                                
                                
                                <label 
                                  for="inputEmail" 
                                  className="sr-only"
                                  
                                
                                >
                                  Email address
                                </label>
                                <span>
                                  <FontAwesomeIcon 
                                    className="inputIcon" 
                                    icon="envelope" 
                                    size="1x"
                                    style={{color: this.state.email_focus == 1 ? "#5724E6" :""}} />
                                  <input
                                    type="email"
                                    id="inputEmail"
                                    className="form-control inputElement"
                                    placeholder="Email address"
                                    style={{outline: "none", boxShadow: "none", border: "1px solid #E0E0E0" , position:"relative"  }}
                                    required

                                    onFocus={() => {this.setState({email_focus: 1})}}
                                    onBlur = {() => {this.setState({email_focus: 0})}}


                                    autoFocus
                                  />
                                </span>
                                <span
                                  href="javascript:void(0)"
                                  onMouseDown={() =>
                                    //this.setState({showPassword1clearText : 1 })
                                    this.login_change_showPassword1clearText(1)
                                  }
                                  onMouseUp={() =>
                                    //this.setState({showPassword1clearText : 0 })
                                    this.login_change_showPassword1clearText(0)
                                  }
                                  onMouseLeave={() =>
                                    //this.setState({showPassword1clearText : 0 })
                                    this.login_change_showPassword1clearText(0)
                                  }
                                >
                                  <FontAwesomeIcon className="inputIconRight" icon={this.state.login_showPassword1clearText == 0 ? "eye-slash" : "eye"} size="1x" />
                                </span>
                                <label for="inputPassword" className="sr-only">
                                  Password
                                </label>
                                <FontAwesomeIcon 
                                  className="inputIcon" 
                                  icon="lock" 
                                  size="1x" 
                                  style={{color: this.state.password_focus == 1 ? "#5724E6" :""}} />
                                <input
                                  type={this.state.login_showPassword1clearText == 0 ? "password": "text"}
                                  id="inputPassword"
                                  style={{outline: "none", boxShadow: "none", border: "1px solid #E0E0E0", position:"relative"   }}
                                  className="form-control inputElement"
                                  placeholder="Password"  
                                  onFocus={() => {this.setState({password_focus: 1})}}
                                  onBlur = {() => {this.setState({password_focus: 0})}}                  
                                  required
                                />
                              
                                <br></br>                                
                                <div 
                                id="rememberme_checkbox"
                                className="checkbox mb-0"
                                style={{textAlign: "left",position:"relative",display: "inline",top:"8px"}}>
                                  <label>
                                    <input
                                      id="rememberme_checkbox"
                                      checked={this.state.login_rememberme}
                                      onChange={(event, value) =>
                                        this.setState({
                                          login_rememberme: this.state.login_rememberme == 1 ? 0 : 1,
                                        })
                                        //alert("ppanagia")
                                      }
                                      type="checkbox"
                                    ></input>{" "}
                                    <h4 className="body16" style={{display: "inline",color:"#02000D",}}>Keep me logged in</h4>
                                  </label>
                                </div>{" "}
                                
                                  
                                <div
                                  className="alert alert-danger"
                                  role="alert"
                                  id="login_error_message"
                                  key="error_message"
                                  style={{
                                    display: "none",
                                    marginTop: "1px",
                                    //marginBottom: "8px",
                                    width: "100%",     
                                    position:"relative",
                                    marginBottom:"-23px",
                                  }}
                                >
                        { this.state.login_error_message }
                      </div>


                      <button  
                        //onClick={this.login}
                        className="btn btn-lg btn-primary btn-block button14" 
                        type="submit"
                        id="login_button"
                        style={{marginTop: "30px", position:"relative", backgroundColor:"#5724E6", outline: "none",  boxShadow:"none",
                        cursor: this.state.login_showSpinner == 1 ? "not-allowed" : ""}}
                        onMouseOver={(event) => 
                          {
                            document.getElementById("login_button").style.backgroundColor="#7649F2";
                            document.getElementById("login_button").style.border="1px solid #7649F2"
                          }}
                        onMouseOut={(event) => {
                          document.getElementById("login_button").style.backgroundColor="#5724E6";
                          document.getElementById("login_button").style.border="1px solid #5724E6"
                        }}
                        onMouseDown={(event) => {
                          document.getElementById("login_button").style.backgroundColor="#9670FF";
                          document.getElementById("login_button").style.border="1px solid #9670FF"
                        }}
                        
                        
                        onMouseUp={(event) => {
                          document.getElementById("login_button").style.backgroundColor="#7649F2";
                          document.getElementById("login_button").style.border="1px solid #7649F2"
                      
                        }}
                        disabled={this.state.login_showSpinner == 1 ? true : false}
                        >                       
                        <FontAwesomeIcon
                          style={{
                            visibility: this.state.login_showSpinner == 0 ? "hidden" : "visible",
                            marginLeft: "-31px"
                            // marginRight: "15px"
                          }}
                          icon="circle-notch"
                        spin
                        />&nbsp;

                          LOG IN
                      </button>
                      <div  style={{textAlign: "center"}}>
                      
                        <label 
                          style={{marginTop: "17px", paddingBottom: this.state.mobile_or_tablet == 1 ? "10px"  : "18px",
                          position:"relative"}}
                          className="button14"
                          >
                        
                          <a 
                            href="/reset_password"
                            id="forgot_text"
                            className="always_purple_href"
                            //href="javascript:void(0)" 
                            //to="/reset_password" //&& this.props.history.push("/reset_password")
                            onClick={(event) => {event.preventDefault() ; this.hide_login_popup(); this.props.history.push("/reset_password")} }
                            style={{textDecoration:"none",color: "#5724E6"}}
                            // onMouseOver={(event) => document.getElementById("forgot_text").style.color="#7649F2"}
                            // onMouseOut={(event) => document.getElementById("forgot_text").style.color="#5724E6"}
                            >
                              Forgot password?
                          </a>
                        </label>
                      </div>{" "}

                      
                      </form>
          
                  </div>
                  
                </React.Fragment>

            : "" }

            {/* search start */}
        <div
                id="search_items"
                className="scrollbar"
                style={{
                    width: "306px", 
                    position: "fixed",
                    display: "none", 
                    height: 'auto',
                    maxHeight: "80%",
                    overflowY: "auto",
                    top: "60px",
                    zIndex: '9999',
                    backgroundColor:"gray",
                    borderRadius: "0 0 4px 4px",
                    transition: "all 0.1s ease",
                    border: this.state.search_expanded == 0 ? "0px solid pink " : "",
                    boxShadow: "0px 4px 14px rgba(2, 0, 13, 0.20)",
                  }} 
                                       
                    >
                        
                    {this.state.search_pornstars_loadingdots == 0 && this.state.channel_pornstars_loadingdots == 0 ?
                    <div style={{marginTop: "0px"}}>

                      {/* {this.state.tags.length == 0 && this.state.pornstars_like.length == 0 && this.state.channels_like.length == 0
                        ? <div className="search_items_title" >Not found</div> : ""
                      } */}
                        {function() {
                          //if(){
                            try{
                              if (this.state.tags.length === 0 && this.state.pornstars_like.length === 0 && this.state.channels_like.length === 0 && this.state.search_pornstars_loadingdots == 0 && this.state.channel_pornstars_loadingdots == 0 ) {
                               
                                // document.getElementById("search_items").style.display = "none";
                                // this.setState({ show_more_tags: 0 });
                                // this.set_search_popup_showing(0);
                                if(this.state.search_expanded == 1){
                                    if(document.activeElement == document.getElementById("search_input")){ 
                                      //if search input is focused
                                      document.getElementById("search_items").style.display = "none";
                                      this.setState({ show_more_tags: 0 });
                                      this.set_search_popup_showing(0);

                                      
                                      document.getElementById("search_input").style.borderRadius = "4px";
                                      document.getElementById("search_input").style.borderRadius = "4px 0 0 4px";
                                      
                                      document.getElementById("search_input").style.borderBottom = 
                                          this.props.theme == lightTheme ?  "1px solid #5724E6" : "1px solid #9F7DFF"


                                      document.getElementById("search_input_search_button").style.borderRadius = "0 4px 4px 0";
                                  }
                                }else{
                                  document.getElementById("search_items").style.display = "none";
                                  this.setState({ show_more_tags: 0 });
                                  this.set_search_popup_showing(0);

                                  document.getElementById("mobile_search_input_div").style.borderRadius = this.state.mobile_or_tablet == 0 ? '4px' :"0px";
                                  document.getElementById("mobile_search_input").style.borderRadius = "4px";
                                //  document.getElementById("mobile_search_input_search_button").style.borderRadius = "0 4px 4px 0";

                                  // document.getElementById("mobile_search_input").style.borderBottom = 
                                  //     this.props.theme == lightTheme ?  "1px solid #5724E6" : "1px solid #9F7DFF"


                                }
                              
                                } else {
                                  return null; // or an alternative JSX element
                                }
                            }catch(e){}
                         // }
                          }.call(this)}


                        {this.state.tags.length > 0 ? 
                          <div className="dummy_search_top_border"></div> : "" }
                        
                        { this.state.tags.slice(0,5).map((tag,index) => (
                            <div>
                              
                            
                            
                            <a href="javascript:void(0)"
                            id={"search_item_href_el"+index} 
                            onClick={(event) => {
                              event.preventDefault()
                              this.set_search_popup_showing(0);
                               if(this.state.search_expanded == 1){
                                 document.getElementById("search_input").value = "";    
                               }
                               else{
                                 document.getElementById("mobile_search_input").value = "";   
                                 this.set_mobile_search_input_popup_showing(); 
                               }
                              this.make_search_inactive();
                              this.setState({mobile_search_input_popup_showing: 0,show_overlay:0,  real_flag_for_mobile_search_input_popup_showing:  0, overlay_full_except_menu_and_mobile_search_value: 0})
                              document.getElementById("menu_inner").style.borderBottom= ""
                              document.body.style.overflow = 'visible';
                              this.go_to_link(event, "/tag/"+tag.id)

                              }
                            } 
                              style={{textDecoration: 'none'}}
                              className="search_link_href_item_classname">
                              <div
                                id={"search_item"+index}
                                className={this.state.search_item_selected_active_element == index ?
                                  "search_individual_item_wrapper active" : "search_individual_item_wrapper"}
                                onMouseOver={() => {this.setState({search_item_selected_active_element: index})}}
                                  style={{
                                      display: index > 5 ? this.state.show_more_tags == 1 ? "block" : "none" :"block",
                                      lineHeight: navigator.userAgent.indexOf("Firefox") != -1 ? "16.5px":"17px"
                                  }}

                              >
                                
                                <img src={
                                    this.state.theme == lightTheme? 
                                          configData.CDN_ENDPOINT+"images"+"/tags_dark.svg" 
                                    :
                                    configData.CDN_ENDPOINT+"images"+"/tags_gray.svg" 
                                    
                                    }
                                    style={{marginTop: "0px", marginRight: "10px", position:"relative",float:"left"}}
                                    width="16" ></img>
                                    <div style={{position:"relative",float:"left"}}>
                                      {tag.tag.toLowerCase().includes(this.state.search_string.toLowerCase()) ? (
                                          <div dangerouslySetInnerHTML={{
                                            __html: (() => {
                                              const re = new RegExp(this.state.search_string, 'gi');
                                              const highlighted = tag.tag.replace(re, `<mark class="custom-highlight">$&</mark>`);
                                              return highlighted;
                                            })()
                                          }} />
                                        ) : (
                                          <div>{tag.tag}</div>
                                        )} 
                                  </div>
                              {tag.has_photo != null ? (
                                  
                                  <img
                                  src={configData.CDN_ENDPOINT + "flags/" + tag.has_photo.replace("flags/", "") + ".svg"}
                                  width="16"
                                  className="flag_wrapper_shadow_class"
                                  style={{marginLeft: "8px", marginTop: "2px", position:"relative", float:"left"}}
                                  />
                              ) : (
                                  ""
                              )}
                              </div>
                            </a>
                            {index == 5 ? 
                                <div
                                    style={{
                                        display: this.state.show_more_tags == 0 ? "block" : "none"
                                    }} 

                                    >
                                    <a href="javascript:void(0);" 
                                        onClick={(event) => {event.preventDefault();this.setState({show_more_tags: 1})}}
                                        >Show more tags</a>
                                    
                                    </div> : ""}                            
                            </div>
                        ))}
                        {this.state.tags.length > 5  ?
                            <div
                            style={{
                                display: this.state.show_more_tags == 1 ? "block" : "none"
                            }} 

                            >
                            <a href="javascript:void(0);" 
                                onClick={() => this.setState({show_more_tags: 0})}
                                >Show less tags</a>
                            
                            </div>
                            : ""
                        }
                        {this.state.pornstars_like.length > 0 && this.state.search_pornstars_loadingdots == 0 && this.state.channel_pornstars_loadingdots == 0 ? 
                        // <div style={{width: "100%", display: "inline", position:"relative", height: '20px'}}>
                          
                            <div className="search_items_title"
                            style={{}}>
                          
                              PORNSTARS
                              {/* <div style={{position:"relative", float:"right"}} className="purple_href">
                                <a  href="/pornstars" 
                                    className="purple_href" 
                                    style={{textDecoration: 'none'}}
                                    onClick={(event) => {
                                      event.preventDefault();
                                      this.set_search_popup_showing(0);
                                      this.setState({mobile_search_input_popup_showing: 0})
                                      
                                      this.go_to_link(event,'/pornstars')}}
                                    >
                                  VIEW ALL &nbsp;
                                  <FontAwesomeIcon  icon={"arrow-right"} 
                                  size="1x" />
                                </a>
                              </div> */}
                           
                          </div> 
                          
                        : 
                        
                        ""}
                        {this.state.pornstars_like.slice(0, 5).map((pornstar,index) => (
                                <a href="javascript:void(0)" 
                                id={"search_item_href_el"+(this.state.tags.length + index)} 
                                onClick={(event) => {
                                  event.preventDefault()
                                  this.set_search_popup_showing(0);
                                  this.setState({mobile_search_input_popup_showing: 0,show_overlay:0, real_flag_for_mobile_search_input_popup_showing: 0, overlay_full_except_menu_and_mobile_search_value: 0})
                                  document.getElementById("menu_inner").style.borderBottom= ""
                                  if(this.state.search_expanded == 1){
                                    document.getElementById("search_input").value = "";    
                                  }
                                  else{
                                    document.getElementById("mobile_search_input").value = "";    
                                    this.set_mobile_search_input_popup_showing();
                                  }
                                  document.body.style.overflow = 'visible';
                                  this.make_search_inactive();
                                  this.go_to_link(event, "/pornstar/"+pornstar.id+"/"+title_to_url(pornstar.pornstar))}
                                  
                                } 
                                  style={{textDecoration: 'none'}}
                                  className="search_link_href_item_classname">
                                  <div
                                    id={"search_item"+(this.state.tags.length + index)}
                                    className={this.state.search_item_selected_active_element == (this.state.tags.length + index) ?
                                      "search_individual_item_wrapper active" : "search_individual_item_wrapper"}
                                    onMouseOver={() => {this.setState({search_item_selected_active_element: (this.state.tags.length + index)})}}
                                    style={{lineHeight: navigator.userAgent.indexOf("Firefox") != -1 ? "16.5px":"17px"}}
                                  >
                                    {/* <img src={
                                    
                                    configData.CDN_ENDPOINT+"prod/pornstar/"+pornstar.id+"/"+pornstar.photoUrl 
                                    
                                    }
                                    
                                    style={{marginTop: "-3px", marginRight: "10px",borderRadius: "50%"}}
                                    width="16" ></img> */}
                                    <img src={
                                    this.state.theme == lightTheme? 
                                         configData.CDN_ENDPOINT+"images"+"/pornstars_dark.svg" 
                                    :
                                          configData.CDN_ENDPOINT+"images"+"/pornstars_gray.svg" 
                                    
                                    }
                                    style={{marginTop: "0px", marginRight: "8px", position: "relative", float: "left"}}
                                    width="16" ></img>
                                    {pornstar.pornstar.toLowerCase().includes(this.state.search_string.toLowerCase()) ? (
                                        <div dangerouslySetInnerHTML={{
                                          __html: (() => {
                                            const re = new RegExp(this.state.search_string, 'gi');
                                            const highlighted = pornstar.pornstar.replace(re, `<mark class="custom-highlight">$&</mark>`);
                                            return highlighted;
                                          })()
                                        }} />
                                      ) : (
                                        <div>{pornstar.pornstar}</div>
                                      )}                                
                                   <h4 style={{position:"relative",float:"right", marginTop: "-16px"}}
                                      className="body14">{pornstar.videos_count} video{pornstar.videos_count > 1 ? "s" : ""}</h4>
                                  </div>
                                </a>
                              ))}

                      {this.state.pornstars_like.length > 5  ?
                            <div
                            className="search_individual_item_wrapper purple_href"
                            style={{lineHeight: navigator.userAgent.indexOf("Firefox") != -1 ? "16.5px":"17px"}}
                            >
                            <a href={"/pornstars/search/"+this.state.search_string}
                               className="purple_href"
                               style={{textDecoration:"none"}}
                                onClick={(event) => {
                                  event.preventDefault();
                                  this.set_search_popup_showing(0)
                                  
                                 // this.setState({mobile_search_input_popup_showing: 0,show_overlay:0, real_flag_for_mobile_search_input_popup_showing: 0,  overlay_full_except_menu_and_mobile_search_value: 0})
                                  document.getElementById("menu_inner").style.borderBottom= ""
                                  if(this.state.search_expanded == 1){
                                    document.getElementById("search_input").value = "";    
                                  }
                                  else{
                                    document.getElementById("mobile_search_input").value = "";  
                                    this.set_mobile_search_input_popup_showing()  
                                  }
                                  
                                  document.body.style.overflow = 'visible';
                                  
                                  this.make_search_inactive();
                                  this.go_to_link(event,"/pornstars/search/"+this.state.search_string)}}
                                >Show more pornstars&nbsp;<FontAwesomeIcon  icon={"arrow-right"} 
                                size="1x" /></a>
                            
                            </div>
                            : ""
                        }
                        
                        {this.state.channels_like.length > 0 && this.state.search_pornstars_loadingdots == 0 && this.state.channel_pornstars_loadingdots == 0 ? 
                            <div className="search_items_title"
                            style={{}}>
                          
                              CHANNELS
                              {/* <div style={{position:"relative", float:"right"}} className="purple_href">
                                <a  href="/channels" 
                                    className="purple_href" 
                                    style={{textDecoration: 'none'}}
                                    onClick={(event) => {
                                      event.preventDefault();
                                      this.setState({mobile_search_input_popup_showing: 0})
                                      this.set_search_popup_showing(0);
                                      this.go_to_link(event,'/channels')}}
                                    >
                                  VIEW ALL &nbsp;
                                  <FontAwesomeIcon  icon={"arrow-right"} 
                                  size="1x" />
                                </a>
                              </div> */}
                            
                          </div> 
                          : 
                        
                        ""}
                        {this.state.channels_like.slice(0, 5).map((channel,index) => (
                          <div>
                                <a href="javascript:void(0)" 
                                id={"search_item_href_el"+(this.state.tags.length + 
                                  (this.state.pornstars_like.length > 5 ? 5 : this.state.pornstars_like.length)
                                    + index)}                                
                                onClick={(event) => {
                                  event.preventDefault()
                                  this.set_search_popup_showing(0);
                                  this.setState({mobile_search_input_popup_showing: 0, show_overlay: 0, real_flag_for_mobile_search_input_popup_showing: 0, overlay_full_except_menu_and_mobile_search_value: 0})
                                  document.getElementById("menu_inner").style.borderBottom= ""
                                  document.body.style.overflow = 'visible';
                                  if(this.state.search_expanded == 1){
                                    document.getElementById("search_input").value = "";    
                                  }
                                  else{
                                    document.getElementById("mobile_search_input").value = "";  
                                    this.set_mobile_search_input_popup_showing();
                                      
                                  }
                                  this.make_search_inactive();
                                  this.go_to_link(event, "/channel/"+channel.id+"/"+title_to_url(channel.channel))}
                                } 
                                  
                                  className="search_link_href_item_classname"
                                  >
                                  <div
                                    id={"search_item"+(this.state.tags.length + (this.state.pornstars_like.length > 5 ? 5 : this.state.pornstars_like.length) + index)}
                                    className={this.state.search_item_selected_active_element == (this.state.tags.length + (this.state.pornstars_like.length > 5 ? 5 : this.state.pornstars_like.length) + index) ?
                                      "search_individual_item_wrapper active" : "search_individual_item_wrapper"}
                                    onMouseOver={() => {this.setState({search_item_selected_active_element: (this.state.tags.length + (this.state.pornstars_like.length > 5 ? 5 : this.state.pornstars_like.length) + index)})}}
                                    style={{lineHeight: navigator.userAgent.indexOf("Firefox") != -1 ? "16.5px":"17px"}}
                                 >
                                    {/* <img src={
                                    
                                    configData.CDN_ENDPOINT+"prod/channel/"+channel.id+"/"+channel.photoUrl 
                                    
                                    }
                                    
                                    style={{marginTop: "-3px", marginRight: "10px",borderRadius: "50%"}}
                                    width="16" ></img> */}
                                    <img src={
                                    this.state.theme == lightTheme? 
                                         configData.CDN_ENDPOINT+"images"+"/channels_dark.svg" 
                                    :
                                          configData.CDN_ENDPOINT+"images"+"/channels_gray.svg" 
                                    
                                    }
                                    style={{marginTop: "-1px", marginRight: "8px", position:"relative",float:"left"}}
                                    width="16" ></img>


                                      {channel.channel.toLowerCase().includes(this.state.search_string.toLowerCase()) ? (
                                        <div dangerouslySetInnerHTML={{
                                          __html: (() => {
                                            const re = new RegExp(this.state.search_string, 'gi');
                                            const highlighted = channel.channel.replace(re, `<mark class="custom-highlight">$&</mark>`);
                                            return highlighted;
                                          })()
                                        }} />
                                      ) : (
                                        <div>{channel.channel}</div>
                                      )}                                 
                                      <h4 style={{position:"relative",float:"right", marginTop: "-16px"}}
                                      className="body14">{channel.videos_count} video{channel.videos_count > 1 ? "s" : ""}
                                      </h4>
                                  </div>
                              </a>
                             
                          </div>
                              
                            ))}
                   {this.state.channels_like.length > 5  ?
                              <div
                              className="search_individual_item_wrapper purple_href" 
                              style={{lineHeight: navigator.userAgent.indexOf("Firefox") != -1 ? "16.5px":"17px"}}       

                              >
                              <a href={"/channels/search/"+this.state.search_string}
                                 className="purple_href"
                                  style={{textDecoration: "none"}}
                                  onClick={(event) => {                                    
                                    event.preventDefault();
                                    this.set_search_popup_showing(0);
                                    this.setState({mobile_search_input_popup_showing: 0,show_overlay:0, real_flag_for_mobile_search_input_popup_showing: 0, overlay_full_except_menu_and_mobile_search_value: 0})
                                    document.getElementById("menu_inner").style.borderBottom= ""
                                    document.body.style.overflow = 'visible';
                                    if(this.state.search_expanded == 1){
                                      document.getElementById("search_input").value = "";    
                                    }
                                    else{
                                      document.getElementById("mobile_search_input").value = "";   
                                      this.set_mobile_search_input_popup_showing(); 
                                    }
                                    
                                    this.go_to_link(event,"/channels/search/"+this.state.search_string)}}
                                  >Show more channels &nbsp;<FontAwesomeIcon  icon={"arrow-right"} 
                                  size="1x" /></a>
                              
                              </div>
                              : ""
                          }
                  
                  </div> : <div className="search_items_title" >Loading...</div>
                  
                       }
            </div>

          {/* search end */}

    
       
       {/* lightgray_lightblack was background in order to get the original colors - they look better */}
      <div id="pornstars_per_country_wrapper"  className="country_per_pornstar_wrapper background"
                              style={{
                                paddingTop:"28px",
                                position: "absolute",
                                //display: this.state.pornstar_countries_popup_showing == 0 ? "none" : "inline-block",
                                display: 'none',
                                zIndex: "99",
                                left: "0", 
                                right: "0", 
                                top: "55px",
                                marginLeft: "auto", 
                                marginRight: "auto", 
                                height: "500px",
                                maxHeight: "80%",
                                
                                borderRadius: "8px",
                                width: "50px !important",
                                paddingRight:"20px",
                                paddingLeft:"20px",
                              }}
                              >

                                  <h4 style={{
                                    position:"absolute",
                                    display:'inline',
                                    left:"21px",
                                    top: "22px"}}
                                  
                                  className="custom-h1 text_color">
                                      Search pornstars
                                    </h4>
                                <FontAwesomeIcon icon="search" size="1x" 
                                    style={{
                                      position:"absolute",  
                                      top: "68px",
                                      left: "29px",
                                      // right: "0px",
                                      zIndex: "9999",
                                      marginTop: "10.5px",
                                      color: this.state.pornstar_input_focused == 0 && navigator.userAgent.indexOf("Firefox") == -1 ? "8B8B8F" : 
                                          this.state.theme == lightTheme ? "#5724E6" : "#9F7DFF"

                                    }}
                                  />
                                <input
                                    className="form-control pornstar_channel_input"
                                    type="search"
                                    dark={this.state.theme == lightTheme ? "false" : "true"}
                                    style={{
                                      marginLeft:"-1px",
                                      borderRadius: "4px",
                                      // width: this.props.pornstar_countries_popup_showing == 1 ? 
                                      // document.getElementById('rightSideNoLeftMenu').offsetWidth+"px" 
                                      // : "calc(100% - 91px)", 
                                      //width: 'calc(document.getElementById("rightSideNoLeftMenu").offsetWidth-91+"px")',
                                      position:"relative",
                                      borderRadius: "4px",
                                      float:"left",
                                      marginTop: "38px",
                                      boxShadow: "none",lineHeight: '40px',
                                      paddingTop: "8.5px"
                                      
                                      }}
                                    onFocus={() => {this.setState({pornstar_input_focused: 1}) }}
                                    onBlur={() => { this.setState({pornstar_input_focused: 0})}}
                                    placeholder="FILTER COUNTRIES"
                                    id="pornstar-countries-search-input" //this.handleInput(value)
                                    onChange={(event, value) => this.handlePornstarPerCountrySearch(event,1)} //</div>this.onTodoChange(e.target.value)}
                                    // onKeyPress={event => {
                                    //   if (event.key == 'Enter' && this.state.one_pornstar_found == 1) {
                                    //     this.props.history.push("/pornstar/"+this.state.pornstars_like[0].id+"/"+title_to_url(this.state.pornstars_like[0].pornstar))
                                    //   }
                                    
                                    // }}
                                  ></input>
                                  <br></br><br></br>
                              {/* <h2 className="pornstar_channel_in_view_video_href_class">
                                Select a country
                                
                                </h2> */}
                              <h4 
                                  className="body16"
                                  style={{
                                    color: "#4C4C58",
                                    position: "absolute",
                                    top: "5px",
                                    right: "25px"
                                  }}

                                  
                                  > <a href="javascript:void(0)" onClick={() => this.hide_pornstar_countries()}
                                  style={{textDecoration: "none", display: "inline-block",marginTop: "18px",
                                  // marginRight:"-0px",
                                  right: "-2px",
                                   position:"absolute", float:"right"
                                   }}
                                   className="icon_close_x_div_wrapper_href_only_purple"
                                   
                                   title="Close"
                                 >
                                   <div
                                     className="icon_close_x_div_wrapper"
                                     style={{   backgroundColor: 'purple !important'}}>             
                                     </div>
                                   </a>
                                    
                                    
                                    </h4>

                
                {/* {this.state.pornstar_countries.map((country) => {
                        // var country_object= flags.filter(
                        //   (objFromA) => objFromA.url == country.country
                        // )[0] || ""
                        
                        <div>
                          {country}
                        {flags.filter(
                          (objFromA) => objFromA.url == country.country
                        )[0]+country.country}
                        </div>
                    
                    })} */}
                    <div
                        id="pornstar_countries_div"
                        style={{position:"relative", 
                                //height: "400px", 
                                height: "calc(100% - 100px)",
                                overflowY:"auto",
                                overflowX: "hidden",
                                //maxHeight: "calc(100% - 5px)",
                                marginTop: "35px",
                                marginLeft: "1px"}}
                        className="scrollbar">
                      { this.state.pornstar_countries_showDotsLoading == 1  ?
                          <React.Fragment>
                             
                                <SkeletonTheme 
                                color={this.state.theme == lightTheme ? 
                                  configData.lightTheme.color : configData.darkTheme.color }
                                //color= {this.state.theme  ? 'red' : 'green'} 

                                highlightColor={this.state.theme == lightTheme? 
                                  configData.lightTheme.highlightColor : configData.darkTheme.highlightColor }>
                                <Skeleton variant="text" animation="wave" width={"240px"} height="21px"
                                  //color={this.state.theme == lightTheme ? "gray" : "black" }                      
                                  //highlightColor={this.state.theme == lightTheme ? "#EBEBEB" : "#3C3C45" }
                                  
                                />
                                </SkeletonTheme>
                                                         
                                <SkeletonTheme 
                                color={this.state.theme == lightTheme ? 
                                  configData.lightTheme.color : configData.darkTheme.color }
                                //color= {this.state.theme  ? 'red' : 'green'} 

                                highlightColor={this.state.theme == lightTheme ? 
                                  configData.lightTheme.highlightColor : configData.darkTheme.highlightColor }>
                                <Skeleton variant="text" animation="wave" width={"240px"} height="21px"
                                  //color={this.state.theme == lightTheme ? "gray" : "black" }                      
                                  //highlightColor={this.state.theme == lightTheme ? "#EBEBEB" : "#3C3C45" }
                                  
                                />
                                </SkeletonTheme>
                                <SkeletonTheme 
                                color={this.state.theme == lightTheme? 
                                  configData.lightTheme.color : configData.darkTheme.color }
                                //color= {this.state.theme  ? 'red' : 'green'} 

                                highlightColor={this.state.theme == lightTheme? 
                                  configData.lightTheme.highlightColor : configData.darkTheme.highlightColor }>
                                <Skeleton variant="text" animation="wave" width={"240px"} height="21px"
                                  //color={this.state.theme == lightTheme ? "gray" : "black" }                      
                                  //highlightColor={this.state.theme == lightTheme ? "#EBEBEB" : "#3C3C45" }
                                  
                                />
                                </SkeletonTheme>
                                <SkeletonTheme 
                                color={this.state.theme == lightTheme? 
                                  configData.lightTheme.color : configData.darkTheme.color }
                                //color= {this.state.theme  ? 'red' : 'green'} 

                                highlightColor={this.state.theme == lightTheme? 
                                  configData.lightTheme.highlightColor : configData.darkTheme.highlightColor }>
                                <Skeleton variant="text" animation="wave" width={"240px"} height="21px"
                                  //color={this.state.theme == lightTheme ? "gray" : "black" }                      
                                  //highlightColor={this.state.theme == lightTheme ? "#EBEBEB" : "#3C3C45" }
                                  
                                />
                                </SkeletonTheme>
                                <SkeletonTheme 
                                color={this.state.theme == lightTheme? 
                                  configData.lightTheme.color : configData.darkTheme.color }
                                //color= {this.state.theme  ? 'red' : 'green'} 

                                highlightColor={this.state.theme == lightTheme? 
                                  configData.lightTheme.highlightColor : configData.darkTheme.highlightColor }>
                                <Skeleton variant="text" animation="wave" width={"240px"} height="21px"
                                  //color={this.state.theme == lightTheme ? "gray" : "black" }                      
                                  //highlightColor={this.state.theme == lightTheme ? "#EBEBEB" : "#3C3C45" }
                                  
                                />
                                </SkeletonTheme>
                                <SkeletonTheme 
                                color={this.state.theme == lightTheme? 
                                  configData.lightTheme.color : configData.darkTheme.color }
                                //color= {this.state.theme  ? 'red' : 'green'} 

                                highlightColor={this.state.theme == lightTheme ? 
                                  configData.lightTheme.highlightColor : configData.darkTheme.highlightColor }>
                                <Skeleton variant="text" animation="wave" width={"240px"} height="21px"
                                  //color={this.state.theme == lightTheme ? "gray" : "black" }                      
                                  //highlightColor={this.state.theme == lightTheme ? "#EBEBEB" : "#3C3C45" }
                                  
                                />
                                </SkeletonTheme>
                                <SkeletonTheme 
                                color={this.state.theme == lightTheme ? 
                                  configData.lightTheme.color : configData.darkTheme.color }
                                //color= {this.state.theme  ? 'red' : 'green'} 

                                highlightColor={this.state.theme == lightTheme ? 
                                  configData.lightTheme.highlightColor : configData.darkTheme.highlightColor }>
                                <Skeleton variant="text" animation="wave" width={"240px"} height="21px"
                                  //color={this.state.theme == lightTheme ? "gray" : "black" }                      
                                  //highlightColor={this.state.theme == lightTheme ? "#EBEBEB" : "#3C3C45" }
                                  
                                />
                                </SkeletonTheme>
                                <SkeletonTheme 
                                color={this.state.theme == lightTheme ? 
                                  configData.lightTheme.color : configData.darkTheme.color }
                                //color= {this.state.theme  ? 'red' : 'green'} 

                                highlightColor={this.state.theme == lightTheme ? 
                                  configData.lightTheme.highlightColor : configData.darkTheme.highlightColor }>
                                <Skeleton variant="text" animation="wave" width={"240px"} height="21px"
                                  //color={this.state.theme == lightTheme ? "gray" : "black" }                      
                                  //highlightColor={this.state.theme == lightTheme ? "#EBEBEB" : "#3C3C45" }
                                  
                                />
                                </SkeletonTheme>
                                <SkeletonTheme 
                                color={this.state.theme == lightTheme ? 
                                  configData.lightTheme.color : configData.darkTheme.color }
                                //color= {this.state.theme  ? 'red' : 'green'} 

                                highlightColor={this.state.theme == lightTheme ? 
                                  configData.lightTheme.highlightColor : configData.darkTheme.highlightColor }>
                                <Skeleton variant="text" animation="wave" width={"240px"} height="21px"
                                  //color={this.state.theme == lightTheme ? "gray" : "black" }                      
                                  //highlightColor={this.state.theme == lightTheme ? "#EBEBEB" : "#3C3C45" }
                                  
                                />
                                </SkeletonTheme>
                                <SkeletonTheme 
                                color={this.state.theme == lightTheme ? 
                                  configData.lightTheme.color : configData.darkTheme.color }
                                //color= {this.state.theme  ? 'red' : 'green'} 

                                highlightColor={this.state.theme == lightTheme ? 
                                  configData.lightTheme.highlightColor : configData.darkTheme.highlightColor }>
                                <Skeleton variant="text" animation="wave" width={"240px"} height="21px"
                                  //color={this.state.theme == lightTheme ? "gray" : "black" }                      
                                  //highlightColor={this.state.theme == lightTheme ? "#EBEBEB" : "#3C3C45" }
                                  
                                />
                                </SkeletonTheme>
                                <SkeletonTheme 
                                color={this.state.theme == lightTheme ? 
                                  configData.lightTheme.color : configData.darkTheme.color }
                                //color= {this.state.theme  ? 'red' : 'green'} 

                                highlightColor={this.state.theme == lightTheme ? 
                                  configData.lightTheme.highlightColor : configData.darkTheme.highlightColor }>
                                <Skeleton variant="text" animation="wave" width={"240px"} height="21px"
                                  //color={this.state.theme == lightTheme ? "gray" : "black" }                      
                                  //highlightColor={this.state.theme == lightTheme ? "#EBEBEB" : "#3C3C45" }
                                  
                                />
                                </SkeletonTheme>
                                <SkeletonTheme 
                                color={this.state.theme == lightTheme ? 
                                  configData.lightTheme.color : configData.darkTheme.color }
                                //color= {this.state.theme  ? 'red' : 'green'} 

                                highlightColor={this.state.theme == lightTheme? 
                                  configData.lightTheme.highlightColor : configData.darkTheme.highlightColor }>
                                <Skeleton variant="text" animation="wave" width={"240px"} height="21px"
                                  //color={this.state.theme == lightTheme ? "gray" : "black" }                      
                                  //highlightColor={this.state.theme == lightTheme ? "#EBEBEB" : "#3C3C45" }
                                  
                                />
                                </SkeletonTheme>
                                <SkeletonTheme 
                                color={this.state.theme == lightTheme? 
                                  configData.lightTheme.color : configData.darkTheme.color }
                                //color= {this.state.theme  ? 'red' : 'green'} 

                                highlightColor={this.state.theme == lightTheme? 
                                  configData.lightTheme.highlightColor : configData.darkTheme.highlightColor }>
                                <Skeleton variant="text" animation="wave" width={"240px"} height="21px"
                                  //color={this.state.theme == lightTheme ? "gray" : "black" }                      
                                  //highlightColor={this.state.theme == lightTheme ? "#EBEBEB" : "#3C3C45" }
                                  
                                />
                                </SkeletonTheme>
                                <SkeletonTheme 
                                color={this.state.theme == lightTheme? 
                                  configData.lightTheme.color : configData.darkTheme.color }
                                //color= {this.state.theme  ? 'red' : 'green'} 

                                highlightColor={this.state.theme == lightTheme? 
                                  configData.lightTheme.highlightColor : configData.darkTheme.highlightColor }>
                                <Skeleton variant="text" animation="wave" width={"240px"} height="21px"
                                  //color={this.state.theme == lightTheme ? "gray" : "black" }                      
                                  //highlightColor={this.state.theme == lightTheme ? "#EBEBEB" : "#3C3C45" }
                                  
                                />
                                </SkeletonTheme>
                                <SkeletonTheme 
                                color={this.state.theme == lightTheme ? 
                                  configData.lightTheme.color : configData.darkTheme.color }
                                //color= {this.state.theme  ? 'red' : 'green'} 

                                highlightColor={this.state.theme == lightTheme ? 
                                  configData.lightTheme.highlightColor : configData.darkTheme.highlightColor }>
                                <Skeleton variant="text" animation="wave" width={"240px"} height="21px"
                                  //color={this.state.theme == lightTheme ? "gray" : "black" }                      
                                  //highlightColor={this.state.theme == lightTheme ? "#EBEBEB" : "#3C3C45" }
                                  
                                />
                                </SkeletonTheme>
                                <SkeletonTheme 
                                color={this.state.theme == lightTheme ? 
                                  configData.lightTheme.color : configData.darkTheme.color }
                                //color= {this.state.theme  ? 'red' : 'green'} 

                                highlightColor={this.state.theme == lightTheme ? 
                                  configData.lightTheme.highlightColor : configData.darkTheme.highlightColor }>
                                <Skeleton variant="text" animation="wave" width={"240px"} height="21px"
                                  //color={this.state.theme == lightTheme ? "gray" : "black" }                      
                                  //highlightColor={this.state.theme == lightTheme ? "#EBEBEB" : "#3C3C45" }
                                  
                                />
                                </SkeletonTheme>
                            </React.Fragment>
                        // <LoadingDotsComponent
                        //   showDotsLoading={1} //{this.state.pornstar_countries_showDotsLoading}
                        //   loop={20}
                        //   onlytext={1}
                        //   //theme={this.state.theme}
                        //   theme={this.state.theme == lightTheme ? true : false}
                        // ></LoadingDotsComponent>
                      : ""}
                    {this.state.pornstar_countries_showDotsLoading == 0 && this.state.pornstar_countries.length == 0? 
                        <h4 className="custom-h3 text_color"
                          style={{textAlign:'center'}}>No countries found</h4>
                    : ""}
                    {this.state.pornstar_countries_showDotsLoading == 0 ?
                        <ErrorComponent showError={this.state.pornstar_countries_showError}></ErrorComponent>
                    : "" }
                        {this.state.pornstar_countries.map((country, index) => (
                        
                          //  var country_object = flags.filter(
                          //   (objFromA) => objFromA.url == pornstar.country
                          //     )[0] || ""
                          <div 
                          style={{ whiteSpace:this.state.pornstar_countries_showDotsLoading == 1 ? "" :  "nowrap"}}
                          >
                            {this.return_country_from_country_code(country.country).length > 0 ?
                            
                             <a href={"/pornstars?country="+country.country} 
                                onClick={(event) => {
                                    this.hide_pornstar_countries();
                                    this.go_to_link(event,"/pornstars?country="+country.country)}}
                                    
                                style={{textDecoration: "none", }}
  
                                 className="videotitle"
                                >
                                 <div>
                                <img 

                                     style={{
                                      position:"relative", float:"left",                                      
                                      backgroundColor:"black",
                                      //boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                                      marginTop: '7px'

                                    }}                                   
                                    alt=""
                                    src={configData.CDN_ENDPOINT + "flags/"+country.country+".svg"}                                   
                                    width="20"
                                    className="flag_wrapper_shadow_class"
                                  />
                               &nbsp;&nbsp;
                               <h4 style={{display: "inline-block", }}>{this.return_country_from_country_code(country.country)}</h4>
                               <FontAwesomeIcon  icon={"arrow-right"} 
                               style={{position:"relative",
                                       float:"right", marginRight:"8px",marginTop: "7px", }}  
                                       className="purple_href"
                                       size="1x" />
                             
                             </div>
                             </a>
                            
                            : ""}                          
                          </div>
                        ))}
                        </div>
                    </div>

      <div id="popup_comment_delete_dialog" className="popup_class"   
          style={{background: "#E62466", position:'fixed', display: this.state.delete_comment_popup_showing == 1 ? "block" : "none",
          zIndex: "99999", width: this.state.mobile_filter_showing == 1 ? 'calc( 100% - 20px )' : '420px'}}>
         
         <div id="internal_popup" 
         style={{
          width: this.state.mobile_filter_showing == 1 ? 'calc( 100%  )' : '420px',          
          height: this.state.mobile_filter_showing == 1 ? 'auto' : '210px',
          minHeight: this.state.mobile_filter_showing == 1 ? '174px' : '194px'}}>
              <div className="pornstar_channel_in_view_video_href_class custom-h1" style={{marginTop:"-8px"}}>Delete confirmation</div>
         
          <div className="pornstar_channel_in_view_video_href_class" 
              style={{
                marginTop:"16px", 
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "20px", /* 125% */
                
                }}>
          Are you sure you want to delete this comment? <br></br> If deleted, you will never be able to restore it.
            </div>
            <button 
                  className="button_red_background"
                  onClick={() => {
                    this.delete_comment(this.state.comment_id_to_delete);
                    this.set_overlay(0);
                    document.getElementById("popup_comment_delete_dialog").style.display = "none"; 
                    document.body.style.overflow = 'visible';
                    document.getElementById("menu").style.marginRight="";
                    window.removeEventListener('resize', this.delete_popup_resize);
                    window.dispatchEvent(new Event('resize'));}}
                  style={{
                    position: this.state.mobile_filter_showing == 1 ? "relative": "absolute",
                    float:"right",
                    bottom:this.state.mobile_filter_showing == 1 ? "" : "15px", 
                    fontWeight:"700", fontSize:"14px",
                    right: this.state.mobile_filter_showing == 1 ? "" : "32px",
                    marginTop: this.state.mobile_filter_showing == 1 ? "24px" : "",
                    width: this.state.mobile_filter_showing == 1 ? "100%" : "",
                    marginBottom:  this.state.mobile_filter_showing == 1 ? "" : "9px",
                  }}
                  >YES, DELETE</button>
              <button 
                 // className="button_purple_background"
                 onClick={() => {
                  
                  this.setState({delete_confirmation: -1});
                  this.setState({show_overlay: 0, delete_comment_popup_showing: 0});
                  document.body.style.overflow = 'visible';
                  document.getElementById("menu").style.marginRight="";
                  window.removeEventListener('resize', this.delete_popup_resize);
                  window.dispatchEvent(new Event('resize'));
                }}
                  style={{
                    position:  this.state.mobile_filter_showing == 1 ? "relative": "absolute",
                    float:"left",
                    fontWeight:"700", fontSize:"14px",
                    bottom:this.state.mobile_filter_showing == 1 ? "" : "15px", 
                    left: this.state.mobile_filter_showing == 1 ? "" : "32px",
                    marginTop: this.state.mobile_filter_showing == 1 ? "8px" : "",
                    width: this.state.mobile_filter_showing == 1 ? "100%" : "",
                    marginBottom:  this.state.mobile_filter_showing == 1 ? "-9px" : "9px" //fix space issue in bottom
                  
                  
                  }}
                  className="button_trans_background white"
                  >NO, KEEP IT</button>
              {/* <button onClick={() => this.set_over18_value(0)}>No, I am not over 18</button> */}
              </div>
          </div>


       <div id="popup" className="popup_class"
       style={{display: "none", width: 
       //"470px",
       this.state.mobile_filter_showing == 1 ? 'calc( 100% - 20px )' : '470px',
       minWidth:"200px",       
       //left: "calc(50% - 235px)"
       //left: "500px",
       //right: "50%",
       position:"fixed",
       left: "50%",
       top: "50%",
       //top: "calc( 50%)",
       //transform: "translateX(-50%)",
        transform: 'translate(-50%,-50%)',
       
       visibility: this.state.mobile_filter_showing == -1  ? "hidden" : "",
       }}>
         <div id="internal_popup" style={{
          //width:'470px',
          width: this.state.mobile_filter_showing == 1 ? 'calc( 100%  )' : '470px',
          height: this.state.mobile_filter_showing == 1 ? 'auto' : '219px',//"auto", controls space between text and button
         minHeight: this.state.mobile_filter_showing == 1 ? '174px' : '194px',
         
        }}
         >
            <div className="pornstar_channel_in_view_video_href_class custom-h1"
            style={{marginTop: "-8px"}}>Are you over 18?</div>
            <div className="pornstar_channel_in_view_video_href_class" 
              style={{
                marginTop:"11px", 
                fontSize: "16px",
                fontStyle: "normal", /* height: 194px; */

                fontWeight: "400",
                lineHeight: "20px", /* 125% */
                
                }}>
            Confirm your age to access content. Click <b>'YES, I AM OVER 18'</b> (or 21 in applicable states) only if you are of legal age.
          </div>
              <button 
                  className="button_purple_background"
                  onClick={() => this.set_over18_value(1)}
                  style={{
                    position: this.state.mobile_filter_showing == 1 ? "relative": "absolute",
                    float:"right",
                    bottom:this.state.mobile_filter_showing == 1 ? "" : "15px", 
                    fontWeight:"700", fontSize:"14px",
                    right: this.state.mobile_filter_showing == 1 ? "" : "32px",
                    marginTop: this.state.mobile_filter_showing == 1 ? "24px" : "",
                    width: this.state.mobile_filter_showing == 1 ? "100%" : "",
                    marginBottom:  this.state.mobile_filter_showing == 1 ? "" : "9px",
                  
                  }}
                  >YES, I AM OVER 18</button>
              <button 
                 // className="button_purple_background"
                  onClick={() => this.set_over18_value(0)}
                  style={{
                    position:  this.state.mobile_filter_showing == 1 ? "relative": "absolute",
                    float:"left",
                    fontWeight:"700", fontSize:"14px",
                    bottom:this.state.mobile_filter_showing == 1 ? "" : "15px", 
                    left: this.state.mobile_filter_showing == 1 ? "" : "32px",
                    marginTop: this.state.mobile_filter_showing == 1 ? "8px" : "",
                    width: this.state.mobile_filter_showing == 1 ? "100%" : "",
                    marginBottom:  this.state.mobile_filter_showing == 1 ? "-9px" : "9px", //fix space issue in bottom
                  
                  }}
                  //className="button_trans_background white"
                    //className={this.props.mobile_filter_showing == 1 ? "button_red_background" : "button_trans_background white" }
                    className={this.state.mobile_filter_showing == 1 ? "button_red_border" : "button_trans_background white"}
                 >NO, I AM NOT OVER 18</button>
              {/* <button onClick={() => this.set_over18_value(0)}>No, I am not over 18</button> */}
              </div>
          </div>
        <div id="navbar_wrapper"
        style={{display: "none"}}>

        </div>
      
      
      <div 
        onClick={() => this.wrapperclick()}
        
        >
           
        <video 
        autoPlay
        preload="auto"
        id="temporary_video_placeholder"
        style={{display: "none"}}
        src={""}
        ></video>
    <div 
    // className={this.state.filters_showing == 1 || this.state.show_overlay == 1? "": ""}
    // style={{zIndex: '999',
    // backgroundColor: 'white',zIndex: "1"
    
  
    
    
    //                   }}
                      >  
      {/* was  <div className={this.state.filters_showing == 1 ? "stop-scrolling": ""}> */}
    <div style={{
    //   backgroundColor: this.state.filters_showing == 1 || this.state.show_overlay == 1? 
    // this.state.theme == lightTheme ? 
    //       configData.OVERLAY_BACKGROUND_COLOR_WHITE_THEME : configData.OVERLAY_BACKGROUND_COLOR_DARK_THEME : "",
    //zIndex: "1"
      
          //backgroundColor: 'white'
        //position:'fixed',top:'0px',left:"0px",right:"0px",bottom:"0px",opacity:"0.6"
        
        }}>
    <div id="intermediateContainer" 
        className="intermediateContainer" 
        style={{maxWidth: "2400px", 
               // zIndex: '994',  
                //paddingLeft: this.state.mobile_filter_showing == 1 ? "10px" : this.state.padding_px_larger_screens+"px",
                //paddingLeft: this.state.padding_px_larger_screens+"px",
              //  zIndex: "1",
                paddingLeft:(this.state.padding_px_larger_screens )+"px",
                paddingRight: (this.state.padding_px_larger_screens )+"px",
                display: this.state.padding_px_larger_screens < 0 ? "none": ""
                }}>
           



          <MenuComponent 
            User={this.props.User}
            menu_selected={this.state.menu_selected}
            set_theme_light={this.set_theme_light}
            set_theme_dark={this.set_theme_dark}
            theme={this.state.theme}
            show_menu={this.show_menu}
            hide_menu={this.hide_menu}
            show_desktop_tags_popup={this.show_desktop_tags_popup}
            hide_desktop_tags_popup={this.hide_desktop_tags_popup}
            history={this.props.history}
            filters_showing={this.state.filters_showing}
            show_menu_value={this.state.menu_showing}
            //ptags={this.state.ptags}
            ptags={this.state.ptags_initial}
            show_filters_value={this.state.filters_showing}            
            mobile_filter_showing={this.state.mobile_filter_showing} 
            mobile_or_tablet={this.state.mobile_or_tablet}
            set_overlay={this.set_overlay}
            show_hide_user_menu_mobile={this.show_hide_user_menu_mobile}           
            //wrapperclick={this.wrapperclick}
            show_overlay={this.state.show_overlay}
            show_login_popup={this.show_login_popup}
            go_to_link={this.go_to_link}
            wrapperclick={this.wrapperclick}
            set_search_popup_showing={this.set_search_popup_showing}
            handleSearch={this.handleSearch}
            set_mobile_search_input_popup_showing={this.set_mobile_search_input_popup_showing}
            search={this.search}
            mobile_search_input_popup_showing={this.state.mobile_search_input_popup_showing}
            search_popup_showing={this.state.search_popup_showing}
            search_expanded={this.state.search_expanded}
            search_arrow_navigator={this.search_arrow_navigator}
            set_search_item_selected_active_element={this.set_search_item_selected_active_element}

            handleTagsFilterInput={this.handleTagsFilterInput}
            tags_filter_value={this.state.tags_filter_value}


            tags_desktop_popup_showing={this.state.tags_desktop_popup_showing}


            tags_expanded_div_became_fixed={this.state.tags_expanded_div_became_fixed}

            set_tag_popup_stay_active={this.set_tag_popup_stay_active}

            selected_tags={this.state.selected_tags}
            inputChange={this.inputChange}

            deleteInput={this.deleteInput}
          ></MenuComponent>

          
        </div>


        <div
          id="mobile_search_input_div"   
          //className="overlay"

          style={{
            position:"fixed", 
            display: this.state.mobile_search_input_popup_showing == 1 ? "block" : "none", 
           // left: "300px",
            borderRadius: "0px",
          //  backgroundColor:"red !important",
            //backgroundColor: 'red',
            //borderBottom:'1px solid red',
            height: this.state.mobile_or_tablet == 0 ? "37px" : "",
            paddingBottom: this.state.mobile_or_tablet == 1 ?  "4px" : "0px",
            
           // width: "306px",
          //  width: "100%",
           
            //border: this.state.theme == lightTheme ? "1px solid #5724E6"  : "1px solid #9F7DFF",
            zIndex: 920
          }}
          onClick={(event) => event.stopPropagation()}
          >
        <form onSubmit={this.search}>
          <div style={{zIndex:99}}>
          <input id='mobile_search_input' 
              type="search"
              className="text_color"
              dark={this.state.theme == lightTheme ? "false" : "true"} 
              autocomplete="off"
                          style={{
                          width:"266px",
                          height: "38px",
                          border: "0px",
                          position:'relative',
                          float:"left",
                          paddingLeft: "16px",
                          backgroundColor: this.state.theme == lightTheme ?  "#F2F2F2" : "#3E3E47",
                         // borderRadius: "4px 0 0 4px",
                          borderRadius:"4px",
                          outline: "none",
                          //#8B8B8F
                         // color: this.state.theme == lightTheme ? "black" : "white",
                          backgroundColor: this.state.theme == lightTheme ?  "#F2F2F2" : "#3E3E47", 
                          //  border: this.state.theme == lightTheme ? "1px solid #5724E6"  : "1px solid #9F7DFF" ,
                          border: '1px solid transparent',
                          zIndex:"102"
                          }}
                      placeholder="Search videos"
                      onChange={(event) => this.handleSearch(event)}
                      onClick={(event) => this.setState({search_item_selected_active_element: -1})} 
                      onKeyDown={(event) => {
                        if (event.key === "Enter" ) {
                          //this.props.search_arrow_navigator(event)
                          event.preventDefault();
                          this.search(event,1)

                        }  
                        if (event.key === "ArrowDown" || event.key === "ArrowUp") {
                          this.search_arrow_navigator(event)

                        }
                          if (event.key === "Escape") {
                            // Run your JavaScript code here
                            //alert('Escape key was pressed!');
                            document.getElementById("mobile_search_input").value = ""
                            //document.getElementById("search_items").style.display = "none";
                            document.getElementById("mobile_search_input_div").style.borderRadius = "0px";
                            document.getElementById("mobile_search_input").style.borderRadius = "4px";
                            //document.getElementById("mobile_search_input_search_button").style.borderRadius = "0 4px 4px 0";

                            document.getElementById("mobile_search_input").style.borderBottom = 
                                this.props.theme == lightTheme ?  "1px solid #5724E6" : "1px solid #9F7DFF"
                            this.set_search_popup_showing(0)
                            this.set_mobile_search_input_popup_showing(0)
                          }
                        }}
                        onFocus={(event) => {
                          var value = document.getElementById("mobile_search_input").value
                          // if(value.length > 0 && this.state.search_popup_showing == 0){
                          //   this.handleSearch(event)
                          // }
                      }}
                      ></input>
                  </div>
                  {this.state.mobile_or_tablet == 0 ? 
                    <button 
                        onClick={(event) => this.search(event)} 
                        type='submit'
                        style={{
                            //marginTop: this.props.show_wide == 1 ? "12px": "8px ",
                            width: "40px",                        
                            //height: this.props.show_wide == 1 ? "45.5px" : "",
                            height: "38px",
                            cursor: "pointer",
                            position:"relative",

                            float: "right",
                            borderRadius: "0 4px 4px 0",
                            textAlign: "center",
                                                
                            
                        }}
                        id="mobile_search_input_search_button"
                        className="button_purple_background"
                        //disabled={this.state.selected_tags.length > 0 ? false : true}
                    >
                    
                    <FontAwesomeIcon
                        className="fa-sync"
                        icon="search"
                        style={{
                        position:"relative",                      
                        right: "3.0px",
                        }}                            
                    />
                    </button>
                  : "" }
                  </form>
        </div>
        
          <div className="App" 
          style={{
          //backgroundColor: "#00000050",
          // opacity: this.state.filters_showing == 1  || this.state.show_overlay == 1 ? "0.1" : ""
          }}
          >
          
             {/* the div below helps with the appear and disappear of scrollbar. the page doesn't move 
        because of the div */}
          {/* <div 
                    style={{
                      paddingLeft: "calc(100vw  - 100%)",
                      position:"relative",
                      float:"left",
                      width: "0px",
                      border:"0px solid red",
                      height:"1px",
                      }}>
                    
                </div> */}
          
          
            <div className="intermediateContainer" 
            style={{paddingLeft: (this.state.padding_px_larger_screens)+"px", 
                    paddingRight:(this.state.padding_px_larger_screens)+"px"}}
                onClick={() => this.state.filters_showing == 1 ? this.wrapperclick() : ""}
                // style={{                    
                //     // //bottom: "20px",
                //     minHeight: this.props.show_footer != 1 ? 
                //       this.state.mobile_or_tablet == 0 ? window.innerHeight-120 + "px" : window.innerHeight-240 + "px"
                      
                //       :  this.state.mobile_or_tablet == 0 ? window.innerHeight-120 + "px" : window.innerHeight-240 + "px"
                //     // ,
                //     // position:"relative"
                //       }}
              >
               
              <ThemeProvider theme={this.state.theme}>
              <>
                <GlobalStyles style={{width:""}}/>
                <Suspense fallback={<div>Loading...</div>}>

                          <div style={{
                            height:'38px',
                            display: this.state.real_flag_for_mobile_search_input_popup_showing ==1 
                            && this.state.mobile_or_tablet == 1 ? "" : "none"
                          }}>

                skata
                </div>
                <Switch>
                    <Route path="/register" 
                        render={({ match }) => (
                          <RegisterComponent
                            match={match}
                            theme={this.state.theme}
                            set_theme_light={this.set_theme_light}
                            set_theme_dark={this.set_theme_dark}
                            User={this.props.User}                            
                            reportWindowSize={this.reportWindowSize_no_left_menu}
                            reportWindowSize_login={this.reportWindowSize_login}
                            filters_showing={this.state.filters_showing}
                            set_mobile_filter_showing={this.set_mobile_filter_showing}
                            show_overlay={this.state.show_overlay}
                            go_to_link={this.go_to_link}
                            google_login={this.google_login}
                            mobile_or_tablet={this.state.mobile_or_tablet}
                            real_flag_for_mobile_search_input_popup_showing={this.state.real_flag_for_mobile_search_input_popup_showing}
                          />
                        )}
                    />
                    <Route path="/logout" component={LogoutComponent} />                                  
                    <Route
                      path="/profile/settings"
                      render={({ match }) => (
                        <UserSettingsComponent
                            match={match} 
                            User={this.props.User} 
                            theme={this.state.theme}
                            history={this.props.history}
                            reportWindowSize={this.reportWindowSize} 
                            show_filters={this.show_filters}
                            hide_filters={this.hide_filters}
                            filters_showing={this.state.filters_showing}
                            menu_showing={this.state.menu_showing}
                            mobile_filter_showing={this.state.mobile_filter_showing}
                            scrolling_down={this.state.scrolling_down}
                            detect_scroll_direction={this.detect_scroll_direction}
                            show_blur={this.state.show_blur}
                            show_overlay={this.state.show_overlay}                            
                            mobile_or_tablet={this.state.mobile_or_tablet}
                            real_flag_for_mobile_search_input_popup_showing={this.state.real_flag_for_mobile_search_input_popup_showing}
                            
                        />
                      )}
                    />
                    
                    <Route
                      path="/profile/mycomments/:page"
                      render={({ props, match }) => (
                        <MyCommentsComponent
                          match={match} 
                          User={this.props.User} 
                          theme={this.state.theme}
                          history={this.props.history}
                          reportWindowSize={this.reportWindowSize} 
                          show_filters={this.show_filters}
                          hide_filters={this.hide_filters}
                          filters_showing={this.state.filters_showing}
                          menu_showing={this.state.menu_showing}
                          mobile_filter_showing={this.state.mobile_filter_showing}
                          scrolling_down={this.state.scrolling_down}
                          detect_scroll_direction={this.detect_scroll_direction}
                          show_blur={this.state.show_blur}
                          mobile_or_tablet={this.state.mobile_or_tablet}
                          window_height={this.state.window_height}
                          pagination_show_less={this.state.pagination_show_less}
                          show_overlay={this.state.show_overlay}
                          real_flag_for_mobile_search_input_popup_showing={this.state.real_flag_for_mobile_search_input_popup_showing}
                        />
                      )}
                    />
                    <Route
                      exact path="/profile/mycomments"
                      render={({ match }) => (
                        <MyCommentsComponent
                          match={match} 
                          User={this.props.User} 
                          theme={this.state.theme}
                          history={this.props.history}
                          reportWindowSize={this.reportWindowSize} 
                          show_filters={this.show_filters}
                          hide_filters={this.hide_filters}
                          filters_showing={this.state.filters_showing}
                          menu_showing={this.state.menu_showing}
                          mobile_filter_showing={this.state.mobile_filter_showing}
                          scrolling_down={this.state.scrolling_down}
                          detect_scroll_direction={this.detect_scroll_direction}
                          show_blur={this.state.show_blur}
                          mobile_or_tablet={this.state.mobile_or_tablet}
                          window_height={this.state.window_height}
                          pagination_show_less={this.state.pagination_show_less}
                          show_overlay={this.state.show_overlay}
                          real_flag_for_mobile_search_input_popup_showing={this.state.real_flag_for_mobile_search_input_popup_showing}
                        />
                      )}
                    />
                    
                    <Route
                      exact path="/profile/watched"
                      render={({ match }) => (
                        <WatchedProfileComponent 
                            match={match} 
                            User={this.props.User} 
                            theme={this.state.theme}
                            history={this.props.history}
                            reportWindowSize={this.reportWindowSize} 
                            show_filters={this.show_filters}
                            hide_filters={this.hide_filters}
                            filters_showing={this.state.filters_showing}
                            show_overlay={this.state.show_overlay}
                            menu_showing={this.state.menu_showing}
                            mobile_filter_showing={this.state.mobile_filter_showing}
                            scrolling_down={this.state.scrolling_down}
                            detect_scroll_direction={this.detect_scroll_direction}
                            show_blur={this.state.show_blur}
                            mobile_or_tablet={this.state.mobile_or_tablet}
                            window_height={this.state.window_height}
                            pagination_show_less={this.state.pagination_show_less}
                            real_flag_for_mobile_search_input_popup_showing={this.state.real_flag_for_mobile_search_input_popup_showing}
                            
                            selected_tags={this.state.selected_tags}
                            inputChange={this.inputChange}

                            deleteInput={this.deleteInput}

                            any_popup_showing_stop_video_hover={this.state.any_popup_showing_stop_video_hover}
                        />
                      )}
                    />
                    <Route
                      exact path="/profile/watched/:page"
                      render={({ match }) => (
                        <WatchedProfileComponent 
                            match={match} 
                            User={this.props.User} 
                            theme={this.state.theme}
                            history={this.props.history}
                            reportWindowSize={this.reportWindowSize} 
                            show_filters={this.show_filters}
                            hide_filters={this.hide_filters}
                            filters_showing={this.state.filters_showing}
                            menu_showing={this.state.menu_showing}
                            mobile_filter_showing={this.state.mobile_filter_showing}
                            scrolling_down={this.state.scrolling_down}
                            detect_scroll_direction={this.detect_scroll_direction}
                            show_blur={this.state.show_blur}
                            mobile_or_tablet={this.state.mobile_or_tablet}
                            window_height={this.state.window_height}
                            pagination_show_less={this.state.pagination_show_less}
                            real_flag_for_mobile_search_input_popup_showing={this.state.real_flag_for_mobile_search_input_popup_showing}
                            
                            selected_tags={this.state.selected_tags}
                            inputChange={this.inputChange}

                            deleteInput={this.deleteInput}

                            any_popup_showing_stop_video_hover={this.state.any_popup_showing_stop_video_hover}
                        />
                      )}
                    />


                    <Route
                      exact path="/profile/favorites"
                      render={({ match }) => (
                        <FavoritesProfileComponent 
                            match={match} 
                            User={this.props.User} 
                            theme={this.state.theme}
                            history={this.props.history}
                            reportWindowSize={this.reportWindowSize} 
                            show_filters={this.show_filters}
                            hide_filters={this.hide_filters}
                            filters_showing={this.state.filters_showing}
                            menu_showing={this.state.menu_showing}
                            mobile_filter_showing={this.state.mobile_filter_showing}
                            scrolling_down={this.state.scrolling_down}
                            detect_scroll_direction={this.detect_scroll_direction}
                            show_blur={this.state.show_blur}
                            mobile_or_tablet={this.state.mobile_or_tablet}
                            window_height={this.state.window_height}
                            pagination_show_less={this.state.pagination_show_less}
                            show_overlay={this.state.show_overlay}
                            real_flag_for_mobile_search_input_popup_showing={this.state.real_flag_for_mobile_search_input_popup_showing}
                            
                            any_popup_showing_stop_video_hover={this.state.any_popup_showing_stop_video_hover}
                        />
                      )}
                    />
                    <Route
                      exact path="/profile/favorites/:page"
                      render={({ match }) => (
                        <FavoritesProfileComponent 
                            match={match} 
                            User={this.props.User} 
                            theme={this.state.theme}
                            history={this.props.history}
                            reportWindowSize={this.reportWindowSize} 
                            show_filters={this.show_filters}
                            hide_filters={this.hide_filters}
                            filters_showing={this.state.filters_showing}
                            menu_showing={this.state.menu_showing}
                            mobile_filter_showing={this.state.mobile_filter_showing}
                            scrolling_down={this.state.scrolling_down}
                            detect_scroll_direction={this.detect_scroll_direction}
                            show_blur={this.state.show_blur}
                            mobile_or_tablet={this.state.mobile_or_tablet}
                            window_height={this.state.window_height}
                            pagination_show_less={this.state.pagination_show_less}
                            show_overlay={this.state.show_overlay}
                            real_flag_for_mobile_search_input_popup_showing={this.state.real_flag_for_mobile_search_input_popup_showing}

                            any_popup_showing_stop_video_hover={this.state.any_popup_showing_stop_video_hover}
                        />
                      )}
                    />

                    <Route
                      path="/login"
                      render={({ match }) => (
                        <LoginComponent 
                            match={match} 
                            filters_showing={this.state.filters_showing}
                            theme={this.state.theme}
                            set_theme_light={this.set_theme_light}
                            set_theme_dark={this.set_theme_dark}
                            //reportWindowSize={this.reportWindowSize}
                            reportWindowSize={this.reportWindowSize_no_left_menu}
                            User={this.props.User} 
                            change_login_rememberme={this.change_login_rememberme}
                            set_mobile_filter_showing={this.set_mobile_filter_showing}
                            show_overlay={this.state.show_overlay}
                            login_showSpinner= {this.state.login_showSpinner}
                            login_rememberme= {this.state.login_rememberme}
                            login_showPassword1clearText={this.state.login_showPassword1clearText}
                            login={this.login}
                            
                            //login_showError= 0
                            login_error_message= {this.state.login_error_message}
                            login_change_showPassword1clearText={this.login_change_showPassword1clearText}
                            login_change_rememberme={this.login_change_rememberme}
                            reportWindowSize_login={this.reportWindowSize_login}
                            go_to_link={this.go_to_link}
                            google_login={this.google_login}
                            mobile_or_tablet={this.state.mobile_or_tablet}
                            real_flag_for_mobile_search_input_popup_showing={this.state.real_flag_for_mobile_search_input_popup_showing}
                            />
                      )}
                    />
                    {/* <Route path="/login" component={LoginComponent} /> */}
                    <Route
                      path="/watch/:id/:title"
                      render={({ match }) => (
                        <ShowVideo 
                            match={match} 
                            User={this.props.User}
                           // theme={this.state.theme}
                            history={this.props.history}
                            
                            filters_showing={this.state.filters_showing}
                            set_disabled_scroll_value={this.set_disabled_scroll_value}
                            //reportWindowSize={this.reportWindowSize}
                            reportWindowSize={this.reportWindowSize_no_left_menu}
                            columns={this.state.columns}
                            show_blur={this.state.show_blur}
                            theme={this.state.theme}
                            show_overlay={this.state.show_overlay}
                            set_overlay={this.set_overlay}
                            delete_comment_show_dialog={this.delete_comment_show_dialog}
                            comment_got_deleted={this.state.comment_got_deleted}
                            mobile_filter_showing={this.state.mobile_filter_showing}
                            mobile_or_tablet={this.state.mobile_or_tablet}                           
                            show_login_popup={this.show_login_popup}
                            pagination_show_less={this.state.pagination_show_less}

                            comment_menu_showing={this.state.comment_menu_showing}
                            current_comment_menu_id_showing={this.state.current_comment_menu_id_showing}

                            set_current_comment_menu_id_showing={this.set_current_comment_menu_id_showing}
                            any_popup_showing_stop_video_hover={this.state.any_popup_showing_stop_video_hover}

                            
                            
                        />
                      )}
                    />
                    <Route
                      exact path="/watch/:id"
                      render={({ match }) => (
                        <ShowVideo  
                          match={match} 
                         // User={this.props.User} 
                          //reportWindowSize={this.reportWindowSize}
                          reportWindowSize={this.reportWindowSize_no_left_menu}
                          filters_showing={this.state.filters_showing}
                          columns={this.state.columns}
                          show_blur={this.state.show_blur}
                          theme={this.state.theme}
                          set_overlay={this.set_overlay}
                          show_overlay={this.state.show_overlay}
                          delete_comment_show_dialog={this.delete_comment_show_dialog}
                          comment_got_deleted={this.state.comment_got_deleted}
                          mobile_filter_showing={this.state.mobile_filter_showing}
                          User={this.props.User}
                          show_login_popup={this.show_login_popup}
                          pagination_show_less={this.state.pagination_show_less}

                          comment_menu_showing={this.state.comment_menu_showing}
                          current_comment_menu_id_showing={this.state.current_comment_menu_id_showing}

                          set_current_comment_menu_id_showing={this.set_current_comment_menu_id_showing}
                          any_popup_showing_stop_video_hover={this.state.any_popup_showing_stop_video_hover}

                        />
                      )}
                    />
                    <Route 
                      path="/channels/search/:search_string/:page" 
                      render={({  match }) => (
                        <ChannelsComponent 
                        isSearch={1}
                          match={match} 
                          scrollbarwidth={this.state.scrollbarwidth}
                          history={this.props.history}
                          //reportWindowSize={this.reportWindowSize} 
                          //reportWindowSize_no_left_menu
                          reportWindowSize={this.reportWindowSize_no_left_menu} 
                          filters_showing={this.state.filters_showing}
                          show_blur={this.state.show_blur}
                          show_overlay={this.state.show_overlay}
                          theme={this.state.theme}
                          mobile_filter_showing={this.state.mobile_filter_showing}
                          mobile_or_tablet={this.state.mobile_or_tablet}
                          window_height={this.state.window_height}
                          pagination_show_less={this.state.pagination_show_less}
                          real_flag_for_mobile_search_input_popup_showing={this.state.real_flag_for_mobile_search_input_popup_showing}
                        />
                      )}
                    />

                    <Route 
                      path="/channels/search/:search_string" 
                      render={({  match }) => (
                        <ChannelsComponent 
                          isSearch={1}
                          match={match} 
                          scrollbarwidth={this.state.scrollbarwidth}
                          history={this.props.history}
                          //reportWindowSize={this.reportWindowSize} 
                          //reportWindowSize_no_left_menu
                          reportWindowSize={this.reportWindowSize_no_left_menu} 
                          filters_showing={this.state.filters_showing}
                          show_blur={this.state.show_blur}
                          show_overlay={this.state.show_overlay}
                          theme={this.state.theme}
                          mobile_filter_showing={this.state.mobile_filter_showing}
                          mobile_or_tablet={this.state.mobile_or_tablet}
                          window_height={this.state.window_height}
                          pagination_show_less={this.state.pagination_show_less}
                          real_flag_for_mobile_search_input_popup_showing={this.state.real_flag_for_mobile_search_input_popup_showing}
                        />
                      )}
                    />
                    

                    <Route 
                      path="/channels/:page" 
                      render={({  match }) => (
                        <ChannelsComponent 

                          match={match} 
                          scrollbarwidth={this.state.scrollbarwidth}
                          history={this.props.history}
                          //reportWindowSize={this.reportWindowSize} 
                          //reportWindowSize_no_left_menu
                          reportWindowSize={this.reportWindowSize_no_left_menu} 
                          filters_showing={this.state.filters_showing}
                          show_blur={this.state.show_blur}
                          show_overlay={this.state.show_overlay}
                          theme={this.state.theme}
                          mobile_filter_showing={this.state.mobile_filter_showing}
                          mobile_or_tablet={this.state.mobile_or_tablet}
                          window_height={this.state.window_height}
                          pagination_show_less={this.state.pagination_show_less}
                          real_flag_for_mobile_search_input_popup_showing={this.state.real_flag_for_mobile_search_input_popup_showing}
                        />
                      )}
                    />
                    <Route 
                      exact path="/channels/" 
                      render={({  match }) => (
                        <ChannelsComponent 
                          scrollbarwidth={this.state.scrollbarwidth}
                          match={match} 
                          filters_showing={this.state.filters_showing}
                          history={this.props.history}
                          //reportWindowSize={this.reportWindowSize} 
                          reportWindowSize={this.reportWindowSize_no_left_menu} 
                          show_blur={this.state.show_blur}
                          show_overlay={this.state.show_overlay}
                          theme={this.state.theme}
                          mobile_filter_showing={this.state.mobile_filter_showing}
                          mobile_or_tablet={this.state.mobile_or_tablet}
                          window_height={this.state.window_height}
                          pagination_show_less={this.state.pagination_show_less}
                          real_flag_for_mobile_search_input_popup_showing={this.state.real_flag_for_mobile_search_input_popup_showing}
                        />  
                      )}
                    />

                    <Route
                      path="/pornstar/:id/:title/:page"
                      
                      render={({  match }) => (
                        <PornstarComponent 

                          match={match} 
                          show_filters={this.show_filters}
                          hide_filters={this.hide_filters}
                          history={this.props.history}
                          //reportWindowSize={this.reportWindowSize} 
                          reportWindowSize={this.reportWindowSize_no_left_menu} 


                          filters_showing={this.state.filters_showing}
                          mobile_filter_showing={this.state.mobile_filter_showing}
                          tablet_filter_showing={this.state.tablet_filter_showing}
                          scrolling_down={this.state.scrolling_down}
                          detect_scroll_direction={this.detect_scroll_direction}
                          show_blur={this.state.show_blur}
                          theme={this.state.theme}
                          mobile_or_tablet={this.state.mobile_or_tablet}
                          show_overlay={this.state.show_overlay}
                          //User={this.props.User}
                          //show_login_popup={this.show_login_popup}
                          window_height={this.state.window_height}
                          User={this.props.User}
                          show_login_popup={this.show_login_popup}
                          set_scrolly={this.set_scrolly}
                          pagination_show_less={this.state.pagination_show_less}
                          set_number_of_filters={this.set_number_of_filters}
                          number_of_filters={this.state.number_of_filters}
                          over_18_popup_showing={this.state.over_18_popup_showing}
                          ptags={this.state.ptags_initial}
                          real_flag_for_mobile_search_input_popup_showing={this.state.real_flag_for_mobile_search_input_popup_showing}
                          overlay_full_except_menu_and_mobile_search_value={this.state.overlay_full_except_menu_and_mobile_search_value}

                          selected_tags={this.state.selected_tags}
                          inputChange={this.inputChange}

                          deleteInput={this.deleteInput}

                          go_to_link={this.go_to_link}

                          any_popup_showing_stop_video_hover={this.state.any_popup_showing_stop_video_hover}
                        />
                      )}
                    />
                    <Route
                      exact path="/pornstar/:id/:title"
                      render={({  match }) => (
                        <PornstarComponent 
                          match={match} 

                          history={this.props.history}
                          //reportWindowSize={this.reportWindowSize} 
                          reportWindowSize={this.reportWindowSize_no_left_menu} 

                          show_filters={this.show_filters}
                          hide_filters={this.hide_filters}

                          filters_showing={this.state.filters_showing}
                          mobile_filter_showing={this.state.mobile_filter_showing}
                          tablet_filter_showing={this.state.tablet_filter_showing}                          
                          scrolling_down={this.state.scrolling_down}
                          detect_scroll_direction={this.detect_scroll_direction}
                          show_blur={this.state.show_blur}
                          theme={this.state.theme}
                          mobile_or_tablet={this.state.mobile_or_tablet}
                          show_overlay={this.state.show_overlay}
                          //User={this.props.User}
                          //show_login_popup={this.show_login_popup}
                          window_height={this.state.window_height}
                          User={this.props.User}
                          show_login_popup={this.show_login_popup}
                          set_scrolly={this.set_scrolly}
                          pagination_show_less={this.state.pagination_show_less}
                          set_number_of_filters={this.set_number_of_filters}
                          number_of_filters={this.state.number_of_filters}
                          over_18_popup_showing={this.state.over_18_popup_showing}
                          ptags={this.state.ptags_initial}
                          real_flag_for_mobile_search_input_popup_showing={this.state.real_flag_for_mobile_search_input_popup_showing}
                          overlay_full_except_menu_and_mobile_search_value={this.state.overlay_full_except_menu_and_mobile_search_value}

                          selected_tags={this.state.selected_tags}
                          inputChange={this.inputChange}

                          deleteInput={this.deleteInput}

                          go_to_link={this.go_to_link}

                          any_popup_showing_stop_video_hover={this.state.any_popup_showing_stop_video_hover}
                        />
                      )}
                      
                    />
                    <Route exact path="/pornstar/:id"  
                      render={({  match }) => (
                        <PornstarComponent 

                          match={match} 
                          //filters_showing={this.state.filters_showing}
                          history={this.props.history}
                          //reportWindowSize={this.reportWindowSize} 
                          reportWindowSize={this.reportWindowSize_no_left_menu} 
                          show_filters={this.show_filters}
                          hide_filters={this.hide_filters}

                          filters_showing={this.state.filters_showing}
                          mobile_filter_showing={this.state.mobile_filter_showing}
                          tablet_filter_showing={this.state.tablet_filter_showing}                          
                          scrolling_down={this.state.scrolling_down}
                          detect_scroll_direction={this.detect_scroll_direction}
                          show_blur={this.state.show_blur}
                          theme={this.state.theme}
                          mobile_or_tablet={this.state.mobile_or_tablet}
                          show_overlay={this.state.show_overlay}
                          //User={this.props.User}
                          //show_login_popup={this.show_login_popup}
                          window_height={this.state.window_height}
                          User={this.props.User}
                          show_login_popup={this.show_login_popup}
                          set_scrolly={this.set_scrolly}
                          pagination_show_less={this.state.pagination_show_less}
                          set_number_of_filters={this.set_number_of_filters}
                          number_of_filters={this.state.number_of_filters}
                          over_18_popup_showing={this.state.over_18_popup_showing}
                          ptags={this.state.ptags_initial}
                          real_flag_for_mobile_search_input_popup_showing={this.state.real_flag_for_mobile_search_input_popup_showing}
                          overlay_full_except_menu_and_mobile_search_value={this.state.overlay_full_except_menu_and_mobile_search_value}

                          selected_tags={this.state.selected_tags}
                          inputChange={this.inputChange}

                          deleteInput={this.deleteInput}

                          go_to_link={this.go_to_link}

                          any_popup_showing_stop_video_hover={this.state.any_popup_showing_stop_video_hover}

                        />
                      )}
                    />
                     <Route path="/pornstars/search/:search_string/:page"  
                      render={({  match }) => (
                        <PornstarsComponent 
                        isSearch={1}
                        match={match}
                        scrollbarwidth={this.state.scrollbarwidth} 
                        //filters_showing={this.state.filters_showing}
                        history={this.props.history}
                        reportWindowSize={this.reportWindowSize_no_left_menu} 
                        show_filters={this.show_filters}
                        hide_filters={this.hide_filters}

                        filters_showing={this.state.filters_showing}
                        mobile_filter_showing={this.state.mobile_filter_showing}
                        scrolling_down={this.state.scrolling_down}
                        detect_scroll_direction={this.detect_scroll_direction}
                        show_blur={this.state.show_blur}
                        show_overlay={this.state.show_overlay}
                        theme={this.state.theme}
                        mobile_or_tablet={this.state.mobile_or_tablet}
                        window_height={this.state.window_height}
                        show_pornstar_countries={this.show_pornstar_countries}
                        pornstar_countries_popup_showing={this.state.pornstar_countries_popup_showing}
                        cookies_dialog_ok={this.state.cookies_dialog_ok}
                        pagination_show_less={this.state.pagination_show_less}
                        real_flag_for_mobile_search_input_popup_showing={this.state.real_flag_for_mobile_search_input_popup_showing}
                        />
                      )}
                    />
                    <Route path="/pornstars/search/:search_string"  
                      render={({  match }) => (
                        <PornstarsComponent 
                        isSearch={1}
                        match={match} 
                        scrollbarwidth={this.state.scrollbarwidth} 
                        //filters_showing={this.state.filters_showing}
                        history={this.props.history}
                        reportWindowSize={this.reportWindowSize_no_left_menu} 
                        show_filters={this.show_filters}
                        hide_filters={this.hide_filters}

                        filters_showing={this.state.filters_showing}
                        mobile_filter_showing={this.state.mobile_filter_showing}
                        scrolling_down={this.state.scrolling_down}
                        detect_scroll_direction={this.detect_scroll_direction}
                        show_blur={this.state.show_blur}
                        show_overlay={this.state.show_overlay}
                        theme={this.state.theme}
                        mobile_or_tablet={this.state.mobile_or_tablet}
                        window_height={this.state.window_height}
                        show_pornstar_countries={this.show_pornstar_countries}
                        pornstar_countries_popup_showing={this.state.pornstar_countries_popup_showing}
                        cookies_dialog_ok={this.state.cookies_dialog_ok}
                        pagination_show_less={this.state.pagination_show_less}
                        real_flag_for_mobile_search_input_popup_showing={this.state.real_flag_for_mobile_search_input_popup_showing}
                        />
                      )}
                    />
                    <Route path="/pornstars/:page"  
                      render={({  match }) => (
                        <PornstarsComponent 

                        match={match} 
                        //filters_showing={this.state.filters_showing}
                        scrollbarwidth={this.state.scrollbarwidth} 
                        history={this.props.history}
                        reportWindowSize={this.reportWindowSize_no_left_menu} 
                        show_filters={this.show_filters}
                        hide_filters={this.hide_filters}

                        filters_showing={this.state.filters_showing}
                        mobile_filter_showing={this.state.mobile_filter_showing}
                        scrolling_down={this.state.scrolling_down}
                        detect_scroll_direction={this.detect_scroll_direction}
                        show_blur={this.state.show_blur}
                        show_overlay={this.state.show_overlay}
                        theme={this.state.theme}
                        mobile_or_tablet={this.state.mobile_or_tablet}
                        window_height={this.state.window_height}
                        show_pornstar_countries={this.show_pornstar_countries}
                        pornstar_countries_popup_showing={this.state.pornstar_countries_popup_showing}
                        cookies_dialog_ok={this.state.cookies_dialog_ok}
                        pagination_show_less={this.state.pagination_show_less}
                        real_flag_for_mobile_search_input_popup_showing={this.state.real_flag_for_mobile_search_input_popup_showing}
                        />
                      )}
                    />
                   

                    <Route exact path="/pornstars/" 
                      render={({  match }) => (
                        <PornstarsComponent 

                        match={match} 
                        ///filters_showing={this.state.filters_showing}
                        scrollbarwidth={this.state.scrollbarwidth} 
                        history={this.props.history}
                        reportWindowSize={this.reportWindowSize_no_left_menu} 
                        show_filters={this.show_filters}
                        hide_filters={this.hide_filters}

                        filters_showing={this.state.filters_showing}
                        mobile_filter_showing={this.state.mobile_filter_showing}
                        scrolling_down={this.state.scrolling_down}
                        detect_scroll_direction={this.detect_scroll_direction}
                        show_blur={this.state.show_blur}
                        show_overlay={this.state.show_overlay}
                        theme={this.state.theme}
                        mobile_or_tablet={this.state.mobile_or_tablet}
                        window_height={this.state.window_height}
                        show_pornstar_countries={this.show_pornstar_countries}
                        pornstar_countries_popup_showing={this.state.pornstar_countries_popup_showing}
                        cookies_dialog_ok={this.state.cookies_dialog_ok}
                        pagination_show_less={this.state.pagination_show_less}
                        real_flag_for_mobile_search_input_popup_showing={this.state.real_flag_for_mobile_search_input_popup_showing}
                        />
                      )}
                    />

                    <Route 
                      path="/tag/:id/:title/:page" 
                      render={({  match }) => (
                        <UserTags 
                          match={match} 
                          scrollbarwidth={this.state.scrollbarwidth} 
                          show_filters={this.show_filters}
                          //={this.hide_filters}
                          history={this.props.history}
                          //reportWindowSize={this.reportWindowSize} 
                          reportWindowSize={this.reportWindowSize_no_left_menu}


                          filters_showing={this.state.filters_showing}
                          mobile_filter_showing={this.state.mobile_filter_showing}
                          scrolling_down={this.state.scrolling_down}
                          detect_scroll_direction={this.detect_scroll_direction}  
                          show_blur={this.state.show_blur}
                          theme={this.state.theme}
                          mobile_or_tablet={this.state.mobile_or_tablet}
                          show_overlay={this.state.show_overlay}
                          //User={this.props.User}
                          //show_login_popup={this.show_login_popup}
                          window_height={this.state.window_height}
                          User={this.props.User}
                          show_login_popup={this.show_login_popup}
                          set_scrolly={this.set_scrolly}   
                          hide_filters={this.hide_filters}   
                          pagination_show_less={this.state.pagination_show_less}
                          set_number_of_filters={this.set_number_of_filters}
                          number_of_filters={this.state.number_of_filters}
                          over_18_popup_showing={this.state.over_18_popup_showing}  
                          real_flag_for_mobile_search_input_popup_showing={this.state.real_flag_for_mobile_search_input_popup_showing}                
                          overlay_full_except_menu_and_mobile_search_value={this.state.overlay_full_except_menu_and_mobile_search_value}
                          selected_tags={this.state.selected_tags}
                          inputChange={this.inputChange}
                          deleteInput={this.deleteInput}

                          wrapperclick={this.wrapperclick}

                          go_to_link={this.go_to_link}

                          any_popup_showing_stop_video_hover={this.state.any_popup_showing_stop_video_hover}
                          
                         
                        />
                      )}
                    />
                    

                    <Route exact path="/tag/:id/:title" 
                      render={({  match }) => (
                        <UserTags 

                          match={match} 
                          scrollbarwidth={this.state.scrollbarwidth} 
                          show_filters={this.show_filters}
                          hide_filters={this.hide_filters}
                          history={this.props.history}
                          //reportWindowSize={this.reportWindowSize} 
                          reportWindowSize={this.reportWindowSize_no_left_menu}


                          filters_showing={this.state.filters_showing}
                          mobile_filter_showing={this.state.mobile_filter_showing}
                          scrolling_down={this.state.scrolling_down}
                          detect_scroll_direction={this.detect_scroll_direction}
                          show_blur={this.state.show_blur}
                          theme={this.state.theme}
                          mobile_or_tablet={this.state.mobile_or_tablet}
                          show_overlay={this.state.show_overlay}
                          //User={this.props.User}
                          //show_login_popup={this.show_login_popup}
                          window_height={this.state.window_height}
                          User={this.props.User}
                          show_login_popup={this.show_login_popup}
                          set_scrolly={this.set_scrolly}
                          pagination_show_less={this.state.pagination_show_less}
                          set_number_of_filters={this.set_number_of_filters}
                          number_of_filters={this.state.number_of_filters}
                          over_18_popup_showing={this.state.over_18_popup_showing}
                          real_flag_for_mobile_search_input_popup_showing={this.state.real_flag_for_mobile_search_input_popup_showing}
                          overlay_full_except_menu_and_mobile_search_value={this.state.overlay_full_except_menu_and_mobile_search_value}
                          selected_tags={this.state.selected_tags}
                          inputChange={this.inputChange}
                          deleteInput={this.deleteInput}

                          wrapperclick={this.wrapperclick}

                          go_to_link={this.go_to_link}

                          any_popup_showing_stop_video_hover={this.state.any_popup_showing_stop_video_hover}
                       
                          />
                      )}
                    />
                    <Route exact path="/tag/:id" 
                      render={({  match }) => (
                        <UserTags 
                          scrollbarwidth={this.state.scrollbarwidth} 
                          match={match} 
                          show_filters={this.show_filters}
                          //hide_filters={this.hide_filters}
                          history={this.props.history}
                          //reportWindowSize={this.reportWindowSize} 
                          reportWindowSize={this.reportWindowSize_no_left_menu}


                          filters_showing={this.state.filters_showing}
                          mobile_filter_showing={this.state.mobile_filter_showing}
                          scrolling_down={this.state.scrolling_down}
                          detect_scroll_direction={this.detect_scroll_direction}
                          show_blur={this.state.show_blur}
                          theme={this.state.theme}
                          mobile_or_tablet={this.state.mobile_or_tablet}
                          show_overlay={this.state.show_overlay}
                          //User={this.props.User}
                          //show_login_popup={this.show_login_popup}
                          window_height={this.state.window_height}
                          User={this.props.User}
                          show_login_popup={this.show_login_popup}
                          set_scrolly={this.set_scrolly}
                          hide_filters={this.hide_filters}
                          pagination_show_less={this.state.pagination_show_less}
                          set_number_of_filters={this.set_number_of_filters}
                          number_of_filters={this.state.number_of_filters}
                          over_18_popup_showing={this.state.over_18_popup_showing}
                          real_flag_for_mobile_search_input_popup_showing={this.state.real_flag_for_mobile_search_input_popup_showing}
                          overlay_full_except_menu_and_mobile_search_value={this.state.overlay_full_except_menu_and_mobile_search_value}
                          selected_tags={this.state.selected_tags}
                          inputChange={this.inputChange}
                          deleteInput={this.deleteInput}

                          wrapperclick={this.wrapperclick}

                          go_to_link={this.go_to_link}

                          any_popup_showing_stop_video_hover={this.state.any_popup_showing_stop_video_hover}
                        />
                      )}                   
                    />
                    <Route path="/tags/:page" 
                      render={({  match }) => (
                        <UserTags 
                          scrollbarwidth={this.state.scrollbarwidth}
                          match={match} 
                          show_filters={this.show_filters}
                          //hide_filters={this.hide_filters}
                          history={this.props.history}
                          //reportWindowSize={this.reportWindowSize} 
                          reportWindowSize={this.reportWindowSize_no_left_menu}


                          filters_showing={this.state.filters_showing}
                          mobile_filter_showing={this.state.mobile_filter_showing}
                          scrolling_down={this.state.scrolling_down}
                          detect_scroll_direction={this.detect_scroll_direction}
                          show_blur={this.state.show_blur}
                          theme={this.state.theme}
                          mobile_or_tablet={this.state.mobile_or_tablet}
                          show_overlay={this.state.show_overlay}
                          //User={this.props.User}
                          //show_login_popup={this.show_login_popup}
                          window_height={this.state.window_height}
                          User={this.props.User}
                          show_login_popup={this.show_login_popup}
                          set_scrolly={this.set_scrolly}
                          hide_filters={this.hide_filters}
                          set_number_of_filters={this.set_number_of_filters}
                          number_of_filters={this.state.number_of_filters}
                          over_18_popup_showing={this.state.over_18_popup_showing}
                          real_flag_for_mobile_search_input_popup_showing={this.state.real_flag_for_mobile_search_input_popup_showing}
                          overlay_full_except_menu_and_mobile_search_value={this.state.overlay_full_except_menu_and_mobile_search_value}
                          
                          selected_tags={this.state.selected_tags}
                          inputChange={this.inputChange}
                          deleteInput={this.deleteInput}
                       
                          wrapperclick={this.wrapperclick}

                          go_to_link={this.go_to_link}

                          any_popup_showing_stop_video_hover={this.state.any_popup_showing_stop_video_hover}
                          />
                      )}
                    
                    
                    />


                    <Route exact path="/tags/" 
                      render={({  match }) => (
                        <UserTags 
                          scrollbarwidth={this.state.scrollbarwidth}
                          match={match} 
                          show_filters={this.show_filters}
                          //hide_filters={this.hide_filters}
                          history={this.props.history}
                          //reportWindowSize={this.reportWindowSize} 
                          reportWindowSize={this.reportWindowSize_no_left_menu}


                          filters_showing={this.state.filters_showing}
                          mobile_filter_showing={this.state.mobile_filter_showing}
                          scrolling_down={this.state.scrolling_down}
                          detect_scroll_direction={this.detect_scroll_direction}
                          show_blur={this.state.show_blur}
                          theme={this.state.theme}
                          mobile_or_tablet={this.state.mobile_or_tablet}
                          show_overlay={this.state.show_overlay}
                          window_height={this.state.window_height}
                          User={this.props.User}
                          show_login_popup={this.show_login_popup}
                          set_scrolly={this.set_scrolly}
                          
                          hide_filters={this.hide_filters}
                          set_number_of_filters={this.set_number_of_filters}
                          number_of_filters={this.state.number_of_filters}
                          over_18_popup_showing={this.state.over_18_popup_showing}
                          real_flag_for_mobile_search_input_popup_showing={this.state.real_flag_for_mobile_search_input_popup_showing}
                          overlay_full_except_menu_and_mobile_search_value={this.state.overlay_full_except_menu_and_mobile_search_value}
                          
                          //selected_tags={this.state.selected_tags}
                          selected_tags={this.state.selected_tags}
                          inputChange={this.inputChange}
                        
                          deleteInput={this.deleteInput}
                          wrapperclick={this.wrapperclick}

                          go_to_link={this.go_to_link}

                          any_popup_showing_stop_video_hover={this.state.any_popup_showing_stop_video_hover}
                        />
                      )}
                    />
                    <Route
                      path="/channel/:id/:title/:page"
                      render={({  match }) => (
                        <ChannelComponent 

                        match={match} 
                        show_filters={this.show_filters}
                        hide_filters={this.hide_filters}
                        history={this.props.history}
                        //reportWindowSize={this.reportWindowSize} 
                        reportWindowSize={this.reportWindowSize_no_left_menu}


                        //filters_showing={this.state.filters_showing}
                        //mobile_filter_showing={this.state.mobile_filter_showing}
                        //scrolling_down={this.state.scrolling_down}
                        filters_showing={this.state.filters_showing}
                        mobile_filter_showing={this.state.mobile_filter_showing}
                        tablet_filter_showing={this.state.tablet_filter_showing}                          
                        scrolling_down={this.state.scrolling_down}
                        detect_scroll_direction={this.detect_scroll_direction}
                        show_blur={this.state.show_blur}
                        theme={this.state.theme}
                        mobile_or_tablet={this.state.mobile_or_tablet}
                        show_overlay={this.state.show_overlay}
                        //User={this.props.User}
                        //show_login_popup={this.show_login_popup}
                        window_height={this.state.window_height}
                        User={this.props.User}
                        show_login_popup={this.show_login_popup}
                        set_scrolly={this.set_scrolly}
                        pagination_show_less={this.state.pagination_show_less}
                        set_number_of_filters={this.set_number_of_filters}
                        number_of_filters={this.state.number_of_filters}

                        over_18_popup_showing={this.state.over_18_popup_showing}
                        ptags={this.state.ptags_initial}
                        real_flag_for_mobile_search_input_popup_showing={this.state.real_flag_for_mobile_search_input_popup_showing}
                        overlay_full_except_menu_and_mobile_search_value={this.state.overlay_full_except_menu_and_mobile_search_value}
                        
                        selected_tags={this.state.selected_tags}
                        inputChange={this.inputChange}

                        deleteInput={this.deleteInput}

                        go_to_link={this.go_to_link}

                        any_popup_showing_stop_video_hover={this.state.any_popup_showing_stop_video_hover}
                        />
                      )}
                      // component={ChannelComponent}
                    />
                    <Route 
                      exact path="/channel/:id/:title"  
                      render={({  match }) => (
                        <ChannelComponent 

                          history={this.props.history}
                          //reportWindowSize={this.reportWindowSize} 
                          reportWindowSize={this.reportWindowSize_no_left_menu}
                          show_filters={this.show_filters}
                          hide_filters={this.hide_filters}
                          //filters_showing={this.state.filters_showing}
                          menu_showing={this.state.menu_showing}
                          //scrolling_down={this.state.scrolling_down}
                          filters_showing={this.state.filters_showing}
                          mobile_filter_showing={this.state.mobile_filter_showing}
                          tablet_filter_showing={this.state.tablet_filter_showing}                          
                          scrolling_down={this.state.scrolling_down}
                          detect_scroll_direction={this.detect_scroll_direction}
                          show_blur={this.state.show_blur}
                          theme={this.state.theme}
                          mobile_or_tablet={this.state.mobile_or_tablet}
                          show_overlay={this.state.show_overlay}
                          //User={this.props.User}
                          //show_login_popup={this.show_login_popup}
                          window_height={this.state.window_height}
                          User={this.props.User}
                          show_login_popup={this.show_login_popup}
                          set_scrolly={this.set_scrolly}
                          pagination_show_less={this.state.pagination_show_less}
                          set_number_of_filters={this.set_number_of_filters}
                          number_of_filters={this.state.number_of_filters}

                          over_18_popup_showing={this.state.over_18_popup_showing}
                          ptags={this.state.ptags_initial}
                          real_flag_for_mobile_search_input_popup_showing={this.state.real_flag_for_mobile_search_input_popup_showing}
                          overlay_full_except_menu_and_mobile_search_value={this.state.overlay_full_except_menu_and_mobile_search_value}
                        
                          selected_tags={this.state.selected_tags}
                          inputChange={this.inputChange}

                          deleteInput={this.deleteInput}

                          go_to_link={this.go_to_link}

                          any_popup_showing_stop_video_hover={this.state.any_popup_showing_stop_video_hover}
                        />
                      )}
                    />
                    <Route 
                      exact path="/channel/:id" 
                      render={({  match }) => (
                        <ChannelComponent 

                        match={match} 
                        show_filters={this.show_filters}
                        hide_filters={this.hide_filters}
                        history={this.props.history}
                        //reportWindowSize={this.reportWindowSize} 
                        reportWindowSize={this.reportWindowSize_no_left_menu} 


                        filters_showing={this.state.filters_showing}
                        mobile_filter_showing={this.state.mobile_filter_showing}
                        scrolling_down={this.state.scrolling_down}
                        detect_scroll_direction={this.detect_scroll_direction}
                        show_blur={this.state.show_blur}
                        theme={this.state.theme}
                        mobile_or_tablet={this.state.mobile_or_tablet}
                        show_overlay={this.state.show_overlay}
                        //User={this.props.User}
                        //show_login_popup={this.show_login_popup}
                        window_height={this.state.window_height}
                        User={this.props.User}
                        show_login_popup={this.show_login_popup}
                        set_scrolly={this.set_scrolly}
                        pagination_show_less={this.state.pagination_show_less}
                        set_number_of_filters={this.set_number_of_filters}
                        number_of_filters={this.state.number_of_filters}

                        over_18_popup_showing={this.state.over_18_popup_showing}
                        real_flag_for_mobile_search_input_popup_showing={this.state.real_flag_for_mobile_search_input_popup_showing}
                        //ptags={this.state.ptags_initial}
                        overlay_full_except_menu_and_mobile_search_value={this.state.overlay_full_except_menu_and_mobile_search_value}
                        
                        selected_tags={this.state.selected_tags}
                        inputChange={this.inputChange}

                        deleteInput={this.deleteInput}

                        go_to_link={this.go_to_link}

                        any_popup_showing_stop_video_hover={this.state.any_popup_showing_stop_video_hover}
                        />
                      )}
                    />

                    <Route 
                      path="/watchlist/:page" 
                      render={({  match }) => (
                        <WatchlistComponent 

                          match={match} 
                          User={this.props.User}
                          show_overlay={this.state.show_overlay}
                          show_login_popup={this.show_login_popup}
                          history={this.props.history}
                          
                          reportWindowSize={this.reportWindowSize} 
                          show_filters={this.show_filters}
                          hide_filters={this.hide_filters}
                          filters_showing={this.state.filters_showing}
                          mobile_filter_showing={this.state.mobile_filter_showing}
                          scrolling_down={this.state.scrolling_down}
                          detect_scroll_direction={this.detect_scroll_direction}
                          show_blur={this.state.show_blur}
                          theme={this.state.theme}
                          mobile_or_tablet={this.state.mobile_or_tablet}
                          window_height={this.state.window_height}
                          pagination_show_less={this.state.pagination_show_less}
                          set_number_of_filters={this.set_number_of_filters}
                          number_of_filters={this.state.number_of_filters}
                          over_18_popup_showing={this.state.over_18_popup_showing}
                          ptags={this.state.ptags_initial}
                          real_flag_for_mobile_search_input_popup_showing={this.state.real_flag_for_mobile_search_input_popup_showing}
                          overlay_full_except_menu_and_mobile_search_value={this.state.overlay_full_except_menu_and_mobile_search_value}
                          selected_tags={this.state.selected_tags}
                          inputChange={this.inputChange}

                          deleteInput={this.deleteInput}

                          go_to_link={this.go_to_link}

                          any_popup_showing_stop_video_hover={this.state.any_popup_showing_stop_video_hover}
                          />
                      )}
                    />
                    <Route

                    exact path="/watchlist/"
                          render={({  match }) => (
                          <WatchlistComponent 

                            match={match} 
                           // window_innerheight_refreshed={this.state.window_innerheight_refreshed}
                            history={this.props.history}
                            reportWindowSize={this.reportWindowSize} 
                            show_filters={this.show_filters}
                            hide_filters={this.hide_filters}
                            filters_showing={this.state.filters_showing}
                            mobile_filter_showing={this.state.mobile_filter_showing}
                            scrolling_down={this.state.scrolling_down}
                            detect_scroll_direction={this.detect_scroll_direction}
                            show_blur={this.state.show_blur}
                            User={this.props.User}
                            show_overlay={this.state.show_overlay}
                            show_login_popup={this.show_login_popup}
                            theme={this.state.theme}
                            mobile_or_tablet={this.state.mobile_or_tablet}
                            window_height={this.state.window_height}
                            pagination_show_less={this.state.pagination_show_less}
                            set_number_of_filters={this.set_number_of_filters}
                            number_of_filters={this.state.number_of_filters}
                            over_18_popup_showing={this.state.over_18_popup_showing}
                            ptags={this.state.ptags_initial}
                            real_flag_for_mobile_search_input_popup_showing={this.state.real_flag_for_mobile_search_input_popup_showing}
                            overlay_full_except_menu_and_mobile_search_value={this.state.overlay_full_except_menu_and_mobile_search_value}
                            
                            selected_tags={this.state.selected_tags}
                            inputChange={this.inputChange}

                            deleteInput={this.deleteInput}

                            go_to_link={this.go_to_link}

                            any_popup_showing_stop_video_hover={this.state.any_popup_showing_stop_video_hover}
                            />
                        )}
                    />
                    <Route
                        path="/watched/:page"
                          render={({  match }) => (
                          <WatchedComponent 
                            // {...props}
                            match={match} 
                            User={this.props.User}
                            show_login_popup={this.show_login_popup}
                            history={this.props.history}
                            reportWindowSize={this.reportWindowSize} 
                            show_filters={this.show_filters}
                            hide_filters={this.hide_filters}
                            filters_showing={this.state.filters_showing}
                            mobile_filter_showing={this.state.mobile_filter_showing}
                            scrolling_down={this.state.scrolling_down}
                            detect_scroll_direction={this.detect_scroll_direction}
                            show_blur={this.state.show_blur}
                            theme={this.state.theme}
                            mobile_or_tablet={this.state.mobile_or_tablet}
                            show_overlay={this.state.show_overlay}
                            window_height={this.state.window_height}
                            pagination_show_less={this.state.pagination_show_less}

                            set_number_of_filters={this.set_number_of_filters}
                            number_of_filters={this.state.number_of_filters}
                            over_18_popup_showing={this.state.over_18_popup_showing}
                            ptags={this.state.ptags_initial}
                            real_flag_for_mobile_search_input_popup_showing={this.state.real_flag_for_mobile_search_input_popup_showing}
                            overlay_full_except_menu_and_mobile_search_value={this.state.overlay_full_except_menu_and_mobile_search_value}
                            
                            selected_tags={this.state.selected_tags}
                            inputChange={this.inputChange}

                            deleteInput={this.deleteInput}

                            go_to_link={this.go_to_link}

                            any_popup_showing_stop_video_hover={this.state.any_popup_showing_stop_video_hover}
                            />
                        )}
                      // path="/watched/:page"
                      // component={WatchedComponent}
                    />
                    <Route
                        exact path="/watched"
                          render={({  match }) => (
                          <WatchedComponent 
                            // {...props}
                            match={match} 
                            User={this.props.User}
                            show_login_popup={this.show_login_popup}
                            history={this.props.history}
                            reportWindowSize={this.reportWindowSize} 
                            show_filters={this.show_filters}
                            hide_filters={this.hide_filters}
                            filters_showing={this.state.filters_showing}
                            mobile_filter_showing={this.state.mobile_filter_showing}
                            scrolling_down={this.state.scrolling_down}
                            detect_scroll_direction={this.detect_scroll_direction}
                            show_blur={this.state.show_blur}
                            theme={this.state.theme}
                            mobile_or_tablet={this.state.mobile_or_tablet}
                            show_overlay={this.state.show_overlay}
                            window_height={this.state.window_height}
                            pagination_show_less={this.state.pagination_show_less}

                            set_number_of_filters={this.set_number_of_filters}
                            number_of_filters={this.state.number_of_filters}
                            over_18_popup_showing={this.state.over_18_popup_showing}
                            ptags={this.state.ptags_initial}
                            real_flag_for_mobile_search_input_popup_showing={this.state.real_flag_for_mobile_search_input_popup_showing}
                            overlay_full_except_menu_and_mobile_search_value={this.state.overlay_full_except_menu_and_mobile_search_value}
                            
                            selected_tags={this.state.selected_tags}
                            inputChange={this.inputChange}

                            deleteInput={this.deleteInput}

                            go_to_link={this.go_to_link}

                            any_popup_showing_stop_video_hover={this.state.any_popup_showing_stop_video_hover}
                            />
                        )}
                      // path="/watched/:page"
                      // component={WatchedComponent}
                    />
                    {/* <Route path="/watched/" component={WatchedComponent} /> */}
                    <Route
                      path="/videos/:page"
                      render={({  match }) => (
                        <ShowVideos 
                          // {...props}
                          match={match} 
                          theme={this.state.theme}
                          history={this.props.history}
                          reportWindowSize={this.reportWindowSize} 
                          show_filters={this.show_filters}
                          hide_filters={this.hide_filters}
                          
                          filters_showing={this.state.filters_showing}
                          menu_showing={this.state.menu_showing}
                          mobile_filter_showing={this.state.mobile_filter_showing}
                          scrolling_down={this.state.scrolling_down}
                          detect_scroll_direction={this.detect_scroll_direction}
                          show_blur={this.state.show_blur}
                          show_overlay={this.state.show_overlay}
                          mobile_or_tablet={this.state.mobile_or_tablet}
                          User={this.props.User}
                          show_login_popup={this.show_login_popup}
                          window_height={this.state.window_height}
                          set_scrolly={this.set_scrolly}
                          pagination_show_less={this.state.pagination_show_less}
                          set_number_of_filters={this.set_number_of_filters}
                          number_of_filters={this.state.number_of_filters}
                          over_18_popup_showing={this.state.over_18_popup_showing}
                          ptags={this.state.ptags_initial}
                          real_flag_for_mobile_search_input_popup_showing={this.state.real_flag_for_mobile_search_input_popup_showing}
                          overlay_full_except_menu_and_mobile_search_value={this.state.overlay_full_except_menu_and_mobile_search_value}
                         
                          selected_tags={this.state.selected_tags}
                          inputChange={this.inputChange}

                          deleteInput={this.deleteInput}

                          go_to_link={this.go_to_link}
                          any_popup_showing_stop_video_hover={this.state.any_popup_showing_stop_video_hover}
                          
                          />
                      )}
                    />
                    <Route
                      exact path="/videos/"
                      render={({  match }) => (
                        <ShowVideos 
                          // {...props}
                          match={match} 
                          theme={this.state.theme}
                          history={this.props.history}
                          reportWindowSize={this.reportWindowSize} 
                          show_filters={this.show_filters}
                          hide_filters={this.hide_filters}
                          filters_showing={this.state.filters_showing}
                          mobile_filter_showing={this.state.mobile_filter_showing}
                          scrolling_down={this.state.scrolling_down}
                          detect_scroll_direction={this.detect_scroll_direction}
                          show_blur={this.state.show_blur}
                          //mobile_or_tablet={this.state.mobile_or_tablet}
                          show_overlay={this.state.show_overlay}
                          User={this.props.User}
                          show_login_popup={this.show_login_popup}
                          mobile_or_tablet={this.state.mobile_or_tablet}
                          window_height={this.state.window_height}
                          set_scrolly={this.set_scrolly}
                          pagination_show_less={this.state.pagination_show_less}
                          set_number_of_filters={this.set_number_of_filters}
                          number_of_filters={this.state.number_of_filters}
                          over_18_popup_showing={this.state.over_18_popup_showing}
                          ptags={this.state.ptags_initial}
                          real_flag_for_mobile_search_input_popup_showing={this.state.real_flag_for_mobile_search_input_popup_showing}
                          overlay_full_except_menu_and_mobile_search_value={this.state.overlay_full_except_menu_and_mobile_search_value}
                          
                          selected_tags={this.state.selected_tags}
                          inputChange={this.inputChange}

                          deleteInput={this.deleteInput}

                          go_to_link={this.go_to_link}

                          any_popup_showing_stop_video_hover={this.state.any_popup_showing_stop_video_hover}
                          />
                      )}
                    />
                    <Route
                        exact path="/terms-of-use/"
                        render={({  match }) => (
                          <TermsOfUseComponent 
                            // {...props}
                            match={match} 
                            theme={this.state.theme}
                            history={this.props.history}
                            reportWindowSize={this.reportWindowSize_no_left_menu} 
                            show_filters={this.show_filters}
                            hide_filters={this.hide_filters}
                            filters_showing={this.state.filters_showing}
                            mobile_filter_showing={this.state.mobile_filter_showing}
                            scrolling_down={this.state.scrolling_down}
                            detect_scroll_direction={this.detect_scroll_direction}
                            show_blur={this.state.show_blur}
                           //mobile_or_tablet={this.state.mobile_or_tablet}
                            show_overlay={this.state.show_overlay}
                            User={this.props.User}
                            show_login_popup={this.show_login_popup}
                            mobile_or_tablet={this.state.mobile_or_tablet}
                            />
                        )}
                      />
                      <Route
                      path="/search/:search_string/:page"
                      render={({  match }) => (
                        <SearchPageComponent 
                          // {...props}
                          match={match} 
                          theme={this.state.theme}
                          history={this.props.history}
                          reportWindowSize={this.reportWindowSize} 
                          show_filters={this.show_filters}
                          hide_filters={this.hide_filters}
                          filters_showing={this.state.filters_showing}
                          menu_showing={this.state.menu_showing}
                          mobile_filter_showing={this.state.mobile_filter_showing}
                          scrolling_down={this.state.scrolling_down}
                          detect_scroll_direction={this.detect_scroll_direction}
                          show_blur={this.state.show_blur}
                          show_overlay={this.state.show_overlay}
                          mobile_or_tablet={this.state.mobile_or_tablet}
                          User={this.props.User}
                          show_login_popup={this.show_login_popup}
                          window_height={this.state.window_height}
                          set_scrolly={this.set_scrolly}
                          pagination_show_less={this.state.pagination_show_less}
                          go_to_link={this.go_to_link}

                          over_18_popup_showing={this.state.over_18_popup_showing}
                          ptags={this.state.ptags_initial}
                          real_flag_for_mobile_search_input_popup_showing={this.state.real_flag_for_mobile_search_input_popup_showing}
                          overlay_full_except_menu_and_mobile_search_value={this.state.overlay_full_except_menu_and_mobile_search_value}
                          any_popup_showing_stop_video_hover={this.state.any_popup_showing_stop_video_hover}

                          selected_tags={this.state.selected_tags}
                          inputChange={this.inputChange}

                          deleteInput={this.deleteInput}

                     
                          />
                      )}
                    />
                      <Route
                      path="/search/:search_string"
                      render={({  match }) => (
                        <SearchPageComponent 
                          // {...props}
                          match={match} 
                          theme={this.state.theme}
                          history={this.props.history}
                          reportWindowSize={this.reportWindowSize} 
                          show_filters={this.show_filters}
                          hide_filters={this.hide_filters}
                          filters_showing={this.state.filters_showing}
                          menu_showing={this.state.menu_showing}
                          mobile_filter_showing={this.state.mobile_filter_showing}
                          scrolling_down={this.state.scrolling_down}
                          detect_scroll_direction={this.detect_scroll_direction}
                          show_blur={this.state.show_blur}
                          show_overlay={this.state.show_overlay}
                          mobile_or_tablet={this.state.mobile_or_tablet}
                          User={this.props.User}
                          show_login_popup={this.show_login_popup}
                          window_height={this.state.window_height}
                          set_scrolly={this.set_scrolly}
                          pagination_show_less={this.state.pagination_show_less}
                          go_to_link={this.go_to_link}
                          over_18_popup_showing={this.state.over_18_popup_showing}
                          ptags={this.state.ptags_initial}
                          real_flag_for_mobile_search_input_popup_showing={this.state.real_flag_for_mobile_search_input_popup_showing}
                          overlay_full_except_menu_and_mobile_search_value={this.state.overlay_full_except_menu_and_mobile_search_value}
                          any_popup_showing_stop_video_hover={this.state.any_popup_showing_stop_video_hover}
                          
                          selected_tags={this.state.selected_tags}
                          inputChange={this.inputChange}

                          deleteInput={this.deleteInput}
                          />
                      )}
                    />
                    
                      <Route
                        exact path="/privacy-policy"
                        render={({  match }) => (
                          <PrivacyPolicyComponent 
                            // {...props}
                            match={match} 
                            theme={this.state.theme}
                            history={this.props.history}
                            reportWindowSize={this.reportWindowSize_no_left_menu} 
                            show_filters={this.show_filters}
                            hide_filters={this.hide_filters}
                            filters_showing={this.state.filters_showing}
                            mobile_filter_showing={this.state.mobile_filter_showing}
                            scrolling_down={this.state.scrolling_down}
                            detect_scroll_direction={this.detect_scroll_direction}
                            show_blur={this.state.show_blur}
                            //mobile_or_tablet={this.state.mobile_or_tablet}
                            show_overlay={this.state.show_overlay}
                            User={this.props.User}
                            show_login_popup={this.show_login_popup}
                            mobile_or_tablet={this.state.mobile_or_tablet}
                            />
                        )}
                      />
                      <Route
                        exact path="/cookie-policy"
                        render={({  match }) => (
                          <CookiePolicyComponent 
                            // {...props}
                            match={match} 
                            theme={this.state.theme}
                            history={this.props.history}
                            reportWindowSize={this.reportWindowSize_no_left_menu} 
                            show_filters={this.show_filters}
                            hide_filters={this.hide_filters}
                            filters_showing={this.state.filters_showing}
                            mobile_filter_showing={this.state.mobile_filter_showing}
                            scrolling_down={this.state.scrolling_down}
                            detect_scroll_direction={this.detect_scroll_direction}
                            show_blur={this.state.show_blur}
                            //mobile_or_tablet={this.state.mobile_or_tablet}
                            show_overlay={this.state.show_overlay}
                            User={this.props.User}
                            show_login_popup={this.show_login_popup}
                            mobile_or_tablet={this.state.mobile_or_tablet}
                            />
                        )}
                      />
                      <Route
                        exact path="/contact-us"
                        render={({  match }) => (
                          <ContactUsComponent 
                            // {...props}
                            match={match} 
                            theme={this.state.theme}
                            history={this.props.history}
                            reportWindowSize={this.reportWindowSize_no_left_menu} 
                            show_filters={this.show_filters}
                            hide_filters={this.hide_filters}
                            filters_showing={this.state.filters_showing}
                            mobile_filter_showing={this.state.mobile_filter_showing}
                            scrolling_down={this.state.scrolling_down}
                            detect_scroll_direction={this.detect_scroll_direction}
                            show_blur={this.state.show_blur}
                            //mobile_or_tablet={this.state.mobile_or_tablet}
                            show_overlay={this.state.show_overlay}
                            User={this.props.User}
                            show_login_popup={this.show_login_popup}
                            mobile_or_tablet={this.state.mobile_or_tablet}
                            />
                        )}
                      />

                    {/* <Route exact path="/videos" component={ShowVideos} /> */}

                    <Route
                      exact path="/"
                      render={({  match }) => (
                        <ShowVideos 
                          // {...props}
                          match={match} 
                          theme={this.state.theme}
                          history={this.props.history}
                          reportWindowSize={this.reportWindowSize} 
                          show_filters={this.show_filters}
                          hide_filters={this.hide_filters}
                          filters_showing={this.state.filters_showing}
                          mobile_filter_showing={this.state.mobile_filter_showing}
                          scrolling_down={this.state.scrolling_down}
                          detect_scroll_direction={this.detect_scroll_direction}
                          show_blur={this.state.show_blur}
                          //mobile_or_tablet={this.state.mobile_or_tablet}
                          show_overlay={this.state.show_overlay}
                          User={this.props.User}
                          show_login_popup={this.show_login_popup}
                          mobile_or_tablet={this.state.mobile_or_tablet}
                          window_height={this.state.window_height}
                          set_scrolly={this.set_scrolly}
                          pagination_show_less={this.state.pagination_show_less}
                          over_18_popup_showing={this.state.over_18_popup_showing}
                          ptags={this.state.ptags_initial}
                          real_flag_for_mobile_search_input_popup_showing={this.state.real_flag_for_mobile_search_input_popup_showing}
                          overlay_full_except_menu_and_mobile_search_value={this.state.overlay_full_except_menu_and_mobile_search_value}
                          set_number_of_filters={this.set_number_of_filters}
                          selected_tags={this.state.selected_tags}          
                          inputChange={this.inputChange}
                          deleteInput={this.deleteInput}

                          go_to_link={this.go_to_link}

                          any_popup_showing_stop_video_hover={this.state.any_popup_showing_stop_video_hover}
                          />
                      )}
                    />

                  <Route
                      exact path="/set_blur_on"
                      render={({  match }) => (
                        <SetBlurOnComponent 
                          // {...props}
                          match={match} 

                          />
                      )}                      
                    />
                    <Route
                      exact path="/reset_password"
                      render={({  match }) => (
                        <ResetPasswordComponent 
                        match={match} 
                        filters_showing={this.state.filters_showing}
                        theme={this.state.theme}
                        set_theme_light={this.set_theme_light}
                        set_theme_dark={this.set_theme_dark}
                        //reportWindowSize={this.reportWindowSize}
                        reportWindowSize={this.reportWindowSize_no_left_menu}
                        User={this.props.User} 
                        set_mobile_filter_showing={this.set_mobile_filter_showing}
                        show_overlay={this.state.show_overlay}
                        login_showSpinner= {this.state.login_showSpinner}
                        login_rememberme= {this.state.login_rememberme}
                        login_showPassword1clearText={this.state.login_showPassword1clearText}
                        login={this.login}
                        
                        //login_showError= 0
                        login_error_message= {this.state.login_error_message}
                        login_change_showPassword1clearText={this.login_change_showPassword1clearText}
                        login_change_rememberme={this.login_change_rememberme}
                        reportWindowSize_login={this.reportWindowSize_login}
                        go_to_link={this.go_to_link}
                        google_login={this.google_login}
                        mobile_or_tablet={this.state.mobile_or_tablet}
                        mobile_filter_showing ={this.state.mobile_filter_showing} 

                        real_flag_for_mobile_search_input_popup_showing={this.state.real_flag_for_mobile_search_input_popup_showing}
                          />
                      )}                      
                    />
                    
                     <Route
                      exact path="/set_blur_off"
                      render={({  match }) => (
                        <SetBlurOffComponent 
                          // {...props}
                          match={match} 

                          />
                      )}
                    />
                    <Route
                      path="/admin/"
                     
                    />
                    <Route
                        key="2" path="/"
                        render={({  match }) => (
                          <Component404 
                            // {...props}
                            match={match} 
                            theme={this.state.theme}
                            history={this.props.history}
                            reportWindowSize={this.reportWindowSize_no_left_menu} 
                            show_filters={this.show_filters}
                            hide_filters={this.hide_filters}
                            filters_showing={this.state.filters_showing}
                            mobile_filter_showing={this.state.mobile_filter_showing}
                            scrolling_down={this.state.scrolling_down}
                            detect_scroll_direction={this.detect_scroll_direction}
                            show_blur={this.state.show_blur}
                            mobile_or_tablet={this.state.mobile_or_tablet}
                            //show_overlay={this.state.show_overlay}
                            //User={this.props.User}
                            //show_login_popup={this.show_login_popup}
                            show_overlay={this.state.show_overlay}
                            User={this.props.User}
                            show_login_popup={this.show_login_popup}
                            
                            any_popup_showing_stop_video_hover={this.state.any_popup_showing_stop_video_hover}
                            />
                        )}
                      />
                       {/* <Route component={Component404} /> */}

                    {/* <Route path="/:page" component={ShowVideos} /> */}
                    </Switch>
                </Suspense>
                </>
            </ThemeProvider>
         
                
              
          {/* && this.props.show_footer == -1 */}

          {/* was like this: {this.state.mobile_or_tablet != -1 && this.props.show_footer == 1  ?, it 
            hides the footer on link click for a second and the page jumps */}
          
            </div>
            
          
            {/* {this.state.mobile_or_tablet != -1  ?
            <div className={this.state.filters_showing == 1 || this.state.show_overlay == 1 ? "overlay" : ""}>
                <FooterComponent 
                  mobile_filter_showing={this.state.mobile_filter_showing}
                  mobile_or_tablet={this.state.mobile_or_tablet}
                  show_overlay={this.state.show_overlay}  
                  cookies_dialog_ok={this.state.cookies_dialog_ok}
                  filters_showing={this.state.filters_showing}
                  theme={this.state.theme}
                  ></FooterComponent>
              </div>  
          : "" }   */}
        </div>
        <CookiesDialog
                mobile_filter_showing={this.state.mobile_filter_showing}
                cookies_dialog_ok={this.state.cookies_dialog_ok}
                go_to_link={this.go_to_link}
                show_overlay={this.state.show_overlay}
                filters_showing={this.state.filters_showing} 
                set_cookies_dialog={this.set_cookies_dialog}
                padding_px={this.state.padding_px_larger_screens}
                theme={this.state.theme}
              ></CookiesDialog>
              </div>
            </div>
      </div>
      <div id="overlay_full_except_menu"
                    style={{
                    //  opacity:'0.5',
                    opacity: this.state.theme == lightTheme ? "0.4" : "0.75",
                      display:this.state.filters_showing == 1 && this.state.menu_showing == 0 ? " block" : "none",//backgroundColor: this.state.filters_showing == 1 || this.state.show_overlay == 1? 
                      backgroundColor: this.state.filters_showing == 1 && this.state.menu_showing == 0? 
                this.state.theme == lightTheme ? 
                    configData.OVERLAY_BACKGROUND_COLOR_WHITE_THEME : configData.OVERLAY_BACKGROUND_COLOR_DARK_THEME : "" 
                        }}
                 onClick={() => this.wrapperclick()}
                  ></div>
 <div id="overlay_full_except_menu_and_mobile_search"
                    style={{
                    //  opacity:'0.5',
                    opacity: this.state.theme == lightTheme ? "0.4" : "0.75",
                      display:this.state.overlay_full_except_menu_and_mobile_search_value == 1 ? " block" : "none",//backgroundColor: this.state.filters_showing == 1 || this.state.show_overlay == 1? 
                      backgroundColor: this.state.overlay_full_except_menu_and_mobile_search_value == 1 ? 
                this.state.theme == lightTheme ? 
                    configData.OVERLAY_BACKGROUND_COLOR_WHITE_THEME : configData.OVERLAY_BACKGROUND_COLOR_DARK_THEME : "" 
                        }}
                 onClick={() => this.wrapperclick()}
                  ></div>

      <div id="overlay_full"
                    style={{
                     // opacity:'0.5',
                     opacity: this.state.theme == lightTheme ? "0.4" : "0.75",
                      display: this.state.show_overlay == 1 ||  this.state.menu_showing == 1 ? " block" : "none",//backgroundColor: this.state.filters_showing == 1 || this.state.show_overlay == 1? 
                      backgroundColor:  this.state.show_overlay == 1 ||  this.state.menu_showing == 1 ? 
                this.state.theme == lightTheme ? 
                    configData.OVERLAY_BACKGROUND_COLOR_WHITE_THEME : configData.OVERLAY_BACKGROUND_COLOR_DARK_THEME : "" 
                        }}
                 onClick={() => this.wrapperclick()}
                  ></div>
      <div className="cookies_dialog_ok_dummy_placeholder" 
        style={{
          // display: this.state.cookies_dialog_ok == 1 ?  "none" : "",
          display: this.state.cookies_dialog_ok == 1 || this.state.filters_showing == 1  || this.state.show_overlay ?  "none" : "",
                // for some reason there is extra space in bottom in pornstars/ page, this is why this ugly fix is in place 
          height: this.state.mobile_filter_showing == 1 ? "74px" : "45px",
          //window.location.pathname.split('/')[1] == 'pornstars'? "45px" : "45px",
          zIndex: '9'
          }}>

        </div>

        {/* <div style="display: none;"> */}

          <img src="https://cdn2.balaporn.com/content/images/tags_dark.svg"  style={{display:"none"}}/>
          <img src="https://cdn2.balaporn.com/content/images/pornstars_dark.svg" style={{display:"none"}}/>
          <img src="https://cdn2.balaporn.com/content/images/channels_dark.svg" style={{display:"none"}}/>
      {/* </div> */}
      
      {/* <div style="visibility: hidden;">

          <img rel="preload" src="https://cdn2.balaporn.com/content/images/tags_dark.svg" as="image" />
          <img rel="preload" src="https://cdn2.balaporn.com/content/images/pornstars_dark.svg" as="image" />
          <img rel="preload" src="https://cdn2.balaporn.com/content/images/channels_dark.svg" as="image" />
      </div> */}
      {/* <CookiesDialog>
        </CookiesDialog> */}
        
      </React.Fragment>
    
    );
    
  }
}

export default withRouter(UserNavBar);