import React, { Component } from "react";
import "./../../../App.css";
import configData from "../../../config.json";
import GetTagAsyncComponent from "./../get_tags_async/get_tags_async";
import { title_to_url } from "../../global_arrays";
import QualityComponent from './quality'
import Slider from '@material-ui/core/Slider';
import { lightTheme, darkTheme } from './../../theme';

// const useStyles = makeStyles({
//   root: {
//     width: 300,
//   },
// });

function valuetext(value) {

  return value < 40 ? value : value+"+";
}


class TagsLeftMenu extends Component {

  
  
  state = {   
    };

    shouldComponentUpdate(nextProps, nextState) {
      

      if(
          this.props.quality_all != nextProps.quality_all ||
          this.props.quality_hd != nextProps.quality_hd ||
          this.props.quality_fourk != nextProps.quality_fourk ||
          this.props.quality_vr != nextProps.quality_vr ||
          this.props.quality_non_hd != nextProps.quality_non_hd ||
          this.props.mins != nextProps.mins || 
          this.props.showTags != nextProps.showTags  ||
          this.props.filters_showing != nextProps.filters_showing ||
          this.props.theme != nextProps.theme ||
          this.props.mobile_or_tablet != nextProps.mobile_or_tablet ||
          this.props.show_overlay != nextProps.show_overlay || 
          this.props.reload_usermenu != nextProps.reload_usermenu ||
          this.props.Ptags != nextProps.Ptags ||
          this.props.selected_tags != nextProps.selected_tags ||
          this.props.mobile_filter_showing != nextProps.mobile_filter_showing
           
          ) {
              //console.log("yes  - FUCKIN RENDER"+this.props.videos)
              return true
      }
      // console.log("no - DO NOT RENDER")
      return false
  }

    

  render() {        
    return (
      <div className={this.props.show_overlay == 1   ? "" : ""} style={{ }}
      >


            <div 
              id={ this.props.is_main_page == 0 ? "filtersLeft":"tagsLeft"}

              //id="tagsLeft"
              className="tagsLeft scrollbar"
              onMouseEnter={() => {if (this.props.mobile_or_tablet != 1) document.getElementsByClassName("tagsLeft")[0].style.overflowY = "auto"}}
                onMouseLeave={() => {if (this.props.mobile_or_tablet != 1)  document.getElementsByClassName("tagsLeft")[0].style.overflowY = "hidden "}}
              onClick={(event) => event.stopPropagation()} // helps to close the menu via click on wrapper. This cancels execute 
                                                        //wrappers onclick
              //style={{height: this.props.mobile_or_tablet == 1 ?  (window.innerHeight - 62)+"px" : document.innerHeight+"px" }}
              style={{ height: this.props.mobile_or_tablet == 1 ?  (window.innerHeight - 62)+"px" 
              : (document.innerHeight+44)+"px" ,
              //marginLeft: "10px",
              // backgroundColor: this.props.filters_showing == 0 && this.props.show_overlay == 1 ? 
              //             this.props.theme == lightTheme ? 
              //             configData.OVERLAY_BACKGROUND_COLOR_WHITE_THEME : configData.OVERLAY_BACKGROUND_COLOR_DARK_THEME
                          
              //             : "",
              position:"fixed",
              top:"58px",  zIndex: 0,
              paddingLeft: this.props.mobile_or_tablet == 0 ? '' : 
                  this.props.mobile_filter_showing == 1 ?  "24px" : '32px'

            }}
              //(document.innerHeight+44)+"px" }}
            
              // style={{height: (window.innerHeight - 62)+"px" }}
              >
                
                {/* <div style={{borderRight: "2px solid red", height: "4500px", display: "inline", position:"absolute"}}></div>
                 */}
                <div
                // style={{borderRight: "2px solid red", position:"relative", marginBottom: "-500px",bottom: "0px", display: "block"}}
                >
              
              {this.props.filters_showing == 1 || this.props.mobile_or_tablet == 1? 
              <a href="javascript:void(0)" onClick={() => this.props.hide_filters(1,1)}
              style={{textDecoration: "none", display: "inline-block",marginTop: "14px",marginBottom: "7px"}}
              className="icon_close_x_div_wrapper_href"
              
              title="Close"
            >
                <div
                  className="icon_close_x_div_wrapper"
                  style={{marginLeft: "-2px", marginTop: "5px", backgroundColor: 'purple !important',}}>             
                  </div>
                </a>
                
                : ""}
              
              
              
              <div className="leftMenuElement" 
                style={{marginTop: "8px",}}
              > 
                    <a
                            href={this.props.User != null && this.props.User != "Unauthorized" 
                            ? "/watchlist": "javascript:void(0)"}
                            
                            onClick={(event) => this.props.User != null && this.props.User != "Unauthorized" ? 
                            this.props.go_to_link(event, "/watchlist", 1) : this.props.show_login_popup("/watchlist")}
                            style={{textDecoration: "none"}}
                            className={document.location.href.split("/")[3].includes("watchlist")  ? "menu_href_color watchlist_leftmenu" : "tags_left_menu_href watchlist_leftmenu"}
                          >
                  
                      
                      <h4 className="body14 ">
                      <div className={document.location.href.split("/")[3].includes("watchlist")  ? "heart_left_menu active" : "heart_left_menu"}
                                      style={{
                                        position:"relative",
                                        float:'left',
                                        marginTop: "0px",
                                        marginRight: "8px",
                                        marginLeft: "0px", 
                                        width: "16px",
                                        height: '16px',
                                      }}></div>                    
                         <div style={{marginTop: "1px",}}> My Watchlist</div>
                          
                        </h4>
                        </a>
                  
              </div>
              <div className="leftMenuElement" style={{marginTop:"8px"}}
                  >
                <a 
                    href={this.props.User != null && this.props.User != "Unauthorized" 
                    ? "/watched": "javascript:void(0)"}
                    
                    onClick={(event) => this.props.User != null && this.props.User != "Unauthorized" ? 
                    this.props.go_to_link(event, "/watched",1) : this.props.show_login_popup("/watched")}
                    //onClick={(event) => this.props.go_to_link(event, "/watched")}
                    style={{textDecoration: "none"}}
                    className={document.location.href.split("/")[3].includes("watched")  ? "menu_href_color watched_left_menu_div " : "tags_left_menu_href watched_left_menu_div"}
                  >
                
                      <h4 className="body14">
                      <div className={document.location.href.split("/")[3].includes("watched") ? "watched_left_menu_icon active" : "watched_left_menu_icon"} 
                                      style={{
                                        position:"relative",
                                        float:'left',
                                        marginTop: "0px",
                                        marginRight: "8px",
                                        marginLeft: "1px",  
                                        width: "16px",
                                        height: '16px',
                                      }}
                                    ></div><div style={{marginTop: "1px",}}>Watched videos</div>                  
                      </h4>
                
                </a>
              </div>
            
              <h4 className="sub12 pornstar_channel_in_view_video_href_class" style={{marginTop:"24px",}}> DURATION(MINUTES):</h4>
              <Slider
                value={this.props.mins}
                max={40}
                step={1}
                style={{width:"202px",marginLeft: "5px", color:  this.props.theme == lightTheme ?  "#5724E6" : "#9F7DFF",zIndex: "9999"}}
                className="purple_href"
              // onMouseUp={() => this.props.performQuery()}
                onClick={(event) => event.stopPropagation()}
                onChange={this.props.handleMinsChange}
                onChangeCommitted={() => this.props.performQuery()}
                
                valueLabelDisplay={ "auto" }
                valueLabelFormat={valuetext}
                aria-labelledby="range-slider"
                getAriaValueText={valuetext}
            />

            <h4 className="tags_left_menu_href caps12" style={{marginTop: "-13px", }}>
              0
            </h4>
            <h4 className="tags_left_menu_href caps12" style={{marginTop: "-22px", marginLeft: "48px"}}>
              10
            </h4>
            <h4 className="tags_left_menu_href caps12" style={{marginTop: "-21px", marginLeft: "99px"}}>
              20
            </h4>
            <h4 className="tags_left_menu_href caps12" style={{marginTop: "-22px", marginLeft: "148px"}}>
              30
            </h4>
            <h4 className="tags_left_menu_href caps12" style={{marginTop: "-22px", marginLeft: "193px"}}>
              40+
            </h4>
            
            
            <div style={{display:"inline-block", marginLeft: "-5px",marginTop:"14px",marginBottom:"14px"}}>
              <QualityComponent
                  quality_clicked={this.props.quality_clicked}
                  quality_all={this.props.quality_all}
                  quality_hd={this.props.quality_hd}
                  quality_fourk={this.props.quality_fourk}
                  quality_vr={this.props.quality_vr}
                  quality_non_hd={this.props.quality_non_hd}
                
              ></QualityComponent>
              </div>
              {this.props.showTags == 1 ? 
                // Search from multiple tags:
                <GetTagAsyncComponent 
                  Ptags={this.props.Ptags} 
                  hide={true} 
                  isSide="1"
                  show_wide={0}
                  theme={this.props.theme}
                  go_to_link={this.props.go_to_link}
                  hide_filters={this.props.hide_filters}
                  //selected_tags={[]}
                  comes_from_leftmenu_search={0}

                  comes_from_tags={this.props.comes_from_tags}
                  
                  selected_tags={this.props.selected_tags}          
                  inputChange={this.props.inputChange}
                  deleteInput={this.props.deleteInput}
                >
                  
                  {" "}
                
                </GetTagAsyncComponent>
                : "" }
                
                {this.props.showTags == 1 ? this.props.Ptags.filter((ptag) => ptag.is_main_tag == 1).map(               
                  (ptag) => (
                    <div className="leftMenuElement" key={ptag.id}>
                      
                      <a
                        href={"/tag/" + ptag.id + "/" + title_to_url(ptag.tag)}
                        style={{textDecoration: "none"}}
                        onClick={(event) =>
                          this.props.go_to_link(
                            event,
                            "/tag/" + ptag.id + "/" + title_to_url(ptag.tag)
                          )
                        }
                        className={this.props.show_blur == 1 ? "tags_left_menu_href blur" : "tags_left_menu_href"}
                      >
                      
                          <h4 className="body14 tags_left_menu_href"
                            style={{marginLeft: "4px"}}>
                            {ptag.tag}
                            {ptag.has_photo != null ? (
                              <img 
                                style={{
                                  marginLeft: "8px",
                                  marginBottom: "2px",
                                }}
                                src={configData.CDN_ENDPOINT + "flags/" + ptag.has_photo.replace("flags/", "") +".svg"}
                                width="20"
                                className="flag_wrapper_shadow_class"
                                
                              />
                            ) : (
                              ""
                            )}
                          </h4>                 
                        </a>
                      </div>
                      
                  )
                ) : ""}
                
            </div>
         
            </div>


      </div>
    );
  }
}

export default TagsLeftMenu;
