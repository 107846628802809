import { Route, Switch } from 'react-router-dom'
import React, { Component, Suspense } from 'react';
import './App.css';
import axios from "axios";
import configData from "./config.json";


//import AdminNavBar from './components/admin/navbar';

import UserNavBar from './components/user/navbar';
import jwtDecode from "jwt-decode";
import { withRouter } from "react-router-dom";



const AdminNavBar = React.lazy(() => import('./components/admin/navbar'));


class App extends Component {
  constructor(props) {
    super(props);
  }
  state={
    user:null,
    show_footer: 1,
  }

  // componentWillReceiveProps(nextProps) {
  //   alert("change uri from navbar alert");
  //   if (nextProps.location !== this.props.location) {
  //     // navigated!
  //     alert("new");
  //   }
  // }

  

  UNSAFE_componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      //console.log("on route change");
      
      //the if below is needed because if i double click login or register, 
      //the footer dissapears and I have to resize in order to make it visible again
      //this fix strangely solves the issue
      if(window.location.pathname === '/login' || window.location.pathname === '/register'){
        //alert("logim")
        this.setState({show_footer: 1})
      }else{
        //alert("NOT LOGIn"+window.location.pathname)
        this.setState({show_footer: -1})
      }
      
       this.componentDidMount();     
    });
  }
  componentWillUnmount() {
      this.unlisten();
  }

  enable_footer = () => {
    //alert(this.state.show_footer)
    if(this.state.show_footer != 1)
      this.setState({show_footer: 1 })
  }

componentDidMount(){
  //alert('panagia')
  try {
    //const jwt = localStorage.getItem("token");
    //const user = jwtDecode(jwt);
    var self=this
    if(localStorage.getItem("token") != null){      
      axios({
        method: "get",
        url: configData.PRIVATE_API_ENDPOINT + "/check_if_session_is_valid?token="+localStorage.getItem("token"),
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          self.setState({ user: jwtDecode(localStorage.getItem("token")) });        
        })
        .catch(function (error) {
          //handle error
          self.setState({user: "Unauthorized"})
          localStorage.removeItem("token");
          // if (error.response && error.response.status == 401) {
          //   localStorage.removeItem("token");                 
          // } else {
          //   alert("generic error - please contact admin");
          // }
        });
    }else{      
      self.setState({user: "Unauthorized"})
    }
    
  } catch (e) {this.setState({user: "Unauthorized"})}
}

    render() {
      return (

        <div className="content" >
        
          <Switch>
            <Suspense fallback={<div>Loading...</div>}>
              <Route path="/admin/"  render={()=>    
                  <AdminNavBar User={this.state.user}/>        
                } />
              {/* more admin tags in admin/navbar.jsx        */}
              <Route path="/:page" render={(match)=><UserNavBar 
                    match={match}
                    UpdateUser={this.componentDidMount.bind(this)} 
                    User={this.state.user}
                    show_footer={this.state.show_footer}
                    enable_footer={this.enable_footer}
                    />} 
                    />
              <Route path="/"  exact render={(match)=><UserNavBar 
                                      match={match} 
                                      UpdateUser={this.componentDidMount.bind(this)} 
                                      User={this.state.user}
                                      show_footer={this.state.show_footer}
                                      enable_footer={this.enable_footer}
                                      />} 
                                     
                                      />   
              {/* <Route path="/show/:id" render={(match)=><UserNavBar match={match} UpdateUser={this.componentDidMount.bind(this)} User={this.state.user}/>} /> 
              <Route path="/tags/:page" render={(match)=><UserNavBar match={match} UpdateUser={this.componentDidMount.bind(this)} User={this.state.user}/>} />  */}
            </Suspense> 
          </Switch>
        </div>
        
        
      );
    }
  }

export default withRouter(App);
