import React, { Component } from 'react';
import {  NavLink } from "react-router-dom";
import { lightTheme, darkTheme } from './../theme';
import SearchUIComponent from './search'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { title_to_url } from "../global_arrays";
import GetTagAsyncComponent from "./get_tags_async/get_tags_async";
import configData from "../../config.json";

library.add(faUser,faBars);


class MenuComponent extends Component {
    state = { 
        tags_expanded: 0,
        tags_filters_input_focused: 0
     }
    shouldComponentUpdate(nextProps, nextState) {
    
        if(
            this.props.theme != nextProps.theme || 
            this.props.User != nextProps.User || 
            this.props.show_menu_value != nextProps.show_menu_value || 
            this.props.show_filters_value != nextProps.show_filters_value || 
            this.props.menu_selected != nextProps.menu_selected ||
            this.props.mobile_filter_showing != nextProps.mobile_filter_showing ||
            this.props.show_overlay != nextProps.show_overlay ||
            this.props.mobile_or_tablet != nextProps.mobile_or_tablet ||
            this.props.search_popup_showing != nextProps.search_popup_showing ||
            this.props.search_expanded != nextProps.search_expanded ||
            this.props.mobile_or_tablet != nextProps.mobile_or_tablet ||
            this.state.tags_expanded != nextState.tags_expanded ||
            this.props.ptags != nextProps.ptags ||
            this.props.tags_desktop_popup_showing != nextProps.tags_desktop_popup_showing ||
            this.props.tags_expanded_div_became_fixed != nextProps.tags_expanded_div_became_fixed ||
            this.state.tags_filters_input_focused != nextState.tags_filters_input_focused ||
            this.props.menu_selected_tags != nextProps.selected_tags
            
            //||
            //this.props.Ptag != nextProps.Ptags
            
          ) {
             //console.log("yes  - FUCKIN RENDER")
             return true
        }
       //console.log("no - DO NOT RENDER")
        return false
      }
    render() {         
        return (
           <div 
                    onClick={(event) => {event.stopPropagation();this.props.wrapperclick()}} // helps to close the menu via click on wrapper. This cancels execute 
                                                 //wrappers onclick
                      
                    >
                           
               <div id="menuLeft" className="menuLeft scrollbar" style={{zIndex: "99"}}>
                
                {/* <div id="hamburger_wrapperz"
                        style={{position: "relative",display:'inline-block',
                                float: "left",
                                marginTop: "22px",
                                marginLeft: this.props.mobile_filter_showing == 1 ? '-5px' : "5px",
                                zIndex: "9999"
                                }}>
                        <a href="javascript:void(0);"
                        onClick={() => (this.props.show_menu_value == 1 
                        && this.props.show_filters_value == 1) ? this.props.hide_menu() : this.props.show_menu() }>
                           
                                <div className={"hamburger_left_menu_icon"} 
                                    style={{
                                        position:"relative",
                                        float:'left',
                                        marginRight: "0px", 
                                    
                                    }}
                                    ></div>
                        </a>
                        
                    </div>     */}
                    <div id="icon_close_x_div_wrapper_href"
                        style={{position: "relative",display:'inline-block',
                                float: "right",
                                marginTop: "22px",
                                marginLeft: this.props.mobile_filter_showing == 1 ? '-5px' 
                                                    : "5px",

                                                    // this.state.tags_expanded == 1 ? '20px' : '-5px'
                                marginRight: this.props.mobile_filter_showing == 1 ?  this.state.tags_expanded == 1 ? "12px" : '20px' : 
                                                this.state.tags_expanded == 1 ? "22px" : "30px",
                                zIndex: "9999"
                                }}>
                        <a href="javascript:void(0);"
                        onClick={() => (this.props.show_menu_value == 1 
                        && this.props.show_filters_value == 1) ? this.props.hide_menu() : this.props.show_menu() }>
                            
                                <div className={"icon_close_x_div_wrapper"} 
                                    style={{
                                        position:"relative",
                                        float:'left',
                                        marginRight: "0px", 
                                    
                                    }}
                                    ></div>
                        </a>
                        
                    </div>    




                
                 <div id="logo" className="videotitle custom-h3"
                    style={{position: "relative",display:'inline-block',
                    marginTop:"7px", width: "auto",marginLeft: this.props.mobile_filter_showing == 1 ? "-5px" : "4px",  fontSize: '20px',
                    float: "left",zIndex: '9999'}}> 
                   {/* BalaPorne */}
                   <a
                            href={"/videos"}
                            style={{textDecoration: "none",zIndex:'0',}}
                            onClick={(event) =>
                            this.props.go_to_link(
                                event,
                                "/videos"
                            )
                            }
                        > 
                   <img src={this.props.theme == lightTheme ? 
                        process.env.PUBLIC_URL + '/logo-white.svg' 
                                    :  process.env.PUBLIC_URL + '/logo-dark.svg' }
                                    

                                    width='133px' alt="Logo" />
                    </a>
                </div>
                <div style={{position:'relative',marginTop: "57px",width: "calc( 100% + 40px )",marginLeft: "-40px"}}>
                    <hr className="hr_color"   ></hr>
                </div>
                
                {/* <div id="hamburger_wrapperz"
                    style={{marginLeft: this.props.mobile_filter_showing == 0 && this.props.mobile_or_tablet == 1 ? "10px" : ""}}
                    >
                        <a href="javascript:void(0);"
                        onClick={() => (this.props.show_menu_value == 1 && this.props.show_filters_value == 1) ? this.props.hide_menu() : this.props.show_menu() }>
                             <FontAwesomeIcon
                                className="fa-bars fa-1x purple_href"
                                //className={document.location.href.split("/")[3].includes("login") ? "fa-sync fa-1x purple_href active" : "fa-sync fa-1x purple_href"} 
                                icon="bars"
                                // style={{
                                //     position:"relative",
                                //     float:'left',
                                //     marginTop: "22px",
                                //     marginRight: "25px", 
                                //     position:"relative",float:"right",
                                    
                                // }}                            
                                />
                        </a>
                    </div>    
                    <div id="logo" className="videotitle custom-h3" style={{marginTop:"15px"}}> 
                        &nbsp;B
                    </div> */}


                
                    {/* <div style={{display: !document.location.href.split("/")[3].includes("login") 
                                &&  !document.location.href.split("/")[3].includes("register") 
                                && !document.location.href.split("/")[3].includes("reset_password")
                            ? "inline" : "none"}}> */}
<div style={{
    
    marginLeft: this.props.mobile_filter_showing == 1 ? "-10px" : "",
    marginTop: !document.location.href.split("/")[3].includes("login") 
    &&  !document.location.href.split("/")[3].includes("register") 
    &&  !document.location.href.split("/")[3].includes("reset_password") ? "0px" : "-17px"
    }}>
{ !document.location.href.split("/")[3].includes("login") 
                                &&  !document.location.href.split("/")[3].includes("register") 
                                &&  !document.location.href.split("/")[3].includes("reset_password") ? 
                                <div style={{marginTop:"",
                                marginRight: this.props.mobile_filter_showing == 1 ? "20px" : "30px",
                                }}>   
                        <h3 
                                className="body16 pornstar_channel_in_view_video_href_class"
                                style={{
                                    display: "inline-block",
                                    marginLeft: this.props.mobile_filter_showing == 1 ? "6px" : "4px"
                        }}>Theme: </h3>
                        
                        
                            
                            
                            <div style={{display: "inline-block", paddingTop:"1px", position: "relative",float:"right",
                                         marginRight: this.state.tags_expanded == 1 ? "-8px" : ""}}>
                                            <label className="switch" >
                                                <input type="checkbox"
                                                checked={this.props.theme == lightTheme ? false : true }
                                                onChange = {() => 
                                                this.props.theme == darkTheme ? this.props.set_theme_light() : this.props.set_theme_dark()
                                                }
                                                
                                                />
                                                <span className="slider round"></span>
                                </label> 
                                </div>
                                
                                
                                <h3 
                                    className="body16 tags_left_menu_href"
                                    style={{
                                        display: "inline-block",
                                        //marginLeft: "50px"
                                        position:"relative",float:"right"
                                        ,marginRight:"8px",
                                        marginTop: '1.5px',
                                }}>{this.props.theme == lightTheme ? "Light" : "Dark"}</h3>&nbsp;        
                           
                                </div>
                      :
                      " "
                    //   <div>   
                    //   <h3 
                    //           className="body16 pornstar_channel_in_view_video_href_class"
                    //           style={{
                    //               display: "inline-block",marginLeft:"16px",height:"1px",border: '0px solid red'
                    //   }}></h3>
                      
                    //   <h3 
                    //           className="body16 tags_left_menu_href"
                    //           style={{
                    //               display: "inline-block",
                    //               marginLeft: "50px",height:"1px",border: '0px solid purple'
                    //   }}>{this.props.theme == lightTheme ? "" : ""}</h3>&nbsp;
                          
                          
                         
                    //           </div>
                   }
                    <hr className="hr_color" style={{
                                //width: this.props.mobile_filter_showing == 1 ? "calc(100% - 8px)" : "calc(100% - 10px)",
                                width: "220px",
                                 marginTop: "8px",
                                // marginLeft: this.props.mobile_filter_showing == 1 ? "14px" : "-10px",
                                marginLeft:  this.props.mobile_filter_showing == 1 ? "4px":"-5px",
                                //marginRight: "auto",
                                //marginRight: "auto",
                                }} />
                      
                      {/* </div> */}
                      <div style={{width:"100%", border: '0px solid red'}}></div>

                        <NavLink                 
                            // className="menuElement"
                            to="/videos"
                            onClick = { () => this.props.hide_menu()}
                            id='videos_left_menu_mobile_and_tablet'
                            
                            style={{textDecoration: "none",position:"relative",display:"inline"
                            }}
                            >
                                
                            <div 
                                className={document.location.href.split("/")[3].includes("videos")  ? "leftMenuElement menu_href_color videos_menu_div" 
                                    : "leftMenuElement tags_left_menu_href videos_menu_div"} style={{width:"100%"}}>
                                <h4 className="body16"> 
                                    {/* <img src={
                                        this.props.theme == lightTheme? 
                                            document.location.href.split("/")[3].includes("videos") ? 
                                                configData.CDN_ENDPOINT+"images"+"/videos_purple.svg" : 
                                                configData.CDN_ENDPOINT+"images"+"/videos_dark.svg" 
                                        :
                                        document.location.href.split("/")[3].includes("videos") ? 
                                        configData.CDN_ENDPOINT+"images"+"/videos_purple_d.svg" : 
                                        configData.CDN_ENDPOINT+"images"+"/videos_gray.svg" 
                                        
                                        }
                                        style={{marginTop: "-3px", marginRight: "8px"}}
                                        width="16" ></img> Videos */}
                                    <div className={document.location.href.split("/")[3].includes("videos")  ? "videos_left_menu active" : "videos_left_menu"}
                                        style={{
                                            position:"relative",
                                            float:'left',
                                            marginRight: "5px", 
                                            width: "18px",
                                            height: "18px",
                                            
                                            marginTop: "9.5px",//"-1px" ,
                                            marginLeft: this.props.mobile_filter_showing == 1 ? "16px" : "16px"
                                            //height: '16px',
                                            
                                        }}></div>                   
                                            <div style={{position:"relative", marginTop: "9px", float:"left", marginLeft: "8px"}}>Videos</div>
                                </h4>    
                            </div>
                        </NavLink>
                <div id='tags_left_menu_mobile_and_tablet' style={{zIndex: '999', position:'',width:"221px"}}>
                    <NavLink                 
                            // className="menuElement"
                            to="/tags"
                            onClick = { () => this.props.hide_menu()}
                            style={{textDecoration: "none",zIndex:"999",position:"relative",
                            display:"inline",
                          //  marginTop: '23px',
                          top: "23px"
                            
                        
                        }}
                            
                            
                            >
                        <div 
                            className={document.location.href.split("/")[3].includes("tag")  ? "leftMenuElement menu_href_color tags_menu_div" 
                            : "leftMenuElement tags_left_menu_href tags_menu_div"}>
                            <h4 className="body16">
                                {/* <img src={
                                    this.props.theme == lightTheme? 
                                        document.location.href.split("/")[3].includes("tags") ? configData.CDN_ENDPOINT+"images"+"/tags_purple.svg" : configData.CDN_ENDPOINT+"images"+"/tags_dark.svg" 
                                    :
                                    document.location.href.split("/")[3].includes("tags") ? configData.CDN_ENDPOINT+"images"+"/tags_purple_d.svg" : configData.CDN_ENDPOINT+"images"+"/tags_gray.svg" 
                                    
                                    }
                                    style={{marginTop: "-3px", marginRight: "16px"}}
                                    width="16" ></img>Tags */}
                                <div className={document.location.href.split("/")[3].includes("tag")  ? "tags_left_menu active" : "tags_left_menu"}
                                  style={{
                                    position:"relative",
                                    float:'left',
                                    
                                    marginRight: "5px", 
                                     width: "18px",
                                     height: "18px",
                                     //marginTop: "24px",
                                     marginTop: "1px",
                                     marginLeft: this.props.mobile_filter_showing == 1 ? "16px" : "16px"
                                     //height: '16px',
                                     
                                  }}></div> 
                                
                                    <div id="" style={{position:"relative", 
                                    //marginTop: "23px", 
                                    float:"left", marginLeft: "8px"}}>Tags</div>
                                    <div style={{position:"relative", marginTop: "-1px", float:"right", marginRight: this.props.mobile_filter_showing == 0 ? "20px" : ""}}>
                                    <a href="javascript:void(0);" 
                                        onClick={(event) => {event.stopPropagation();event.preventDefault();
                                        this.state.tags_expanded == 0 ? this.setState({tags_expanded: 1}) : this.setState({tags_expanded: 0})
                                        
                                        }}>
                                            {
                                            
                                            // this.state.tags_expanded == 1 ? 
                                            // //'>' 
                                            // <div className='tags_arrow_for_expansion'></div>
                                            
                                            // : 
                                            
                                            <div className='tags_arrow_for_expansion'
                                                style={{width: '18px', height: "18px",  marginLeft: '-15px',transform: 
                                                this.state.tags_expanded == 0 ? "rotate(270deg)" : "rotate(90deg)", 
                                                marginTop: "3.0px", position:'relative'}}
                                            ></div> 
                                            
                                            }  
                                            </a>
                                        
                                    </div>
                            </h4>
                            
                        </div> 
                    </NavLink>  

                    {/* <div style={{position:"relative",  display: this.state.tags_expanded == 1 ? "inline-block" : "none",}}> */}
                    <div style={{position:"relative",  display: this.state.tags_expanded == 1 ? "none" : "none",}}>
                    <FontAwesomeIcon icon="search" size="1x" 
                                    style={{
                                      position:"absolute",  
                                      top: "50px",
                                      left: "24px",
                                    
                                      // right: "0px",
                                      zIndex: "9999",
                                     // marginTop: "10.5px",
                                      color: this.state.tags_filters_input_focused == 0 && navigator.userAgent.indexOf("Firefox") == -1 ? "8B8B8F" : 
                                          this.props.theme == lightTheme ? "#5724E6" : "#9F7DFF"

                                    }}
                                  />
                                <input
                                    className="form-control pornstar_channel_input"
                                    type="search"
                                    dark={this.props.theme == lightTheme ? "false" : "true"}
                                    value={this.props.tags_filter_value}
                                    style={{
                                      
                                      position:"relative",
                                      borderRadius: "4px",
                                     // display: this.state.tags_expanded == 1 ? "" : "none",
                                      // width: this.props.pornstar_countries_popup_showing == 1 ? 
                                      // document.getElementById('rightSideNoLeftMenu').offsetWidth+"px" 
                                      // : "calc(100% - 91px)", 
                                      //width: 'calc(document.getElementById("rightSideNoLeftMenu").offsetWidth-91+"px")',
                                      position:"relative",
                                    
                                      borderRadius: "4px",
                                      //marginBottom: "20px",
                                      width: '180px',
                                      float:"left",
                                      marginLeft: "14px",
                                      marginTop: "39px",
                                      boxShadow: "none",lineHeight: '40px',
                                      border: '1px solid pink',display:"block",
                                      //paddingTop: "8.5px"
                                      
                                      }}
                                    onClick={(event) => {event.stopPropagation();
                                //        event.preventDefault();
                                     }}
                                    onFocus={(event) => {event.stopPropagation();event.preventDefault();this.setState({tags_filters_input_focused: 1}) }}
                                    onBlur={(event) => { event.stopPropagation();event.preventDefault();this.setState({tags_filters_input_focused: 0})}}
                                    placeholder="FILTER TAGS"
                                    id="pornstar-countries-search-input" //this.handleInput(value)
                                    onChange={(event) => {
                                        //console.log(event.target.value)
                                        event.stopPropagation();event.preventDefault();
                                        this.props.handleTagsFilterInput(event)
                                    }} 
                                  ></input>
                                 {""}
                          </div>
                          <div
                            style={{position:"relative",  display: this.state.tags_expanded == 1 ? "inline-block" : "none",
                                    marginTop:"24px",
                                    marginLeft: this.props.mobile_filter_showing == 1 ? '11px' : "0px"
                                }}
                            onClick={(event) => {event.stopPropagation();event.preventDefault();}}>
                            <GetTagAsyncComponent 
                                Ptags={this.props.ptags} 
                                hide={true} 
                                isSide="1"
                                show_wide={0}
                                theme={this.props.theme}
                                go_to_link={this.props.go_to_link}
                                hide_filters={this.props.hide_menu}
                                hide_filters_flag={1}
                                hide_menu_flag={1}
                                hide_menu={this.props.hide_menu}
                                comes_from_leftmenu_search={0}
                                //comes_from_tags={0}

                                comes_from_tags={this.props.comes_from_tags}

                                selected_tags={this.props.selected_tags}
                
                

                                inputChange={this.props.inputChange}

                                deleteInput={this.props.deleteInput}
                                
                                ></GetTagAsyncComponent>
                            </div>
                        </div>  
                      
                  <div style={{position:"relative", zIndex:'0',display:this.state.tags_expanded == 0 ? "none" : ""}}>
                           <div style={{
                            width: '100%',height: '167px',position:'relative',border: '0px solid red'
                            ,display:this.props.tags_expanded_div_became_fixed == 0 ? "none" : ""
                            }}></div>
                            <div style={{position:"relative", height: "8px"}}></div>
                    {this.state.tags_expanded == 1 ? this.props.ptags.filter(ptag => ptag.is_main_tag === 1).map(               
                       

                  
                        (ptag) => (

                            <a
                            href={"/tag/" + ptag.id + "/" + title_to_url(ptag.tag)}
                            style={{textDecoration: "none",zIndex:'0',}}
                            onClick={(event) =>
                            this.props.go_to_link(
                                event,
                                "/tag/" + ptag.id + "/" + title_to_url(ptag.tag)
                            )
                            }
                            className={this.props.show_blur == 1 ? "tags_left_menu_href blur" : "tags_left_menu_href"}
                        > 
                            <div 
                                style={{display:"inline-block",paddingLeft: "20px",position:'relative',zIndex:'0',}}
                                className="leftMenuElement" key={ptag.id}>

                            
                            
                            
                                <h4 className="body14 tags_left_menu_href"
                                    style={{marginLeft: "4px",display:'inline-block'}}>
                                    
                                    
                                    
                                    {ptag.tag.toLowerCase().includes(this.props.tags_filter_value.toLowerCase()) ? (
                                        <div 
                                            style={{position:'relative',float:'left',display:"inline-block"}}
                                            className="body14 tags_left_menu_href"
                                            dangerouslySetInnerHTML={{
                                            __html: (() => {
                                            const re = new RegExp(this.props.tags_filter_value, 'gi');
                                            const highlighted = ptag.tag.replace(re, `<mark class="custom-highlight">$&</mark>`);
                                            return highlighted;
                                            })()
                                        }} />
                                        ) : (
                                        <div style={{position:'relative',float:'left',display:"inline-block"}}>{ptag.tag}</div>
                                        )} 


                                    {ptag.has_photo != null ? (
                                    <img 
                                        style={{
                                        marginLeft: "8px",
                                        marginBottom: "2px",
                                        position:'relative',
                                        float:'left',
                                        }}
                                        src={configData.CDN_ENDPOINT + "flags/" + ptag.has_photo.replace("flags/", "") +".svg"}
                                        width="20"
                                        className="flag_wrapper_shadow_class"
                                        
                                    />
                                    
                                    ) : (
                                    ""
                                    )}
                                    
                                </h4>                 
                                
                            </div>
                            </a>
                        )
                        ) : ""
                        
                        }
                        </div>
                        <div style={{position:"relative",display: "inline", float:"left",width:'100%',border:""}}></div>
                        {/* <div style={{border: '0px solid red',height:"30px",position:"relative",float:"left", 
                            paddingLeft: '20px',
                            width: "100%", 
                            display: this.props.ptags.length == 0 && this.state.tags_expanded == 1 ? "inline-block"  : 'none'}} 
                            className='custom-h4 text_color'>Not found</div> */}
                        <div
                            className="search_individual_item_wrapper purple_href"
                            style={{display:this.state.tags_expanded == 1 ? "inline-block" : "none", 
                            backgroundColor: 'transparent',
                            position:"relative",
                            // height: "10px",
                            //bottom: "-40px",
                            //height: '0px',
                            border: '0px solid red',
                            
                            //lineHeight: navigator.userAgent.indexOf("Firefox") != -1 ? "16.5px":"17px"
                        }}
                            >
                            <a href={"/tags/"}
                               className="purple_href"
                               style={{textDecoration:"none",border: '0px solid purple' }}
                                onClick={(event) => {
                                  event.preventDefault();
                                  
                                  //this.make_search_inactive();
                                  this.props.go_to_link(event,"/tags/")}}
                                >Show all tags&nbsp;<FontAwesomeIcon  icon={"arrow-right"} 
                                size="1x" /></a>
                            
                            </div>

                             
                        
                        
                                             
                    <NavLink                 
                            // className="menuElement"
                            to="/pornstars"
                            onClick = { () => this.props.hide_menu()}
                            className={document.location.href.split("/")[3].includes("pornstar")  ? "menu_href_color" : ""}
                            style={{textDecoration: "none",position:"relative",display:"inline-block",
                            marginTop: this.state.tags_expanded == 0 ? "17px" : "-9px"}}
                            >
                        <div className={document.location.href.split("/")[3].includes("pornstar")  ? "leftMenuElement menu_href_color pornstars_menu_div" 
                            : "leftMenuElement tags_left_menu_href pornstars_menu_div"}
                            
                            >
                            <h4 className="body16"> 
                                {/* <img src={
                                    this.props.theme == lightTheme? 
                                        document.location.href.split("/")[3].includes("pornstars") ? configData.CDN_ENDPOINT+"images"+"/pornstars_purple.svg" : configData.CDN_ENDPOINT+"images"+"/pornstars_dark.svg" 
                                    :
                                    document.location.href.split("/")[3].includes("pornstars") ? configData.CDN_ENDPOINT+"images"+"/pornstars_purple_d.svg" : configData.CDN_ENDPOINT+"images"+"/pornstars_gray.svg" 
                                    
                                    }
                                    style={{marginTop: "-3px", marginRight: "8px"}}
                                    width="16" ></img> Pornstars */}
                                <div className={document.location.href.split("/")[3].includes("pornstar")   ? "pornstars_left_menu active" : "pornstars_left_menu"}
                                  style={{
                                    position:"relative",
                                    float:'left',
                                    marginTop: "21px",
                                    marginRight: "5px", 
                                     width: "20px",
                                     height: "20px",
                                     //marginTop: "17px",
                                     marginLeft: this.props.mobile_filter_showing == 1 ? "16px" : "16px"
                                     //height: '16px',
                                     
                                  }}></div>                   
                                    <div style={{position:"relative", float:"left", 
                                    marginTop: "23px", 
                                    marginLeft: "6.5px"}}>Pornstars</div>
                            </h4>
                        </div>
                    </NavLink> 
                    <NavLink                 
                            // className="menuElement"
                            to="/channels"
                            onClick = { () => this.props.hide_menu()}
                            style={{textDecoration: "none", position:"relative",display:"inline-block",
                                    marginTop:'8px'
                                }}
                            >
                        <div className={document.location.href.split("/")[3].includes("channel")  ? "leftMenuElement menu_href_color channels_menu_div" 
                            : "leftMenuElement tags_left_menu_href channels_menu_div"}>
                            <h4 className="body16"> 
                                {/* <img src={
                                        this.props.theme == lightTheme? 
                                            document.location.href.split("/")[3].includes("channels") ? configData.CDN_ENDPOINT+"images"+"/channels_purple.svg" : configData.CDN_ENDPOINT+"images"+"/channels_dark.svg" 
                                        :
                                        document.location.href.split("/")[3].includes("channels") ? configData.CDN_ENDPOINT+"images"+"/channels_purple_d.svg" : configData.CDN_ENDPOINT+"images"+"/channels_gray.svg" 
                                        
                                        }
                                        style={{marginTop: "-3px", marginRight: "8px"}}
                                        width="16" ></img> Channels */}
                                <div className={document.location.href.split("/")[3].includes("channel")  ? "channels_left_menu active" : "channels_left_menu"}
                                  style={{
                                    position:"relative",
                                    float:'left',
                                   
                                    marginRight: "5px", 
                                     width: "18px",
                                     height: "18px",
                                     //marginTop: "16px",
                                     marginTop: "-1.5px",
                                     marginLeft: this.props.mobile_filter_showing == 1 ? "18px" : "18px"
                                     //height: '16px',
                                     
                                  }}></div>                   
                                    <div style={{position:"relative", 
                                    //marginTop: "16px", 
                                    float:"left", marginLeft: "7px"}}>Channels</div>
                            </h4>
                        </div>
                    </NavLink>
                    {/* {this.props.User != null &&
                    (this.props.User != "Unauthorized" && ( */}
                        <div>
                            <hr className="hr_color" style={{
                                //width: this.props.mobile_filter_showing == 1 ? "calc(100% - 8px)" : "calc(100% - 10px)",
                                width: "220px",
                                // marginTop: "8px",
                                // marginLeft: this.props.mobile_filter_showing == 1 ? "14px" : "-10px",
                                marginLeft:  this.props.mobile_filter_showing == 1 ? "4px":"-5px",
                                //marginRight: "auto",
                                //marginRight: "auto",
                                }} />
                        <a
                            // className="menuElement"
                            href={this.props.User != null && this.props.User != "Unauthorized" 
                            ? "/watchlist": "javascript:void(0);"}
                            
                            onClick={(event) => this.props.User != null && this.props.User != "Unauthorized" ? 
                             this.props.go_to_link(event, "/watchlist", 1) :  
                           this.props.show_login_popup("/watchlist")}
                            style={{textDecoration: "none", }}
                            >
                        <div className={document.location.href.split("/")[3].includes("watchlist")  ? "leftMenuElement menu_href_color watchlist_leftmenu watched_left_menu_div" 
                            : "leftMenuElement tags_left_menu_href watchlist_leftmenu watched_left_menu_div"}>
                            <h4 className="body16"> 
                                <div className={document.location.href.split("/")[3].includes("watchlist")  ? "heart_left_menu active" : "heart_left_menu"}
                                  style={{
                                    position:"relative",
                                    float:'left',
                                    marginRight: "5px", 
                                     width: "18px",
                                     height: "18px",
                                     marginTop: "9.5px",
                                     marginLeft: this.props.mobile_filter_showing == 1 ? "16px" : "16px"
                                     //height: '16px',
                                     
                                  }}></div>                   
                                    <div style={{position:"relative", marginTop: "8.5px", float:"left", marginLeft: "8px"}}>My Watchlist</div>
                      
                            </h4>
                        </div>
                       </a>
                        
                        
                        
                        <a href={this.props.User != null && this.props.User != "Unauthorized" 
                            ? "/watched": "javascript:void(0);"}
                            
                            onClick={(event) => this.props.User != null && this.props.User != "Unauthorized" ? 
                            this.props.go_to_link(event, "/watched",1) : this.props.show_login_popup("/watched")}
                            style={{textDecoration: "none", marginTop: "27.5px", position:"relative",display:"block",
                            pointerEvents: this.props.mobile_or_tablet == 1 ? '' : ''}}
                            >
                        <div className={document.location.href.split("/")[3].includes("watched")  ? "leftMenuElement menu_href_color watched_left_menu_div" 
                            : "leftMenuElement tags_left_menu_href watched_left_menu_div"}>
                            <h4 className="body16"> 
                                <div className={document.location.href.split("/")[3].includes("watched") ? "watched_left_menu_icon active" : "watched_left_menu_icon"} 
                                    style={{
                                        position:"relative",
                                        float:'left',
                                        marginRight: "0px", 
                                        width: "18px",
                                        height: "18px",
                                        //marginTop: "27.5px",
                                        marginLeft: this.props.mobile_filter_showing == 1 ? "16px" : "16px"
                                        // width: "15px",
                                        // height: '15px',
                                    }}
                                    ></div><div style={{position:"relative", 
                                    //marginTop: "26.5px", 
                                    float:"left", marginLeft: "12px"}}>
                                        Watched videos</div>
                            </h4>
                        </div>
                       </a>
                        </div>            

                
                </div>

                </div>
        
                <div style={{position:"relative",  right: "0px",}}>
        <div 
        id='menu_container' 
        style={{position:"relative", height: "58px",

        // backgroundColor: this.props.filters_showing == 1 || this.props.show_overlay == 1 ? 
        //           this.props.theme == lightTheme ? 
        //             configData.OVERLAY_BACKGROUND_COLOR_WHITE_THEME : configData.OVERLAY_BACKGROUND_COLOR_DARK_THEME
                
        //             : "",
        
                         width: "100%",
                          
        
                      }}>
        
            
            <div 
                id="menu"
                // style={{position:"fixed", top: "0",  
                // height: "58px",  
                // display: "block",zIndex: "9995",maxWidth:"inherit",width: "50%", }}
                style={{ position: "fixed", top: "0", height: "58px", display: "block", zIndex: "60", width: "inherit",
                left: "0px",
                right: '0px',
                // backgroundColor:  (this.props.filters_showing && this.props.show_menu_value == 1 ) || this.props.show_overlay == 1 ? 
                //   this.props.theme == lightTheme ? 
                //     configData.OVERLAY_BACKGROUND_COLOR_WHITE_THEME : configData.OVERLAY_BACKGROUND_COLOR_DARK_THEME
                
                //     : "",
                    alignItems:"center" , justifyContent:"center", display:"flex",
                    

                          }}


                >

            <div id="menu_inner" 
             className={ (this.props.filters_showing && this.props.show_menu_value == 1 ) || this.props.show_overlay == 1  ? "" : ""}
            
            style={{position:"relative", float:"center",height: "58px",minWidth: "260px"
            
            ,  
            // backgroundColor:  (this.props.filters_showing && this.props.show_menu_value == 1 ) || this.props.show_overlay == 1 ? 
            // this.props.theme == lightTheme ? 
            // configData.OVERLAY_BACKGROUND_COLOR_WHITE_THEME : configData.OVERLAY_BACKGROUND_COLOR_DARK_THEME
           
            // : "", 
            zIndex: "60",
            
            }}>
                
{/*                
                <div 
                    id="menu_border_div"
                    className={this.props.filters_showing == 1 || this.props.show_overlay == 1 ? "overlay" : ""}
                    onClick={() => this.props.filters_showing == 1 ? this.props.wrapperclick() : ""}
                    style={{
                        position:"relative", width: "calc( 100% + 14px) ",
                   // marginLeft: this.props.mobile_filter_showing == 0 ? "0px" : "10px", 
                    //borderBottom: '1px solid red', 
                    height: "58px", 
                    left: "-7px", 
                    right: "-29px",
                    //border: '1px solid pink',
                
                }}
                    
                    
                    >   */}
                    <div id="hamburger_wrapper"
                    style={{
                        marginLeft: this.props.mobile_filter_showing == 0 && this.props.mobile_or_tablet == 1 ? "10px" : ""
                   // marginLeft: this.props.mobile_filter_showing == 1 ? '-5px' : "5px",
                    }}
                    >
                        <a href="javascript:void(0);" id="hamburger_wrapper_href"
                        onClick={() => (this.props.show_menu_value == 1 && this.props.show_filters_value == 1) ? this.props.hide_menu() : this.props.show_menu() }>
                             {/* <FontAwesomeIcon
                                className="fa-bars fa-1x purple_href"
                                icon="bars"
                                
                                /> */}
                            <div className={"hamburger_left_menu_icon"} 
                                    style={{
                                        position:"relative",
                                        float:'left',
                                        marginRight: "0px", 
                                    
                                    }}
                                    ></div>
                        </a>
                    </div>    
                    <div id="logo" className="videotitle custom-h3" 
                            style={{marginTop:"8px", width: "auto",
                            marginLeft: this.props.mobile_or_tablet == 0 ? "" :"14px",
                            fontSize:"20px"
                            }}> 
                        {/* BalaPornrr */}
                        {/* <img src={process.env.PUBLIC_URL + this.props.theme == lightTheme ?
                        '/logo-white.svg' : process.env.PUBLIC_URL + '/logo-dark.svg' }
                        

                        width='100px' alt="Logo" /> */}

            <a
                            href={"/videos"}
                            style={{textDecoration: "none",zIndex:'0',}}
                            onClick={(event) =>
                            this.props.go_to_link(
                                event,
                                "/videos"
                            )
                            }
                        > 
            <img src={this.props.theme == lightTheme ? 
            process.env.PUBLIC_URL + '/logo-white.svg' 
                        :  process.env.PUBLIC_URL + '/logo-dark.svg' }
                        

                        width='133px' alt="Logo" />

                </a>
                    </div>
                    
                        <div id="menu_desktop"
                            style={{ position:"relative",
                                    display:  this.props.mobile_or_tablet == 0 && this.props.mobile_filter_showing == 0 ? "" : "none",
                                    left: "-13px"
                                    }}>
                            {/* <div className="intermediateContainer"> */}
                            <div className={document.location.href.split("/")[3].includes("video") 
                                        ? "menu_item_left active" : "menu_item_left"}
                                        >
                                    <NavLink                 
                                    
                                        className="menu_href_class"
                                        to="/videos"
                                    >
                                    
                                
                                        <h4  style={{
                                            marginTop:"18px",fontWeight: "500"}} className="custom-h4">VIDEOS
                                        </h4>
                                    
                                    
                                    </NavLink>
                                </div>
                                <div className={document.location.href.split("/")[3].includes("tag") 
                                    ? "menu_item_left active" : "menu_item_left"}                    
                                        style={{marginLeft: "8px"}}
                                        >
                                    <NavLink                 
                                    // className="menuElement"
                                        to="/tags"
                                        className="menu_href_class"
                                        isActive={ () => document.location.href.split("/")[3].includes("tag") }
                                        onMouseOver={() => this.props.show_desktop_tags_popup()}
                                        onMouseOut={() => this.props.hide_desktop_tags_popup()}
                                        onClick={()=>this.props.hide_desktop_tags_popup(1)}
                                        id="tags_menu_desktop_id"
                                        
                                    >
                                   
                                    
                                        <h4 style={{marginTop:"18px",fontWeight: "500"}} className="custom-h4">
                                            <div style={{position:'relative',float:'left'}} 
                                            onMouseOver={() => this.props.set_tag_popup_stay_active(1)}
                                            onMouseOut={() => this.props.set_tag_popup_stay_active(0)}>TAGS</div>
                                            {
                                            
                                            <div className='tags_arrow_for_expansion'
                                                style={{width: '18px', height: "18px",  marginLeft: '0px',transform: 
                                                this.props.tags_desktop_popup_showing == 0 ? "rotate(270deg)" : "rotate(90deg)", 
                                                marginTop: "3.5px", marginLeft: '8px', position:'relative',float:'left'
                                               
                                            }}
                                            onMouseOver={() => this.props.set_tag_popup_stay_active(1)}
                                            onMouseOut={() => this.props.set_tag_popup_stay_active(0)}
                                            ></div>                                                                                  
                                            }  
                                        </h4>
                                
                                    
                                    </NavLink>
                                </div>
                                <div className={document.location.href.split("/")[3].includes("pornstar") 
                                    ? "menu_item_left active" : "menu_item_left"}
                                        style={{marginLeft: "8px"}}
                                        >
                                    <NavLink                 
                                    // className="menuElement"
                                        to="/pornstars"
                                        className="menu_href_class"
                                        isActive={ () => document.location.href.split("/")[3].includes("pornstar") }
                                    >
                                    
                                        
                                            <h4 style={{marginTop:"18px",fontWeight: "500"}} className="custom-h4">
                                                PORNSTARS
                                            </h4>
                                </NavLink>
                                </div>
                                <div className={document.location.href.split("/")[3].includes("channel") 
                                    ? "menu_item_left active" : "menu_item_left"}
                                    style={{marginLeft: "8px"}}
                                    >
                                    <NavLink                 
                                        // className="menuElement"
                                            to="/channels"
                                            className="menu_href_class"
                                            isActive={ () => document.location.href.split("/")[3].includes("channel") }

                                        >
                                
                                        
                                            <h4 style={{marginTop:"18px",fontWeight: "500"}}
                                               className="custom-h4"
                                            // className={document.location.href.split("/")[3].includes("channel") 
                                            // ? "menu_item_left active" : "menu_item_left"}
                                            >CHANNELS</h4>
                                        
                                    
                                    
                                    </NavLink>
                                </div>
                            
                                    { !document.location.href.split("/")[3].includes("login") 
                                    &&  !document.location.href.split("/")[3].includes("register") 
                                    &&  !document.location.href.split("/")[3].includes("reset_password") ? 
                                <div className="menu_item_left" style={{marginTop: "20px", 
                                marginLeft: "15px"
                                
                                }}>
                                    <label className="switch">
                                        
                                            <input type="checkbox"
                                            checked={this.props.theme == lightTheme ? false : true }
                                            onChange = {() => 
                                            this.props.theme == darkTheme ? this.props.set_theme_light() : this.props.set_theme_dark()
                                            }
                                            />
                                            <span className="slider round"></span>
                                            
                                        </label> 
                                </div>
                                :""}
                            </div>
                    

                    
                    {this.props.User == null ||
                    (this.props.User == "Unauthorized" &&  this.props.mobile_filter_showing == 0&& (
                            <React.Fragment>
                                <div className={document.location.href.split("/")[3].includes("register") 
                                ? "menu_item_right active" : "menu_item_right"}
                                style={{marginLeft: this.props.mobile_filter_showing == 0 && this.props.mobile_or_tablet == 1 ? "20px" : ""}}
                                    >
                                <NavLink
                                        to={"/register?return_to=" + window.location.pathname}
                                        className="menu_href_class"
                                        // style={{color: "red"}}
                                        style={{marginRight: this.props.mobile_or_tablet == 1 
                                            && this.props.mobile_filter_showing == 0 ?  "0px" : "",
                                    }}
                                    >
                                    {/* <h4 style={{marginTop:"16px",}}>SIGNUP</h4> */} 
                                                       
                                        <button
                                        style={{
                                            position:"relative",
                                            float:"right",
                                            // marginRight: "25px",
                                            fontSize: "14px",
                                            lineHeight: "22px",
                                            marginTop: "10px",
                                            fontWeight: "500",
                                            paddingRight: this.props.mobile_or_tablet == 1 
                                            && this.props.mobile_filter_showing == 0 ? "0px" : "",
                                            paddingLeft: this.props.mobile_or_tablet == 1 
                                            && this.props.mobile_filter_showing == 0 ? "0px" : ""
                                            }}
                                            className="button_trans_background"
                                            
                                        //   onClick={() => this.comment_cancel()}
                                            >
                                        SIGN UP
                                        </button>
                                </NavLink>
                                </div>
                                <div className={document.location.href.split("/")[3].includes("login") 
                                ? "menu_item_right active" : "menu_item_right"}>
                                    <NavLink
                                        to={"/login?return_to=" + window.location.pathname}
                                        className="menu_href_class active"
                                    >
                                    <button
                                    style={{
                                        position:"relative",
                                        float:"right",
                                        // marginRight: "25px",
                                        fontSize: "14px",
                                        lineHeight: "22px",
                                        marginTop: "10px",
                                        fontWeight: "500",
                                        paddingRight: this.props.mobile_or_tablet == 1 
                                        && this.props.mobile_filter_showing == 0 ? "0px" : "",
                                        paddingLeft: this.props.mobile_or_tablet == 1 
                                        && this.props.mobile_filter_showing == 0 ? "0px" : "",
                                        // paddingLeft: this.props.mobile_or_tablet == 1 
                                        // && this.props.mobile_filter_showing == 0 ? "0px" : "",
                                     //    marginRight:"20px",
                                        
                                        }}
                                        className="button_trans_background"
                                    //   onClick={() => this.comment_cancel()}
                                        >
                                    LOGIN 
                                    </button>
                                </NavLink>
                                
                            </div>
                            <SearchUIComponent
                                mobile_or_tablet={this.props.mobile_or_tablet}
                                theme={this.props.theme}
                                go_to_link={this.props.go_to_link}
                                set_search_popup_showing={this.props.set_search_popup_showing}
                                history={this.props.history}
                                handleSearch={this.props.handleSearch}
                                set_mobile_search_input_popup_showing={this.props.set_mobile_search_input_popup_showing}
                                search={this.props.search}
                                mobile_search_input_popup_showing={this.props.mobile_search_input_popup_showing}
                                search_popup_showing={this.props.search_popup_showing}
                                search_expanded={this.props.search_expanded}
                                search_arrow_navigator={this.props.search_arrow_navigator}
                                set_search_item_selected_active_element={this.props.set_search_item_selected_active_element}
                                mobile_filter_showing={this.props.mobile_filter_showing}
                                >
                            </SearchUIComponent>
                        </React.Fragment>
                    ))}


                    {this.props.User == null ||
                    (this.props.User == "Unauthorized" &&  this.props.mobile_filter_showing == 1&& (
                            <React.Fragment>
                                {/* <div className={document.location.href.split("/")[3].includes("register") 
                                ? "menu_item_right active" : "menu_item_right"}
                                
                                    > */}
                <a 
                    href={"/login?return_to=" + window.location.pathname}
                    
                    //onClick={(event) => this.props.User != null && this.props.User != "Unauthorized" ? 
                    //event.preventDefault() && this.props.go_to_link(event, "/login?return_to=" + window.location.pathname) : this.props.show_login_popup("/watched")}
                    
                    onClick={(event) => this.props.go_to_link(event, "/login?return_to=" + window.location.pathname)}
                    style={{textDecoration: "none"}}
                    //className={document.location.href.split("/")[3].includes("watched")  ? "purple_href user_button_menu_div " : "tags_left_menu_href user_button_menu_div"}
                  >
                {/* <FontAwesomeIcon
                className="fa-user fa-1x purple_href"
                //className={document.location.href.split("/")[3].includes("login") ? "fa-sync fa-1x purple_href active" : "fa-sync fa-1x purple_href"} 
                icon="user"
                style={{
                    position:"relative",
                    float:'left',
                    marginTop: "22px",
                    marginRight: "25px", 
                    position:"relative",float:"right",
                    color:document.location.href.split("/")[3].includes("login") ? "#B9A1FF" : ""
                    //color: this.props.theme == lightTheme ? "#5724E6" : "#9F7DFF",
                    
                }}                            
                /> */}

                    <div className={"user_left_menu_icon"} 
                                    style={{
                                      //  marginTop: "22px",
                                        marginRight: "10px", 
                                        marginTop: "16.5px",
                                        position:"relative",float:"right", 
                                    
                                    }}
                                    ></div>
                      
                      {/* <div className={document.location.href.split("/")[3].includes("login") ? "user_button_menu_icon active" : "user_button_menu_icon"} 
                                      style={{
                                        position:"relative",
                                        float:'left',
                                        marginTop: "22px",
                                        marginRight: "25px", 
                                        position:"relative",float:"right",
                                        width: "20px",
                                    
                                        // width: "15px",
                                        // height: '15px',
                                      }}
                                    ></div>     */}



                
                </a>


                                {/* </div> */}
                                {/* <div className={document.location.href.split("/")[3].includes("login") 
                                ? "menu_item_right active" : "menu_item_right"}>
                                    <NavLink
                                        to={"/login?return_to=" + window.location.pathname}
                                        className="menu_href_class active"
                                    >
                                  
                                </NavLink>
                            </div> */}
                            <SearchUIComponent
                                mobile_or_tablet={this.props.mobile_or_tablet}
                                theme={this.props.theme}
                                go_to_link={this.props.go_to_link}
                                set_search_popup_showing={this.props.set_search_popup_showing}
                                history={this.props.history}
                                handleSearch={this.props.handleSearch}
                                set_mobile_search_input_popup_showing={this.props.set_mobile_search_input_popup_showing}
                                search={this.props.search}
                                mobile_search_input_popup_showing={this.props.mobile_search_input_popup_showing}
                                search_popup_showing={this.props.search_popup_showing}
                                search_expanded={this.props.search_expanded}
                                search_arrow_navigator={this.props.search_arrow_navigator}
                                set_search_item_selected_active_element={this.props.set_search_item_selected_active_element}
                                mobile_filter_showing={this.props.mobile_filter_showing}
                                >
                                
                            </SearchUIComponent>
                        </React.Fragment>
                    ))}

            {/* mobile logged in */}
        {this.props.User && this.props.User != "Unauthorized"  &&  this.props.mobile_filter_showing != 0 &&  this.props.mobile_filter_showing != -1 && (
                    <React.Fragment>
                        <div id="menu_item_right" className={document.location.href.split("/")[3].includes("register") 
                            ? "menu_item_right active" : "menu_item_right"}
                            
                                >
                            {/*  */}
                            <a href="javascript:void(0);"
                                onClick={() => {this.props.show_hide_user_menu_mobile()}}
                                style={{textDecoration: "none",width: "50px"}}
                            >
                                <div style={{width: "40px", position:"relative",float:"right", right: "-20px"}}>
                                <div 
                                    id="circle"
                                    className="circle"
                                    style={{
                                        marginTop:"17.0px",
                                        position:"relative",
                                        flot:"right",
                                        display:"block",
                                        width: "24px",
                                        height: "24px",
                                        
                                        lineHeight: "40px",
                                        fontSize: "14px",
                                        lineHeight: "25px"
                                        }}>
                                    {this.props.User.sub.charAt(0).toUpperCase()}
                                </div>
                                </div>
                            </a>
                            </div>  
                        <SearchUIComponent
                            mobile_or_tablet={this.props.mobile_or_tablet}
                            theme={this.props.theme}
                            go_to_link={this.props.go_to_link}
                            set_search_popup_showing={this.props.set_search_popup_showing}
                            history={this.props.history}
                            handleSearch={this.props.handleSearch}
                            set_mobile_search_input_popup_showing={this.props.set_mobile_search_input_popup_showing}
                            search={this.props.search}
                            mobile_search_input_popup_showing={this.props.mobile_search_input_popup_showing}
                            search_popup_showing={this.props.search_popup_showing}
                            search_expanded={this.props.search_expanded}
                            search_arrow_navigator={this.props.search_arrow_navigator}
                            set_search_item_selected_active_element={this.props.set_search_item_selected_active_element}
                            mobile_filter_showing={this.props.mobile_filter_showing}
                            >
                            
                        </SearchUIComponent>
                    </React.Fragment>
                )}
                                            


            {this.props.User && this.props.User != "Unauthorized"  &&  this.props.mobile_filter_showing == 0 && (
                    <React.Fragment>
                        <div style={{position: "relative", zIndex: 9999}}>
                        <div  className={document.location.href.split("/")[3].includes("register") 
                            ? "menu_item_right active" : "menu_item_right"}
                        style={{marginLeft: this.props.mobile_filter_showing == 0 && this.props.mobile_or_tablet == 1 ? "20px" : ""}}
                            
                                >
                            <NavLink
                                    to={"/logout?return_to=" + window.location.pathname}
                                    className="menu_href_class"
                                >
                                {/* <h4 style={{marginTop:"16px"}}>LOGOUT</h4> */}
                                <button
                                style={{
                                    position:"relative",
                                    float:"right",
                                    // marginRight: "25px",
                                    fontSize: "14px",
                                    lineHeight: "22px",
                                    marginTop: "10px",
                                    fontWeight: "500",
                                    paddingRight: this.props.mobile_or_tablet == 1 
                                        && this.props.mobile_filter_showing == 0 ? "0px" : "",
                                    paddingLeft: this.props.mobile_or_tablet == 1 
                                    && this.props.mobile_filter_showing == 0 ? "0px" : ""
                                    }}
                                    className="button_trans_background"
                                //   onClick={() => this.comment_cancel()}
                                    >
                                LOGOUT 
                                </button>
                            </NavLink>
                            </div>
                            <div className={document.location.href.split("/")[3].includes("profile") 
                            ? "menu_item_right active" : "menu_item_right"}
                            
                                >
                            <NavLink
                                    to={"/profile/settings"}
                                    className="menu_href_class"
                                    isActive={ () => document.location.href.split("/")[3].includes("profile") }
                                >
                                {/* <h4 style={{marginTop:"16px"}}>PROFILE</h4> */}
                                <button
                                style={{
                                    position:"relative",
                                    float:"right",
                                    // marginRight: "25px",
                                    fontSize: "14px",
                                    lineHeight: "22px",
                                    marginTop: "10px",
                                    fontWeight: "500",
                                    paddingRight: this.props.mobile_or_tablet == 1 
                                        && this.props.mobile_filter_showing == 0 ? "0px" : "",
                                    paddingLeft: this.props.mobile_or_tablet == 1 
                                    && this.props.mobile_filter_showing == 0 ? "0px" : ""
                                    
                                    }}
                                    className="button_trans_background"
                                //   onClick={() => this.comment_cancel()}
                                    >
                                PROFILE 
                                </button>
                            </NavLink>
                            
                                
                            </div>  
                            <SearchUIComponent
                                mobile_or_tablet={this.props.mobile_or_tablet}
                                theme={this.props.theme}
                                go_to_link={this.props.go_to_link}
                                history={this.props.history}
                                set_search_popup_showing={this.props.set_search_popup_showing}
                                handleSearch={this.props.handleSearch}
                                set_mobile_search_input_popup_showing={this.props.set_mobile_search_input_popup_showing}
                                search={this.props.search}
                                mobile_search_input_popup_showing={this.props.mobile_search_input_popup_showing}
                                search_popup_showing={this.props.search_popup_showing}
                                search_expanded={this.props.search_expanded}
                                search_arrow_navigator={this.props.search_arrow_navigator}
                                set_search_item_selected_active_element={this.props.set_search_item_selected_active_element}
                                mobile_filter_showing={this.props.mobile_filter_showing}
                                ></SearchUIComponent>
                        </div>
                    </React.Fragment>
                )}
                    {/* </div> */}
                </div>

                </div>
               </div> 
               
               </div>
             
             
             
             </div>
            
          );
    }
}
 
export default MenuComponent;