import React, { Component } from "react";
import { title_to_url, filterOptions, sortSuggestions } from "./../../global_arrays";
import configData from "../../../config.json";
import TextField from "@material-ui/core/TextField";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { withRouter } from 'react-router-dom';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSpinner, faSearch } from "@fortawesome/free-solid-svg-icons";
import { lightTheme } from './../../theme';
import {  toast_error, toast_info } from "./../../global_arrays";

import Chip from '@material-ui/core/Chip';


library.add(faSpinner, faSearch);



class GetTagAsyncComponent extends Component {

  
  state = {
   selected_tags: [],
   
    showSpinner: 0,    
    showNoTagsError: 0,
  };

  
  //notes:
  //there is a bug and when the user clicks a tag from the left menu(tags_Left_menu) 
  //while being in tags page, then this component doesn't rerender 
  //and there is an incosistency between selected tags in tags_left_menu and tags main component
  //this is why there are some checks ( comes_from_leftmenu_search ) and if so, it is using props tags_selected
  //so it can render from the parent

  componentWillUnmount(){
    
    // this.setState({showNoTagsError: 0})
    
  }

  async componentDidMount() {
  
    this.setState({showNoTagsError: 0})
    

    // push values to autocomplete
    const urlParams = new URLSearchParams(window.location.search);
    var myParam = urlParams.getAll("tag_id");

   
    if (myParam.length == 0 && window.location.pathname.split("/")[1] == "tag") {
      myParam = [window.location.pathname.split("/")[2]]
    }
    if(myParam.length > 0){
      this.setState({pixels_to_remove_from_search_button: 1})
    }else{
      this.setState({pixels_to_remove_from_search_button: 0})
    }
   
    var i = 0,
      j = 0;
    var temp_selected_tags = [];
    for (i = 0; i < myParam.length; i++) {
      for (j = 0; j < this.props.Ptags.length; j++) {
        if (this.props.Ptags[j].id == myParam[i]) {          
          temp_selected_tags.push({
            id: myParam[i],
            tag: this.props.Ptags[j].tag,
          });
        }
      }
    }
    await this.setState({ selected_tags: temp_selected_tags });
    console.log("done")
    console.log(this.props.selected_tags)
    
    //end
  }
  search = async (event) => {
    
    if(this.props.isSide != 1){
      this.props.set_tags_async_error_no_tag_selected(0)
    }
    const start = Date.now();   
    if(this.props.comes_from_leftmenu_search == 1 && this.props.selected_tags.length == 0  ){
      this.setState({showNoTagsError: 1})
      if(this.props.show_wide == 1)
        this.props.set_tags_async_error_no_tag_selected(1)
      return
    }
    if(this.props.isSide == 1 && this.props.selected_tags.length == 0 && this.props.comes_from_leftmenu_search == 0){
      this.setState({showNoTagsError: 1})
      if(this.props.show_wide == 1)
        this.props.set_tags_async_error_no_tag_selected(1)
      return
    }
    
    var esc = encodeURIComponent;
    var query = ""
    if(this.props.comes_from_leftmenu_search == 1){
       query = this.props.selected_tags
        .map((k) => "tag_id" + "=" + esc(k.id))
        .join("&");
      }else{
        query = this.props.selected_tags
        .map((k) => "tag_id" + "=" + esc(k.id))
        .join("&");
      }
    if(this.props.isSide != 1){  
      this.props.set_showTagsAsyncLoadingWhenSearchingVideo(1)
    }
    
   
    if (this.props.comes_from_leftmenu_search == 1 && this.props.selected_tags.length == 1  ) {
      
        var uri = "/tag/" +
        this.props.selected_tags[0].id +
        "/" +
        title_to_url(this.props.selected_tags[0].tag) +
        "/1"
        //this is required because for some reason, in one tag selected in tags page,
        //if click again with the same tag, it doens't trigger componentdidmount in parent
        //so i do it manually with this condition        
        if(uri == '/'+window.location.pathname.substring(1)){
         
          this.props.go_to_link(event,uri,-1,1)
        }else{
        this.props.go_to_link(event,uri)
      }
    }
    else if(this.props.selected_tags.length == 1 && this.props.comes_from_leftmenu_search == 0){
      var uri = "/tag/" +
        this.props.selected_tags[0].id +
        "/" +
        title_to_url(this.props.selected_tags[0].tag) +
        "/1"
        this.props.go_to_link(event,uri)
        
        if(this.props.hide_menu_flag == 1){
          //alert("hide menu")
          this.props.hide_menu()
        }
    } 
    else {
      if(this.props.hide_menu_flag == 1){
        //alert("hide menu")
        this.props.hide_menu()
      }
        if(this.props.comes_from_tags == 1){
          this.props.hide_filters(1,1)
        }
        
        
                
        
        var uri = "/tags/1/?" + query;
        this.props.go_to_link(event,uri)



    }

    this.setState({ currentPage: 1 });
    setTimeout(async() => {
      this.setState({ showSpinner: 0 });
    }, configData.WAITING_MILIS - (Date.now() - start));
   
  };

  deleteInput = async(value) => {    
    await this.setState(prevState => ({
      selected_tags: prevState.selected_tags.filter(tag => tag.tag !== value)
    }));
    if (this.props.selected_tags.length == 0) {
      try {
        this.props.async_search_is_empty();
      } catch (e) {}
    }
  }

  inputChange = async(value) => {    
    if(value.length > 5){
      toast_error("You can't select more than 5 tags")
      return
    }
    await this.setState({ selected_tags: value });    
    if (value.length == 0) {
      try {
        this.props.async_search_is_empty();
      } catch (e) {}
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    
    if(this.props.selected_tags != nextProps.selected_tags && this.props.comes_from_tags == 1){
      this.componentDidMount()
    }


    if(this.props.Ptags != nextProps.Ptags || 
      this.props.selected_tags != nextProps.selected_tags ||
      this.props.selected_tags != nextState.selected_tags ||     
      this.state.showSpinner != nextState.showSpinner ||
      this.props.theme != nextProps.theme || 
      this.props.id != nextProps.id ||
      this.state.showNoTagsError != nextState.showNoTagsError ||
      this.props.tags_async_error_no_tag_selected != nextProps.tags_async_error_no_tag_selected ||
      this.props.comes_from_leftmenu_search != nextProps.comes_from_leftmenu_search ||
      this.props.showTagsAsyncLoadingWhenSearchingVideo != nextProps.showTagsAsyncLoadingWhenSearchingVideo ||
      this.props.calc_width != nextProps.calc_width ||
      this.props.selected_tags != nextProps.selected_tags ||
      this.props.mobile_or_tablet != nextProps.mobile_or_tablet ||
      this.props.showingVideos != nextProps.showingVideos ||
      this.props.mobile_filter_showing != nextProps.mobile_filter_showing
      ){
      return true
    }    
    return false
  }
  
   

  render() {
    // Define the custom styles


    const CustomDeleteIcon = ({ photoUrl, option }) => (
      <div style={{        
        backgroundColor: this.props.theme == lightTheme ? "#EDEDED" : "#2a2a30",		
        width: "15px", height: '24px',
        paddingTop: navigator.userAgent.indexOf("Firefox") != -1 ? "2px": "2px",
        marginTop: navigator.userAgent.indexOf("Firefox") != -1 ?  "-2px" : "0px",borderRadius: '4px',}}

          >          
             {/* <a href="javascript:void(0)" onClick={() =>  this.props.comes_from_leftmenu_search == 1 ? 
            this.props.deleteInput(option) : this.deleteInput(option)} */}
             <a href="javascript:void(0)" onClick={() =>  this.props.deleteInput(option)}
                style={{textDecoration: "none", display: "inline-block",marginTop: "2px"}}
                className="icon_close_x_div_wrapper_href"
                
                title="Close"
              >
                  <div
                    className="icon_close_x_div_wrapper"
                    style={{marginLeft: "-10px", marginTop: "2px", backgroundColor: 'purple !important', width: '12px', height: '12px'}}>             
                    </div>
                  </a>
      </div>
    );
    
    return (
      <div style={{maxWidth: "2341px",
          width: "100%"
        
            }}>
                   
        <div style={{
              position:"relative",
              float:"left",
              width: 
             //"calc(100% - 52px)",
             this.props.showingVideos == 1 ? 
                this.props.mobile_filter_showing == 0 && this.props.mobile_or_tablet == 1 ? "calc(100% - 100px)" 
              : "calc(100% - 90px)" : 
              this.props.mobile_or_tablet == 0 ? "calc(100% - 52px)" : 
                this.props.mobile_filter_showing == 0 ? "calc(100% - 100px)" : "calc(100% - 90px)",
              marginTop: this.props.show_wide == 1 ? "20px" : "15px",              
              marginLeft: this.props.show_wide == 1 ? 
              this.props.mobile_or_tablet == 1 && this.props.mobile_filter_showing == 0 && this.props.showingVideos == 0 ? "30px" : 
              "20px" 
              : "0px",
              display: 'flex'              
              }}> 
              <div style={{position:"relative",float:"left", width: "100%",}}>
                
         
                <Autocomplete                
                        
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={option.tag}
                        label={option.tag}
                        deleteIcon={
                          <CustomDeleteIcon
                            option={option.tag}
                          />
                        
                        } 
                        style={{
                          borderRadius: '4px',
                          height: "24px",
                          marginLeft: "8px",
                          paddingTop: navigator.userAgent.indexOf("Firefox") != -1 ?  "2px" : "",
                          lineHeight:"24px",
                          //lineHeight:'54px',
                          backgroundColor: this.props.theme == lightTheme ? "#EDEDED" : "#2a2a30",
                          color: this.props.theme == lightTheme ? "#02000D" : "#EDEDED"
                          // Add any other inline styling rules you want to modify
                        }}   
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  multiple
                  id="tags-outlined"
                  options={this.props.Ptags}
                  
                  getOptionLabel={(option) => 
                    
                    option.tag 
                  
                  }
                  filterOptions={filterOptions}
                  sortSuggestions={sortSuggestions}
                  //value={this.props.use_state_instead_of_props_for_selected == 1 ? this.state.selected_tags : this.props.selected_tags}                  
                  value={this.props.selected_tags}
                  renderOption={(option) => (
                    <React.Fragment>
                      <span style={{lineHeight:"24px",}}>
                        {option.tag}
                        {option.has_photo != null ? (
                          <img
                            style={{
                              marginLeft: "4px",
                              marginBottom: "2px",
                            }}
                            src={configData.CDN_ENDPOINT + "flags/" + option.has_photo.replace("flags/", "")+".svg"}
                            width="18"
                            className="flag_wrapper_shadow_class"
                          />
                        ) : (
                          ""
                        )}
                      
                      </span>
                    </React.Fragment>
                  )}
                  // onChange={(event, value) => this.props.comes_from_leftmenu_search == 1 ? 
                  //   this.inputChange(value) : this.inputChange(value)} //console.log(value)
                   onChange={(event, value) =>  this.props.inputChange(value)} //console.log(value)
                  filterSelectedOptions
                  renderInput={(params) => (
                    <React.Fragment>
                      
                      <TextField
                        {...params}
                        variant="standard" 
                        size="small"
                        
                        
                        
                        placeholder={this.props.selected_tags == 0 ? 
                          this.props.show_wide == 0 ? "TAGS(UP TO 5)" : "SEARCH UP TO 5 TAGS" 
                          : ""}
                        style={{
                          border: this.props.theme == lightTheme ? "1px solid #e0e0e0" : "1px solid #2A2A30",
                          outline: "none",
                          boxShadow: "none",                        

                          borderRadius: this.props.show_wide == 0 ? "4px" :"4px 0 0 4px",
                          textAlign: "center",
                          width: this.props.show_wide == 1 ? "100%" : "213px",
                      }}
                      InputProps={{ ...params.InputProps, 
                        style: { 
                        fontSize:"16px", fontWeight:"400",                      
                        paddingLeft: this.props.show_wide == 0 ? 
                          this.props.selected_tags == 0 ? "18px" : "0px" : //when on side 
                          this.props.selected_tags == 0 ? "15px" : "0px",
                        
                        paddingTop: this.props.show_wide == 0 ? 
                          this.props.selected_tags == 0 ? "7px" : "1.0px" : //when on side 
                          this.props.selected_tags == 0 ? "7px" : "2.5px",                        
                        minHeight: this.props.isSide != 1 ? "38px" : "35px",//"39.0px",
                        color: this.props.theme == lightTheme ? "black" : "white",                                                     
                      } }}
                          
                      />
                    </React.Fragment>
                  )}
                />
              </div>
            </div>
              <div style={{marginTop: "1.5px" 
              
              ,postiion: this.props.show_wide == 1 ? "relative" : "", float: this.props.show_wide == 1 ?  "right" : ""
                }}
                >

                  <button 
                      className={this.props.show_wide == 1 ? "button_purple_background semi_rounded_border" : "button_purple_background" }
                      onClick={(event) => this.search(event)} 
                      style={{
                        marginTop: this.props.show_wide == 1 ? "18px": "10px ",
                        
                        width: this.props.show_wide == 1 ? "50px" : "213px",
                        height: this.props.show_wide == 1  ? this.props.theme == lightTheme ? "40px" : "40px" : "",
                        cursor: "pointer",
                        marginRight: this.props.show_wide == 1 && this.props.mobile_or_tablet == 0 && this.props.showingVideos == 0 ? 
                         "-18px" 
                         : 
              
                          this.props.mobile_filter_showing == 0 && this.props.mobile_or_tablet == 1 && this.props.showingVideos == 1 
                          ? "30px" :
                          "20px", //20 
                         
                         
                        lineHeight: "20px",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign:"center",
                      position:"relative",
                       
                        
                      }}
                      
                      
                  >
                    <FontAwesomeIcon
                          style={{
                            display: this.props.showTagsAsyncLoadingWhenSearchingVideo != 1 ? "none" : "inline-block",
                            marginTop: "2px",
                            marginLeft: "-1px"
                          }}
                          icon="circle-notch"
                          size={this.props.show_wide == 1 ? "lg" : "1x"}
                        spin
                        />
                  <FontAwesomeIcon
                    className="fa-sync"
                    icon="search"
                    size={this.props.show_wide == 1 ? "lg" : "1x"}
                    style={{
                      display: this.props.showTagsAsyncLoadingWhenSearchingVideo != 1 ? "inline-block" : "none",
                      marginTop: this.props.show_wide == 1 ? "2px" : "3.0px",
                      marginLeft:  this.props.show_wide == 1 ? "-2px" : "48px",       
                      position:"relative",float:"left"  ,            
                      
                    }}                            
                  />
                  {this.props.show_wide == 1 ? "" : 
                  <div style={{marginTop: "0px", position:"relative",float:"left" ,marginLeft: "8px"   }}>
                       SEARCH
                  </div>
                  }
                </button>
            </div>  
       
      <div>

        
      <div className="alert alert-danger alert-dismissible fade show" role="alert"
          style={{display: this.state.showNoTagsError == 1 && this.props.show_wide != 1 ? "" : "none",
                  position:"block",
                  marginTop: "10px",
                  marginBottom: "-5px",
                  
                  width: "213px"}}>
                  
            You haven't selected any tags
            <button type="button" className="close" onClick={() => this.setState({showNoTagsError: 0})}  aria-label="Close">
              <span aria-hidden="true">&times;</span> 
            </button>
          </div>
          
          <div className="alert alert-danger alert-dismissible fade show" role="alert"
          style={{display: this.state.showNoTagsError == 1 && this.props.show_wide == 1 ? "flex" : "none",
                  position:"relative",
                  top: "8px",
                  left: "20px", 
                  width: "calc(100% - 40px)"}}>
                  
            You haven't selected any tags
            <button type="button" className="close" onClick={() => 
              this.setState({showNoTagsError: 0})
              }  aria-label="Close">
              <span aria-hidden="true">&times;</span> 
            </button>
          </div>

          </div>
        
        {this.state.show_wide == 1 ? 
        <div><br></br></div>
         : 
         <div style={{height: "8px"}}></div>}
         
      </div>
    );
  }
}

export default withRouter(GetTagAsyncComponent);