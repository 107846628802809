import React, { Component } from 'react';
class QualityComponent extends Component {
    state = { 

     }
    
     shouldComponentUpdate(nextProps, nextState) {

        if(
            this.props.quality_all != nextProps.quality_all ||
            this.props.quality_hd != nextProps.quality_hd ||
            this.props.quality_fourk != nextProps.quality_fourk ||
            this.props.quality_vr != nextProps.quality_vr ||
            this.props.quality_non_hd != nextProps.quality_non_hd 
             
            ) {
                //console.log("yes  - FUCKIN RENDER"+this.props.videos)
                return true
        }
        // console.log("no - DO NOT RENDER")
        return false
    }

    render() { 
        return (
            <React.Fragment>
            {this.props.is_horizontal == 1  ? "" :
                    <h4 className="sub12 pornstar_channel_in_view_video_href_class">&nbsp;&nbsp;QUALITY:</h4>
                }
                <div 
                    className={this.props.quality_all == 1 ? "qualityBox active tag12" : "qualityBox tag12"}
                    onClick={() => this.props.quality_clicked(1)}
                >All</div>
                <div 
                    className={this.props.quality_hd == 1 ? 
                        this.props.is_horizontal == 1 ? "qualityBox_horizontal qualityBox active tag12"  : "qualityBox active tag12"
                        : 
                        this.props.is_horizontal == 1 ? " qualityBox qualityBox_horizontal tag12" : " qualityBox tag12" }
                    onClick={() => this.props.quality_clicked(2)
                    
                    }
                    onMouseOver="this.style.cursor='pointer'"
                    >HD</div>
                <div 
                    className={this.props.quality_fourk == 1 ? 
                        this.props.is_horizontal == 1 ? "qualityBox_horizontal qualityBox active tag12"  : "qualityBox active tag12"
                        : 
                        this.props.is_horizontal == 1 ? " qualityBox qualityBox_horizontal tag12" : " qualityBox tag12" }
                    onClick={() => this.props.quality_clicked(3)}
                    >4K</div>
                <div 
                    className={this.props.quality_vr == 1 ? 
                        this.props.is_horizontal == 1 ? "qualityBox_horizontal qualityBox active tag12"  : "qualityBox active tag12"
                        : 
                        this.props.is_horizontal == 1 ? " qualityBox qualityBox_horizontal tag12" : " qualityBox tag12" }
                    onClick={() => this.props.quality_clicked(4)}
                    >VR</div>
                <div 
                    className={this.props.quality_non_hd == 1 ? 
                        this.props.is_horizontal == 1 ? "qualityBox_horizontal qualityBox active tag12"  : "qualityBox active tag12"
                        : 
                        this.props.is_horizontal == 1 ? " qualityBox qualityBox_horizontal tag12" : " qualityBox tag12" }
                    style={{width: "77px"}}
                    onClick={() => this.props.quality_clicked(5)}
                    >NON-HD</div>
            </React.Fragment>
          );
    }
}
 
export default QualityComponent;