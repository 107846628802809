import React, { Component } from 'react';
class OrderHorizontal extends Component {
    state = {  }
    shouldComponentUpdate(nextProps, nextState) {
    
        if(
            
            this.props.sort_best != nextProps.sort_best ||
            this.props.sort_trending != nextProps.sort_trending || 
            this.props.sort_new != nextProps.sort_new ||
            this.props.sort_period != nextProps.sort_period ||
            this.props.best_parameter != nextProps.best_parameter ||
            this.props.show_overlay != nextProps.show_overlay
            
          ) {
            // console.log("yes  - FUCKIN RENDER")
             return true
        }
       //console.log("no - DO NOT RENDER")
        return false
      }
    render() { 
       // console.log("w na sou...")
        return (
        <React.Fragment>
            <div style={{position:"relative",float:"left", marginLeft: "-5px", marginTop: "-2px"}} 
               
                
                >
               
                <a href="javascript:void(0);"
                    onClick={() => this.props.change_sort("newest")}
                    >
                    <div style={{position:"relative", float:"left", width: "85px"}}
                        className={this.props.sort_new == 1 ? "qualityBox qualityBox_horizontal tag12 active" 
                        : "qualityBox_horizontal qualityBox tag12"}>
                        NEWEST
                    </div>
                </a>

                <a href="javascript:void(0);"
                    onClick={() => this.props.change_sort("best")}>
                    <div style={{position:"relative", float:"left",marginLeft: "15px", width: "115px"}}
                        className={this.props.sort_best == 1 ? "qualityBox qualityBox_horizontal tag12 active" 
                        : "qualityBox qualityBox_horizontal tag12"}>
                        MOST VIEWED
                    </div>
                </a>
                <a href="javascript:void(0);"
                    onClick={() => this.props.change_sort("trending")}
                    >
                    <div style={{position:"relative", float:"left",marginLeft: "15px", width: "85px"}}
                        className={this.props.sort_trending == 1 ? "qualityBox qualityBox_horizontal tag12 active" 
                        : "qualityBox qualityBox_horizontal tag12"}>                    
                        TRENDING
                    </div>
                </a>
            </div>
            <div style={{position:"relative",float:"left"}}>
                
            </div>
        </React.Fragment>
            
         );
    }
}
 
export default OrderHorizontal;