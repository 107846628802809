import React, { Component } from "react";
import configData from "../../../config.json";
import { title_to_url } from "./../../global_arrays";
import jwtDecode from "jwt-decode";
class PrintTagsComponent extends Component {
  print_tag = () => {
    //if(parseInt(jwtDecode(localStorage.getItem("token")).is_admin ) == 1){
    var x = this.props.PTag.tag;
    try{
      if(parseInt(jwtDecode(localStorage.getItem("token")).is_admin ) == 1){
        return x+"-"+this.props.PTag.weight;
      }else{
        return x
      }
    }catch(e){
      return x
    }
  };

  state = {};
  render() {
    return (
      <div
      className="tagswrapper"
        style={{
          border: "0px solid green",
          display: this.props.hide == 0 ? "inline-block" : "none",
          height: "35px",
        }}
        
      >
        <a href={"/tag/"+this.props.PTag.id+"/"+title_to_url(this.props.PTag.tag)}
              onClick={async(event) => { 
                  //await this.props.set_showingVideos(1); 
                  this.props.go_to_link(event, "/tag/"+this.props.PTag.id+"/"+title_to_url(this.props.PTag.tag),0);
                  
                   window.scrollTo(0,0)
                }
              }
              
            >
            <h4 
               
                className=
                {this.props.show_blur == 1 ? "channels_and_pornstars_name_href body16 blur" 
                : "channels_and_pornstars_name_href body16"}>{this.props.PTag ? this.print_tag() : ""}
                
                {this.props.PTag && this.props.PTag.has_photo != null ? (
                <img 
                  style={{
                    marginLeft: "4px",
                    marginBottom: "2px", 
                    display: "inline-block"               
                  }}
                  src={configData.CDN_ENDPOINT + "flags/"+this.props.PTag.has_photo.replace("flags/", "")+".svg"}
                  width="25"
                  className="flag_wrapper_shadow_class"
              />
            ) : (
              ""
            )}
          </h4>
        </a>
        
      </div>
    );
  }
}

export default PrintTagsComponent;
