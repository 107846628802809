import React, { Component } from 'react';
class ErrorComponent extends Component {
    state = {  }

    shouldComponentUpdate(nextProps, nextState) {
        //alert("panagia")
        
        if(this.props.showError != nextProps.showError
          ) {
             return true
        }
        return false
      }

    render() { 
        return (
        
        <div 
            className="alert alert-danger" 
            role="alert"
            style={{display: this.props.showError == 0 ? "none" : "",
                marginTop: "20px",
                width: "calc(100% - 40px)",
                marginLeft: "20px"}}
            >
            {this.props.code && this.props.code == "404" ?
                this.props.entity+" was not found" 
               :
              ""
        }
        {this.props.code && this.props.code == "401" ?
                "You are not authorized - please login and try again" 
               :
              ""
        } 
        {! this.props.code  || this.props.code == 1?
                "An unhandled error occured - please try again later."
               :
              ""
        } 
        </div> 
            
        );
    }
}
 
export default ErrorComponent;